import {forwardRef} from 'react'

const ChartWrapper = forwardRef(function ChartWrapper(props, chartRef) {
  const {className, title, subtitle} = props

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1 text-gray-600'>{title}</span>
          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pb-0 pt-0'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_2_chart' className='h-[180px]'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
})

export default ChartWrapper
