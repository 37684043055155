import {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Precon from '../../../store/ducks/precon.duck'
import BCProjects from './BCProjects'
import BCSyncButton from './BCSyncButton'
import Grid from '@mui/material/Grid'
import Button from 'react-bootstrap/Button'
import LaunchIcon from '@mui/icons-material/Launch'
import {Link} from 'react-router-dom'
import {ModalLayoutI} from '../../../components/Modals/ModalLayouts'
import {FooterLayout} from '../../../components/Modals/BasicModalWrapper'
import {useAppModal} from '../../../context/GlobalModalContext'

export default function BidBuildingConnectedSync() {
  const dispatch = useDispatch()
  const bidQuote = useSelector((state) => state.precon.BidQuote)

  const {openModal} = useAppModal()

  const handlePressSearchBCProjects = useCallback(() => {
    openModal(BidBuildingConnectedProjectModalKey)
    dispatch(Precon.actions.fetchBCProjects())
  }, [dispatch, openModal])

  return (
    <div className='pl-5 pb-5'>
      <div>
        <img src='/media/company-logos/BCLogo.png' height={40} alt='BuildingConnected Logo' />
      </div>
      <Grid container direction='row' spacing={4}>
        <Grid item md={8}>
          <div className='highlight-text mt-[30px] mb-5'>
            {bidQuote && bidQuote.bcProjectID !== '' && bidQuote.bcProjectID !== 0 ? (
              <>
                <span>
                  Mapped to: {bidQuote.bcProjectNumber} {`${bidQuote.bcProjectNumber !== '' ? ' - ' : ''}`} {bidQuote.bcProjectName}{' '}
                </span>
                <a href={`https://app.buildingconnected.com/projects/${bidQuote.bcProjectID}/bid-packages`} target='_BCFromHPM'>
                  <LaunchIcon className='inline-icon' fontSize='small' /> View
                </a>
              </>
            ) : (
              <span>No BuildingConnected Project Selected.</span>
            )}
          </div>
          <Grid item md={12}>
            <Grid container direction='row' spacing={4} justifyContent='space-between'>
              <Grid item>
                <Button size='sm' variant={bidQuote && bidQuote.bcProjectID !== '' && bidQuote.bcProjectID !== 0 ? 'secondary' : 'primary'} onClick={handlePressSearchBCProjects}>
                  Search BC Projects
                </Button>
                <BCSyncButton />
                <Link to='/bidsheet'>
                  <Button className='ml-3' size='sm' variant='primary'>
                    &gt; Summary
                  </Button>
                </Link>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export const BidBuildingConnectedProjectModalKey = '@Modal_BidBuildingConnectedProject'
export const BidBuildingConnectedProjectModal = () => {
  return (
    <ModalLayoutI modalKey={BidBuildingConnectedProjectModalKey} title='Select Building Connected Project' className=' ' footerLayout={FooterLayout.GridThreeButton} showSaveButton={false}>
      <BCProjects />
    </ModalLayoutI>
  )
}
