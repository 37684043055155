import {applyMiddleware, compose, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {persistStore} from 'redux-persist'
// import LogRocket from 'logrocket'

import {rootReducer, rootSaga} from './rootDuck'

const actionSanitizer = (action) => {
  switch (action.type) {
    case 'SET_REFERENCE':
      return {...action, payload: {...action.payload, grid: '<<REFERENCE>>'}}
    case 'SET_COLLECTION':
      return {...action, collection: '<<COLLECTION>>'}
    case 'SET_GRID':
      return {...action, payload: '<<GRID>>'}
    default:
      return action
  }
}

const stateSanitizer = (state) => {
  return {
    ...state,
    project: {...state.project, ProjectsGrid: '<<ProjectsGrid>>', ManpowerGrid: '<<ManpowerGrid>>', ManpowerGridWeeks: '<<ManpowerGridWeeks>>'},
    reference: {...state.reference, Employees: '<<Employees>>'}
  }
}

const composeEnhancers =
  (process.env.REACT_APP_ENV === 'dev' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionSanitizer,
      stateSanitizer
    })) ||
  compose

const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware))) //, LogRocket.reduxMiddleware()

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>

export default store
