import {useState, useEffect, useCallback, useRef, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Precon from '../store/ducks/precon.duck'
import * as Reference from '../store/ducks/reference.duck'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Popover from '@mui/material/Popover'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro'
import format from 'date-fns/format'
import {saveAs} from 'file-saver'
import {Buffer} from 'buffer'
import UploadFileDropzone from './UploadFileDropzone'

const FilesContainerId = 'files-container-elem'
export default function Files({projectID, bidTradeID, bidTradeQuoteID}) {
  /* Props: 
        bidTradeID: (optional) called from Trades page with a bidTradeID
        projectID: 
        bidTradeQuoteID : 
    */

  const dispatch = useDispatch()
  const files = useSelector((state) => state.precon.Files)
  const fileDownload = useSelector((state) => state.precon.File)
  const postFileStatus = useSelector((state) => state.reference.Status.postFileStatus)

  const [downloadStatus, setDownloadStatus] = useState('Not Started')
  const [downloadFileName, setDownloadFileName] = useState('')
  const [newFiles, setNewFiles] = useState([])

  const uploadFileCloseHandlerRef = useRef()
  const initialiseUploadFileHandler = (target) => {
    uploadFileCloseHandlerRef.current = target
  }

  useEffect(() => {
    dispatch(Precon.actions.fetchFiles({projectID: projectID, bidTradeID: bidTradeID, bidTradeQuoteID: bidTradeQuoteID}))
  }, [dispatch, projectID, bidTradeID, bidTradeQuoteID])

  useEffect(() => {
    if (postFileStatus && postFileStatus === 'Complete') {
      !!uploadFileCloseHandlerRef?.current && uploadFileCloseHandlerRef.current()
      dispatch(Reference.actions.setRefStatus({statusName: 'postFileStatus', statusValue: 'Not Started'}))
    }
  }, [dispatch, postFileStatus])

  const updateFileType = useCallback(
    (fileId, newFileType) => {
      dispatch(Precon.actions.updateFileType({fileID: fileId, newType: newFileType, projectID: projectID, bidTradeID: bidTradeID, bidTradeQuoteID: bidTradeQuoteID}))
    },
    [bidTradeID, bidTradeQuoteID, dispatch, projectID]
  )

  function handleFileFieldChange(propertyName, files) {
    console.log('PropertyName')
    console.log(propertyName)
    console.log('Files')
    console.log(files)
    setNewFiles(files)
    // setNewFile(event.currentTarget.files[0])
  }

  function addFile() {
    // Move from useState to FormData
    const formData = new FormData()
    // Add fields
    formData.append('projectID', projectID)
    formData.append('bidTradeID', bidTradeID)
    formData.append('bidTradeQuoteID', bidTradeQuoteID)
    // Add files
    for (const fileUploadItem of newFiles) {
      formData.append('newfiles', fileUploadItem)
    }
    // Ok Time to Dispatch
    process.env.REACT_APP_DEBUG && console.log(...formData)
    dispatch(Reference.actions.setRefStatus({statusName: 'postFileStatus', statusValue: 'Saving...'}))
    dispatch(Precon.actions.postFile({formData: formData, projectID: projectID, bidTradeID: bidTradeID, bidTradeQuoteID: bidTradeQuoteID}))
    setNewFiles([])
    // modal is closed by update to the status via redux
  }

  const downloadFile = useCallback(
    (file) => {
      process.env.REACT_APP_DEBUG && console.log('Download File')
      if (downloadStatus === 'Not Started') {
        dispatch(Precon.actions.fetchFile({fileID: file.id}))
        setDownloadFileName(file.filename)
        setDownloadStatus('Fetching')
      }
    },
    [dispatch, downloadStatus]
  )

  const updateFileExportFlag = useCallback(
    (file) => {
      process.env.REACT_APP_DEBUG && console.log('Update File Export Flag')
      dispatch(Precon.actions.putFileExportFlag({fileID: file.id, forExport: !file.forExport, projectID: projectID, bidTradeID: bidTradeID, bidTradeQuoteID: bidTradeQuoteID}))
    },
    [bidTradeID, bidTradeQuoteID, dispatch, projectID]
  )

  // grab the blob from state and tell the browser to download
  const downloadBlobFile = useCallback(() => {
    if (fileDownload && fileDownload !== '') {
      const file = new Blob([Buffer.from(fileDownload, 'base64')])
      saveAs(file, downloadFileName)
    }
  }, [downloadFileName, fileDownload])

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  useEffect(() => {
    if (downloadStatus === 'Fetching' && fileDownload && fileDownload !== '') {
      downloadBlobFile()
      setDownloadStatus('Not Started')
    }
  }, [downloadStatus, downloadBlobFile, fileDownload])

  const deleteFile = useCallback(
    (file) => {
      dispatch(Precon.actions.deleteFile({fileID: file.id, projectID: projectID, bidTradeID: bidTradeID, bidTradeQuoteID: bidTradeQuoteID}))
    },
    [bidTradeID, bidTradeQuoteID, dispatch, projectID]
  )

  const columns = useMemo(
    () => [
      {field: 'id', headerName: 'id', hide: true},
      {
        field: 'filename',
        headerName: 'File',
        width: 550,
        renderCell: (params) => (
          <span onClick={() => downloadFile(files.find((file) => file.id === params.row.id))} className='cell-link'>
            {params.row.filename}
          </span>
        )
      },
      {
        field: 'fileType',
        headerName: 'Type',
        width: 140
      },
      {
        field: 'forExport',
        headerName: 'Include in PDF',
        width: 120,
        renderCell: (params) =>
          params.row.fileType !== 'Export Template' ? (
            <span onClick={() => updateFileExportFlag(files.find((file) => file.id === params.row.id))} className='cell-link'>
              {params.row.forExport === true ? 'Yes' : 'No'}
            </span>
          ) : (
            ''
          )
      },
      {
        field: 'createdOn',
        type: 'date',
        headerName: 'Date Added',
        width: 110,
        renderCell: (params) => format(new Date(params.value ? params.value : null), 'dd-MM-yyyy'),
        valueFormatter: (params) => format(new Date(params.value ? params.value : null), 'dd-MM-yyyy')
      },
      {field: 'createdBy', headerName: 'Uploaded By', width: 170},
      {
        field: 'none',
        headerName: 'Actions',
        width: 160,
        renderCell: (params) => {
          const targetFile = files.find((file) => {
            if (file?.id && params?.row?.id) {
              return file.id === params.row.id
            }
            return false
          })
          return <FileTypeButton onDelete={() => deleteFile(targetFile)} onUpdate={updateFileType} fileId={targetFile.id} initialFileType={targetFile.fileType} />
        }
      }
    ],
    [files, downloadFile, updateFileExportFlag, deleteFile, updateFileType]
  )

  return (
    <div id={FilesContainerId}>
      <UploadFileButton handleFileFieldChange={handleFileFieldChange} newFiles={newFiles} addFile={addFile} postFileStatus={postFileStatus} initialiseUploadFileHandler={initialiseUploadFileHandler} />

      <Paper>
        <div className='h-[300px]'>
          <DataGridPro
            rows={files}
            columns={columns}
            checkboxSelection={false}
            rowHeight={38}
            pagination={false}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false
                }
              }
            }}
          />
        </div>
      </Paper>
    </div>
  )
}

const UploadFileButtonId = 'upload-file-button-popover'
const UploadFileButton = ({handleFileFieldChange, newFiles, addFile, postFileStatus, initialiseUploadFileHandler}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    initialiseUploadFileHandler(handleClose)
  }, [initialiseUploadFileHandler])

  const open = Boolean(anchorEl)
  const id = open ? UploadFileButtonId : undefined
  return (
    <div className='mb-[6px] text-right'>
      <Button aria-describedby={id} size='sm' className='ml-3' onClick={handleClick}>
        Upload File
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <UploadFileDropzone onDrop={(acceptedFiles) => handleFileFieldChange('newfile', acceptedFiles)} files={newFiles} onSuccess={addFile} onCancel={handleClose} postFileStatus={postFileStatus} />
      </Popover>
    </div>
  )
}

const FileTypeButtonButtonId = 'file-type-button-popover'
const FileTypeButton = ({onDelete, onUpdate, fileId, initialFileType}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [fileType, setFileType] = useState(initialFileType)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUpdate = () => {
    onUpdate(fileId, fileType)
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? FileTypeButtonButtonId : undefined
  return (
    <>
      <Button variant='secondary' size='sm' onClick={handleClick} className='text-black-primary font-medium border-0 bg-gray-light mr-[14px]'>
        Type
      </Button>
      <Button variant='secondary' size='sm' onClick={onDelete} className='text-black-primary font-medium border-0 bg-gray-light mr-[14px]'>
        Delete
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        container={() => document.getElementById(FilesContainerId)}
      >
        <div className='p-3'>
          <Col md='12'>
            <h3>Change File Type</h3>
          </Col>
          <hr />
          <Col md='12'>
            <Form.Group className='min-w-[250px]'>
              <Form.Control as='select' value={fileType} name='FileType' onChange={(event) => setFileType(event.target.value)} className='formcontrol-edit'>
                <option value=''>Not Selected</option>
                <option value='Export Template'>Export Template</option>
                <option value='Other'>Other</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <hr />
          <Grid container justifyContent='space-between'>
            <Grid item></Grid>
            <Grid item>
              <Button variant='success' className='mr-[10px]' onClick={handleUpdate}>
                Update
              </Button>
              <Button variant='secondary' onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  )
}
