import {useMsal} from '@azure/msal-react'
import {loginRequest} from './authProvider'
import {useIsAuthenticated} from '@azure/msal-react'
import Button from 'react-bootstrap/Button'

function handleLogin(instance: any) {
  instance.loginPopup(loginRequest).catch((error: any) => {
    console.error(error)
  })
}

export const SignInButton = () => {
  const {instance} = useMsal()
  return (
    <Button variant='primary' className='ml-auto' onClick={() => handleLogin(instance)}>
      Sign in with Azure AD
    </Button>
  )
}

export function Login() {
  const isAuthenticated = useIsAuthenticated()
  process.env.REACT_APP_DEBUG && console.log(`In Login. isAuthenticated: ${isAuthenticated}`)

  return (
    <>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Log in to HITT Pricing Model</h1>
      </div>
      <div className='text-center'>
        <SignInButton />
      </div>
    </>
  )
}
