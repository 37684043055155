import styled from '@emotion/styled'
import Select from '@mui/material/Select'

const StyledSelect = styled(Select)`
  display: flex;
  min-width: 116px;
  background: #ffe9bc;
`

const CustomSelect = ({normalWidth, alignLeft, ...restProps}) => {
  return <StyledSelect {...restProps} left={alignLeft} auto={normalWidth} />
}

export default CustomSelect
