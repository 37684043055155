import {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../store/ducks/project.duck'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import {saveAs} from 'file-saver'
import {Buffer} from 'buffer'

export default function Export({payload, buttonGroup}) {
  const dispatch = useDispatch()
  const header = useSelector((state) => state.project.projectHeader.values)
  const exportXlsx = useSelector((state) => state.project.exportXlsx)

  const [exportExcelStatus, setExportExcelStatus] = useState('Not Started')

  // process.env.REACT_APP_DEBUG && console.log('payload');
  // process.env.REACT_APP_DEBUG && console.log(payload);
  const [thisPayload, setthisPayload] = useState({})

  // initial call from the download button - fires fetch action if needed, otherwise triggers download
  const exportFile = (actionPayload) => {
    setthisPayload(actionPayload)
    switch (actionPayload.type) {
      case 'pdf':
      case 'excel': {
        if (exportXlsx && exportXlsx === 'asdsdas') {
          downloadExport(actionPayload)
        } else if (exportExcelStatus === 'Not Started') {
          dispatch(Project.actions.fetchExportXlsx(actionPayload))
          setExportExcelStatus('Fetching')
        }
        break
      }
      default:
    }
  }

  // grab the blob from state and tell the browser to download
  const downloadExport = useCallback(() => {
    var exportDocName = `${header.name} - ${thisPayload.fileName}`
    switch (thisPayload.type) {
      case 'excel': {
        if (exportXlsx && exportXlsx !== '') {
          const file = new Blob([Buffer.from(exportXlsx, 'base64')], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml'})
          if (exportDocName.slice(-5) === '.xlsx') {
            saveAs(file, exportDocName)
          } else {
            saveAs(file, `${exportDocName}.xlsx`)
          }
        }
        break
      }
      case 'pdf': {
        if (exportXlsx && exportXlsx !== '') {
          const file = new Blob([Buffer.from(exportXlsx, 'base64')], {type: 'application/pdf'})
          saveAs(file, `${exportDocName}.pdf`)
        }
        break
      }
      default:
    }
  }, [exportXlsx, header.name, thisPayload.fileName, thisPayload.type])

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  useEffect(() => {
    if (exportExcelStatus === 'Fetching' && exportXlsx && exportXlsx !== '') {
      downloadExport()
      setExportExcelStatus('Not Started')
    }
  }, [exportExcelStatus, exportXlsx, downloadExport])

  const getButtonLabel = (title) => {
    return <>{exportExcelStatus === 'Not Started' ? <>{title ? title : 'Export'}</> : <>{exportExcelStatus === 'Fetching' ? 'Exporting...' : 'Download'}</>}</>
  }

  if (buttonGroup) {
    return (
      <DropdownButton className='inline-block' drop='down' title={getButtonLabel(buttonGroup.buttonTitle)} id='dropdown-menu-align-right' size='sm'>
        {buttonGroup.payloads.map((payload, index) => (
          <Dropdown.Item key={index} eventKey={index} onClick={() => exportFile(payload)}>
            {getButtonLabel(payload.buttonTitle, payload.type)}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    )
  } else {
    return (
      <Button className='ml-3' variant='primary' size='sm' disabled={exportExcelStatus === 'Fetching'} onClick={() => exportFile(payload)}>
        {getButtonLabel(payload.buttonTitle)}
      </Button>
    )
  }
}
