const actionTypes = {
  SET_CACHE: 'SET_CACHE',
  SET_STATUS: 'SET_STATUS',
  STOP_REHYDRATE: 'STOP_REHYDRATE',
  // Init actiontypes to build a project with all collection
  SET_PROJECTID: 'SET_PROJECTID',
  CLEAR_PROJECT: 'CLEAR_PROJECT',
  FETCH_ProjectHeader: 'FETCH_ProjectHeader',
  SET_INITIAL: 'SET_INITIAL',
  PUT_RECALC_PROJECT: 'PUT_RECALC_PROJECT',
  FETCH_Dashboard: 'FETCH_Dashboard',
  FETCH_ReportDashboardTrends: 'FETCH_ReportDashboardTrends',
  FETCH_ReportDashboardRecentActivities: 'FETCH_ReportDashboardRecentActivities',

  CLEAR_Summary: 'CLEAR_Summary',
  CLEAR_Expenses: 'CLEAR_Expenses',

  FETCH_Summary: 'FETCH_Summary',
  FETCH_TargetEfficiency: 'FETCH_TargetEfficiency',
  // Generic actions to set and put properties
  FETCH_PROPERTIES: 'FETCH_PROPERTIES',
  SET_PROPERTIES: 'SET_PROPERTIES',
  SET_COLLECTION: 'SET_COLLECTION',
  SET_PROPERTY: 'SET_PROPERTY',
  PUT_PROPERTY: 'PUT_PROPERTY',
  PUT_PROJECTCONFIGURATIONOPTION: 'PUT_PROJECTCONFIGURATIONOPTION',
  SET_GRID: 'SET_GRID',
  PUT_GRID: 'PUT_GRID',
  // Fetch actions to populate project list
  FETCH_ProjectsGrid: 'FETCH_ProjectsGrid',
  FETCH_ClientSummaryGrid: 'FETCH_ClientSummaryGrid',
  SET_PROJECTS: 'SET_PROJECTS',
  // Fetch actions to populate all project properties collection
  FETCH_ProjectConfiguration: 'FETCH_ProjectConfiguration',
  FETCH_ProjectVersions: 'FETCH_ProjectVersions',
  FETCH_APILOGSTATISTICS: 'FETCH_APILOGSTATISTICS',
  FETCH_BidPermissions: 'FETCH_BidPermissions',
  FETCH_ProjectBusinessUnits: 'FETCH_ProjectBusinessUnits',
  FETCH_ProjectInformationGrid: 'FETCH_ProjectInformationGrid',
  FETCH_SummaryRequirementsGrid: 'FETCH_SummaryRequirementsGrid',
  FETCH_SummaryLaborSummaryTable: 'FETCH_SummaryLaborSummaryTable',
  FETCH_SummaryHITTCostFeeTable: 'FETCH_SummaryHITTCostFeeTable',
  FETCH_SummaryReturnOnLaborTable: 'FETCH_SummaryReturnOnLaborTable',
  FETCH_ManpowerGrandTotals: 'FETCH_ManpowerGrandTotals',
  FETCH_ManpowerCostGrid: 'FETCH_ManpowerCostGrid',
  FETCH_ManpowerGrid: 'FETCH_ManpowerGrid',
  FETCH_ManpowerGridPostingRates: 'FETCH_ManpowerGridPostingRates',
  FETCH_PreconDept: 'FETCH_PreconDept',
  FETCH_TasksToAdd: 'FETCH_TasksToAdd',
  FETCH_PreconResourceGrid: 'FETCH_PreconResourceGrid',
  FETCH_PreconResourceGrid_TEMP: 'FETCH_PreconResourceGrid_TEMP',
  FETCH_PreconDeptExpensesGrid: 'FETCH_PreconDeptExpensesGrid',
  FETCH_PreconDeptExpensesGrid_TEMP: 'FETCH_PreconDeptExpensesGrid_TEMP',
  FETCH_ProjectTasks: 'FETCH_ProjectTasks',
  FETCH_FieldOfficeSupport: 'FETCH_FieldOfficeSupport',
  FETCH_FieldOfficeSupportExpensesGrid: 'FETCH_FieldOfficeSupportExpensesGrid',
  SET_FieldOfficeSupportExpensesGrid: 'SET_FieldOfficeSupportExpensesGrid',
  SET_GeneralRequirementsExpensesGrid: 'SET_GeneralRequirementsExpensesGrid',
  FETCH_GeneralRequirements: 'FETCH_GeneralRequirements',
  FETCH_GeneralRequirementsExpensesGrid: 'FETCH_GeneralRequirementsExpensesGrid',
  FETCH_NonBillable: 'FETCH_NonBillable',
  FETCH_NonBillableExpensesGrid: 'FETCH_NonBillableExpensesGrid',
  FETCH_ProjectEmployeeRolesToAdd: 'FETCH_ProjectEmployeeRolesToAdd',
  FETCH_ProjectOperationsEmployeesToAdd: 'FETCH_ProjectOperationsEmployeesToAdd',
  FETCH_ExportXlsx: 'FETCH_ExportXlsx',
  SET_EXPORT_XLSX: 'SET_EXPORT_XLSX',
  FETCH_ProjectScheduleXlsx: 'FETCH_ProjectScheduleXlsx',
  FETCH_ClientSummaryXlsx: 'FETCH_ClientSummaryXlsx',
  FETCH_ClientSummaryPDF: 'FETCH_ClientSummaryPDF',
  FETCH_ProjectPerDiemRates: 'FETCH_ProjectPerDiemRates',
  CHECK_ProjectPerDiemRates: 'CHECK_ProjectPerDiemRates',
  FETCH_ManpowerGridWeeks: 'FETCH_ManpowerGridWeeks',
  FETCH_ManpowerGridMonths: 'FETCH_ManpowerGridMonths',
  SET_SummaryCalculationsXlsx: 'SET_SummaryCalculationsXlsx',
  FETCH_SummaryCalculationsXlsx: 'FETCH_SummaryCalculationsXlsx',

  SET_ContractDurationWeeksQuotedOnly: 'SET_ContractDurationWeeksQuotedOnly',
  SET_ContractDurationWeeksQuoted: 'SET_ContractDurationWeeksQuoted',
  PUT_ContractDurationWeeksQuoted: 'PUT_ContractDurationWeeksQuoted',

  SET_SummaryHITTCostFeeTable: 'SET_SummaryHITTCostFeeTable',
  PUT_SummaryHITTCostFeeTable: 'PUT_SummaryHITTCostFeeTable',

  PUT_ProjectBusinessUnits: 'PUT_ProjectBusinessUnits',
  CHANGE_EXPENSE_GRID_ROW: 'CHANGE_EXPENSE_GRID_ROW',
  SET_EXPENSE_GRID_TOTALS: 'SET_EXPENSE_GRID_TOTALS',
  CALC_EXPENSE_GRID: 'CALC_EXPENSE_GRID',
  PUT_NEW_EXPENSE_GRID_ROW: 'PUT_NEW_EXPENSE_GRID_ROW',
  REMOVE_EXPENSE_GRID_ROW: 'REMOVE_EXPENSE_GRID_ROW',
  COPY_EXPENSE_TEMPLATE_ITEMS_TO_PROJECT: 'COPY_EXPENSE_TEMPLATE_ITEMS_TO_PROJECT',

  CHANGE_MANPOWER_COST_GRID_ROW: 'CHANGE_MANPOWER_COST_GRID_ROW',
  PUT_RESOURCE_BUDGET: 'PUT_RESOURCE_BUDGET',
  PUT_RESOURCE_NOTE: 'PUT_RESOURCE_NOTE',
  CHANGE_MANPOWER_COST_GRID_ROW_EMPLOYEE: 'CHANGE_MANPOWER_COST_GRID_ROW_EMPLOYEE',
  PUT_NEW_MANPOWER_GRID_ROW: 'PUT_NEW_MANPOWER_GRID_ROW',
  ADD_MANPOWER_COST_GRID_ROW: 'ADD_MANPOWER_COST_GRID_ROW',
  REMOVE_MANPOWER_COST_GRID_ROW: 'REMOVE_MANPOWER_COST_GRID_ROW',
  PUT_RESOURCE_PERDIEM: 'PUT_RESOURCE_PERDIEM',
  PUT_ADDRESS: 'PUT_ADDRESS',
  PUT_RESET_POSTINGRATES: 'PUT_RESET_POSTINGRATES',

  PUT_MANPOWER_GRID_ROW: 'PUT_MANPOWER_GRID_ROW',
  REMOVE_MANPOWER_GRID_ROW: 'REMOVE_MANPOWER_GRID_ROW',
  PUT_ProjectTasks: 'PUT_ProjectTasks',

  PUT_PRECON_RESOURCE_GRID_ROW: 'PUT_PRECON_RESOURCE_GRID_ROW',
  CHANGE_PRECON_RESOURCE_GRID_ROW_EMPLOYEE: 'CHANGE_PRECON_RESOURCE_GRID_ROW_EMPLOYEE',
  PUT_NEW_PRECON_GRID_ROW: 'PUT_NEW_PRECON_GRID_ROW',

  SET_TAB: 'SET_TAB',
  PUT_COLUMNS_ORDER: 'PUT_COLUMNS_ORDER',

  DELETE_PROJECT: 'DELETE_PROJECT',
  COPY_PROJECT: 'COPY_PROJECT',

  GET_REPORT: 'GET_REPORT',
  SET_REPORT: 'SET_REPORT',
  SET_APISTATUS: 'SET_APISTATUS',
  SET_HUBCONNECTION: 'SET_HUBCONNECTION',
  SET_APIMESSAGES: 'SET_APIMESSAGES',
  SET_TEMP: 'SET_TEMP',
  SET_RESOURCE_BUDGET: 'SET_RESOURCE_BUDGET',

  FETCH_ProjectSoftCostsGrid: 'FETCH_ProjectSoftCostsGrid',
  PUT_ProjectSoftCostsGrid: 'PUT_ProjectSoftCostsGrid'
}

export default actionTypes
