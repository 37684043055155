import {useMemo, useRef, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../store/ducks/project.duck'
import * as Reference from '../store/ducks/reference.duck'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
export const CONFIG = {
  MessageTime: 5000
}

const NOT_STARTED = 'Recalc'
export const IN_PROGRESS = 'Calculating...'
export const COMPLETE = 'Complete'

export default function Recalc({calledBy, className}) {
  const dispatch = useDispatch()
  const projectID = useSelector((state) => state.project.projectID)
  const recalcStatus = useSelector((state) => state.reference.Status.recalcStatus)

  function startRecalc() {
    if (recalcStatus === IN_PROGRESS) {
      return null
    }
    dispatch(
      Project.actions.putRecalcProject({
        projectID: projectID ? projectID : 0,
        calledBy: calledBy
      })
    )
    dispatch(Reference.actions.setRefStatus({statusName: 'recalcStatus', statusValue: IN_PROGRESS}))
  }

  // Recalc completed timeout
  const completeTimeoutRef = useRef(null)
  const clearCompleteTimeout = () => {
    if (completeTimeoutRef.current) {
      clearTimeout(completeTimeoutRef.current)
    }
  }

  useEffect(() => {
    return () => {
      clearCompleteTimeout()
    }
  }, [])

  useEffect(() => {
    const checkCompleted = () => {
      clearCompleteTimeout()
      if (recalcStatus === COMPLETE) {
        completeTimeoutRef.current = setTimeout(() => {
          dispatch(Reference.actions.setRefStatus({statusName: 'recalcStatus', statusValue: NOT_STARTED}))
        }, CONFIG.MessageTime)
      }
    }
    checkCompleted()
  }, [recalcStatus, dispatch])

  const getButtonIcon = useMemo(() => {
    switch (recalcStatus) {
      case NOT_STARTED:
        return <i className='bi fs-4 bi-arrow-clockwise'></i>
      case IN_PROGRESS:
        return <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
      case COMPLETE:
        return <i className='bi fs-4 bi-check2-circle'></i>
      default:
        return null
    }
  }, [recalcStatus])

  const getButtonVariant = useMemo(() => {
    switch (recalcStatus) {
      case NOT_STARTED:
        return 'primary'
      case IN_PROGRESS:
        return 'warning'
      case COMPLETE:
        return 'success'
      default:
        return null
    }
  }, [recalcStatus])

  return (
    <Button size='sm' className={className} variant={getButtonVariant} onClick={startRecalc}>
      {getButtonIcon} {recalcStatus}
    </Button>
  )
}
