import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Grid from '@mui/material/Grid'
import {GlobalModal, useAppModal} from '../../context/GlobalModalContext'
import {createContext, useCallback, useContext, useMemo} from 'react'
import TestExecution from '../UserTesting/TestExecution'

export const FooterLayout = {
  TwoButton: 1,
  GridThreeButton: 2,
  ThreeButton: 3,
  Empty: 4,
  None: 5
}

const BasicModalContext = createContext({
  handleCancel: undefined,
  onSave: undefined,
  confirmButtonText: 'Save',
  cancelButtonText: 'Cancel',
  saveAndAddButtonText: 'Save & Add',
  onDelete: undefined,
  onSaveAndAdd: undefined,
  showSaveButton: true,
  disableSaveButton: false,
  formId: undefined
})

const BasicModalWrapper = ({
  children,
  className = '',
  contentClassName,
  headerClassName = '',
  dialogClassName = '',
  bodyClassName = '',
  footerClassName = '',
  modalKey,
  onCancelCallback,
  onSave,
  formId,
  onSaveAndAdd,
  onDelete,
  title,
  showSaveButton = true,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Save',
  saveAndAddButtonText = 'Save & Add',
  footerLayout = FooterLayout.TwoButton,
  disableSaveButton = false
}) => {
  const {closeModal} = useAppModal()
  const handleCancel = useCallback(() => {
    if (onCancelCallback) {
      onCancelCallback()
    }
    closeModal()
  }, [closeModal, onCancelCallback])

  const wrapped = useMemo(
    () => ({
      handleCancel,
      onSave,
      onSaveAndAdd,
      confirmButtonText,
      cancelButtonText,
      saveAndAddButtonText,
      showSaveButton,
      onDelete,
      disableSaveButton,
      formId
    }),
    [cancelButtonText, confirmButtonText, disableSaveButton, formId, handleCancel, onDelete, onSave, onSaveAndAdd, saveAndAddButtonText, showSaveButton]
  )

  const renderFooter = useCallback(() => {
    switch (footerLayout) {
      case FooterLayout.Empty:
        return null
      case FooterLayout.GridThreeButton:
        return <GridThreeButtonLayout />
      case FooterLayout.ThreeButton:
        return <ThreeButtonLayout />
      case FooterLayout.TwoButton:
      default:
        return <TwoButtonLayout />
    }
  }, [footerLayout])
  return (
    <BasicModalContext.Provider value={wrapped}>
      <GlobalModal modalKey={modalKey} className={className} contentClassName={contentClassName} dialogClassName={dialogClassName}>
        {title && (
          <Modal.Header closeButton className={headerClassName}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className={bodyClassName}>
          <Grid container>
            <Grid item md={12}>
              <TestExecution />
            </Grid>
            <Grid item md={12}>
              {children}
            </Grid>
          </Grid>
        </Modal.Body>
        {footerLayout !== FooterLayout.None && <Modal.Footer className={footerClassName}>{renderFooter()}</Modal.Footer>}
      </GlobalModal>
    </BasicModalContext.Provider>
  )
}

export default BasicModalWrapper

// Footer layouts
const TwoButtonLayout = () => {
  const {handleCancel, onSave, confirmButtonText, cancelButtonText, showSaveButton, formId} = useContext(BasicModalContext)
  return (
    <>
      <Button variant='secondary' onClick={handleCancel}>
        {cancelButtonText}
      </Button>
      {showSaveButton && (
        <Button type={formId ? 'submit' : undefined} form={formId} variant='primary' onClick={onSave}>
          {confirmButtonText}
        </Button>
      )}
    </>
  )
}

const GridThreeButtonLayout = () => {
  const {onDelete, handleCancel, onSave, confirmButtonText, cancelButtonText, showSaveButton, disableSaveButton, formId} = useContext(BasicModalContext)
  return (
    <Grid container justifyContent='space-between'>
      <Grid item>
        {!!onDelete && (
          <Button variant='warning' onClick={onDelete}>
            Delete
          </Button>
        )}
      </Grid>
      <Grid item>
        <Button variant='secondary' onClick={handleCancel}>
          {cancelButtonText}
        </Button>
        {showSaveButton && (
          <Button type={formId ? 'submit' : undefined} form={formId} variant='primary' className='ml-[10px]' disabled={disableSaveButton} onClick={onSave}>
            {confirmButtonText}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

const ThreeButtonLayout = () => {
  const {handleCancel, onSave, onSaveAndAdd, confirmButtonText, cancelButtonText, saveAndAddButtonText} = useContext(BasicModalContext)
  return (
    <>
      <Button variant='secondary' onClick={handleCancel}>
        {cancelButtonText}
      </Button>
      <Button variant='info' onClick={onSaveAndAdd}>
        {saveAndAddButtonText}
      </Button>
      <Button variant='primary' onClick={onSave}>
        {confirmButtonText}
      </Button>
    </>
  )
}
