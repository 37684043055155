import {useEffect, useCallback, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as wjFlexGrid from '@grapecity/wijmo.react.grid'
import {ImmutabilityProvider} from '@grapecity/wijmo.react.grid.immutable'
import * as Project from '../../../../store/ducks/project.duck'
import Grid from '@mui/material/Grid'
import Button from 'react-bootstrap/Button'
import {useAppModal} from '../../../../context/GlobalModalContext'

const SoftCostsWorksheet = () => {
  const gridRef = useRef()
  const dispatch = useDispatch()
  const softCostsGrid = useSelector((state) => state.project.SoftCostsGrid.values)
  const {closeModal} = useAppModal()

  const handleSaveGrid = useCallback(() => {
    const gridData = gridRef.current.control.rows.map((row, ix) => ({
      ...row.dataItem,
      ColumnOrder: ix + 1
    }))
    dispatch(Project.actions.putProjectSoftCostsGrid(gridData))
    // save grid
    closeModal()
  }, [dispatch, closeModal])
  // const handleResetGrid = useCallback(() => {
  //   // reset grid
  // }, [])

  useEffect(() => {
    dispatch(Project.actions.fetchProjectSoftCostsGrid({popCache: true}))
  }, [dispatch])

  return (
    <div className='flex flex-fill flex-col'>
      <Grid container className='flex justify-end w-[1000px]'>
        <Grid item className={'flex'}>
          <Button variant='primary' size='sm' className='ml-2 mt-3' onClick={handleSaveGrid}>
            Save
          </Button>
          {/* <Button variant='primary' size='sm' className='ml-2 mt-3' onClick={handleResetGrid}>
            Reset
          </Button> */}
        </Grid>
      </Grid>
      <div className='mb-[30px] w-full max-w-[1000px] flex flex-fill overflow-auto h-0'>
        {/* cellEditEnded={(s, e) => s.autoSizeColumn(e.col)}  */}
        <wjFlexGrid.FlexGrid
          id='softcostsworksheet-grid'
          ref={gridRef}
          // initialized={onGridInitialized}
          // loadedRows={onloadedRows}
          frozenColumns={5}
          alternatingRowStep={0}
          allowSorting={false}
          allowResizing='Columns'
          // keyActionTab={wjcgrid.KeyAction.Cycle}
          allowDelete={false}
          allowAddNew={false}
        >
          <ImmutabilityProvider itemsSource={softCostsGrid} />
          <wjFlexGrid.FlexGridColumn binding='SoftCostsWorksheetRowID' header='ID' width={20} isReadOnly={true} visible={false} />
          <wjFlexGrid.FlexGridColumn binding='SoftCostsTemplateRowID' header='SoftCostsTemplateRowID' width={20} isReadOnly={true} visible={false} />
          <wjFlexGrid.FlexGridColumn binding='Label' header='Soft Costs' width={'*'} visible={true} cssClassAll='header-left' />
          <wjFlexGrid.FlexGridColumn binding='Value' header='Coverage Limit' format='c0' width={140} visible={true} />
        </wjFlexGrid.FlexGrid>
      </div>
    </div>
  )
}

export default SoftCostsWorksheet
