export default function currencyFormat(amount, decimalCount = 0) {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 0 : decimalCount

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencySign: 'accounting',
      maximumFractionDigits: decimalCount
    })

    return formatter.format(amount).replace('$', '')
  } catch (e) {
    console.log(e)
  }
}
