import {useAppModal} from '../../context/GlobalModalContext'
import TradeScopeTemplates from '../../pages/home/templateselectors/TradeScopeTemplates'
import {ModalLayoutMd} from './ModalLayouts'
import {FooterLayout} from './BasicModalWrapper'

export const TradeScopeTemplateModalKey = '@Modal_TradeScopeTemplate'
const TradeScopeTemplateModal = () => {
  const {closeModal} = useAppModal()
  return (
    <ModalLayoutMd modalKey={TradeScopeTemplateModalKey} className='h-[1000px]' title='Trade Scope Templates' footerLayout={FooterLayout.Empty}>
      <TradeScopeTemplates closeModal={closeModal} />
    </ModalLayoutMd>
  )
}

export default TradeScopeTemplateModal
