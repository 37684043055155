import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import Card from 'react-bootstrap/Card'
import ReactPlayer from 'react-player'
import usePageProps from '../hooks/usePageProps'
import {getHelpModulesForPage} from '../helpers/page'
import {toAbsoluteUrl} from '../../_metronic/helpers'

export const HelpVideoViewer = ({title, description, uri, thumbnail}) => {
  // const authToken = useSelector((state) => state.auth.authToken)
  return (
    <Card className='border border-gray-300 mb-3 w-full'>
      <Card.Body className='p-5'>
        <Card.Subtitle className='mb-2 text-gray-600'>Video: {title}</Card.Subtitle>
        <Card.Text>{description}</Card.Text>
        {uri && uri !== '' && (
          <ReactPlayer className={'w-100'} url={uri} controls={true} pip={false} width={undefined} height={200} light={`${toAbsoluteUrl('/media/video-thumbnails/')}${thumbnail}thumb.jpg`} />
        )}
      </Card.Body>
    </Card>
  )
}

export const HelpViewer = ({field, content}) => {
  return (
    <Card className='border border-gray-300 mb-3 w-full'>
      <Card.Body className='p-5'>
        {/* <Card.Title>{topic.field}</Card.Title> */}
        <Card.Subtitle className='mb-2 text-gray-600'>{field}</Card.Subtitle>
        <Card.Text>{content}</Card.Text>
      </Card.Body>
    </Card>
  )
}

function HelpViewerContainer() {
  // const filters = useSelector((state) => state.auth.Filters)
  const helpTopics = useSelector((state) => state.reference.HelpTopics)
  const helpVideos = useSelector((state) => state.reference.HelpVideos)

  const {currentPage, hash} = usePageProps()

  const helpModules = useMemo(() => getHelpModulesForPage(currentPage, hash) || [], [currentPage, hash])
  const filteredHelpVideos = helpVideos.filter((video) => helpModules.includes('All') || video.modules.some((item) => helpModules.includes(item)))
  const filteredHelpTopics = helpTopics.filter((topic) => helpModules.includes('All') || (helpModules.indexOf(topic.module) > -1 && topic.helpContent.length > 3))

  return (
    <>
      {filteredHelpVideos.map((video, index) => {
        return <HelpVideoViewer key={`key-${index}`} title={video.title} description={video.description} uri={video.uri} thumbnail={video?.modules[0]?.replace(' ', '')} />
      })}
      {/* Help Topics for <span style={{ fontWeight: 500 }}>{helpModule}</span> */}
      {filteredHelpTopics.map((topic, index) => {
        return <HelpViewer key={`key-${index}`} field={topic.field} content={topic.helpContent} />
      })}
    </>
  )
}

export default HelpViewerContainer
