/* eslint-disable no-unused-vars */
import {useEffect, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Reference from '../../../store/ducks/reference.duck'
import * as Precon from '../../../store/ducks/precon.duck'
import * as Auth from '../../../store/ducks/auth.duck'
import * as Yup from 'yup'
import * as wjCore from '@grapecity/wijmo'
import * as wjcgrid from '@grapecity/wijmo.grid'
import * as wjFlexGrid from '@grapecity/wijmo.react.grid'
import {Menu} from '@grapecity/wijmo.input'
import {ImmutabilityProvider} from '@grapecity/wijmo.react.grid.immutable'
import AppForm from '../../../components/Form/AppForm'
// import BasicModalWrapper, {FooterLayout} from '../../../components/Modals/BasicModalWrapper'
import {Field, useFormikContext} from 'formik'
import AppSelect from '../../../components/Form/AppSelect'
import BidHeader from '../../../components/BidHeader'
import {ALTERNATE_STATUSES} from '../BidAlternates'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Grid from '@mui/material/Grid'
import AppFormField from '../../../components/Form/AppFormField'
import ColFormField from '../../../components/Form/ColFormField'
import {NumberFormatCustom} from '../../../components/NumberFormatCustom'
import {DataChangeAction} from '@grapecity/wijmo.grid.immutable'
import {useNavigate} from 'react-router-dom'
import AppToast from '../../../helpers/AppToast'
import {da} from 'date-fns/locale'

const validationSchema = Yup.object().shape({
  description: Yup.string().required('Description is required')
})

const EditAlternateFormId = 'edit-alternate-form'

const Alternate = ({type, alternateID, setAlternateID}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const alternate = useSelector((state) => state.precon.Alternate)
  const alternateItemsGrid = useSelector((state) => state.precon.Alternate.items)

  useEffect(() => {
    dispatch(
      Precon.actions.fetchAlternate({
        bidQuoteID: bidQuoteID,
        alternateID: alternateID,
        type: type
      })
    )
  }, [dispatch, alternateID, bidQuoteID, type])

  function formatItem(s, e) {
    if (e.panel.cellType === wjcgrid.CellType.ColumnHeader) {
      let col = s.columns[e.col]
      if (col.binding === 'trade' || col.binding === 'comments') {
        wjCore.addClass(e.cell, 'column-header-left')
      } else {
        wjCore.addClass(e.cell, 'column-header-center')
      }
    }
    if (e.panel.cellType === wjcgrid.CellType.Cell) {
      let item = e.panel.rows[e.row].dataItem
      // Colour the totals and subtotals rows
      if (item && item.trade === 'Total Quoted') {
        wjCore.addClass(e.cell, 'precalc-total-row-highlight')
      } else if (item.trade === 'Trade Total' || item.trade === 'Subtotal Quoted') {
        wjCore.addClass(e.cell, 'precalc-subtotal-row-highlight')
      }
    }
  }

  const calcAlternateTotal = (grid, newItem) => {
    // merge the new row into the grid
    let temp = grid.map((row) => {
      // console.log(
      //   `row.id: ${row.id}, row.trade: ${row.trade}, newItem.id: ${newItem.id}, newItem.trade: ${newItem.trade}, itemCost: ${row.note === 'Excluded' ? 0 : row.budget} (row.note: ${
      //     row.note
      //   }, row.budget: ${row.budget})`
      // )
      return row.id === newItem.id && row.trade === newItem.trade ? {...newItem, note: newItem.note, itemCost: newItem.note === 'Excluded' ? 0 : row.budget} : row
    })
    // calculate the Total
    let total = temp.filter((r) => r.id === 0 && r.trade !== 'Trade Total' && r.trade !== 'Total Quoted').reduce((acc, row) => acc + row.itemCost, 0)
    dispatch(Precon.actions.setAlternateItems(temp.map((row) => (row.trade === 'Total Quoted' ? {...row, itemCost: total} : row))))
  }

  // link to trades with filter
  const goToTrade = useCallback(
    (bidTradeID) => {
      dispatch(Precon.actions.setBidTradeID(bidTradeID))
      dispatch(Precon.actions.fetchBidTrade({bidTradeID: bidTradeID}))
      dispatch(Auth.actions.setFilters({filterName: 'bidTradeID', filterValue: bidTradeID}))
      navigate(`/trades#${type === 'VE' ? 've' : 'alternates'}`)
    },
    [dispatch, navigate, type]
  )
  const buildMenu = (s) => {
    let menu = new Menu(document.createElement('div'), {
      owner: s.hostElement,
      displayMemberPath: 'header',
      subItemsPath: 'items',
      commandParameterPath: 'cmd',
      dropDownCssClass: 'ctx-menu',
      closeOnLeave: true,
      itemsSource: [{header: 'Go to Trade', cmd: 'cmdGoToTrade'}, {cmd: '-'}, {header: 'Include', cmd: 'cmdIncludeIndirectCost'}, {header: 'Exclude', cmd: 'cmdExcludeIndirectCost'}],
      command: {
        // enable/disable commands
        canExecuteCommand: (cmd) => {
          let dataItem = s.rows[s.selection.row].dataItem
          if (dataItem.bidTradeID > 0 && cmd === 'cmdGoToTrade') {
            return true
          } else if (dataItem.note === 'Included' && cmd === 'cmdExcludeIndirectCost') {
            return true
          } else if (dataItem.note === 'Excluded' && cmd === 'cmdIncludeIndirectCost') {
            return true
          }
          return false
        },
        // execute menu commands
        executeCommand: (cmd) => {
          let dataItem = s.rows[s.selection.row].dataItem
          if (dataItem && dataItem.alternateID > 0) {
            console.log(dataItem)
            switch (cmd) {
              case 'cmdGoToTrade':
                goToTrade(dataItem.bidTradeID)
                break
              case 'cmdIncludeIndirectCost':
                dataItem.status = 'Included'
                dataItem.note = 'Included'
                dispatch(Precon.actions.putAlternateIndirect([{...dataItem}]))
                calcAlternateTotal(s.collectionView.items, {...dataItem})
                break
              case 'cmdExcludeIndirectCost':
                dataItem.status = 'Excluded'
                dataItem.note = 'Excluded'
                if (dataItem.trade === 'General Conditions' || dataItem.trade === 'General Requirements' || dataItem.trade === 'Preconstruction') {
                  dataItem.itemCost = 0
                  dataItem.budget = 0
                }

                dispatch(Precon.actions.putAlternateIndirect([{...dataItem}]))
                calcAlternateTotal(s.collectionView.items, {...dataItem})
                break
              default:
            }
          }
          // restore focus to active grid cell (TFS 439964)
          s.refresh()
          let sel = s.selection,
            cell = s.cells.getCellElement(sel.row, sel.col)
          if (cell) {
            cell.focus()
          }
        }
      }
    })
    // done
    return menu
  }

  function onGridInitialized(s) {
    // Add context menu
    let host = s.hostElement,
      menu = buildMenu(s)
    host.addEventListener(
      'contextmenu',
      (e) => {
        // select the cell/column that was clicked
        let sel = s.selection,
          ht = s.hitTest(e),
          row = ht.getRow()
        switch (ht.panel) {
          case s.cells:
            s.select(ht.row, ht.col)
            break
          case s.columnHeaders:
            s.select(sel.row, ht.col)
            break
          case s.rowHeaders:
            s.select(ht.row, sel.col)
            break
          default:
            return // invalid panel
        }
        e.preventDefault() // cancel the browser's default menu bidTradeID && row.dataItem.bidTradeID === 0
        // show the menu for the current column
        if (s.selection.col > -1 && row.dataItem && row.dataItem.tradeTitle !== 'Total Quoted' && row.dataItem.tradeTitle !== 'Trade Total' && row.dataItem.tradeTitle !== 'Subtotal Quoted') {
          menu.show(e) // and show ours
        }
      },
      true
    )
    console.log('Grid initialised')
  }

  const onCancel = () => {
    setAlternateID()
  }

  // Edit Alternate Modal methods
  const onSubmitForm = useCallback(
    (values) => {
      var payload = {
        ...values,
        bidQuoteID: bidQuoteID,
        items: []
      }
      dispatch(Precon.actions.putAlternate(payload))
      setAlternateID()
    },
    [bidQuoteID, dispatch, setAlternateID]
  )

  const onSubmitRecalc = useCallback(
    (values) => {
      var payload = {
        ...values,
        bidQuoteID: bidQuoteID
      }
      dispatch(Precon.actions.putAlternate(payload))
    },
    [bidQuoteID, dispatch]
  )

  const onDeleteAlternate = useCallback(
    (alternateValues) => {
      dispatch(Precon.actions.deleteAlternate(alternateValues))
      setAlternateID()
    },
    [dispatch, setAlternateID]
  )

  return (
    <div>
      <BidHeader />
      <h4>{type}</h4>
      <AppForm
        formId={EditAlternateFormId}
        enableReinitialize={true}
        initialValues={alternate}
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
        formProps={{
          noValidate: true,
          autoComplete: 'off'
        }}
      >
        <Table aria-label='simple table' className='summary-table w-full'>
          <tbody className='summary-tablebody'>
            <tr>
              <td>
                <Grid container spacing={3}>
                  <Grid item md={4} className='mb-5'>
                    <Row>
                      <Col md='12'>
                        <Field component={AppFormField} formAs='textarea' id='edit-alternate-description' type='text' name='description' label='Description' autoComplete='new-password' rows={2} />
                      </Col>
                    </Row>
                    <Row className='mt-8'>
                      <Col md='12'>
                        <Field component={AppFormField} formAs='textarea' id='edit-alternate-comments' type='text' name='comments' label='Comments' autoComplete='new-password' rows={2} />
                      </Col>
                    </Row>
                    <Row className='mt-8'>
                      <Col md='6'>
                        <Field
                          component={AppSelect}
                          label='Status'
                          id='edit-alternate-status'
                          name='status'
                          options={ALTERNATE_STATUSES}
                          variant='outlined'
                          size='small'
                          className='select bg-white-primary w-[50px] mt-0.5'
                          labelProps={{className: 'form-label'}}
                        />
                      </Col>
                      <Col md='6'>
                        <Field
                          component={ColFormField}
                          id='breakout-area-summary-project-unit-value'
                          name='quotedPC'
                          label='Quoted %'
                          variant='outlined'
                          className='mt-2'
                          size='small'
                          normal={1}
                          yellow={1}
                          InputProps={{
                            readOnly: false,
                            inputComponent: NumberFormatCustom,
                            inputProps: {decimalplaces: 0}
                          }}
                          autoComplete='off'
                        />
                      </Col>
                    </Row>
                  </Grid>
                  <Grid item md={8}>
                    <Row>
                      <Col md='12'>
                        <div className='mb-2'>Trade Costs</div>
                        <wjFlexGrid.FlexGrid
                          className='mt-0'
                          id='alternate-items-grid'
                          alternatingRowStep={0}
                          headersVisibility='Column'
                          allowSorting={false}
                          allowDelete={false}
                          allowAddNew={false}
                          autoRowHeights={true}
                          formatItem={formatItem}
                          initialized={onGridInitialized}
                        >
                          <ImmutabilityProvider itemsSource={alternateItemsGrid} dataChanged={onGridDataChanged} />
                          <wjFlexGrid.FlexGridColumn binding='trade' header='Trade' width='*' cssClass='cell-monospace-left' wordWrap={true} isReadOnly={true} />
                          <wjFlexGrid.FlexGridColumn binding='itemCost' header='Total' format='n0' width={120} isReadOnly={false} cssClass='cell-monospace-right' isRequired={false} />
                          <wjFlexGrid.FlexGridColumn binding='note' header='Comments' width='*' cssClassAll='header-left' wordWrap={true} isReadOnly={true} cssClass='cell-monospace-left' />
                        </wjFlexGrid.FlexGrid>
                      </Col>
                    </Row>
                  </Grid>
                </Grid>
                <FooterWithinForm formId={EditAlternateFormId} onCancel={onCancel} onDelete={onDeleteAlternate} onSubmitRecalc={onSubmitRecalc} alternateID={alternateID} />
              </td>
            </tr>
          </tbody>
        </Table>
      </AppForm>
    </div>
  )

  // Dispatches data change actions to the Redux Store in response to user edits made via the grid.
  function onGridDataChanged(s, e) {
    // console.log(e.newItem)
    switch (e.action) {
      case DataChangeAction.Change:
        // Check to make sure row is complete before changing (as it may an add row)
        if (e.newItem.trade === 'General Conditions' || e.newItem.trade === 'General Requirements' || e.newItem.trade === 'Preconstruction') {
          if (e.newItem.itemCost != 0) {
            e.newItem.status = 'Included'
            e.newItem.note = 'Included'
            e.newItem.budget = e.newItem.itemCost
            dispatch(Precon.actions.putAlternateIndirect([{...e.newItem}]))
            calcAlternateTotal(s.collectionView.items, {...e.newItem})
          } else {
            e.newItem.status = 'Excluded'
            e.newItem.note = 'Excluded'
            e.newItem.budget = 0
            dispatch(Precon.actions.putAlternateIndirect([{...e.newItem}]))
            calcAlternateTotal(s.collectionView.items, {...e.newItem})
          }
        } else {
          const appToast = new AppToast(undefined, 'Only Preconstruction, General Conditions, and Project Contingency rows are editable.')
          appToast.displayErrorToast()
        }
        break
      default:
      // throw 'Unknown data action';
    }
  }
}

const FooterWithinForm = ({formId, onCancel, onDelete, onSubmitRecalc, alternateID}) => {
  const {values} = useFormikContext()

  const handleClickDelete = () => {
    onDelete(values)
    return null
  }
  return (
    <Grid container justifyContent='space-between'>
      <Grid item>
        {alternateID ? (
          <Button variant='warning' onClick={handleClickDelete}>
            Delete
          </Button>
        ) : null}
      </Grid>
      <Grid item>
        {alternateID ? (
          <Button variant='success' onClick={() => onSubmitRecalc(values)}>
            Refresh
          </Button>
        ) : null}
        <Button variant='secondary' onClick={onCancel} className='ml-[10px]'>
          Cancel
        </Button>
        <Button type='submit' form={formId} variant='primary' className='ml-[10px]'>
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export default Alternate
