import Modal from 'react-bootstrap/Modal'
import {GlobalModal, useAppModal} from '../../context/GlobalModalContext'
import Subcontractor from '../../pages/home/subcontractors/Subcontractor'

export const SubContractorModalKey = '@Modal_SubContractor'
const SubContractorModal = ({existingSubcontractors, addSubcontractorToBid, removeSubcontractorFromBid, bidTradeQuoteID, subcontractorCompanyName, subcontractorId, setSelectedContractor}) => {
  const {closeModal} = useAppModal()
  return (
    <GlobalModal modalKey={SubContractorModalKey} dialogClassName='edit-lg-dialog-modal'>
      <Modal.Header closeButton className='edit-lg-modal'>
        <Modal.Title>{subcontractorCompanyName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='edit-lg-modal'>
        {subcontractorId != null ? (
          <Subcontractor
            subcontractorID={subcontractorId}
            existingSubcontractors={existingSubcontractors}
            addSubcontractorToBid={addSubcontractorToBid}
            removeSubcontractorFromBid={removeSubcontractorFromBid}
            setSelectedContractor={setSelectedContractor}
            closeModal={closeModal}
            bidTradeQuoteID={bidTradeQuoteID}
          />
        ) : null}
      </Modal.Body>
    </GlobalModal>
  )
}

export default SubContractorModal
