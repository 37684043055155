import {useCallback, useMemo, useRef} from 'react'
import FieldLabel from '../FieldLabel'
import TextField from '@mui/material/TextField'

const SetupTextField = (props) => {
  const {
    label,
    getLabel,
    id,
    field: {name, onBlur, onChange, value},
    form: {errors, setFieldTouched, values},
    onBlurCallback,
    disabled,
    getDisabled,
    getClassname,
    className,
    ...inputProps
  } = props

  const fieldLabel = useMemo(() => {
    let result = label
    if (getLabel) {
      result = getLabel(name, value, values)
    }
    return result
  }, [getLabel, label, name, value, values])

  const inputClassname = useMemo(() => {
    let result = className || ''
    if (getClassname) {
      result += ' ' + getClassname(name, value, values)
    }
    return result
  }, [className, getClassname, name, value, values])

  const disableField = useMemo(() => {
    let result = disabled
    if (getDisabled) {
      result = getDisabled(name, value, values)
    }
    return result
  }, [disabled, getDisabled, name, value, values])

  const hasError = !!errors[name]
  const errorClassname = hasError ? 'setup-error-field-cell' : ''

  const prevValueRef = useRef(value)
  const updatePreviousValue = () => {
    prevValueRef.current = value
  }

  const handleChange = (val) => {
    updatePreviousValue()
    onChange(name)(val)
  }

  const handleBlur = useCallback(() => {
    setFieldTouched(name)
    onBlur(name)
    // Only call onBlurCallback if the value has changed and does not have error
    if (!!onBlurCallback && prevValueRef.current !== value && !hasError) {
      onBlurCallback(name, value, values)
    }
  }, [hasError, name, onBlur, onBlurCallback, setFieldTouched, value, values])

  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      handleBlur()
      updatePreviousValue()
    }
  }
  return (
    <>
      <td className={errorClassname}>
        <FieldLabel field={fieldLabel} disabled={disableField} />
      </td>
      <td className={errorClassname}>
        <TextField
          id={id}
          name={name}
          className={inputClassname}
          onClick={updatePreviousValue}
          onBlur={handleBlur}
          onKeyDown={handlePressEnter}
          value={value}
          onChange={handleChange}
          error={hasError}
          disabled={disableField}
          {...inputProps}
        />
      </td>
    </>
  )
}
export default SetupTextField
