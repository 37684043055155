import {put, takeLatest, all, select, takeEvery} from 'redux-saga/effects'
import axios from 'axios'
import {persistReducer} from 'redux-persist'
import {REHYDRATE} from 'redux-persist/lib/constants'
import storage from 'redux-persist/lib/storage'
import AppToast from '../../helpers/AppToast'
import isCached from '../../components/Cache'
import actionTypes from './project/actionTypes'

export const BASE_URL = process.env.REACT_APP_APIIP + '/calc'

const assumedUserID = (state) => state.auth.assumedUserID
const projectID = (state) => state.project.projectID
const projectLoad = (state) => state.project.projectLoading
const projectHydration = (state) => state.project.projectHydration
const displayOptions = (state) => state.reference.DisplayOptions
const cache = (state) => state.project.cache

const initialState = {
  cache: {},
  projectLoading: false,
  projectHydration: false,
  projectID: '',
  bidID: '',
  editPermission: false,
  hubConnection: undefined,
  apiMessages: '',
  projectHeader: {
    values: {
      name: '',
      street: '',
      city: '',
      state: '',
      office: '',
      primaryBusinessUnit: '',
      leadDesignFirm: '',
      opportunityNo: '',
      contractType: '',
      isCopy: '',
      copyDescription: '',
      progress: 0
    },
    projectID: 0
  },

  // Collections
  Dashboard: {values: {}, projectID: 0},
  ProjectConfiguration: {
    values: {
      TradePricingIndirectCostInclusions: '[]',
      CalculateFeeExclusions: '[]'
    },
    projectID: 0
  },
  ProjectInformationGrid: {values: {ProjectAreaUnit: 'N/A'}, projectID: 0},
  SummaryRequirementsGrid: {values: {}, projectID: 0},
  SummaryLaborSummaryTable: {values: {}, projectID: 0},
  SummaryHITTCostFeeTable: {values: {}, projectID: 0},
  SummaryReturnOnLaborTable: {values: {}, projectID: 0},
  Manpower: {values: {}, projectID: 0},
  PreconDept: {values: {}, projectID: 0},
  FieldOfficeSupport: {values: {}, projectID: 0},
  GeneralRequirements: {values: {}, projectID: 0},
  NonBillable: {values: {}, projectID: 0},

  // Grids
  BidPermissions: {values: [], projectID: 0},
  ClientSummaryGrid: {values: [], projectID: 0},
  ProjectsGrid: {values: [], projectID: 0},
  ExpenseItemsToAdd: {values: [], projectID: 0},
  FieldOfficeSupportExpensesGrid: {values: [], projectID: 0},
  GeneralRequirementsExpensesGrid: {values: [], projectID: 0},
  ManpowerGrid: {values: [], projectID: 0},
  ManpowerGridPostingRates: {values: [], projectID: 0},
  ManpowerGridWeeks: {values: [], projectID: 0},
  ManpowerGridMonths: {values: [], projectID: 0},
  ManpowerCostGrid: {values: [], projectID: 0},
  NonBillableExpensesGrid: {values: [], projectID: 0},
  PreconDeptExpensesGrid: {values: [], projectID: 0},
  PreconDeptExpensesGridTemp: {values: [], projectID: 0},
  PreconResourceGrid: {values: [], projectID: 0},
  PreconResourceGridTemp: {values: [], projectID: 0},
  ProjectBusinessUnits: {values: [], projectID: 0},
  ProjectEmployeeRolesToAdd: {values: [], projectID: 0},
  ProjectOperationsEmployeesToAdd: {values: [], projectID: 0},
  ProjectVersions: {values: [], projectID: 0},
  ProjectTasks: {values: [], projectID: 0},
  TargetEfficiency: {values: [], projectID: 0},
  TasksToAdd: {values: [], projectID: 0},
  APILogStatistics: {values: [], projectID: 0},
  SoftCostsGrid: {value: [], projectID: 0},

  exportXlsx: '',
  projectScheduleXlsx: '',
  clientSummaryXlsx: '',
  clientSUmmaryPDF: '',
  summaryCalculationsXlsx: '',
  pageTab: {summary: 'summary', setup: 'setup', tradescope: 'items'},
  PerDiemRate: {values: null},
  resourceBudget: {
    projectResourceID: 0,
    postingRate: 0,
    constructionBudget: 0,
    closeoutBudget: 0,
    newConstructionHours: 0,
    newCloseoutHours: 0,
    PostingRate: 0,
    constructionWeeklyBudget: 0,
    closeoutWeeklyBudget: 0,
    EmployeeName: ''
  },
  reports: {dashboardTrends: [], recentActivities: []},
  APIStatus: {
    projectResourceCalc: 'Not Started'
  },
  bidStatus: {
    title: 'Loading...',
    projectinformation: 'Not Started',
    requirements: 'Not Started',
    costAndFee: 'Not Started',
    riskEvaluation: 'Not Started',
    preconDept: 'Not Started',
    manpower: 'Not Started',
    fieldOfficeSupport: 'Not Started',
    generalRequirements: 'Not Started'
  },
  Temp: {
    preconAddForm: {
      id: undefined,
      formEmployee: null,
      formEmployeeID: '',
      formTeamID: 1,
      formTeamName: 'Operations',
      formIsPartnerResource: 'false',
      formEmployeeRoleID: '',
      formPartnerPostingRate: 0,
      formConstructionDefaultHours: 0,
      formCloseoutDefaultHours: 0,
      formRateDiscountPC: 100
    }
  }
}

export const reducer = persistReducer({storage, key: 'HPM2-Project-2023-03-02', whitelist: ['projectID', 'bidID', 'editPermission', 'projectHeader']}, (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INITIAL': {
      return initialState
    }
    case 'CLEAR_PROJECT': {
      // Loop cache and remove any entry with a projectID in the payload
      let newCache = {}
      for (const [key, value] of Object.entries(state.cache)) {
        if (!(value.payload && value.payload.projectID)) {
          newCache = {...newCache, [key]: value}
        }
      }
      return {
        ...state,
        // Trimmed Cache
        cache: newCache,
        // Header
        projectHeader: {...initialState.projectHeader, values: {...initialState.projectHeader.values, name: 'Loading...'}},
        // Collections
        ProjectConfiguration: {values: {}, projectID: 0},
        ProjectInformationGrid: {values: {}, projectID: 0},
        SummaryRequirementsGrid: {values: {}, projectID: 0},
        SummaryLaborSummaryTable: {values: {}, projectID: 0},
        SummaryHITTCostFeeTable: {values: {}, projectID: 0},
        SummaryReturnOnLaborTable: {values: {}, projectID: 0},
        Manpower: {values: {}, projectID: 0},
        PreconDept: {values: {}, projectID: 0},
        FieldOfficeSupport: {values: {}, projectID: 0},
        GeneralRequirements: {values: {}, projectID: 0},
        NonBillable: {values: {}, projectID: 0},
        // Grids
        BidPermissions: {values: [], projectID: 0},
        ClientSummaryGrid: {values: [], projectID: 0},
        ExpenseItemsToAdd: {values: [], projectID: 0},
        FieldOfficeSupportExpensesGrid: {values: [], projectID: 0},
        GeneralRequirementsExpensesGrid: {values: [], projectID: 0},
        ManpowerGrid: {values: [], projectID: 0},
        ManpowerGridWeeks: {values: [], projectID: 0},
        ManpowerGridMonths: {values: [], projectID: 0},
        ManpowerCostGrid: {values: [], projectID: 0},
        NonBillableExpensesGrid: {values: [], projectID: 0},
        PreconDeptExpensesGrid: {values: [], projectID: 0},
        PreconResourceGrid: {values: [], projectID: 0},
        ProjectBusinessUnits: {values: [], projectID: 0},
        ProjectEmployeeRolesToAdd: {values: [], projectID: 0},
        ProjectOperationsEmployeesToAdd: {values: [], projectID: 0},
        ProjectVersions: {values: [], projectID: 0},
        ProjectTasks: {values: [], projectID: 0},
        TargetEfficiency: {values: [], projectID: 0},
        TasksToAdd: {values: [], projectID: 0}
      }
    }
    case 'SET_CACHE': {
      return {
        ...state,
        cache: {...state.cache, [action.payload.caller.type]: {payload: action.payload.clear ? 'VOID' : action.payload.caller.payload, resultCount: action.payload.count, timestamp: Date.now()}}
      }
    }
    case 'SET_TAB': {
      return {
        ...state,
        pageTab: {...state.pageTab, [action.payload.page]: action.payload.tab}
      }
    }
    case 'SET_STATUS': {
      return {
        ...state,
        Status: action.Status
      }
    }
    case 'SET_APISTATUS': {
      return {
        ...state,
        APIStatus: {...state.APIStatus, [action.payload.statusName]: action.payload.statusValue}
      }
    }
    case 'SET_HUBCONNECTION': {
      return {
        ...state,
        hubConnection: action.payload
      }
    }
    case 'SET_APIMESSAGES': {
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
      var now = new Date()
      return {
        ...state,
        apiMessages: String(now.getHours()).padStart(2, '0') + ':' + String(now.getMinutes()).padStart(2, '0') + ' - ' + action.payload
      }
    }
    case 'SET_TEMP': {
      return {
        ...state,
        Temp: {...state.Temp, [action.payload.tempName]: action.payload.tempValue}
      }
    }
    case 'SET_PROJECTS': {
      return {
        ...state,
        projects: action.projects
      }
    }
    case 'SET_PROJECTID': {
      return {
        ...state,
        projectID: action.payload.projectID,
        bidID: action.payload.bidID,
        editPermission: action.payload.editPermission,
        projectLoading: true
      }
    }
    case 'SET_PROPERTIES': {
      return {
        ...state,
        properties: action.payload
      }
    }
    case 'SET_COLLECTION': {
      return {
        ...state,
        [action.collectionName]: action.collection
      }
    }
    case 'SET_PROPERTY': {
      return {
        ...state,
        [action.payload.CollectionName]: {
          values: {
            ...state[action.payload.CollectionName].values,
            [action.payload.PropertyName]: action.payload.PropertyValue
          }
        }
      }
    }
    case 'ADD_MANPOWER_COST_GRID_ROW': {
      const newGrid = state[action.payload.gridName]
      newGrid.push(action.payload.item)
      // console.log(newGrid)
      return {
        ...state,
        [action.payload.gridName]: newGrid
      }
    }
    case 'PUT_PRECON_RESOURCE_GRID_ROW': {
      // loop grid, when we get to the changed row, overwrite it
      console.log()
      const changedGrid = state.PreconResourceGrid.values.map((row) => (action.payload.id === row.id ? action.payload : row))
      return {
        ...state,
        PreconResourceGrid: {values: changedGrid, projectID: state.projectID}
      }
    }
    case 'SET_RESOURCE_BUDGET': {
      return {
        ...state,
        resourceBudget: action.payload
      }
    }
    case 'CHANGE_EXPENSE_GRID_ROW': {
      return {
        ...state,
        [action.payload.gridName]: {
          ...[action.payload.gridName],
          values: action.payload.grid
        }
      }
    }
    case 'REMOVE_EXPENSE_GRID_ROW': {
      // console.log('REMOVE_EXPENSE_GRID_ROW')
      // console.log(action.payload.grid)
      return {
        ...state,
        [action.payload.gridName]: {
          ...[action.payload.gridName],
          values: action.payload.grid
        }
      }
    }
    case 'SET_GRID': {
      return {
        ...state,
        [action.payload.gridName]: action.payload.grid
      }
    }
    case 'SET_EXPENSE_GRID_TOTALS': {
      return {
        ...state,
        [action.payload.CollectionName]: {
          ...state[action.payload.CollectionName],
          [action.payload.PropertyNameCost]: action.payload.PropertyValueCost,
          [action.payload.PropertyNameQuoted]: action.payload.PropertyValueQuoted,
          [action.payload.PropertyNameNonBillable]: action.payload.PropertyValueNonBillable
        }
      }
    }
    case 'SET_ContractDurationWeeksQuoted': {
      return {
        ...state,
        SummaryRequirementsGrid: {
          ...state.SummaryRequirementsGrid,
          ContractDurationWeeksQuoted: action.payload.ContractDurationWeeksQuoted,
          Calc_ContractDurationMonthsQuoted: action.payload.Calc_ContractDurationMonthsQuoted,
          Calc_ContractDurationWeeksDelta: action.payload.Calc_ContractDurationWeeksDelta,
          Calc_ContractDurationMonthsDeltaPC: action.payload.Calc_ContractDurationMonthsDeltaPC,
          Calc_ContractDurationMonthsDelta: action.payload.Calc_ContractDurationMonthsDelta,
          Calc_ContractDurationWeeksDeltaPC: action.payload.Calc_ContractDurationWeeksDeltaPC
        }
      }
    }
    case 'SET_ContractDurationWeeksQuotedOnly': {
      return {
        ...state,
        SummaryRequirementsGrid: {
          ...state.SummaryRequirementsGrid,
          ContractDurationWeeksQuoted: action.payload.ContractDurationWeeksQuoted
        }
      }
    }
    case 'SET_SummaryHITTCostFeeTable': {
      return {
        ...state,
        SummaryHITTCostFeeTable: {
          ...state.SummaryHITTCostFeeTable,
          ...action.payload
        }
      }
    }
    case 'SET_EXPORT_XLSX': {
      return {
        ...state,
        exportXlsx: action.payload
      }
    }
    case 'SET_PROJECTSCHEDULE_XLSX': {
      return {
        ...state,
        projectScheduleXlsx: action.payload
      }
    }
    case 'SET_SummaryCalculationsXlsx': {
      return {
        ...state,
        summaryCalculationsXlsx: action.payload
      }
    }
    case 'SET_CLIENTSUMMARY_XLSX': {
      return {
        ...state,
        clientSummaryXlsx: action.payload
      }
    }
    case 'SET_CLIENTSUMMARY_PDF': {
      return {
        ...state,
        clientSummaryPDF: action.payload
      }
    }
    case 'SET_REPORT': {
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.reportName]: action.payload.grid
        }
      }
    }
    case 'SET_FieldOfficeSupportExpensesGrid': {
      return {
        ...state,
        FieldOfficeSupportExpensesGrid: action.payload
      }
    }
    case 'SET_GeneralRequirementsExpensesGrid': {
      return {
        ...state,
        GeneralRequirementsExpensesGrid: action.payload
      }
    }
    case 'STOP_REHYDRATE': {
      // Sets flag to stop REHYDRATE running on project API calls
      // console.log('Stop Rehydrate Running');
      return {...state, projectHydration: false}
    }
    case REHYDRATE: {
      // Detecting Redux Persist Rehydrate process underway - though this runs a few times and not all are for Project ID
      // console.log('Rehydrate Running');
      if (state.projectID && !state.projectLoading) {
        // Ok Project ID has now been set by Redux Persist - Rehydrate thus set projectLoading flag to true
        // console.log('Project Loading and Hydrate Flag Set');
        return {...state, projectLoading: true, projectHydration: true}
      }
      return state
    }

    default:
      return state
  }
})

// ############## Action Helpers ##############

export function* fetchCollections(rehydrateFlag) {
  process.env.REACT_APP_DEBUG && console.log('Fetch Collections, rehydrateFlag: ' + rehydrateFlag)
  // check projectLoading flag set - otherwise projectid may not be set which will cause FETCH_PROPERTIES to bounce off
  // console.log('Fetching Collections Mastercall Pre Check');
  let projectLoading = yield select(projectLoad)
  let projecthydration = yield select(projectHydration)
  // let authTokenChk = yield select(authTokenCheck);
  if ((projectLoading && !rehydrateFlag) || (rehydrateFlag && projecthydration)) {
    // Prevent hydrate running again
    if (rehydrateFlag && projecthydration) {
      yield put({type: 'STOP_REHYDRATE'})
    }
  }
}

export function* fetchSummary(action) {
  yield put({type: 'FETCH_ProjectConfiguration', payload: action.payload})
  yield put({type: 'FETCH_ProjectInformationGrid', payload: action.payload})
  yield put({type: 'FETCH_SummaryHITTCostFeeTable', payload: action.payload})
  yield put({type: 'FETCH_SummaryRequirementsGrid', payload: action.payload})
  yield put({type: 'FETCH_SummaryLaborSummaryTable', payload: action.payload})
  yield put({type: 'FETCH_SummaryReturnOnLaborTable', payload: action.payload})
}

export function* clearSummary() {
  process.env.REACT_APP_DEBUG && console.log('****** Clear Summary ******')
  yield put({type: 'FETCH_ProjectInformationGrid', payload: {popCache: true}})
  yield put({type: 'FETCH_ProjectConfiguration', payload: {popCache: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_SummaryHITTCostFeeTable'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_SummaryRequirementsGrid'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_SummaryLaborSummaryTable'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_SummaryReturnOnLaborTable'}, clear: true}})
}

export function* clearExpenses() {
  console.log('clearExpenses')
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_NonBillable'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_NonBillableExpensesGrid'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_PreconDept'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_PreconDeptExpensesGrid'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_FieldOfficeSupport'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_FieldOfficeSupportExpensesGrid'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_GeneralRequirements'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_GeneralRequirementsExpensesGrid'}, clear: true}})
  yield put({type: 'SET_CACHE', payload: {caller: {type: 'FETCH_ClientSummaryGrid'}, clear: true}})
}

export function* fetchCollection(collectionName, action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/collection/' + currentProjectID + '/' + collectionName
        const response = yield axios.get(newURL)
        // Check response
        if (response.status === 200) {
          if (response.returnStatus === 'Error') {
            yield put({type: 'PUT_ERROR', payload: {Request: 'fetchCollection (P1E2): ' + collectionName, ErrorType: 'REDUX Function', Message: response.data.returnText}})
            yield put({type: 'SET_COLLECTION', collectionName: collectionName, collection: {values: {}, projectID: 0}})
          } else {
            const switchDataType = (item) => {
              if (item.DataType === 'Numeric') {
                item.Value = parseFloat(item.Value)
              }
              return item.Value
            }
            const newcollection = JSON.parse(response.data[0].result)
            var PropertiesValues = newcollection.reduce((obj, item) => Object.assign(obj, {[item.Name]: switchDataType(item)}), {})
            yield put({type: 'SET_COLLECTION', collectionName: collectionName, collection: {values: PropertiesValues, projectID: currentProjectID}})
            yield put({type: 'SET_CACHE', payload: {caller: action, count: newcollection.length}})
            process.env.REACT_APP_DEBUG && console.log(`ok fetched: ${action.type} - ${collectionName}`)
          }
        } else {
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchCollection (P2E2): ' + collectionName, ErrorType: 'REDUX Function', Message: response.data.returnText}})
          yield put({type: 'SET_COLLECTION', collectionName: collectionName, collection: {values: {}, projectID: 0}})
        }
      }
    } catch (error) {
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchCollection (P2E3): ' + collectionName, ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_COLLECTION', collectionName: collectionName, collection: {values: {}, projectID: 0}})
    }
  }
}

export function* fetchDashboard(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/collection/1/Dashboard'
      const response = yield axios.get(newURL)
      if (response.status === 200) {
        if (response.returnStatus === 'Error') {
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchDashboard', ErrorType: 'REDUX Function', Message: response.data.returnText}})
          yield put({type: 'SET_COLLECTION', collectionName: 'Dashboard', collection: {values: {}, projectID: 0}})
        } else {
          process.env.REACT_APP_DEBUG && console.log(`ok fetched: ${action.type}`)
          const switchDataType = (item) => {
            if (item.DataType === 'Numeric') {
              item.Value = parseFloat(item.Value)
            }
            return item.Value
          }
          const newcollection = JSON.parse(response.data[0].result)
          var PropertiesValues = newcollection.reduce((obj, item) => Object.assign(obj, {[item.Name]: switchDataType(item)}), {})
          yield put({type: 'SET_COLLECTION', collectionName: 'Dashboard', collection: {values: PropertiesValues, projectID: 0}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: newcollection.length}})
        }
      } else {
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchDashboard ', ErrorType: 'REDUX Function', Message: response.data.returnText}})
        yield put({type: 'SET_COLLECTION', collectionName: 'Dashboard', collection: {values: {}, projectID: 0}})
      }
    } catch (error) {
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchDashboard', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_COLLECTION', collectionName: 'Dashboard', collection: {values: {}, projectID: 0}})
    }
  }
}

export function* putProperties(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/properties/' + currentProjectID
    const jPayload = JSON.stringify(action.payload)
    const response = yield axios.put(newURL, {jPayload})
    process.env.REACT_APP_DEBUG && console.log(response)
    // Check response

    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('Ok saved.')
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putProjectConfigurationOption(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/projectconfigurationoption/' + currentProjectID
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('Ok saved.')
      yield put({type: 'FETCH_ProjectConfiguration', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putManpowerGridRow(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/manpowergridrow/' + currentProjectID
    const jPayload = JSON.stringify(action.payload)
    const response = yield axios.put(newURL, jPayload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putProjectTasks(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/putprojecttasks/' + currentProjectID
    const jPayload = JSON.stringify(action.payload)
    const response = yield axios.put(newURL, {jPayload})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putNewExpenseGridRow(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/newexpensegridrow/' + currentProjectID
    const jPayload = JSON.stringify(action.payload)
    const response = yield axios.put(newURL, {jPayload})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putExpenseGridRow(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/expensegridrow/' + currentProjectID
    const jPayload = JSON.stringify(action.payload.row)
    const response = yield axios.put(newURL, [{jPayload}])
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putRemoveExpenseGridRow(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/removeexpensegridrow/' + currentProjectID + '/' + action.payload.projectExpenseItemID
    process.env.REACT_APP_DEBUG && console.log(newURL)
    const response = yield axios.put(newURL)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok deleted')
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchExpensesGridGeneric(gridName, action, isTemp = false) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/getexpensegrid/' + currentProjectID
        process.env.REACT_APP_DEBUG && console.log(`${newURL}`)
        const jPayload = JSON.stringify({bidSection: gridName})
        const response = yield axios.put(newURL, jPayload)
        const finalGridName = gridName.replaceAll(' ', '') + 'ExpensesGrid' + (isTemp ? 'Temp' : '')
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok fetched: ${gridName}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: finalGridName, grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchExpensesGrid(gridName, action) {
  yield fetchExpensesGridGeneric(gridName, action, false)
}

export function* fetchExpensesGridTemp(gridName, action) {
  yield fetchExpensesGridGeneric(gridName, action, true)
}

export function* calcExpenseGrid(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  // Note this is not an Axios or API call
  class calcGrid extends Array {
    sum(key) {
      return this[0].reduce((a, b) => a + (b[key] || 0), 0)
    }
  }
  const changedGrid = new calcGrid(action.payload.grid)

  const totalCost = changedGrid.sum('Subtotal')
  const totalQuoted = changedGrid.sum('QuotedSubTotal')
  const totalNonBillable = totalQuoted - totalCost

  const collectionName = action.payload.gridName.replace('ExpensesGrid', '') //get the collection name from the expense grid name
  const costPropertyName = 'Calc_Total' + collectionName + 'ExpensesCost'
  const quotedPropertyName = 'Calc_Total' + collectionName + 'ExpensesQuoted' //Calc_TotalFieldOfficeSupportQuoted
  const nonBillablePropertyName = 'Calc_Total' + collectionName + 'Delta' //Calc_TotalFieldOfficeSupportDelta

  yield put({
    type: 'SET_EXPENSE_GRID_TOTALS',
    payload: {
      CollectionName: collectionName,
      PropertyNameCost: costPropertyName,
      PropertyValueCost: totalCost,
      PropertyNameQuoted: quotedPropertyName,
      PropertyValueQuoted: totalQuoted,
      PropertyNameNonBillable: nonBillablePropertyName,
      PropertyValueNonBillable: totalNonBillable
    }
  })

  // yield put({ type: 'PUT_EXPENSE_GRID_TOTALS', payload: {
  //   [costPropertyName]: totalCost,
  //   [quotedPropertyName]: totalQuoted } });

  if (action.payload.row !== undefined) {
    yield put({
      type: 'PUT_EXPENSE_GRID_ROW',
      payload: {
        row: action.payload.row,
        collectionName: collectionName,
        bidSection: action.payload.bidSection
      }
    })
  }
}

export function* fetchExpenseItemsToAdd(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/expenseitemstoadd/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ExpenseItemsToAdd', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'ExpenseItemsToAdd', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'ExpenseItemsToAdd', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchProjectEmployeeRolesToAdd(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let buFilter = ''
      if (!action.payload.buFilter || action.payload.buFilter === '') {
        buFilter = 'none'
      } else {
        buFilter = action.payload.buFilter
      }
      if (action.payload.projectID > 0) {
        const newURL = BASE_URL + '/employeerolestoadd/' + action.payload.projectID + '/' + buFilter
        process.env.REACT_APP_DEBUG && console.log(newURL)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ProjectEmployeeRolesToAdd', grid: {values: gridValues, projectID: action.payload.projectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'ProjectEmployeeRolesToAdd', grid: {values: [], projectID: action.payload.projectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'ProjectEmployeeRolesToAdd', grid: {values: [], projectID: action.payload.projectID}}})
    }
  }
}

export function* fetchProjectOperationsEmployeesToAdd(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/operationsemployeetoadd/' + currentProjectID
        process.env.REACT_APP_DEBUG && console.log(newURL)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ProjectOperationsEmployeesToAdd', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'ProjectOperationsEmployeesToAdd', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'ProjectOperationsEmployeesToAdd', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchTasksToAdd(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/taskstoadd/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'TasksToAdd', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'TasksToAdd', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'TasksToAdd', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchManpowerGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    let options = yield select(displayOptions)
    let period = options.some((row) => row.displayView === 'Manpower' && row.optionName === 'period')
      ? options.find((row) => row.displayView === 'Manpower' && row.optionName === 'period').optionValue
      : 'week'
    if (action.payload && action.payload.period) {
      period = action.payload.period
    }
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/manpowergrid/' + currentProjectID + '/' + period
        process.env.REACT_APP_DEBUG && console.log(newURL)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGrid', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGrid', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGrid', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchManpowerGridPostingRates(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    let options = yield select(displayOptions)
    let period = options.some((row) => row.displayView === 'Manpower' && row.optionName === 'period')
      ? options.find((row) => row.displayView === 'Manpower' && row.optionName === 'period').optionValue
      : 'week'
    if (action.payload && action.payload.period) {
      period = action.payload.period
    }
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/ManpowerGridPostingRates/' + currentProjectID + '/' + period
        process.env.REACT_APP_DEBUG && console.log(newURL)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGridPostingRates', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGridPostingRates', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGridPostingRates', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchProjectWeeks(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/projectweeks/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGridWeeks', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGridWeeks', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGridWeeks', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchProjectMonths(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/projectmonths/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGridMonths', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGridMonths', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerGridMonths', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchTasks(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/tasks/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'Tasks', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'Tasks', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'Tasks', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchManpowerCostGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/manpowercostgrid/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerCostGrid', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerCostGrid', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'ManpowerCostGrid', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchPreconResourceGridGeneric(action, gridName) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/preconresourcegrid/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName, grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName, grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName, grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* fetchPreconResourceGrid(action) {
  yield fetchPreconResourceGridGeneric(action, 'PreconResourceGrid')
}

export function* fetchPreconResourceGridTemp(action) {
  yield fetchPreconResourceGridGeneric(action, 'PreconResourceGridTemp')
}

export function* fetchTargetEfficiency(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/targetefficiency/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_COLLECTION', collectionName: 'TargetEfficiency', collection: {values: gridValues, projectID: currentProjectID}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchClientSummaryGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/clientsummary/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ClientSummaryGrid', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
          yield put({type: 'SET_CLIENTSUMMARY_XLSX', payload: ''})
          yield put({type: 'SET_CLIENTSUMMARY_PDF', payload: ''})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchExportXlsx(action) {
  yield put({type: 'SET_EXPORT_XLSX', payload: ''})
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    if (currentProjectID > 0) {
      const newURL = BASE_URL + '/excelexport'
      const response = yield axios.put(newURL, action.payload)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_EXPORT_XLSX', payload: response.data[0].exportXlsx})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchClientSummaryXlsx(action) {
  yield put({type: 'SET_CLIENTSUMMARY_XLSX', payload: ''})
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    if (currentProjectID > 0) {
      const newURL = BASE_URL + '/excelexport/' + currentProjectID + '/0'
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_CLIENTSUMMARY_XLSX', payload: response.data[0].exportXlsx})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchProjectScheduleXlsx(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    if (currentProjectID > 0) {
      const newURL = BASE_URL + '/projectscheduleexcel/' + currentProjectID
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_PROJECTSCHEDULE_XLSX', payload: response.data[0].exportXlsx})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchSummaryCalculationsXlsx(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    yield put({type: 'SET_SummaryCalculationsXlsx', payload: ''}) // Reset the result container while processing continues.
    if (currentProjectID > 0) {
      const newURL = BASE_URL + '/summarycalculationsexcel/' + currentProjectID
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_SummaryCalculationsXlsx', payload: response.data[0].exportXlsx})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchClientSummaryPDF(action) {
  // Reset the result container while processing continues.
  yield put({type: 'SET_CLIENTSUMMARY_PDF', payload: ''})
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    if (currentProjectID > 0) {
      const newURL = BASE_URL + '/clientsummarypdf/' + currentProjectID
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_CLIENTSUMMARY_PDF', payload: response.data})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchProjectPerDiemRates(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      var newPayload = {...action.payload, projectID: currentProjectID}
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(newPayload))
      const newURL = process.env.REACT_APP_APIIP + '/reference/perDiemRates'
      const response = yield axios.put(newURL, newPayload)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data[0])
        yield put({type: 'SET_COLLECTION', collectionName: 'PerDiemRate', collection: {values: response.data[0], projectID: currentProjectID}})
        yield put({type: 'SET_CACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* checkProjectPerDiemRates(action) {
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/checkresourceperdiem'
    const newPayload = {...action.payload, projectID: currentProjectID}
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, newPayload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      var result = JSON.parse(response.data[0].result)
      process.env.REACT_APP_DEBUG && console.log(result)
      process.env.REACT_APP_DEBUG && console.log(result[0].tempPerDiemDays)
      process.env.REACT_APP_DEBUG && console.log(result[0].tempPerDiemCost)
      yield put({type: 'SET_FILTERS', payload: {filterName: 'tempPerDiemDays', filterValue: result[0].tempPerDiemDays}})
      yield put({type: 'SET_FILTERS', payload: {filterName: 'tempPerDiemCost', filterValue: result[0].tempPerDiemCost}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchProjectsGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    var status
    if (action.payload.status) {
      status = action.payload.status
    } else {
      status = '0'
      yield put({type: 'SET_FILTERS', payload: {filterName: 'projectsGridStatus', filterValue: '0'}})
    }
    try {
      let currentassumedUserID = yield select(assumedUserID)
      const newURL = BASE_URL + '/projectsgrid/' + currentassumedUserID + '/' + status
      process.env.REACT_APP_DEBUG && console.log(newURL)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_GRID', payload: {gridName: 'ProjectsGrid', grid: {values: response.data, projectID: 0}}})
        yield put({type: 'SET_CACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchProjectVersions(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/projectversions/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ProjectVersions', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidPermissions(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/bidpermissions/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'BidPermissions', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchProjectBusinessUnits(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/projectbusinessunits/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response.data)
          // const gridValues = JSON.parse(response.data).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ProjectBusinessUnits', grid: {values: response.data, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putProjectBusinessUnits(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    const newURL = BASE_URL + '/projectbusinessunits/'
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_ProjectBusinessUnits', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchProjectTasks(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload && action.payload.projectID > 0) {
        const newURL = BASE_URL + '/projecttasks/' + action.payload.projectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'ProjectTasks', grid: {values: gridValues, projectID: action.payload.projectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putResetPostingRates(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/ResetPostingRates/' + currentProjectID
    const jPayload = JSON.stringify(action.payload.newItem)
    const response = yield axios.put(newURL, {jPayload})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putManpowerCostGridRow(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/manpowercostgridrow/' + currentProjectID
    const jPayload = JSON.stringify(action.payload.newItem)
    const response = yield axios.put(newURL, {jPayload})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putResourceBudget(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/resourcebudget/' + currentProjectID
    // const jPayload =  JSON.stringify(action.payload)
    const response = yield axios.put(newURL, JSON.stringify(action.payload))
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putResourceNote(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/resourcenote/' + currentProjectID
    // const jPayload =  JSON.stringify(action.payload)
    const response = yield axios.put(newURL, JSON.stringify(action.payload))
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putManpowerGridRowEmployee(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/manpowercostgridrowemployee/' + currentProjectID
    const jPayload = JSON.stringify(action.payload)
    const response = yield axios.put(newURL, {jPayload})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putPreconResourceGridRow(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/preconresourcegridrow/' + currentProjectID
    const response = yield axios.put(newURL, [action.payload])
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putPreconResourceGridRowEmployee(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/preconresourcegridrowemployee/' + currentProjectID
    // const jPayload =  JSON.stringify(action.payload)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putNewPreconGridRow(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/putprecongridrow/' + currentProjectID
    const jPayload = JSON.stringify(action.payload.item)
    const response = yield axios.put(newURL, {jPayload})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putNewManpowerGridRow(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/putnewmanpowergridrow/' + currentProjectID
    // const jPayload = JSON.stringify(action.payload.item)
    const response = yield axios.put(newURL, action.payload.item)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putDeleteManpowerGridRow(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    const newURL = BASE_URL + '/putdeletemanpowergridrow/' + action.payload.item.id
    const response = yield axios.put(newURL, {})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok deleted')
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putColumnsOrder(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/putcolumnsorder/' + currentProjectID + '/' + action.payload.gridName
    const jPayload = JSON.stringify(action.payload.grid)
    const response = yield axios.put(newURL, {jPayload})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteProject(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    const newURL = BASE_URL + '/deleteproject/' + action.payload
    const response = yield axios.put(newURL)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok deleted')
      yield put({type: 'FETCH_ProjectVersions', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putCopyProject(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    const newURL = BASE_URL + '/copyproject/' + action.payload.projectID + '/' + action.payload.copyDescription
    const response = yield axios.put(newURL)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok deleted')
      yield put({type: 'FETCH_ProjectVersions', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* copyExpenseTemplateItemsToProject(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/copyexpensetemplateitemstoproject/' + currentProjectID
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok copied')
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putResourcePerDiem(action) {
  try {
    const newURL = BASE_URL + '/resourceperdiem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putAddress(action) {
  try {
    const newURL = BASE_URL + '/address'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_ProjectPerDiemRates', payload: {projectID: action.payload.projectID, state: '', city: '', fiscalYear: '', popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchAPILogStatistics(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/apiLogStatistics/' + action.payload.projectID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_GRID', payload: {gridName: 'APILogStatistics', grid: {values: response.data, projectID: action.payload.projectID}}})
        yield put({type: 'SET_CACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchReport(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/report/' + action.payload.reportName + '/' + action.payload.assumedUserID
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REPORT', reportName: action.payload.reportName, payload: {reportName: action.payload.reportName, grid: gridValues}})
        yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchReportDashboardTrends(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/report/dashboardTrends/' + action.payload.assumedUserID
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        process.env.REACT_APP_DEBUG && console.log(gridValues)
        yield put({type: 'SET_REPORT', reportName: 'dashboardTrends', payload: {reportName: 'dashboardTrends', grid: gridValues}})
        yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchReportDashboardRecentActivities(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/report/recentActivities/' + action.payload.assumedUserID
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = response.data[0].result !== '' ? JSON.parse(response.data[0].result).map((item) => ({...item})) : []
        process.env.REACT_APP_DEBUG && console.log(gridValues)
        yield put({type: 'SET_REPORT', reportName: 'recentActivities', payload: {reportName: 'recentActivities', grid: gridValues}})
        yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchProjectHeader(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload.projectID > 0) {
        const newURL = BASE_URL + '/projectheader/' + action.payload.projectID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          process.env.REACT_APP_DEBUG && console.log(gridValues[0])
          yield put({type: 'SET_GRID', payload: {gridName: 'projectHeader', grid: {values: gridValues[0], projectID: action.payload.projectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          process.env.REACT_APP_DEBUG && console.log(response)
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      } else {
        yield put({type: 'SET_GRID', payload: {gridName: 'projectHeader', grid: initialState.projectHeader}})
        yield put({type: 'SET_CACHE', payload: {caller: action, count: 0}})
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchProjectSoftCostsGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID > 0) {
        const newURL = BASE_URL + '/SoftCosts/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
          yield put({type: 'SET_GRID', payload: {gridName: 'SoftCostsGrid', grid: {values: gridValues, projectID: currentProjectID}}})
          yield put({type: 'SET_CACHE', payload: {caller: action, count: gridValues.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
          yield put({type: 'SET_GRID', payload: {gridName: 'SoftCostsGrid', grid: {values: [], projectID: currentProjectID}}})
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
      yield put({type: 'SET_GRID', payload: {gridName: 'SoftCostsGrid', grid: {values: [], projectID: currentProjectID}}})
    }
  }
}

export function* putProjectSoftCostsGrid(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    let currentProjectID = yield select(projectID)
    const newURL = BASE_URL + '/SoftCosts/' + currentProjectID
    const jPayload = JSON.stringify(action.payload)
    const response = yield axios.put(newURL, {jPayload})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putRecalcProject(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    const newURL = BASE_URL + '/RecalcProject/' + action.payload.projectID
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export const actions = {
  setProjectInitialState: () => ({type: actionTypes.SET_INITIAL}),
  setCache: (payload) => ({type: actionTypes.SET_CACHE, payload: payload}),
  clearCache: (payload) => ({type: actionTypes.SET_CACHE, payload: {caller: {type: payload?.type}, clear: true}}),
  setProjectID: (payload) => ({type: actionTypes.SET_PROJECTID, payload: payload}),
  deleteProject: (payload) => ({type: actionTypes.DELETE_PROJECT, payload: payload}),
  putCopyProject: (payload) => ({type: actionTypes.COPY_PROJECT, payload: payload}),
  putRecalcProject: (payload) => ({type: actionTypes.PUT_RECALC_PROJECT, payload: payload}),

  clearProject: (payload) => ({type: actionTypes.CLEAR_PROJECT, payload: payload}),
  clearSummary: () => ({type: actionTypes.CLEAR_Summary}),
  clearExpenses: () => ({type: actionTypes.CLEAR_Expenses}),

  fetchProjectHeader: (payload) => ({type: actionTypes.FETCH_ProjectHeader, payload: payload}),
  fetchProjectsGrid: (payload) => ({type: actionTypes.FETCH_ProjectsGrid, payload: payload}),
  fetchDashboard: (payload) => ({type: actionTypes.FETCH_Dashboard, payload: payload}),
  fetchReportDashboardTrends: (payload) => ({type: actionTypes.FETCH_ReportDashboardTrends, payload: payload}),
  fetchReportDashboardRecentActivities: (payload) => ({type: actionTypes.FETCH_ReportDashboardRecentActivities, payload: payload}),
  fetchProjectBusinessUnits: (payload) => ({type: actionTypes.FETCH_ProjectBusinessUnits, payload: payload}),
  fetchProjectInformationGrid: (payload) => ({type: actionTypes.FETCH_ProjectInformationGrid, payload: payload}),
  fetchProjectConfiguration: (payload) => ({type: actionTypes.FETCH_ProjectConfiguration, payload: payload}),
  fetchSummaryRequirementsGrid: (payload) => ({type: actionTypes.FETCH_SummaryRequirementsGrid, payload: payload}),
  fetchSummaryHITTCostFeeTable: (payload) => ({type: actionTypes.FETCH_SummaryHITTCostFeeTable, payload: payload}),
  fetchNonBillable: (payload) => ({type: actionTypes.FETCH_NonBillable, payload: payload}),
  fetchNonBillableExpensesGrid: (payload) => ({type: actionTypes.FETCH_NonBillableExpensesGrid, payload: payload}),
  fetchProjectEmployeeRolesToAdd: (payload) => ({type: actionTypes.FETCH_ProjectEmployeeRolesToAdd, payload: payload}),
  fetchProjectOperationsEmployeesToAdd: (payload) => ({type: actionTypes.FETCH_ProjectOperationsEmployeesToAdd, payload: payload}),
  fetchProjectScheduleXlsx: () => ({type: actionTypes.FETCH_ProjectScheduleXlsx}),
  fetchSummaryCalculationsXlsx: () => ({type: actionTypes.FETCH_SummaryCalculationsXlsx}),

  fetchExportXlsx: (payload) => ({type: actionTypes.FETCH_ExportXlsx, payload: payload}),
  fetchClientSummaryXlsx: () => ({type: actionTypes.FETCH_ClientSummaryXlsx}),
  fetchClientSummaryPDF: () => ({type: actionTypes.FETCH_ClientSummaryPDF}),
  fetchProjectPerDiemRates: (payload) => ({type: actionTypes.FETCH_ProjectPerDiemRates, payload: payload}),
  checkResourcePerDiem: (payload) => ({type: actionTypes.CHECK_ProjectPerDiemRates, payload: payload}),
  fetchTasksToAdd: (payload) => ({type: actionTypes.FETCH_TasksToAdd, payload: payload}),
  fetchManpowerGridWeeks: (payload) => ({type: actionTypes.FETCH_ManpowerGridWeeks, payload: payload}),
  fetchManpowerGridMonths: (payload) => ({type: actionTypes.FETCH_ManpowerGridMonths, payload: payload}),
  fetchSummaryLaborSummaryTable: (payload) => ({type: actionTypes.FETCH_SummaryLaborSummaryTable, payload: payload}),
  fetchProjectVersions: (payload) => ({type: actionTypes.FETCH_ProjectVersions, payload: payload}),

  fetchSummary: (payload) => ({type: actionTypes.FETCH_Summary, payload: payload}),
  fetchPrecon: (payload) => ({type: actionTypes.FETCH_PreconDept, payload: payload}),
  fetchPreconResourceGrid: (payload) => ({type: actionTypes.FETCH_PreconResourceGrid, payload: payload}),
  fetchPreconResourceGridTemp: (payload) => ({type: actionTypes.FETCH_PreconResourceGrid_TEMP, payload: payload}),
  fetchPreconExpensesGrid: (payload) => ({type: actionTypes.FETCH_PreconDeptExpensesGrid, payload: payload}),
  fetchPreconExpensesGridTemp: (payload) => ({type: actionTypes.FETCH_PreconDeptExpensesGrid_TEMP, payload: payload}),
  fetchProjectWeeks: (payload) => ({type: actionTypes.FETCH_ManpowerGridWeeks, payload: payload}),
  fetchProjectMonths: (payload) => ({type: actionTypes.FETCH_ManpowerGridMonths, payload: payload}),
  fetchManpower: (payload) => ({type: actionTypes.FETCH_ManpowerGrandTotals, payload: payload}),
  fetchManpowerCostGrid: (payload) => ({type: actionTypes.FETCH_ManpowerCostGrid, payload: payload}),
  fetchManpowerGrid: (payload) => ({type: actionTypes.FETCH_ManpowerGrid, payload: payload}),
  fetchManpowerGridPostingRates: (payload) => ({type: actionTypes.FETCH_ManpowerGridPostingRates, payload: payload}),
  fetchFieldOfficeSupport: (payload) => ({type: actionTypes.FETCH_FieldOfficeSupport, payload: payload}),
  fetchFieldOfficeSupportExpensesGrid: (payload) => ({type: actionTypes.FETCH_FieldOfficeSupportExpensesGrid, payload: payload}),
  fetchGeneralRequirements: (payload) => ({type: actionTypes.FETCH_GeneralRequirements, payload: payload}),
  fetchGeneralRequirementsExpensesGrid: (payload) => ({type: actionTypes.FETCH_GeneralRequirementsExpensesGrid, payload: payload}),
  fetchClientSummaryGrid: (payload) => ({type: actionTypes.FETCH_ClientSummaryGrid, payload: payload}),
  fetchTargetEfficiency: (payload) => ({type: actionTypes.FETCH_TargetEfficiency, payload: payload}),
  fetchProjectTasks: (payload) => ({type: actionTypes.FETCH_ProjectTasks, payload: payload}),
  fetchAPILogStatistics: (payload) => ({type: actionTypes.FETCH_APILOGSTATISTICS, payload: payload}),
  fetchBidPermissions: (payload) => ({type: actionTypes.FETCH_BidPermissions, payload: payload}),

  setFieldOfficeSupportExpensesGrid: (payload) => ({type: actionTypes.SET_FieldOfficeSupportExpensesGrid, payload: payload}),
  setGeneralRequirementsExpensesGrid: (payload) => ({type: actionTypes.SET_GeneralRequirementsExpensesGrid, payload: payload}),
  setContractDurationWeeksQuoted: (payload) => ({type: actionTypes.SET_ContractDurationWeeksQuoted, payload: payload}),
  setContractDurationWeeksQuotedOnly: (payload) => ({type: actionTypes.SET_ContractDurationWeeksQuotedOnly, payload: payload}),
  putContractDurationWeeksQuoted: (payload) => ({type: actionTypes.PUT_ContractDurationWeeksQuoted, payload: payload}),
  setSummaryHITTCostFeeTable: (payload) => ({type: actionTypes.SET_SummaryHITTCostFeeTable, payload: payload}),
  putSummaryHITTCostFeeTable: (payload) => ({type: actionTypes.PUT_SummaryHITTCostFeeTable, payload: payload}),
  setProperty: (payload) => ({type: actionTypes.SET_PROPERTY, payload: payload}),
  putProperty: (payload) => ({type: actionTypes.PUT_PROPERTY, payload: payload}),
  putProjectConfigurationOption: (payload) => ({type: actionTypes.PUT_PROJECTCONFIGURATIONOPTION, payload: payload}),
  setGrid: (payload) => ({type: actionTypes.SET_GRID, payload: payload}),
  calcExpenseGrid: (payload) => ({type: actionTypes.CALC_EXPENSE_GRID, payload: payload}),
  changeExpenseGridRow: (payload) => ({type: actionTypes.CHANGE_EXPENSE_GRID_ROW, payload: payload}),
  putNewExpenseGridRow: (payload) => ({type: actionTypes.PUT_NEW_EXPENSE_GRID_ROW, payload: payload}),
  removeExpenseGridRow: (payload) => ({type: actionTypes.REMOVE_EXPENSE_GRID_ROW, payload: payload}),
  copyExpenseTemplateItemsToProject: (payload) => ({type: actionTypes.COPY_EXPENSE_TEMPLATE_ITEMS_TO_PROJECT, payload: payload}),
  putProjectBusinessUnits: (payload) => ({type: actionTypes.PUT_ProjectBusinessUnits, payload: payload}),
  putResourceNote: (payload) => ({type: actionTypes.PUT_RESOURCE_NOTE, payload: payload}),

  changeManpowerCostGridRow: (payload) => ({type: actionTypes.CHANGE_MANPOWER_COST_GRID_ROW, payload: payload}),
  putResourceBudget: (payload) => ({type: actionTypes.PUT_RESOURCE_BUDGET, payload: payload}),
  putManpowerGridRowEmployee: (payload) => ({type: actionTypes.CHANGE_MANPOWER_COST_GRID_ROW_EMPLOYEE, payload: payload}),
  removeManpowerCostGridRow: (payload) => ({type: actionTypes.REMOVE_MANPOWER_COST_GRID_ROW, payload: payload}),
  putManpowerGridRow: (payload) => ({type: actionTypes.PUT_MANPOWER_GRID_ROW, payload: payload}),
  putResourcePerDiem: (payload) => ({type: actionTypes.PUT_RESOURCE_PERDIEM, payload: payload}),
  putAddress: (payload) => ({type: actionTypes.PUT_ADDRESS, payload: payload}),
  putResetPostingRates: (payload) => ({type: actionTypes.PUT_RESET_POSTINGRATES, payload: payload}),

  putPreconResourceGridRow: (payload) => ({type: actionTypes.PUT_PRECON_RESOURCE_GRID_ROW, payload: payload}),
  putPreconResourceGridRowEmployee: (payload) => ({type: actionTypes.CHANGE_PRECON_RESOURCE_GRID_ROW_EMPLOYEE, payload: payload}),
  putNewManpowerGridRow: (payload) => ({type: actionTypes.PUT_NEW_MANPOWER_GRID_ROW, payload: payload}),
  removeManpowerGridRow: (payload) => ({type: actionTypes.REMOVE_MANPOWER_GRID_ROW, payload: payload}),
  putNewPreconGridRow: (payload) => ({type: actionTypes.PUT_NEW_PRECON_GRID_ROW, payload: payload}),

  putProjectTasks: (payload) => ({type: actionTypes.PUT_ProjectTasks, payload: payload}),
  setTab: (payload) => ({type: actionTypes.SET_TAB, payload: payload}),
  putColumnsOrder: (payload) => ({type: actionTypes.PUT_COLUMNS_ORDER, payload: payload}),
  getReport: (payload) => ({type: actionTypes.GET_REPORT, payload: payload}),
  setAPIStatus: (payload) => ({type: actionTypes.SET_APISTATUS, payload: payload}),
  setHUBConnection: (payload) => ({type: actionTypes.SET_HUBCONNECTION, payload: payload}),
  setAPIMessages: (payload) => ({type: actionTypes.SET_APIMESSAGES, payload: payload}),
  setTemp: (payload) => ({type: actionTypes.SET_TEMP, payload: payload}),
  setResourceBudget: (payload) => ({type: actionTypes.SET_RESOURCE_BUDGET, payload: payload}),

  fetchProjectSoftCostsGrid: (payload) => ({type: actionTypes.FETCH_ProjectSoftCostsGrid, payload: payload}),
  putProjectSoftCostsGrid: (payload) => ({type: actionTypes.PUT_ProjectSoftCostsGrid, payload: payload})
}

function* actionWatcher() {
  yield takeLatest('SET_VALIDUSER', fetchCollections, true)
  yield takeLatest('FETCH_ProjectsGrid', fetchProjectsGrid)
  yield takeLatest('SET_PROJECTID', fetchCollections, false)
  yield takeLatest('PUT_RECALC_PROJECT', putRecalcProject)
  yield takeLatest('FETCH_ProjectHeader', fetchProjectHeader)
  yield takeLatest('FETCH_ReportDashboardTrends', fetchReportDashboardTrends)
  yield takeLatest('FETCH_ReportDashboardRecentActivities', fetchReportDashboardRecentActivities)
  yield takeLatest('FETCH_ProjectVersions', fetchProjectVersions)
  yield takeLatest('FETCH_BidPermissions', fetchBidPermissions)
  yield takeLatest('FETCH_APILOGSTATISTICS', fetchAPILogStatistics)
  yield takeLatest('FETCH_ProjectBusinessUnits', fetchProjectBusinessUnits)
  yield takeLatest('FETCH_Summary', fetchSummary)
  yield takeLatest('CLEAR_Summary', clearSummary)
  yield takeLatest('CLEAR_Expenses', clearExpenses)
  yield takeLatest('FETCH_ProjectConfiguration', fetchCollection, 'ProjectConfiguration')
  yield takeLatest('FETCH_ProjectInformationGrid', fetchCollection, 'ProjectInformationGrid')
  yield takeLatest('FETCH_SummaryRequirementsGrid', fetchCollection, 'SummaryRequirementsGrid')
  yield takeLatest('FETCH_SummaryLaborSummaryTable', fetchCollection, 'SummaryLaborSummaryTable')
  yield takeLatest('FETCH_SummaryHITTCostFeeTable', fetchCollection, 'SummaryHITTCostFeeTable')
  yield takeLatest('FETCH_SummaryReturnOnLaborTable', fetchCollection, 'SummaryReturnOnLaborTable')
  yield takeLatest('FETCH_ManpowerGrandTotals', fetchCollection, 'Manpower')
  yield takeLatest('FETCH_PreconDept', fetchCollection, 'PreconDept')
  yield takeLatest('FETCH_FieldOfficeSupport', fetchCollection, 'FieldOfficeSupport')
  yield takeLatest('FETCH_GeneralRequirements', fetchCollection, 'GeneralRequirements')
  yield takeLatest('FETCH_Dashboard', fetchDashboard)
  yield takeLatest('FETCH_NonBillable', fetchCollection, 'NonBillable')
  yield takeLatest('FETCH_TargetEfficiency', fetchTargetEfficiency)
  yield takeLatest('FETCH_PreconDeptExpensesGrid', fetchExpensesGrid, 'Precon Dept')
  yield takeLatest('FETCH_PreconDeptExpensesGrid_TEMP', fetchExpensesGridTemp, 'Precon Dept')
  yield takeLatest('FETCH_PreconResourceGrid', fetchPreconResourceGrid)
  yield takeLatest('FETCH_PreconResourceGrid_TEMP', fetchPreconResourceGridTemp)
  yield takeLatest('FETCH_Tasks', fetchTasks)
  yield takeLatest('FETCH_ProjectTasks', fetchProjectTasks)
  yield takeLatest('FETCH_TasksToAdd', fetchTasksToAdd)
  yield takeLatest('FETCH_FieldOfficeSupportExpensesGrid', fetchExpensesGrid, 'Field Office Support')
  yield takeLatest('FETCH_GeneralRequirementsExpensesGrid', fetchExpensesGrid, 'General Requirements')
  yield takeLatest('FETCH_NonBillableExpensesGrid', fetchExpensesGrid, 'NonBillable')
  yield takeLatest('FETCH_ExpenseItemsToAdd', fetchExpenseItemsToAdd)
  yield takeLatest('FETCH_ManpowerGrid', fetchManpowerGrid)
  yield takeLatest('FETCH_ManpowerGridPostingRates', fetchManpowerGridPostingRates)
  yield takeLatest('FETCH_ManpowerGridWeeks', fetchProjectWeeks)
  yield takeLatest('FETCH_ManpowerGridMonths', fetchProjectMonths)
  yield takeLatest('FETCH_ManpowerCostGrid', fetchManpowerCostGrid)
  yield takeLatest('FETCH_ClientSummaryGrid', fetchClientSummaryGrid)
  yield takeLatest('FETCH_ProjectEmployeeRolesToAdd', fetchProjectEmployeeRolesToAdd)
  yield takeLatest('FETCH_ProjectOperationsEmployeesToAdd', fetchProjectOperationsEmployeesToAdd)

  yield takeLatest('FETCH_ExportXlsx', fetchExportXlsx)
  yield takeLatest('FETCH_ProjectScheduleXlsx', fetchProjectScheduleXlsx)
  yield takeLatest('FETCH_SummaryCalculationsXlsx', fetchSummaryCalculationsXlsx)
  yield takeLatest('FETCH_ClientSummaryXlsx', fetchClientSummaryXlsx)
  yield takeLatest('FETCH_ClientSummaryPDF', fetchClientSummaryPDF)
  yield takeLatest('FETCH_ProjectPerDiemRates', fetchProjectPerDiemRates)
  yield takeLatest('CHECK_ProjectPerDiemRates', checkProjectPerDiemRates)

  yield takeLatest('PUT_EstimatedContractValueQuoted', putProperties)
  yield takeLatest('PUT_ContractDurationWeeksQuoted', putProperties)
  yield takeLatest('PUT_SummaryHITTCostFeeTable', putProperties)
  yield takeEvery('PUT_PROPERTY', putProperties)
  yield takeEvery('PUT_PROJECTCONFIGURATIONOPTION', putProjectConfigurationOption)

  yield takeEvery('CHANGE_EXPENSE_GRID_ROW', calcExpenseGrid)
  yield takeLatest('PUT_NEW_EXPENSE_GRID_ROW', putNewExpenseGridRow)
  yield takeLatest('CALC_EXPENSE_GRID', calcExpenseGrid)
  yield takeEvery('PUT_EXPENSE_GRID_ROW', putExpenseGridRow)
  yield takeEvery('REMOVE_EXPENSE_GRID_ROW', putRemoveExpenseGridRow)
  yield takeLatest('COPY_EXPENSE_TEMPLATE_ITEMS_TO_PROJECT', copyExpenseTemplateItemsToProject)

  yield takeLatest('PUT_ProjectBusinessUnits', putProjectBusinessUnits)
  yield takeLatest('PUT_ProjectTasks', putProjectTasks)

  yield takeEvery('CHANGE_MANPOWER_COST_GRID_ROW', putManpowerCostGridRow)
  yield takeEvery('PUT_RESOURCE_BUDGET', putResourceBudget)
  yield takeEvery('PUT_RESOURCE_NOTE', putResourceNote)
  yield takeEvery('CHANGE_MANPOWER_COST_GRID_ROW_EMPLOYEE', putManpowerGridRowEmployee)
  yield takeLatest('PUT_EXPENSE_GRID_TOTALS', putProperties)
  yield takeEvery('PUT_MANPOWER_GRID_ROW', putManpowerGridRow)
  yield takeLatest('PUT_RESOURCE_PERDIEM', putResourcePerDiem)
  yield takeLatest('PUT_ADDRESS', putAddress)
  yield takeLatest('PUT_RESET_POSTINGRATES', putResetPostingRates)

  yield takeEvery('PUT_PRECON_RESOURCE_GRID_ROW', putPreconResourceGridRow)
  yield takeEvery('CHANGE_PRECON_RESOURCE_GRID_ROW_EMPLOYEE', putPreconResourceGridRowEmployee)
  yield takeLatest('PUT_NEW_MANPOWER_GRID_ROW', putNewManpowerGridRow)
  yield takeEvery('REMOVE_MANPOWER_GRID_ROW', putDeleteManpowerGridRow)
  yield takeEvery('REMOVE_MANPOWER_COST_GRID_ROW', putDeleteManpowerGridRow)

  yield takeLatest('PUT_NEW_PRECON_GRID_ROW', putNewPreconGridRow)
  yield takeLatest('PUT_COLUMNS_ORDER', putColumnsOrder)

  yield takeLatest('DELETE_PROJECT', deleteProject)
  yield takeLatest('COPY_PROJECT', putCopyProject)

  yield takeEvery('GET_REPORT', fetchReport)

  yield takeLatest('FETCH_ProjectSoftCostsGrid', fetchProjectSoftCostsGrid)
  yield takeLatest('PUT_ProjectSoftCostsGrid', putProjectSoftCostsGrid)
}

export function* saga() {
  yield all([actionWatcher()])
}
