import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'

const StyledTextField = styled(TextField)`
  background-color: ${(props) => (props.yellow ? '#ffe9bc' : props.red ? '#ffbebe' : 'white')};
  border-radius: 4px;
  & input {
    color: ${(props) => (props.normal ? 'black' : 'red')};
    text-align: ${(props) => (props.left ? 'left' : 'right')};
    width: ${(props) => (props.pc ? '74px' : props.adornment ? '76px' : props.auto ? 'auto' : '90px')};
    font-family: 'Open Sans', sans-serif !important;
  }
`

const CustomTextField = ({normalWidth, alignLeft, ...restProps}) => {
  return <StyledTextField {...restProps} left={alignLeft} auto={normalWidth} />
}

export default CustomTextField
