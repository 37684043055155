import FormControl from '@mui/material/FormControl'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import styled from '@emotion/styled'

export const CustomTableRow = styled(TableRow)`
  height: 32px;
`

export const CustomTableCellHeader = styled(TableCell)`
  background: white;
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  padding: 0 16px;
`

export const CustomTableCell = styled(TableCell)`
  padding: 0 16px;
`

export const CustomFormControl = styled(FormControl)`
  padding: 0 16px;
`
