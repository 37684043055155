import {Configuration, PopupRequest} from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURECLIENTID ? process.env.REACT_APP_AZURECLIENTID : '',
    authority: 'https://login.microsoftonline.com/3727d36e-6ef8-43d8-b31a-027bd31182f6',
    redirectUri: process.env.REACT_APP_AZUREREDIRECTUIRI
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read']
}
// scopes: ["https://bidcalcapi.azurewebsites.net/access_as_user"]
