import {all, take} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from './ducks/auth.duck'
import * as project from './ducks/project.duck'
import * as precon from './ducks/precon.duck'
import * as reference from './ducks/reference.duck'
import {REHYDRATE} from 'redux-persist/lib/constants'

// const authT = (state) => state.auth.authToken;

export const rootReducer = combineReducers({
  auth: auth.reducer,
  project: project.reducer,
  precon: precon.reducer,
  reference: reference.reducer
})

export function* rootSaga() {
  if (process.env.REACT_APP_DEBUG) {
    console.log('Waiting for ALL rehydration')
  }
  yield take(REHYDRATE) // Wait for rehydrate to prevent sagas from running with empty store
  if (process.env.REACT_APP_DEBUG) {
    console.log('Rehydrated ALL')
  }
  yield all([auth.saga(), project.saga(), precon.saga(), reference.saga()])
}
