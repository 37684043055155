import {useEffect, useMemo, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Reference from '../../../store/ducks/reference.duck'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

export const NOT_STARTED = 'Sync with BC'
export const IN_PROGRESS = 'BC sync in progress...'
export const COMPLETE = 'BC Sync Complete'
export const CONFIG = {
  ValidationErrorMessageTime: 5000
}

export default function BCSyncButton() {
  const dispatch = useDispatch()
  const bidQuote = useSelector((state) => state.precon.BidQuote)
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const projectID = useSelector((state) => state.project.projectID)
  const syncBCProjectStatus = useSelector((state) => state.reference.Status.syncBCProjectStatus)

  // Sync completed timeout
  const completeTimeoutRef = useRef(null)
  const clearCompleteTimeout = () => {
    if (completeTimeoutRef.current) {
      clearTimeout(completeTimeoutRef.current)
    }
  }

  useEffect(() => {
    return () => {
      clearCompleteTimeout()
    }
  }, [])

  useEffect(() => {
    const checkCompleted = () => {
      clearCompleteTimeout()
      if (syncBCProjectStatus === COMPLETE) {
        completeTimeoutRef.current = setTimeout(() => {
          dispatch(Reference.actions.setRefStatus({statusName: 'syncBCProjectStatus', statusValue: NOT_STARTED}))
        }, CONFIG.ValidationErrorMessageTime)
      }
    }
    checkCompleted()
  }, [syncBCProjectStatus, dispatch])

  function syncBCProject() {
    if (syncBCProjectStatus === IN_PROGRESS) {
      return null
    }
    dispatch(Reference.actions.setRefStatus({statusName: 'syncBCProjectStatus', statusValue: IN_PROGRESS}))
    dispatch(Reference.actions.postSyncBCProject({projectID: projectID, bcProjectID: bidQuote.bcProjectID, bidQuoteID: bidQuoteID}))
  }

  const getButtonIcon = useMemo(() => {
    switch (syncBCProjectStatus) {
      case NOT_STARTED:
        return <i className='bi fs-4 bi-cloud-arrow-down'></i>
      case IN_PROGRESS:
        return <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
      case COMPLETE:
        return <i className='bi fs-4 bi-check2-circle'></i>
      default:
        return null
    }
  }, [syncBCProjectStatus])

  const getButtonVariant = useMemo(() => {
    switch (syncBCProjectStatus) {
      case NOT_STARTED:
        return 'primary'
      case IN_PROGRESS:
        return 'warning'
      case COMPLETE:
        return 'success'
      default:
        return null
    }
  }, [syncBCProjectStatus])

  return bidQuote && bidQuote.bcProjectID !== '' && bidQuote.bcProjectID !== 0 ? (
    <Button size='sm' className='ml-3' variant={getButtonVariant} onClick={syncBCProject}>
      {getButtonIcon} {syncBCProjectStatus}
    </Button>
  ) : null
}
