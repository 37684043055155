import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Auth from '../store/ducks/auth.duck'
import * as Project from '../store/ducks/project.duck'
import * as Precon from '../store/ducks/precon.duck'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import isEmpty from 'lodash/isEmpty'

function ProjectSelector() {
  const dispatch = useDispatch()
  const selector = useSelector((state) => state.project.ProjectsGrid.values)
  const filters = useSelector((state) => state.auth.Filters)
  const projectID = useSelector((state) => state.project.projectID)
  const recentProjects = useSelector((state) => state.auth.recentProjects)

  useEffect(() => {
    dispatch(Project.actions.fetchProjectsGrid({status: filters.projectsGridStatus}))
  }, [dispatch, filters.projectsGridStatus])

  const changeProject = (row) => {
    console.log(row)
    if (!row?.ProjectID) {
      // TODO: handle project id not found
      process.env.REACT_APP_DEBUG && console.log('src/app/components/ProjectSelector.js', 'Project ID not found')
      return
    }
    const payload = {
      projectID: row.ProjectID,
      bidID: row.BidID,
      bidQuoteID: row.BidQuoteID,
      bidTradeID: row.FirstBidTradeID,
      editPermission: row.PermissionType === 'Edit' ? true : false
    }

    let newBidTradeId = payload.bidTradeID ? payload.bidTradeID : 0

    // clear project
    dispatch(Project.actions.clearProject())

    // setup project
    dispatch(Project.actions.setProjectID(payload))
    dispatch(Project.actions.fetchProjectHeader({projectID: row.ProjectID}))
    dispatch(Precon.actions.setBidID(payload.bidID))

    // setup BidQuote
    dispatch(Precon.actions.setBidQuoteID(payload.bidQuoteID))
    dispatch(Precon.actions.setBidTradeID(newBidTradeId))
    dispatch(Auth.actions.setFilters({filterName: 'bidTradeID', filterValue: newBidTradeId}))
    dispatch(Precon.actions.fetchBidQuote({bidQuoteID: payload.bidQuoteID}))
    dispatch(Precon.actions.fetchBidTrades({bidQuoteID: payload.bidQuoteID}))
    dispatch(Precon.actions.fetchBidTrade({bidTradeID: payload.bidTradeID}))
    dispatch(Precon.actions.fetchBidTradeGrid({bidTradeID: newBidTradeId}))

    // Add project to recent projects list, if not already there
    if (!recentProjects.some((recent) => recent.projectID === row.ProjectID)) {
      dispatch(Auth.actions.putRecentProjects({projectId: row.ProjectID}))
    }
  }

  const filterOptions = (options, {inputValue}) =>
    options.filter(
      (project) =>
        project.SearchTerms &&
        (inputValue === '*' ||
          inputValue.length === 0 ||
          (inputValue.match(/^[a-zA-Z0-9_&@ ]*$/) !== null && inputValue.split(' ').every((word) => project.SearchTerms.toLowerCase().search(word.toLowerCase()) !== -1)))
    )

  const matchOption = (option, value) => {
    if (value && option.ProjectID === value.ProjectID) {
      return true
    } else {
      return false
    }
  }

  if (isEmpty(selector)) {
    return <></>
  }
  return (
    <Autocomplete
      id='projectSelector'
      className='flex flex-nowrap w-full sm:w-[400px] lg:pt-4'
      options={selector}
      filterOptions={filterOptions}
      getOptionLabel={(option) => (option.ProjectName ? option.ProjectName : '')}
      isOptionEqualToValue={(option, value) => matchOption(option, value)}
      value={selector.some((row) => row.ProjectID === projectID) ? selector.find((row) => row.ProjectID === projectID) : null}
      onChange={(event, newValue) => changeProject(newValue)}
      style={{fullWidth: true}}
      disableClearable
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.ProjectName}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          type='text'
          variant='standard'
          label='Project Switcher'
          size='small'
          name='ProjectSelector'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
        />
      )}
    />
  )
}

export default ProjectSelector
