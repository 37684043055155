import {useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../store/ducks/project.duck'
import * as Precon from '../store/ducks/precon.duck'
import * as Reference from '../store/ducks/reference.duck'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

const NOT_STARTED = 'Refresh'
const IN_PROGRESS = 'Fetching...'
const COMPLETE = 'Complete'

export default function Refresh({isLoaded}) {
  const dispatch = useDispatch()
  const isLoadedStatus = useSelector((state) => state.reference.Status.isLoadedStatus)

  function startRefresh() {
    if (isLoaded.message === IN_PROGRESS) {
      return null
    }
    // Loop all dependencies and clear them from caches
    isLoaded?.projectDeps?.forEach((dep) => {
      dispatch(Project.actions.clearCache({type: dep}))
    })
    isLoaded?.preconDeps?.forEach((dep) => {
      dispatch(Precon.actions.clearCache({type: dep}))
    })
    // At this stage - do not refresh reference dependencies
    if (isLoaded.refreshReferenceDeps) {
      isLoaded.referenceDeps?.forEach((dep) => {
        dispatch(Reference.actions.clearCache({type: dep}))
      })
    }
  }

  const getButtonIcon = useMemo(() => {
    switch (isLoadedStatus) {
      case NOT_STARTED:
        return <i className='bi fs-4 bi-arrow-clockwise'></i>
      case IN_PROGRESS:
        return <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
      case COMPLETE:
        return <i className='bi fs-4 bi-check2-circle'></i>
      default:
        return null
    }
  }, [isLoadedStatus])

  const getButtonVariant = useMemo(() => {
    switch (isLoadedStatus) {
      case NOT_STARTED:
        return 'primary'
      case IN_PROGRESS:
        return 'warning'
      case COMPLETE:
        return 'success'
      default:
        return null
    }
  }, [isLoadedStatus])

  return (
    <Button size='sm' className='ml-3' variant={getButtonVariant} onClick={startRefresh}>
      {getButtonIcon} {isLoadedStatus}
    </Button>
  )
}
