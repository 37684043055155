import SoftCostsWorksheet from './SoftCostsWorksheet'
import {ModalLayoutI} from '../../../../components/Modals/ModalLayouts'
import {FooterLayout} from '../../../../components/Modals/BasicModalWrapper'

export const SoftCostsWorksheetModalKey = '@Modal_SoftCostsWorksheet'
const SoftCostsWorksheetModal = () => {
  return (
    <ModalLayoutI modalKey={SoftCostsWorksheetModalKey} title='Soft Costs Worksheet' footerLayout={FooterLayout.Empty}>
      <SoftCostsWorksheet />
    </ModalLayoutI>
  )
}

export default SoftCostsWorksheetModal
