import {useEffect, useMemo, useCallback, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Precon from '../../store/ducks/precon.duck'
import BidHeader from '../../components/BidHeader'
import Button from 'react-bootstrap/Button'
import Paper from '@mui/material/Paper'
import NotLoaded, {useIsLoaded} from '../../components/IsLoaded'
import Refresh from '../../components/Refresh'
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro'
import Alternate from './Alternates/Alternate'
import format from 'date-fns/format'

export const ALTERNATE_STATUSES = [
  {label: 'Proposed', value: 'Proposed'},
  {label: 'Accepted', value: 'Accepted'},
  {label: 'Rejected', value: 'Rejected'}
]

export default function BidAlternates({type}) {
  const dispatch = useDispatch()
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const projectID = useSelector((state) => state.project.projectID)
  const bidAlternatesGrid = useSelector((state) => state.precon.BidAlternatesGrid)
  const filteredAlternates = bidAlternatesGrid.filter((alternate) => alternate.type === type)
  const preconCache = useSelector((state) => state.precon.cache)
  const isLoaded = useIsLoaded({type: 'bidQuote', preconDeps: ['FETCH_BIDALTERNATESGRID']})

  useEffect(() => {
    if (!preconCache.FETCH_BIDALTERNATESGRID || preconCache?.FETCH_BIDALTERNATESGRID?.payload === 'VOID') {
      dispatch(Precon.actions.fetchBidAlternatesGrid({bidQuoteID: bidQuoteID}))
    }
  }, [dispatch, bidQuoteID, preconCache.FETCH_BIDALTERNATESGRID])

  useEffect(() => {
    dispatch(
      Precon.actions.fetchBidTrades({
        bidQuoteID: bidQuoteID ? bidQuoteID : 0,
        projectID: projectID ? projectID : 0
      })
    )
  }, [dispatch, bidQuoteID, projectID])

  const [alternateID, setAlternateID] = useState()
  const [localType, setLocalType] = useState(type)

  // If the type changes, reset the alternateID
  useEffect(() => {
    if (localType !== type) {
      setLocalType(type)
      setAlternateID(null)
    }
  }, [localType, type])

  const initAlternateValues = useMemo(
    () => ({
      id: 0,
      type: type,
      alternateID: 0,
      alternateIndex: 1000,
      description: '',
      total: 0,
      status: 'Proposed',
      comments: '',
      forBidTradeID: 0,
      quotedPC: 100
    }),
    [type]
  )

  const onEditAlternate = useCallback(
    (alternate) => {
      setAlternateID(alternate.id)
    },
    [setAlternateID]
  )

  if (!isLoaded.result) {
    return <NotLoaded payload={isLoaded} />
  }

  const columns = [
    {field: 'alternateIndex', type: 'number', headerName: '#', width: 50, cellClassName: 'grid-mono-font'},
    {
      field: 'id',
      headerName: 'Description',
      hide: false,
      width: 420,
      renderCell: (params) => (
        <strong>
          <div onClick={() => setAlternateID(params.row.id)} className='cell-link pl-[5px]'>
            {params.row.description}
          </div>
        </strong>
      )
    },
    {field: 'total', type: 'number', headerName: 'Total', width: 120, cellClassName: 'grid-mono-font'},
    {field: 'status', type: 'string', headerName: 'Status', width: 100},
    {
      field: 'updatedOn',
      type: 'date',
      headerName: 'Updated On',
      width: 110,
      valueFormatter: ({value}) => (value ? format(new Date(value), 'MM-dd-yyyy') : ''),
      cellClassName: 'grid-mono-font',
      align: 'center'
    },
    {field: 'comments', type: 'string', headerName: 'Comments'}
  ]

  if (alternateID || alternateID === 0) {
    return <Alternate type={type} alternateID={alternateID} setAlternateID={setAlternateID} />
  } else {
    return (
      <div>
        <BidHeader />
        <div className='text-right mb-[10px]'>
          <Refresh isLoaded={isLoaded} />
          <Button size='sm' className='ml-3' onClick={() => onEditAlternate(initAlternateValues)}>
            Add {type}
          </Button>
        </div>
        <Paper className='h-[700px]'>
          <DataGridPro rows={filteredAlternates} columns={columns} checkboxSelection={false} rowHeight={38} disableColumnMenu={true} />
        </Paper>
      </div>
    )
  }
}
