import {toast} from 'react-toastify'
import actionToErrorMessageMap from './ActionToErrorMessage'

class AppToast {
  constructor(err, actionType, response) {
    this.error = err
    this.actionType = actionType
    this.response = response
  }

  #getDisplayErrorMessage() {
    if (this.actionType === undefined) {
      return this.response || 'Something went wrong.' // Default message
    }
    return actionToErrorMessageMap[this.actionType] || this.actionType // Fallback to action type if no message is found
  }

  displayErrorToast(overrideContent, overrideOptions) {
    const displayMessage = this.#getDisplayErrorMessage()
    toast.error(overrideContent || displayMessage, overrideOptions)
  }

  displaySuccessToast(overrideContent, overrideOptions) {
    toast.success(overrideContent || this.response, {autoClose: 5000, ...overrideOptions})
  }
}

export default AppToast
