import {AddResourceModalWrapper} from '../../../../components/Modals/ModalLayouts'
import WarningIcon from '@mui/icons-material/Warning'
import format from 'date-fns/format'
import {useCallback} from 'react'

export const ProjectDateConfirmModalKey = '@Modal_ProjectDateConfirm'
const ConfirmDateChangeModal = ({onSaveDate, propertyName, updateDateChangeLocally, propertyLabel, oldValue, agreeDateChangeValue, dateChangeOperation, dateChangeWeeks, dateChangePhase}) => {
  const handleSave = useCallback(() => onSaveDate(propertyName, agreeDateChangeValue, updateDateChangeLocally), [agreeDateChangeValue, onSaveDate, propertyName, updateDateChangeLocally])

  return (
    <AddResourceModalWrapper modalKey={ProjectDateConfirmModalKey} title='Confirm Project Date Change' confirmButtonText='Confirm' onSave={handleSave}>
      <div className='help-aside mt-0 mb-0 pb-[25px]'>
        <WarningIcon className='inline-block -mt-1 mr-[6px]' />
        <span className='font-medium text-[18px]'>Warning! </span>
        <br />
        <br />
        <span className='font-medium'>Changing project dates will also change the project schedule weeks and costs.</span>
        <br />
        <br />
        You are changing <span className='font-medium'>{propertyLabel}</span> from <span className='font-medium'>{oldValue && format(new Date(oldValue), 'MM-dd-yyyy')}</span> to{' '}
        <span className='font-medium'>{agreeDateChangeValue && format(new Date(agreeDateChangeValue.toString()), 'MM-dd-yyyy')}</span>
        <br />
        <br />
        This will{' '}
        <span className='font-medium'>
          {dateChangeOperation} {dateChangeWeeks} week{dateChangeWeeks > 1 && 's'}
        </span>{' '}
        {dateChangeOperation === 'add' ? 'to' : 'from'} the <span className='font-medium'>{dateChangePhase}</span> phase of the schedule.
      </div>
    </AddResourceModalWrapper>
  )
}

export default ConfirmDateChangeModal
