import {AddResourceModalWrapper} from '../../../../components/Modals/ModalLayouts'
import {useAppModal} from '../../../../context/GlobalModalContext'

export const CompleteProjectSetupModalKey = '@Modal_CompleteProjectSetup'
function CompleteProjectSetupModal({showSaveButton, errors}) {
  const {closeModal} = useAppModal()
  return (
    <AddResourceModalWrapper modalKey={CompleteProjectSetupModalKey} title='Complete Project Setup' onSave={closeModal} confirmButtonText='Confirm' showSaveButton={showSaveButton}>
      <div className='help-aside mt-0 mb-0 pb-[25px]'>
        You must complete the following to continue:
        <hr />
        {Object.keys(errors).map((msg, index) => (
          <li key={index}>{getFieldName(msg)}</li>
        ))}
      </div>
    </AddResourceModalWrapper>
  )
}

export default CompleteProjectSetupModal

// TODO: Extract to helper function
function getFieldName(field) {
  switch (field) {
    case 'CRM_AnticipatedProjectCosts':
      return 'Estimated Contract Value'
    case 'PreConstructionStartDate':
      return 'Preconstruction Start Date'
    case 'SalaryEscalationRate':
      return 'Salary Escalation Rate'
    case 'PreConstructionEndDate':
      return 'Preconstruction End Date'
    case 'StateWorkPerformedIn':
      return 'State Work Performed In'
    case 'CRM_EstConstructionStartDate':
      return 'Est Construction Start Date'
    case 'CRM_EstConstructionEndDate':
      return 'Construction Substantial Completion Date'
    case 'CloseOutDate':
      return 'Closeout Date'
    case 'PPBondEffectiveDate':
      return 'P&P Bond Effective Date'
    case 'WarrantyPeriodMonths':
      return 'Warranty Period in Months'
    case 'ProjectAreaUnit':
      return 'Project Area Unit'
    case 'ProjectAreaValue':
      return 'Project Area Value'
    case 'BuildersRiskQuoteApproval':
      return 'Builders Risk Quote Approval'
    default:
      return ''
  }
}
