// Auth Action Types
import authAT from '../../store/ducks/auth/actionTypes'
// Precon Action Types
import prcAT from '../../store/ducks/precon/actionTypes'
// Project Action Types
import prjAT from '../../store/ducks/project/actionTypes'
// Reference Action Types
import refAT from '../../store/ducks/reference/actionTypes'

// General Display Messages
// const GeneralDisplayMessages = {
//   1: 'Unable to update data. Please try again.',
//   2: 'Unable to save progress. Please try again.'
// }

// Auth Action to Message
const authActionToMessageMap = {
  [authAT.INIT_FILTERS]: 'INIT_FILTERS',
  [authAT.SET_FILTERS]: 'SET_FILTERS',

  [authAT.STOP_REHYDRATE]: 'STOP_REHYDRATE',
  [authAT.SET_ADACCESS]: 'SET_ADACCESS',
  [authAT.SET_USERROLE]: 'SET_USERROLE',
  [authAT.SET_VALIDUSER]: 'SET_VALIDUSER',
  [authAT.SET_ASSUMEDUSER]: 'SET_ASSUMEDUSER',
  [authAT.AAD_ACQUIRED_ID_TOKEN_SUCCESS]: 'AAD_ACQUIRED_ID_TOKEN_SUCCESS',

  [authAT.AAD_INITIALIZED]: 'AAD_INITIALIZED',
  [authAT.AAD_LOGIN_SUCCESS]: 'AAD_LOGIN_SUCCESS',
  [authAT.SET_ACCOUNT]: 'SET_ACCOUNT',
  [authAT.SET_ACCESSTOKEN]: 'SET_ACCESSTOKEN',
  [authAT.SET_AUTHTOKEN]: 'SET_AUTHTOKEN',
  [authAT.SET_AUTHTOKEN_ONLY]: 'SET_AUTHTOKEN_ONLY',
  [authAT.SET_CLEAR_AUTHTOKEN]: 'SET_CLEAR_AUTHTOKEN',
  [authAT.GET_USERROLE]: 'GET_USERROLE',

  [authAT.PUT_RECENTPROJECTS]: 'PUT_RECENTPROJECTS',
  [authAT.SET_RECENTPROJECTS]: 'SET_RECENTPROJECTS',

  [authAT.ATTEMPT_REAUTH]: 'ATTEMPT_REAUTH',
  [authAT.RESET_REAUTH]: 'RESET_REAUTH'
}

// Precon Action to Message
const preconActionToMessageMap = {
  [prcAT.SET_PRECON_TEMP]: 'SET_PRECON_TEMP',
  [prcAT.SET_PRECONCACHE]: 'SET_PRECONCACHE',
  [prcAT.SET_PRECON_UPDATES]: 'SET_PRECON_UPDATES',
  [prcAT.SET_BIDID]: 'SET_BIDID',
  [prcAT.SET_BIDQUOTEID]: 'SET_BIDQUOTEID',
  [prcAT.SET_BIDQUOTE]: 'SET_BIDQUOTE',
  [prcAT.SET_BIDTRADE]: 'SET_BIDTRADE',
  [prcAT.SET_BIDTRADEID]: 'SET_BIDTRADEID',
  [prcAT.SET_ACTION_PARAMS]: 'SET_ACTION_PARAMS',
  [prcAT.FETCH_BIDQUOTE]: 'FETCH_BIDQUOTE',
  [prcAT.FETCH_PRECON_REFERENCES]: 'FETCH_PRECON_REFERENCES',
  [prcAT.SET_PRECON_COLLECTION]: 'SET_PRECON_COLLECTION',
  [prcAT.PUT_BIDQUOTE_BCPROJECTID]: 'PUT_BIDQUOTE_BCPROJECTID',
  [prcAT.PUT_BIDQUOTE_UNLINK_BCPROJECTID]: 'PUT_BIDQUOTE_UNLINK_BCPROJECTID',
  [prcAT.PUT_BIDQUOTE_BLANK]: 'PUT_BIDQUOTE_BLANK',

  [prcAT.FETCH_TAGVALUES]: 'FETCH_TAGVALUES',
  [prcAT.CLEAR_SUBCONTRACTOR]: 'CLEAR_SUBCONTRACTOR',
  [prcAT.FETCH_SUBCONTRACTORS]: 'FETCH_SUBCONTRACTORS',
  [prcAT.FETCH_SUBCONTRACTOR]: 'FETCH_SUBCONTRACTOR',
  [prcAT.FETCH_SUBCONTRACTOR_CONTACTS]: 'FETCH_SUBCONTRACTOR_CONTACTS',
  [prcAT.FETCH_SUBCONTRACTOR_OFFICES]: 'FETCH_SUBCONTRACTOR_OFFICES',
  [prcAT.FETCH_SUBCONTRACTOR_BIDHISTORY]: 'FETCH_SUBCONTRACTOR_BIDHISTORY',
  [prcAT.FETCH_BIDCLARIFICATIONS]: 'FETCH_BIDCLARIFICATIONS',
  [prcAT.SET_BIDCLARIFICATIONS]: 'SET_BIDCLARIFICATIONS',
  [prcAT.COPY_CLARIFICATIONSTEMPLATE_TO_BID]: 'COPY_CLARIFICATIONSTEMPLATE_TO_BID',
  [prcAT.FETCH_BIDALTERNATESGRID]: 'FETCH_BIDALTERNATESGRID',
  [prcAT.FETCH_TRADEALTERNATESGRID]: 'FETCH_TRADEALTERNATESGRID',
  [prcAT.PUT_ALTERNATE_TRADE_ITEM]: 'PUT_ALTERNATE_TRADE_ITEM',

  [prcAT.FETCH_BCPROJECTS]: 'FETCH_BCPROJECTS',
  [prcAT.FETCH_BIDTRADE]: 'FETCH_BIDTRADE',
  [prcAT.FETCH_BIDTRADES]: 'FETCH_BIDTRADES',
  [prcAT.FETCH_BIDTRADEGRID]: 'FETCH_BIDTRADEGRID',
  [prcAT.FETCH_BIDTRADE_BUDGETGRID]: 'FETCH_BIDTRADE_BUDGETGRID',
  [prcAT.FETCH_BIDSHEETGRID]: 'FETCH_BIDSHEETGRID',
  [prcAT.FETCH_BIDSHEETGRID_TEMP]: 'FETCH_BIDSHEETGRID_TEMP',
  [prcAT.FETCH_BIDSUMMARY_GRIDTOTALS]: 'FETCH_BIDSUMMARY_GRIDTOTALS',
  [prcAT.FETCH_BIDSUMMARY_GRIDTOTALS_TEMP]: 'FETCH_BIDSUMMARY_GRIDTOTALS_TEMP',
  [prcAT.FETCH_BIDTRADEQUOTE]: 'FETCH_BIDTRADEQUOTE',
  [prcAT.SET_BIDSUMMARY_GRIDTOTALS]: 'SET_BIDSUMMARY_GRIDTOTALS',
  [prcAT.PUT_BIDTRADESUMMARYTOTALS_GRIDROW]: 'PUT_BIDTRADESUMMARYTOTALS_GRIDROW',
  [prcAT.SET_BIDSUMMARY_GRIDTOTALS_TEMP]: 'SET_BIDSUMMARY_GRIDTOTALS_TEMP',
  [prcAT.CLEAR_BIDSHEETGRID_TEMP]: 'CLEAR_BIDSHEETGRID_TEMP',
  [prcAT.CLEAR_BIDSUMMARY_GRIDTOTALS_TEMP]: 'CLEAR_BIDSUMMARY_GRIDTOTALS_TEMP',

  [prcAT.PUT_SUBCONTRACTOR]: 'PUT_SUBCONTRACTOR',
  [prcAT.PUT_SUBCONTRACTOR_CONTACTS]: 'PUT_SUBCONTRACTOR_CONTACTS',
  [prcAT.PUT_SUBCONTRACTOR_OFFICES]: 'PUT_SUBCONTRACTOR_OFFICES',
  [prcAT.PUT_BIDTRADEQUOTE]: 'PUT_BIDTRADEQUOTE',

  [prcAT.PUT_COPYBIDQUOTE]: 'PUT_COPYBIDQUOTE',
  [prcAT.PUT_CREATEBIDQUOTE]: 'PUT_CREATEBIDQUOTE',
  [prcAT.PUT_BIDQUOTE]: 'PUT_BIDQUOTE',
  [prcAT.PUT_BIDTRADEQUOTE_BASEBID_RESET]: 'PUT_BIDTRADEQUOTE_BASEBID_RESET',
  [prcAT.PUT_TAGVALUES]: 'PUT_TAGVALUES',
  [prcAT.PUT_BIDTRADE]: 'PUT_BIDTRADE',
  [prcAT.PUT_BIDTRADES]: 'PUT_BIDTRADES',
  [prcAT.DELETE_BIDTRADE]: 'DELETE_BIDTRADE',

  [prcAT.PUT_BIDTRADE_GENERALREQUIREMENTS_PLACEHOLDER]: 'PUT_BIDTRADE_GENERALREQUIREMENTS_PLACEHOLDER',
  [prcAT.PUT_BIDTRADESUMMARYGRIDROW]: 'PUT_BIDTRADESUMMARYGRIDROW',
  [prcAT.PUT_BIDSHEETGRIDROW]: 'PUT_BIDSHEETGRIDROW',
  [prcAT.PUT_BIDSHEETGRID_PREVBUDGET_INDIRECTROW]: 'PUT_BIDSHEETGRID_PREVBUDGET_INDIRECTROW',
  [prcAT.PUT_SELECTEDBIDTRADEQUOTE]: 'PUT_SELECTEDBIDTRADEQUOTE',
  [prcAT.PUT_NEWBIDTRADEQUOTE]: 'PUT_NEWBIDTRADEQUOTE',
  [prcAT.PUT_DELETEBIDTRADEQUOTE]: 'PUT_DELETEBIDTRADEQUOTE',
  [prcAT.PUT_BIDTRADEGRIDROW]: 'PUT_BIDTRADEGRIDROW',
  [prcAT.PUT_BIDTRADEBUDGET_GRIDROW]: 'PUT_BIDTRADEBUDGET_GRIDROW',
  [prcAT.SET_BIDTRADEGRID]: 'SET_BIDTRADEGRID',
  [prcAT.SET_BIDTRADEGRIDBUDGET]: 'SET_BIDTRADEGRIDBUDGET',
  [prcAT.SET_BIDTRADEGRID_ADDROW]: 'SET_BIDTRADEGRID_ADDROW',
  [prcAT.PUT_DELETEBIDTRADEGRIDROW]: 'PUT_DELETEBIDTRADEGRIDROW',
  [prcAT.PUT_DELETEBIDTRADE_BUDGET_GRIDROW]: 'PUT_DELETEBIDTRADE_BUDGET_GRIDROW',
  [prcAT.PUT_BIDCLARIFICATION]: 'PUT_BIDCLARIFICATION',
  [prcAT.DELETE_BIDCLARIFICATION]: 'DELETE_BIDCLARIFICATION',
  [prcAT.PUT_ALTERNATE]: 'PUT_ALTERNATE',
  [prcAT.DELETE_ALTERNATE]: 'DELETE_ALTERNATE',
  [prcAT.REORDER_BIDTRADEQUOTES]: 'REORDER_BIDTRADEQUOTES',
  [prcAT.REORDER_BIDTRADEITEMS]: 'REORDER_BIDTRADEITEMS',
  [prcAT.REORDER_BIDTRADEITEM]: 'REORDER_BIDTRADEITEM',
  [prcAT.REORDER_BIDTRADE_BUDGETITEM]: 'REORDER_BIDTRADE_BUDGETITEM',
  [prcAT.COPY_BIDTRADETEMPLATE_TOBID]: 'COPY_BIDTRADETEMPLATE_TOBID',

  [prcAT.FETCH_PROPOSALS]: 'FETCH_PROPOSALS',
  [prcAT.FETCH_PROPOSAL]: 'FETCH_PROPOSAL',
  [prcAT.PUT_PROPOSAL]: 'PUT_PROPOSAL',
  [prcAT.DELETE_PROPOSAL]: 'DELETE_PROPOSAL',
  [prcAT.FETCH_SNAPSHOTS]: 'FETCH_SNAPSHOTS',
  [prcAT.FETCH_SNAPSHOT]: 'FETCH_SNAPSHOT',
  [prcAT.PUT_SNAPSHOT]: 'PUT_SNAPSHOT',
  [prcAT.DELETE_SNAPSHOT]: 'DELETE_SNAPSHOT',
  [prcAT.PUT_BIDTRADENOTE]: 'PUT_BIDTRADENOTE',
  [prcAT.PUT_BIDTRADEITEM_NOTE]: 'PUT_BIDTRADEITEM_NOTE',

  [prcAT.COPY_BidQuoteChecklist_TOBID]: 'COPY_BidQuoteChecklist_TOBID',
  [prcAT.SYNC_ChecklistToBidQuote]: 'SYNC_ChecklistToBidQuote',

  [prcAT.PUT_FILEEXPORTFLAG]: 'PUT_FILEEXPORTFLAG',
  [prcAT.FETCH_FILES]: 'FETCH_FILES',
  [prcAT.SET_FILES]: 'SET_FILES',
  [prcAT.POST_FILE]: 'POST_FILE',
  [prcAT.DELETE_FILE]: 'DELETE_FILE',
  [prcAT.FETCH_FILE]: 'FETCH_FILE',
  [prcAT.SET_FILE]: 'SET_FILE',

  [prcAT.RESET_STAGINGDATA]: 'RESET_STAGINGDATA'
}

// Project Action to Message
const projectActionToMessageMap = {
  [prjAT.SET_CACHE]: 'SET_CACHE',
  [prjAT.SET_STATUS]: 'SET_STATUS',
  [prjAT.STOP_REHYDRATE]: 'STOP_REHYDRATE',

  [prjAT.SET_PROJECTID]: 'SET_PROJECTID',
  [prjAT.CLEAR_PROJECT]: 'CLEAR_PROJECT',
  [prjAT.FETCH_ProjectHeader]: 'FETCH_ProjectHeader',
  [prjAT.SET_INITIAL]: 'SET_INITIAL',
  [prjAT.FETCH_Dashboard]: 'FETCH_Dashboard',
  [prjAT.FETCH_ReportDashboardTrends]: 'FETCH_ReportDashboardTrends',
  [prjAT.FETCH_ReportDashboardRecentActivities]: 'FETCH_ReportDashboardRecentActivities',

  [prjAT.CLEAR_Summary]: 'CLEAR_Summary',
  [prjAT.CLEAR_Expenses]: 'CLEAR_Expenses',

  [prjAT.FETCH_Summary]: 'FETCH_Summary',
  [prjAT.FETCH_TargetEfficiency]: 'FETCH_TargetEfficiency',

  [prjAT.FETCH_PROPERTIES]: 'FETCH_PROPERTIES',
  [prjAT.SET_PROPERTIES]: 'SET_PROPERTIES',
  [prjAT.SET_COLLECTION]: 'SET_COLLECTION',
  [prjAT.SET_PROPERTY]: 'SET_PROPERTY',
  [prjAT.PUT_PROPERTY]: 'PUT_PROPERTY',
  [prjAT.PUT_PROJECTCONFIGURATIONOPTION]: 'PUT_PROJECTCONFIGURATIONOPTION',
  [prjAT.SET_GRID]: 'SET_GRID',
  [prjAT.PUT_GRID]: 'PUT_GRID',

  [prjAT.FETCH_ProjectsGrid]: 'FETCH_ProjectsGrid',
  [prjAT.FETCH_ClientSummaryGrid]: 'FETCH_ClientSummaryGrid',
  [prjAT.SET_PROJECTS]: 'SET_PROJECTS',
  // Fetch actions to populate all project properties collection
  [prjAT.FETCH_ProjectConfiguration]: 'FETCH_ProjectConfiguration',
  [prjAT.FETCH_ProjectVersions]: 'FETCH_ProjectVersions',
  [prjAT.FETCH_APILOGSTATISTICS]: 'FETCH_APILOGSTATISTICS',
  [prjAT.FETCH_BidPermissions]: 'FETCH_BidPermissions',
  [prjAT.FETCH_ProjectBusinessUnits]: 'FETCH_ProjectBusinessUnits',
  [prjAT.FETCH_ProjectInformationGrid]: 'FETCH_ProjectInformationGrid',
  [prjAT.FETCH_SummaryRequirementsGrid]: 'FETCH_SummaryRequirementsGrid',
  [prjAT.FETCH_SummaryLaborSummaryTable]: 'FETCH_SummaryLaborSummaryTable',
  [prjAT.FETCH_SummaryHITTCostFeeTable]: 'FETCH_SummaryHITTCostFeeTable',
  [prjAT.FETCH_SummaryReturnOnLaborTable]: 'FETCH_SummaryReturnOnLaborTable',
  [prjAT.FETCH_ManpowerGrandTotals]: 'FETCH_ManpowerGrandTotals',
  [prjAT.FETCH_ManpowerCostGrid]: 'FETCH_ManpowerCostGrid',
  [prjAT.FETCH_ManpowerGrid]: 'FETCH_ManpowerGrid',
  [prjAT.FETCH_PreconDept]: 'FETCH_PreconDept',
  [prjAT.FETCH_TasksToAdd]: 'FETCH_TasksToAdd',
  [prjAT.FETCH_PreconResourceGrid]: 'FETCH_PreconResourceGrid',
  [prjAT.FETCH_PreconDeptExpensesGrid]: 'FETCH_PreconDeptExpensesGrid',
  [prjAT.FETCH_ProjectTasks]: 'FETCH_ProjectTasks',
  [prjAT.FETCH_FieldOfficeSupport]: 'FETCH_FieldOfficeSupport',
  [prjAT.FETCH_FieldOfficeSupportExpensesGrid]: 'FETCH_FieldOfficeSupportExpensesGrid',
  [prjAT.FETCH_GeneralRequirements]: 'FETCH_GeneralRequirements',
  [prjAT.FETCH_GeneralRequirementsExpensesGrid]: 'FETCH_GeneralRequirementsExpensesGrid',
  [prjAT.FETCH_NonBillable]: 'FETCH_NonBillable',
  [prjAT.FETCH_NonBillableExpensesGrid]: 'FETCH_NonBillableExpensesGrid',
  [prjAT.FETCH_ProjectEmployeeRolesToAdd]: 'FETCH_ProjectEmployeeRolesToAdd',
  [prjAT.FETCH_ProjectOperationsEmployeesToAdd]: 'FETCH_ProjectOperationsEmployeesToAdd',
  [prjAT.FETCH_ExportXlsx]: 'FETCH_ExportXlsx',
  [prjAT.SET_EXPORT_XLSX]: 'SET_EXPORT_XLSX',
  [prjAT.FETCH_ProjectScheduleXlsx]: 'FETCH_ProjectScheduleXlsx',
  [prjAT.FETCH_ClientSummaryXlsx]: 'FETCH_ClientSummaryXlsx',
  [prjAT.FETCH_ClientSummaryPDF]: 'FETCH_ClientSummaryPDF',
  [prjAT.FETCH_ProjectPerDiemRates]: 'FETCH_ProjectPerDiemRates',
  [prjAT.CHECK_ProjectPerDiemRates]: 'CHECK_ProjectPerDiemRates',
  [prjAT.FETCH_ManpowerGridWeeks]: 'FETCH_ManpowerGridWeeks',
  [prjAT.FETCH_ManpowerGridMonths]: 'FETCH_ManpowerGridMonths',
  [prjAT.SET_SummaryCalculationsXlsx]: 'SET_SummaryCalculationsXlsx',
  [prjAT.FETCH_SummaryCalculationsXlsx]: 'FETCH_SummaryCalculationsXlsx',

  [prjAT.SET_ContractDurationWeeksQuotedOnly]: 'SET_ContractDurationWeeksQuotedOnly',
  [prjAT.SET_ContractDurationWeeksQuoted]: 'SET_ContractDurationWeeksQuoted',
  [prjAT.PUT_ContractDurationWeeksQuoted]: 'PUT_ContractDurationWeeksQuoted',

  [prjAT.SET_SummaryHITTCostFeeTable]: 'SET_SummaryHITTCostFeeTable',
  [prjAT.PUT_SummaryHITTCostFeeTable]: 'PUT_SummaryHITTCostFeeTable',

  [prjAT.PUT_ProjectBusinessUnits]: 'PUT_ProjectBusinessUnits',
  [prjAT.CHANGE_EXPENSE_GRID_ROW]: 'CHANGE_EXPENSE_GRID_ROW',
  [prjAT.SET_EXPENSE_GRID_TOTALS]: 'SET_EXPENSE_GRID_TOTALS',
  [prjAT.CALC_EXPENSE_GRID]: 'CALC_EXPENSE_GRID',
  [prjAT.PUT_NEW_EXPENSE_GRID_ROW]: 'PUT_NEW_EXPENSE_GRID_ROW',
  [prjAT.REMOVE_EXPENSE_GRID_ROW]: 'REMOVE_EXPENSE_GRID_ROW',
  [prjAT.COPY_EXPENSE_TEMPLATE_ITEMS_TO_PROJECT]: 'COPY_EXPENSE_TEMPLATE_ITEMS_TO_PROJECT',

  [prjAT.CHANGE_MANPOWER_COST_GRID_ROW]: 'CHANGE_MANPOWER_COST_GRID_ROW',
  [prjAT.PUT_RESOURCE_BUDGET]: 'PUT_RESOURCE_BUDGET',
  [prjAT.PUT_RESOURCE_NOTE]: 'PUT_RESOURCE_NOTE',
  [prjAT.CHANGE_MANPOWER_COST_GRID_ROW_EMPLOYEE]: 'CHANGE_MANPOWER_COST_GRID_ROW_EMPLOYEE',
  [prjAT.PUT_NEW_MANPOWER_GRID_ROW]: 'PUT_NEW_MANPOWER_GRID_ROW',
  [prjAT.ADD_MANPOWER_COST_GRID_ROW]: 'ADD_MANPOWER_COST_GRID_ROW',
  [prjAT.REMOVE_MANPOWER_COST_GRID_ROW]: 'REMOVE_MANPOWER_COST_GRID_ROW',
  [prjAT.PUT_RESOURCE_PERDIEM]: 'PUT_RESOURCE_PERDIEM',
  [prjAT.PUT_ADDRESS]: 'PUT_ADDRESS',

  [prjAT.PUT_MANPOWER_GRID_ROW]: 'PUT_MANPOWER_GRID_ROW',
  [prjAT.REMOVE_MANPOWER_GRID_ROW]: 'REMOVE_MANPOWER_GRID_ROW',
  [prjAT.PUT_ProjectTasks]: 'PUT_ProjectTasks',

  [prjAT.PUT_PRECON_RESOURCE_GRID_ROW]: 'PUT_PRECON_RESOURCE_GRID_ROW',
  [prjAT.CHANGE_PRECON_RESOURCE_GRID_ROW_EMPLOYEE]: 'CHANGE_PRECON_RESOURCE_GRID_ROW_EMPLOYEE',
  [prjAT.PUT_NEW_PRECON_GRID_ROW]: 'PUT_NEW_PRECON_GRID_ROW',

  [prjAT.SET_TAB]: 'SET_TAB',
  [prjAT.PUT_COLUMNS_ORDER]: 'PUT_COLUMNS_ORDER',

  [prjAT.DELETE_PROJECT]: 'DELETE_PROJECT',
  [prjAT.COPY_PROJECT]: 'COPY_PROJECT',

  [prjAT.GET_REPORT]: 'GET_REPORT',
  [prjAT.SET_REPORT]: 'SET_REPORT',
  [prjAT.SET_APISTATUS]: 'SET_APISTATUS',
  [prjAT.SET_HUBCONNECTION]: 'SET_HUBCONNECTION',
  [prjAT.SET_APIMESSAGES]: 'SET_APIMESSAGES',
  [prjAT.SET_TEMP]: 'SET_TEMP',
  [prjAT.SET_RESOURCE_BUDGET]: 'SET_RESOURCE_BUDGET'
}

const referenceActionToMessageMap = {
  [refAT.SET_REFCACHE]: 'SET_REFCACHE',
  [refAT.SET_REFSTATUS]: 'SET_REFSTATUS',
  [refAT.SET_INITIAL]: 'SET_INITIAL',
  [refAT.SET_REFERENCE]: 'SET_REFERENCE',
  [refAT.FETCH_REFERENCES]: 'FETCH_REFERENCES',
  [refAT.FETCH_HelpTopics]: 'FETCH_HelpTopics',
  [refAT.DELETE_HelpTopic]: 'DELETE_HelpTopic',

  [refAT.FETCH_Contexts]: 'FETCH_Contexts',
  [refAT.FETCH_Tags]: 'FETCH_Tags',
  [refAT.FETCH_States]: 'FETCH_States',
  [refAT.FETCH_Counties]: 'FETCH_Counties',
  [refAT.FETCH_BusinessUnits]: 'FETCH_BusinessUnits',
  [refAT.FETCH_Employees]: 'FETCH_Employees',
  [refAT.FETCH_EmployeeRoles]: 'FETCH_EmployeeRoles',
  [refAT.FETCH_ExpenseUnits]: 'FETCH_ExpenseUnits',
  [refAT.FETCH_Tasks]: 'FETCH_Tasks',
  [refAT.FETCH_Users]: 'FETCH_Users',

  [refAT.FETCH_DISPLAY_OPTIONS]: 'FETCH_DISPLAY_OPTIONS',
  [refAT.SET_DISPLAY_OPTIONS]: 'SET_DISPLAY_OPTIONS',
  [refAT.PUT_DISPLAY_OPTIONS]: 'PUT_DISPLAY_OPTIONS',

  [refAT.FETCH_EXPENSETEMPLATES]: 'FETCH_EXPENSETEMPLATES',
  [refAT.FETCH_EXPENSECATEGORIES]: 'FETCH_EXPENSECATEGORIES',
  [refAT.FETCH_EXPENSETEMPLATEITEMS]: 'FETCH_EXPENSETEMPLATEITEMS',
  [refAT.FETCH_BuildersRiskRates]: 'FETCH_BuildersRiskRates',

  [refAT.FETCH_SEARCHCRM]: 'FETCH_SEARCHCRM',
  [refAT.SET_SEARCHCRM]: 'SET_SEARCHCRM',
  [refAT.SET_SEARCHCRMSTATUS]: 'SET_SEARCHCRMSTATUS',
  [refAT.SET_SYNCHCRMSTATUS]: 'SET_SYNCHCRMSTATUS',
  [refAT.PUT_SEARCHCRM]: 'PUT_SEARCHCRM',
  [refAT.PUT_SYNCBCPROJECT]: 'PUT_SYNCBCPROJECT',

  [refAT.SET_HELP]: 'SET_HELP',
  [refAT.PUT_USER]: 'PUT_USER',
  [refAT.DELETE_USER]: 'DELETE_USER',
  [refAT.PUT_ERROR]: 'PUT_ERROR',
  [refAT.PUT_SYSTEMPARAMETER]: 'PUT_SYSTEMPARAMETER',
  [refAT.PUT_EXPENSETEMPLATE]: 'PUT_EXPENSETEMPLATE',
  [refAT.PUT_TAG]: 'PUT_TAG',

  [refAT.CHANGE_EXPENSE_CATEGORY_GRID_ROW]: 'CHANGE_EXPENSE_CATEGORY_GRID_ROW',
  [refAT.DELETE_EXPENSE_CATEGORY_GRID_ROW]: 'DELETE_EXPENSE_CATEGORY_GRID_ROW',
  [refAT.CHANGE_EXPENSE_ITEM_GRID_ROW]: 'CHANGE_EXPENSE_ITEM_GRID_ROW',
  [refAT.CHANGE_EXPENSE_UNIT_ROW]: 'CHANGE_EXPENSE_UNIT_ROW',
  [refAT.CHANGE_OFFICE_GRID_ROW]: 'CHANGE_OFFICE_GRID_ROW',
  [refAT.CHANGE_STATE_GRID_ROW]: 'CHANGE_STATE_GRID_ROW',
  [refAT.CHANGE_COUNTY_GRID_ROW]: 'CHANGE_COUNTY_GRID_ROW',
  [refAT.CHANGE_BUILDERSRISKRATE_GRID_ROW]: 'CHANGE_BUILDERSRISKRATE_GRID_ROW',
  [refAT.CHANGE_EMPLOYEE_ROLE_GRID_ROW]: 'CHANGE_EMPLOYEE_ROLE_GRID_ROW',
  [refAT.CHANGE_EMPLOYEE_GRID_ROW]: 'CHANGE_EMPLOYEE_GRID_ROW',
  [refAT.CHANGE_TASK_GRID_ROW]: 'CHANGE_TASK_GRID_ROW',
  [refAT.CHANGE_USER_ROLE_GRID_ROW]: 'CHANGE_USER_ROLE_GRID_ROW',
  [refAT.PUT_HELP_TOPIC]: 'PUT_HELP_TOPIC',
  [refAT.CHANGE_BUSINESSUNIT_ROW]: 'CHANGE_BUSINESSUNIT_ROW',
  [refAT.ADD_BID_PERMISSION]: 'ADD_BID_PERMISSION',
  [refAT.DELETE_BID_PERMISSION]: 'DELETE_BID_PERMISSION',
  [refAT.DELETE_EXPENSETEMPLATE]: 'DELETE_EXPENSETEMPLATE',
  [refAT.DELETE_EXPENSE_UNIT_ROW]: 'DELETE_EXPENSE_UNIT_ROW',
  [refAT.DELETE_EXPENSETEMPLATEITEM]: 'DELETE_EXPENSETEMPLATEITEM',
  [refAT.FETCH_PERDIEMRATES]: 'FETCH_PERDIEMRATES',
  [refAT.PUT_PERDIEMRATE]: 'PUT_PERDIEMRATE',
  [refAT.DELETE_PERDIEMRATE]: 'DELETE_PERDIEMRATE',

  [refAT.FETCH_CSIDIVISIONS]: 'FETCH_CSIDIVISIONS',
  [refAT.PUT_CSICODE]: 'PUT_CSICODE',
  [refAT.PUT_CSIVERSION]: 'PUT_CSIVERSION',
  [refAT.PUT_Clarification]: 'PUT_Clarification',
  [refAT.DELETE_Clarification]: 'DELETE_Clarification',

  [refAT.FETCH_BIDTRADETEMPLATES]: 'FETCH_BIDTRADETEMPLATES',
  [refAT.FETCH_BIDTRADETEMPLATE]: 'FETCH_BIDTRADETEMPLATE',
  [refAT.PUT_BIDTRADETEMPLATE]: 'PUT_BIDTRADETEMPLATE',
  [refAT.DELETE_BIDTRADETEMPLATE]: 'DELETE_BIDTRADETEMPLATE',
  [refAT.PUT_BIDTRADEITEMTEMPLATE]: 'PUT_BIDTRADEITEMTEMPLATE',
  [refAT.DELETE_BIDTRADEITEMTEMPLATE]: 'DELETE_BIDTRADEITEMTEMPLATE',
  [refAT.REORDER_BIDTRADEITEMTEMPLATES]: 'REORDER_BIDTRADEITEMTEMPLATES',

  [refAT.FETCH_CLARIFICATIONTEMPLATES]: 'FETCH_CLARIFICATIONTEMPLATES',
  [refAT.FETCH_CLARIFICATIONTEMPLATE]: 'FETCH_CLARIFICATIONTEMPLATE',
  [refAT.PUT_CLARIFICATIONTEMPLATE]: 'PUT_CLARIFICATIONTEMPLATE',
  [refAT.DELETE_CLARIFICATIONTEMPLATE]: 'DELETE_CLARIFICATIONTEMPLATE',

  [refAT.FETCH_APPROVERS]: 'FETCH_APPROVERS',
  [refAT.FETCH_CSICodes]: 'FETCH_CSICodes',
  [refAT.FETCH_CSIVersions]: 'FETCH_CSIVersions',

  [refAT.FETCH_BidQuoteChecklistTemplates]: 'FETCH_BidQuoteChecklistTemplates',
  [refAT.FETCH_BidQuoteChecklistTemplate]: 'FETCH_BidQuoteChecklistTemplate',
  [refAT.SET_BidQuoteChecklistTemplateItems]: 'SET_BidQuoteChecklistTemplateItems',
  [refAT.PUT_BidQuoteChecklistTemplate]: 'PUT_BidQuoteChecklistTemplate',
  [refAT.PUT_BidQuoteChecklistItemTemplate]: 'PUT_BidQuoteChecklistItemTemplate',
  [refAT.DELETE_BidQuoteChecklistItemTemplate]: 'DELETE_BidQuoteChecklistItemTemplate',
  [refAT.DELETE_BidQuoteChecklistTemplate]: 'DELETE_BidQuoteChecklistTemplate'
}

const allActionTypesToErrorMessage = {
  ...authActionToMessageMap,
  ...preconActionToMessageMap,
  ...projectActionToMessageMap,
  ...referenceActionToMessageMap
}

export default allActionTypesToErrorMessage
