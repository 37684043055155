import {msalConfig, loginRequest} from './components/authProvider' //./app/modules/auth/components/authProvider

export async function setupAxios(axios, store, msalInstance) {
  async function handleLogin() {
    let account = msalInstance.getActiveAccount()
    if (account) {
      clearStorage(account)
    }
    msalInstance.loginPopup(loginRequest).catch((error) => {
      console.error(error)
    })
  }

  // add auth token to api request headers
  axios.interceptors.request.use(
    (config) => {
      // Need to handle if we are using the accesstoken for graph API use or authtoken for HPM API
      if (config.url.search('graph.microsoft.com') !== -1) {
        const {
          auth: {accessToken}
        } = store.getState()
        if (accessToken) {
          config.headers = {Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json'}
        }
      } else {
        if (config.url.search('/sendfile') !== -1) {
          // ok it is a form post
          // console.log('Its a form PUT');
          const {
            auth: {authToken}
          } = store.getState()
          if (authToken) {
            config.headers = {Authorization: `Bearer ${authToken}`, 'Content-Type': 'multipart/form-data'}
          }
        } else {
          const {
            auth: {authToken}
          } = store.getState()
          config.headers = {Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json'}
        }
      }
      return config
    },
    (err) => Promise.reject(err)
  )

  // on api response with error, handle 401s caused by token expiry
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      const {
        auth: {reauthAttempts}
      } = store.getState()
      if (error.response.status === 401 && !originalRequest._retry && reauthAttempts < 15) {
        // API call has failed as token has expired (this is never configurable, and is less than 20 minutes)
        process.env.REACT_APP_DEBUG && console.log('Unauthorized: the API has thrown a 401')

        // // Trigger get token process
        let authToken = await getAuthToken(store, msalInstance)

        process.env.REACT_APP_DEBUG && console.log(authToken)
        process.env.REACT_APP_DEBUG && console.log(reauthAttempts)

        // If we have been able to get a new token then rerun the api request
        if (authToken) {
          originalRequest._retry = true
          process.env.REACT_APP_DEBUG && console.log('Re-running: ' + originalRequest)
          return axios(originalRequest)
        }
        // otherwise, our msal account is not active any longer, so reject the request. auth.authtoken will have been set to undefined in getToken, so we will be back at the login screen
        else {
          originalRequest._retry = false
          return Promise.reject(error)
        }
      } else if (reauthAttempts >= 15) {
        // Something has gone really bad, we have a token, and the client believes it is authenticated but the API is still giving a 401

        await handleLogin()
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export async function getAuthToken(store, msalInstance) {
  async function handleLogin() {
    msalInstance.loginPopup(loginRequest).catch((error) => {
      console.error(error)
    })
  }

  // Start with the active account
  let account = msalInstance.getActiveAccount()

  // Default to using the first account if no account is active
  if (!account && msalInstance.getAllAccounts().length > 0) {
    account = msalInstance.getAllAccounts()[0]
    msalInstance.setActiveAccount(account)
  }

  process.env.REACT_APP_DEBUG && console.log('AuthHelpers: getAuthToken')
  process.env.REACT_APP_DEBUG && console.log(account)

  if (account) {
    const request = {
      scopes: ['User.Read'],
      account: account,
      forceRefresh: true
    }

    // Acquire a new idToken silently
    const authResult = await msalInstance.acquireTokenSilent(request).catch((error) => {
      console.error(error)
      handleLogin()
    })
    process.env.REACT_APP_DEBUG && console.log('AuthHelpers: authResult')
    process.env.REACT_APP_DEBUG && console.log(authResult)

    if (authResult) {
      store.dispatch({type: 'SET_ACCESSTOKEN', payload: authResult.accessToken})
      store.dispatch({type: 'SET_AUTHTOKEN', payload: authResult.idToken})
      store.dispatch({type: 'RESET_REAUTH'})
    }

    return authResult ? authResult.idToken : undefined
  } else {
    store.dispatch({type: 'ATTEMPT_REAUTH'})

    await handleLogin()

    return undefined
  }
}

/**
 * This method clears localStorage of any claims challenge entry
 * @param {Object} account
 */
export const clearStorage = (account) => {
  for (var key in sessionStorage) {
    if (key.startsWith(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}`)) sessionStorage.removeItem(key)
  }
}
