import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../../store/ducks/project.duck'
import BidHeader from '../../components/BidHeader'
import BuildersRisk from './setup/BuildersRisk'
import Files from '../../components/Files'
import ProjectBusinessUnits from './setup/ProjectBusinessUnits'
import BidBuildingConnectedSync from './setup/BidBuildingConnectedSync'
import NotLoaded, {useIsLoaded} from '../../components/IsLoaded'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import SetupTab from './setup/Setup/SetupTab'
import FeeExclusions from './setup/FeeExclusions'
import BidQuoteAreasTab from './setup/BidQuoteAreas/BidQuoteAreasTab'

export default function ProjectSetup() {
  const dispatch = useDispatch()

  const projectID = useSelector((state) => state.project.projectID)
  const pageTab = useSelector((state) => state.project.pageTab.setup)
  const userRole = useSelector((state) => state.auth.roleDesc)
  const assumedUserRole = useSelector((state) => state.auth.assumedUserRole)
  const projectInformationGrid = useSelector((state) => state.project.ProjectInformationGrid.values)

  useEffect(() => {
    dispatch(Project.actions.fetchProjectInformationGrid({projectID: projectID}))
  }, [dispatch, projectID])

  const [key, setKey] = useState(pageTab)

  function setThisTab(k) {
    setKey(k)
    dispatch(Project.actions.setTab({page: 'setup', tab: k}))
  }

  function getBRApproved() {
    switch (projectInformationGrid.BuildersRiskQuoteApproval) {
      case 'Approved to Proceed':
        return 'Approved'
      case 'Not Approved':
        return 'Not Approved'
      default:
        return 'Pending'
    }
  }

  const isLoaded = useIsLoaded({
    type: 'project',
    projectDeps: ['FETCH_ProjectInformationGrid'],
    ignoreSetup: true
  })

  if (!isLoaded.result) {
    return <NotLoaded payload={isLoaded} />
  }

  return (
    <div>
      <BidHeader />

      <div className='container-fluid py-5 hpm-content-div'>
        <Tabs id='setupCombined' activeKey={key} onSelect={(k) => setThisTab(k)} mountOnEnter={true} className='mb-7'>
          <Tab eventKey='setup' title='Setup'>
            <SetupTab />
          </Tab>
          <Tab eventKey='buildersrisk' title={<span>{`Builders Risk: (${getBRApproved()})`}</span>}>
            <BuildersRisk />
          </Tab>
          <Tab eventKey='breakoutAreas' title='Breakout Areas'>
            <BidQuoteAreasTab />
          </Tab>
          <Tab eventKey='projectconfiguration' title='Fee Exclusions'>
            <FeeExclusions />
          </Tab>
          {userRole === 'Administrator' && assumedUserRole === '' ? (
            <Tab eventKey='businessunits' title='Business Units'>
              <ProjectBusinessUnits />
            </Tab>
          ) : null}
          <Tab eventKey='buildingconnected' title='BuildingConnected'>
            <BidBuildingConnectedSync />
          </Tab>
          <Tab eventKey='files' title='Files' className='pt-[10px]'>
            {/* temporary fix to issue where MUI DataGrid in component errors on hide with no height */}
            {key === 'files' ? <Files projectID={projectID} /> : null}
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}
