import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../../../store/ducks/project.duck'
import * as Reference from '../../../store/ducks/reference.duck'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import Button from 'react-bootstrap/Button'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

export default function ProjectBusinessUnits() {
  const projectID = useSelector((state) => state.project.projectID)
  const businessUnits = useSelector((state) => state.reference.BusinessUnits)
  const projectBusinessUnits = useSelector((state) => state.project.ProjectBusinessUnits.values)
  const dispatch = useDispatch()

  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const [checked, setChecked] = useState([])
  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  useEffect(() => {
    dispatch(Project.actions.fetchProjectBusinessUnits(projectID))
  }, [dispatch, projectID])
  useEffect(() => {
    dispatch(Reference.actions.fetchBusinessUnits())
  }, [dispatch])

  const putProjectBusinessUnits = () => {
    var payload = {
      projectID: projectID,
      businessUnits: right.filter((row) => !row.isPrimary)
    }
    dispatch(Project.actions.putProjectBusinessUnits(payload))
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const numberOfChecked = (items) => intersection(checked, items).length

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  useEffect(() => {
    setLeft(businessUnits.filter((bu) => !projectBusinessUnits.some((pbu) => pbu.businessUnitID === bu.businessUnitID)))
    setRight(projectBusinessUnits)
  }, [businessUnits, projectBusinessUnits])

  const customList = (title, items) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{'aria-label': 'all items selected'}}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List dense component='div' role='list' sx={{height: 400, width: 350, overflow: 'auto'}}>
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`

          return (
            <ListItem key={`item_${value.id}`} role='listitem' button onClick={handleToggle(value)}>
              <ListItemIcon className='min-w-[38px]'>
                <Checkbox checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple inputProps={{'aria-labelledby': labelId}} />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.businessUnitCode} - ${value.businessUnitName} ${value.isPrimary ? ' (Primary)' : ''}`} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )

  return (
    <div className='container-fluid summary-div'>
      <Grid container spacing={0} justifyContent='center' alignItems='center'>
        <Grid item>
          {customList('Reference List', left)}
          <Grid item md={12}>
            <Grid container justifyContent='space-between'>
              <Grid item></Grid>
              <Grid item className='h-[55px]'></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className='w-[80px]'>
          <Grid container spacing={3} direction='column' alignItems='center'>
            <Grid item>
              <Button variant='primary' size='small' className='w-[50px] m-[10px]' onClick={handleCheckedRight} disabled={leftChecked.length === 0} aria-label='move selected right'>
                <ChevronRightIcon />
              </Button>
              <Button variant='primary' size='small' className='w-[50px] m-[10px]' onClick={handleCheckedLeft} disabled={rightChecked.length === 0} aria-label='move selected left'>
                <ChevronLeftIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {customList('Project Business Units', right)}
          <Grid item md={12}>
            <Grid container justifyContent='space-between'>
              <Grid item></Grid>
              <Grid item className='pt-[20px]'>
                <Button variant='primary' onClick={() => putProjectBusinessUnits()}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
