import {useEffect, useState} from 'react'
import {getPageTitle, getPageHeader} from '../helpers/page'
import {useLocation} from 'react-router-dom'

const usePageProps = () => {
  const [pageTitle, setPageTitle] = useState('HPM')
  const [pageHeader, setPageHeader] = useState('HPM')
  const location = useLocation()
  const {pathname, hash} = location
  const currentPage = pathname.slice(1 /* Remove leading slash. */).toLowerCase()
  const thisHash = hash.substring(1)

  useEffect(() => {
    const newPageTitle = getPageTitle(currentPage)
    setPageTitle(newPageTitle)
    setPageHeader(getPageHeader(currentPage))
    document.title = newPageTitle
  }, [currentPage, pageTitle])

  return {
    pageTitle,
    pageHeader,
    currentPage,
    hash: thisHash
  }
}

export default usePageProps
