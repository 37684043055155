import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Reference from '../../../store/ducks/reference.duck'
import * as Precon from '../../../store/ducks/precon.duck'
import * as wjCore from '@grapecity/wijmo'
import * as wjcgrid from '@grapecity/wijmo.grid'
import * as wjFlexGrid from '@grapecity/wijmo.react.grid'
import * as wjGridFilter from '@grapecity/wijmo.react.grid.filter'
import {ImmutabilityProvider} from '@grapecity/wijmo.react.grid.immutable'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from 'react-bootstrap/Button'
import {useAppModal} from '../../../context/GlobalModalContext'

export default function ClarificationsTemplates() {
  const dispatch = useDispatch()
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const clarificationTemplates = useSelector((state) => state.reference.ClarificationTemplates)
  const clarificationTemplate = useSelector((state) => state.reference.ClarificationTemplate)

  const {closeModal} = useAppModal()

  useEffect(() => {
    dispatch(Reference.actions.fetchClarificationTemplates())
  }, [dispatch])

  const [id, setID] = useState(1)

  useEffect(() => {
    if (clarificationTemplate.id && id !== clarificationTemplate.id) {
      dispatch(Reference.actions.fetchClarificationTemplate(id))
    }
  }, [dispatch, id, clarificationTemplate.id])

  const gridRef = useRef()

  function changeTemplate(id) {
    setID(id)
    dispatch(Reference.actions.fetchClarificationTemplate(id))
  }

  function copyTemplateToBid(action) {
    closeModal()
    dispatch(Precon.actions.copyClarificationTemplateToBid({bidQuoteID: bidQuoteID, clarificationTemplateID: id, action: action}))
  }

  function onGridInitialized(s) {
    s.collectionView.sortDescriptions.splice(0, 0, new wjCore.SortDescription('itemIndex', true))
    // create a GroupRow to show aggregates automatically
    var row = new wjcgrid.GroupRow()
    // add the new GroupRow to the grid's 'columnFooters' panel
    s.columnFooters.rows.push(row)
    // add a sigma to the header to show that this is a summary row
    s.bottomLeftCells.setCellData(0, 0, '\u03A3')
    s.columnHeaders.rows.cssClassAll = 'group-row'
    console.log('Grid initialised')
  }

  function onloadedRows(grid) {
    for (let i = 0; i < grid.rows.length; i++) {
      let row = grid.rows[i]
      let item = row.dataItem
      // Trade specific items header row
      if (item && item.isHeader) {
        row.cssClass = 'bidtradeitemtemplates-grid-header'
      }
      // Trade specific items row
      if (item && !item.isHeader) {
        row.cssClass = 'bidtradeitemtemplates-grid-item'
      }
    }
  }

  return (
    <div className='container-fluid'>
      <Grid container justifyContent='space-between' className='mb-3'>
        <Grid item>
          <FormControl variant='outlined' size='small'>
            <Select
              id='templateSelector'
              aria-label='Select Template'
              name='templateID'
              className='bg-white-primary min-w-[230px]'
              value={id && clarificationTemplates.some((template) => template.id === id) ? id : ''}
              onChange={(event) => changeTemplate(parseInt(event.target.value))}
              placeholder='Select a tempalte'
            >
              {clarificationTemplates.map((template) => (
                <MenuItem key={`key=${template.id}`} value={template.id}>
                  {template.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant='primary' size='sm' className='ml-2' onClick={() => copyTemplateToBid('replace')}>
            Copy &amp; Replace
          </Button>
          <Button variant='primary' size='sm' className='ml-2' onClick={() => copyTemplateToBid('append')}>
            Copy &amp; Append
          </Button>
        </Grid>
      </Grid>

      <div className='mb-[30px]'>
        {/* cellEditEnded={(s, e) => s.autoSizeColumn(e.col)}  */}
        <wjFlexGrid.FlexGrid
          id='bidtradeitemtemplates-grid'
          ref={gridRef}
          initialized={onGridInitialized}
          loadedRows={onloadedRows}
          frozenColumns={5}
          alternatingRowStep={0}
          allowSorting={false}
          allowResizing='Columns'
          keyActionTab={wjcgrid.KeyAction.Cycle}
          allowDelete={false}
          allowAddNew={false}
        >
          <ImmutabilityProvider itemsSource={clarificationTemplate.clarifications} />
          <wjGridFilter.FlexGridFilter filterColumns={['Description']} />
          <wjFlexGrid.FlexGridColumn binding='id' header='ID' width={20} isReadOnly={true} visible={false} />
          <wjFlexGrid.FlexGridColumn binding='clarificationIndex' header='ItemOrder' width={20} isReadOnly={true} visible={false} />
          <wjFlexGrid.FlexGridColumn binding='description' header='Description' width='*' isReadOnly={false} cssClassAll='header-left' />
        </wjFlexGrid.FlexGrid>
      </div>
    </div>
  )
}
