import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import BidHeader from './BidHeader'
import ProgressBar from 'react-bootstrap/ProgressBar'

export function useIsLoaded({type, projectDeps, referenceDeps, preconDeps, ignoreSetup, refreshReferenceDeps}) {
  // does a basic check on type and whether that type is set (project or bidquote)
  // if loaded, but setup is incomplete, then we stop loading and redirect the user to Project Setup
  // if dependencies are passed in, then it will check the cache for those actions and payloads

  const projectID = useSelector((state) => state.project.projectID)
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const bidQuote = useSelector((state) => state.precon.bidQuote)
  const projectCache = useSelector((state) => state.project.cache)
  const referenceCache = useSelector((state) => state.reference.cache)
  const preconCache = useSelector((state) => state.precon.cache)
  const header = useSelector((state) => state.project.projectHeader.values)

  ignoreSetup = true // overriding setup validation for now, will reinstate after UAT. It is blocking users

  let payload = {
    type: type,
    result: true,
    message: '',
    deps: '',
    progress: 0
  }

  if (type === 'bidQuote' && (!bidQuoteID || bidQuoteID === '' || bidQuoteID === 0 || (bidQuote && bidQuote.id === 0))) {
    payload = {
      ...payload,
      result: false,
      message: 'No BidQuote Selected'
    }
  } else if (type === 'project' && (!projectID || projectID === '' || projectID === 0)) {
    payload = {
      ...payload,
      result: false,
      message: 'No Project Selected'
    }
  } else if (header.progress === 0 && !ignoreSetup) {
    // if we have loaded something, but setup is incomplete
    payload = {
      ...payload,
      result: false,
      message: 'Setup Incomplete'
    }
  } // if we have a project/bidquote loaded, then check dependencies
  else {
    var result = 0
    var depsLength = 0
    var debug = 0
    var missingDeps = []

    if (projectDeps && Array.isArray(projectDeps)) {
      projectDeps.forEach((dep) => {
        debug === 1 && process.env.REACT_APP_DEBUG && console.log(`dep: ${dep} - ${Object.hasOwn(projectCache, dep) && projectCache[dep].payload && projectCache[dep].payload !== 'VOID'}`)
        if (Object.hasOwn(projectCache, dep) && projectCache[dep].payload && projectCache[dep].payload !== 'VOID') {
          return result++
        } else {
          // add missing dep to array
          missingDeps.push(dep)
          return null
        }
      })
      depsLength = depsLength + projectDeps.length
    }

    if (preconDeps && Array.isArray(preconDeps)) {
      preconDeps.forEach((dep) => {
        debug === 1 && process.env.REACT_APP_DEBUG && console.log(`dep: ${dep} - ${Object.hasOwn(preconCache, dep) && preconCache[dep].payload && preconCache[dep].payload !== 'VOID'}`)
        if (Object.hasOwn(preconCache, dep) && preconCache[dep].payload && preconCache[dep].payload !== 'VOID') {
          return result++
        } else {
          // add missing dep to array
          missingDeps.push(dep)
          return null
        }
      })
      depsLength = depsLength + preconDeps.length
    }

    if (referenceDeps && Array.isArray(referenceDeps)) {
      referenceDeps.forEach((dep) => {
        debug === 1 &&
          process.env.REACT_APP_DEBUG &&
          console.log(`dep: ${dep} - ${Object.hasOwn(referenceCache, dep) && (!referenceCache[dep].payload || (referenceCache[dep].payload && referenceCache[dep].payload !== 'VOID'))}`)
        if (Object.hasOwn(referenceCache, dep) && (!referenceCache[dep].payload || (referenceCache[dep].payload && referenceCache[dep].payload !== 'VOID'))) {
          return result++
        } else {
          // add missing dep to array
          missingDeps.push(dep)
          return null
        }
      })
      depsLength = depsLength + referenceDeps.length
    }

    payload = {
      ...payload,
      result: result === depsLength,
      message: !(result === depsLength) ? 'Fetching...' : '',
      deps: `${result}/${depsLength}`,
      progress: (result / depsLength) * 100,
      projectDeps: projectDeps,
      preconDeps: preconDeps,
      referenceDeps: referenceDeps,
      missingDeps: missingDeps,
      refreshReferenceDeps: refreshReferenceDeps
    }

    // process.env.REACT_APP_DEBUG && console.log('********************* Check cache ****************************')
    // process.env.REACT_APP_DEBUG && console.log(`${result}/${depsLength}`)
  }
  return payload
}

export default function NotLoaded({payload, noHeader}) {
  if (payload && payload.message === 'Setup Incomplete') {
    return (
      <div>
        {noHeader ? null : <BidHeader />}
        <div className='help-aside'>
          <p>
            You must complete all Project Setup fields before working on this bid:{' '}
            <Link to='/setup' className='obvious-link'>
              Go to Setup
            </Link>
            .
          </p>
        </div>
      </div>
    )
  } else if (payload && payload.message === 'Fetching...') {
    return (
      <div>
        {noHeader ? null : <BidHeader />}
        <div className='loading-tile'>
          {/* <img alt="logo" src='/media/misc/loading_1.gif' height={30} width={30} /> */}
          <div className='loading-message'>Fetching data{payload.deps ? ` ${payload.deps}` : ''} tasks...</div>
          <div className='mt-[10px]'>
            <ProgressBar className='w-[350px] bg-silver-primary' animated now={payload.progress + 20} />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        {noHeader ? null : <BidHeader />}
        <div className='help-aside'>
          <p>
            To start working on a bid, select a project from the{' '}
            <Link to='/dashboard' className='obvious-link'>
              Dashboard
            </Link>
            .
          </p>
        </div>
      </div>
    )
  }
}
