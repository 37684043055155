import InputAdornment from '@mui/material/InputAdornment'
import {NumberFormatCustom} from '../components/NumberFormatCustom'

const buildInputPropsGeneric = (readOnly, dp, inputComponent, startAdornment, endAdornment, props) => ({
  readOnly,
  startAdornment: startAdornment !== undefined && <InputAdornment position='start'>{startAdornment}</InputAdornment>,
  endAdornment: endAdornment !== undefined && <InputAdornment position='end'>{endAdornment}</InputAdornment>,
  inputComponent,
  inputProps: dp !== undefined ? {decimalplaces: dp} : undefined,
  ...props
})

export const buildInputPropsDollar = ({readOnly, dp, ...props}) => buildInputPropsGeneric(readOnly, dp, NumberFormatCustom, '$', undefined, props)

export const buildInputPropsPct = ({readOnly, dp, ...props}) => buildInputPropsGeneric(readOnly, dp, NumberFormatCustom, '%', undefined, props)

export const buildInputPropsBase = ({readOnly, ...props}) => buildInputPropsGeneric(readOnly, undefined, undefined, undefined, undefined, props)
