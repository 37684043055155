import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../../../store/ducks/project.duck'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import {NumberFormatCustom} from '../../../components/NumberFormatCustom'
import {calcHours, calcCost} from './CalcBudget'
import {useAppModal} from '../../../context/GlobalModalContext'
import {AddResourceModalWrapper} from '../../../components/Modals/ModalLayouts'

export const SetTeamMemberBudgetModalKey = '@Modal_SetTeamMemberBudget'
export default function SetResourceBudget() {
  const dispatch = useDispatch()
  const projectInformationGrid = useSelector((state) => state.project.ProjectInformationGrid.values)
  const weeks = useSelector((state) => state.project.ManpowerGridWeeks.values)
  const resourceBudget = useSelector((state) => state.project.resourceBudget)

  const {closeModal} = useAppModal()

  function changeBudget(event) {
    let propertyName = event.target.name
    let newValue = event.target.value.replace(/,/g, '')
    if (event.keyCode !== 9) {
      if (propertyName === 'constructionBudget') {
        let newHours = calcHours(projectInformationGrid.SalaryEscalationRate, weeks, 'Construction', resourceBudget.postingRate, parseFloat(newValue))
        let newBudgetTotal = newValue
        if (newHours > 40) {
          newHours = 40
          newBudgetTotal = calcCost(projectInformationGrid.SalaryEscalationRate, weeks, 'Construction', resourceBudget.postingRate, newHours)
        }
        let constructionWeeklyBudget = newBudgetTotal / weeks.filter((week) => week.Phase === 'Construction').length
        dispatch(
          Project.actions.setResourceBudget({
            ...resourceBudget,
            newConstructionHours: newHours,
            [propertyName]: newBudgetTotal,
            constructionWeeklyBudget: constructionWeeklyBudget
          })
        )
      }
      if (propertyName === 'closeoutBudget') {
        let newHours = calcHours(projectInformationGrid.SalaryEscalationRate, weeks, 'Close-out', resourceBudget.postingRate, parseFloat(newValue))
        let newBudgetTotal = newValue
        if (newHours > 40) {
          newHours = 40
          newBudgetTotal = calcCost(projectInformationGrid.SalaryEscalationRate, weeks, 'Close-out', resourceBudget.postingRate, newHours)
        }
        let closeoutWeeklyBudget = newBudgetTotal / weeks.filter((week) => week.Phase === 'Close-out').length
        dispatch(
          Project.actions.setResourceBudget({
            ...resourceBudget,
            newCloseoutHours: newHours,
            [propertyName]: newBudgetTotal,
            closeoutWeeklyBudget: closeoutWeeklyBudget
          })
        )
      }
      if (propertyName === 'newConstructionHours') {
        var newHours = parseFloat(newValue)
        if (newHours > 40) {
          newHours = 40
        }
        let newBudgetTotal = calcCost(projectInformationGrid.SalaryEscalationRate, weeks, 'Construction', resourceBudget.postingRate, parseFloat(newHours))
        let constructionWeeklyBudget = newBudgetTotal / weeks.filter((week) => week.Phase === 'Construction').length
        dispatch(
          Project.actions.setResourceBudget({
            ...resourceBudget,
            newConstructionHours: newHours,
            constructionBudget: newBudgetTotal,
            constructionWeeklyBudget: constructionWeeklyBudget
          })
        )
      }
      if (propertyName === 'newCloseoutHours') {
        let newHours = parseFloat(newValue)
        if (newHours > 40) {
          newHours = 40
        }
        let newBudgetTotal = calcCost(projectInformationGrid.SalaryEscalationRate, weeks, 'Close-out', resourceBudget.postingRate, parseFloat(newHours))
        let closeoutWeeklyBudget = newBudgetTotal / weeks.filter((week) => week.Phase === 'Close-out').length
        dispatch(
          Project.actions.setResourceBudget({
            ...resourceBudget,
            newCloseoutHours: newHours,
            closeoutBudget: newBudgetTotal,
            closeoutWeeklyBudget: closeoutWeeklyBudget
          })
        )
      }
      if (propertyName === 'constructionWeeklyBudget') {
        let newBudgetTotal = parseFloat(newValue) * weeks.filter((week) => week.Phase === 'Construction').length
        let newHours = calcHours(projectInformationGrid.SalaryEscalationRate, weeks, 'Construction', resourceBudget.postingRate, parseFloat(newBudgetTotal))
        if (newHours > 40) {
          newHours = 40
          newBudgetTotal = calcCost(projectInformationGrid.SalaryEscalationRate, weeks, 'Construction', resourceBudget.postingRate, newHours)
        }
        let constructionWeeklyBudget = newBudgetTotal / weeks.filter((week) => week.Phase === 'Construction').length
        dispatch(
          Project.actions.setResourceBudget({
            ...resourceBudget,
            newConstructionHours: newHours,
            constructionBudget: newBudgetTotal,
            constructionWeeklyBudget: constructionWeeklyBudget
          })
        )
      }
      if (propertyName === 'closeoutWeeklyBudget') {
        let newBudgetTotal = parseFloat(newValue) * weeks.filter((week) => week.Phase === 'Close-out').length
        let newHours = calcHours(projectInformationGrid.SalaryEscalationRate, weeks, 'Close-out', resourceBudget.postingRate, parseFloat(newBudgetTotal))
        if (newHours > 40) {
          newHours = 40
          newBudgetTotal = calcCost(projectInformationGrid.SalaryEscalationRate, weeks, 'Close-out', resourceBudget.postingRate, newHours)
        }
        let closeoutWeeklyBudget = newBudgetTotal / weeks.filter((week) => week.Phase === 'Close-out').length
        dispatch(
          Project.actions.setResourceBudget({
            ...resourceBudget,
            newCloseoutHours: newHours,
            closeoutBudget: newBudgetTotal,
            closeoutWeeklyBudget: closeoutWeeklyBudget
          })
        )
      }
    }
  }

  function putResourceBudget() {
    dispatch(Project.actions.putResourceBudget(resourceBudget))
    closeModal()
  }

  return (
    <AddResourceModalWrapper modalKey={SetTeamMemberBudgetModalKey} title='Set Team Member Budget' onSave={putResourceBudget}>
      <Form>
        <Row>
          <Col md='6'>
            <Form.Group controlId='Role'>
              <Form.Label>Team Member</Form.Label>
              <br />
              <TextField variant='outlined' value={resourceBudget && resourceBudget.EmployeeName} size='small' className='textfield w-full' readOnly autoComplete='off' />
            </Form.Group>
          </Col>
          {/* <Col md="6">
        <Form.Group controlId="addForm.formPartnerPostingRate">
          <Form.Label>Posting Rate</Form.Label>
            <br />
            <TextField
              id="none"
              name="employeeRate"
              variant="outlined"
              value={resourceBudget && resourceBudget.postingRate}
              readOnly
              size="small"
              className="textfield"
              InputProps={{ 
                classes: { input: 'input-money-edit' }, 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                inputComponent: NumberFormatCustom,
                inputProps: { decimalplaces: 2 }  }}
              autoComplete='off'
              />
        </Form.Group>
      </Col> */}
        </Row>
        <Row className='mt-8'>
          <Col>
            <Form.Group controlId='constructionBudget'>
              <Form.Label>
                <span className='font-semibold'>Construction</span> Actual
              </Form.Label>
              <TextField
                id='constructionBudget'
                name='constructionBudget'
                variant='outlined'
                defaultValue={resourceBudget.constructionBudget}
                value={resourceBudget.constructionBudget}
                onKeyUp={changeBudget}
                size='small'
                className='textfield-edit'
                InputProps={{
                  classes: {input: 'input-money-edit'},
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                  inputProps: {decimalplaces: 0}
                }}
                autoComplete='off'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='closeoutBudget'>
              <Form.Label>
                <span className='font-semibold'>Close-out</span> Actual
              </Form.Label>
              <TextField
                id='closeoutBudget'
                name='closeoutBudget'
                variant='outlined'
                defaultValue={resourceBudget.closeoutBudget}
                value={resourceBudget.closeoutBudget}
                onKeyUp={changeBudget}
                size='small'
                className='textfield-edit'
                InputProps={{
                  classes: {input: 'input-money-edit'},
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                  inputProps: {decimalplaces: 0}
                }}
                autoComplete='off'
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-8'>
          <Col>
            <Form.Group controlId='newConstructionHours'>
              <Form.Label>
                <span className='font-semibold'>Construction</span> Hours per week
              </Form.Label>
              <TextField
                id='newConstructionHours'
                name='newConstructionHours'
                variant='outlined'
                defaultValue={resourceBudget.newConstructionHours}
                value={resourceBudget.newConstructionHours}
                onKeyUp={changeBudget}
                size='small'
                className='textfield-edit'
                InputProps={{
                  classes: {input: 'input-number-edit'},
                  inputComponent: NumberFormatCustom,
                  inputProps: {decimalplaces: 1}
                }}
                autoComplete='off'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='newCloseoutHours'>
              <Form.Label>
                <span className='font-semibold'>Close-out</span> Hours per week
              </Form.Label>
              <TextField
                id='newCloseoutHours'
                name='newCloseoutHours'
                variant='outlined'
                defaultValue={resourceBudget.newCloseoutHours}
                value={resourceBudget.newCloseoutHours}
                onKeyUp={changeBudget}
                size='small'
                className='textfield-edit'
                InputProps={{
                  classes: {input: 'input-number-edit'},
                  inputComponent: NumberFormatCustom,
                  inputProps: {decimalplaces: 1}
                }}
                autoComplete='off'
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-8'>
          <Col>
            <Form.Group controlId='constructionWeeklyBudget'>
              <Form.Label>
                <span className='font-semibold'>Construction</span> Actual per week
              </Form.Label>
              <TextField
                id='constructionWeeklyBudget'
                name='constructionWeeklyBudget'
                variant='outlined'
                defaultValue={resourceBudget.constructionWeeklyBudget}
                value={resourceBudget.constructionWeeklyBudget}
                onKeyUp={changeBudget}
                size='small'
                className='textfield-edit'
                InputProps={{
                  classes: {input: 'input-money-edit'},
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                  inputProps: {decimalplaces: 0}
                }}
                autoComplete='off'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='closeoutWeeklyBudget'>
              <Form.Label>
                <span className='font-semibold'>Close-out</span> Actual per week
              </Form.Label>
              <TextField
                id='closeoutWeeklyBudget'
                name='closeoutWeeklyBudget'
                variant='outlined'
                defaultValue={resourceBudget.closeoutWeeklyBudget}
                value={resourceBudget.closeoutWeeklyBudget}
                onKeyUp={changeBudget}
                size='small'
                className='textfield-edit'
                InputProps={{
                  classes: {input: 'input-money-edit'},
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                  inputProps: {decimalplaces: 0}
                }}
                autoComplete='off'
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </AddResourceModalWrapper>
  )
}
