import {useEffect, useRef, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Reference from '../../../store/ducks/reference.duck'
import * as Precon from '../../../store/ducks/precon.duck'
import BidQuoteChecklistTemplates from '../templateselectors/BidQuoteChecklistTemplates'
import {Formik} from 'formik'
import * as Yup from 'yup'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MUIMenuItem from '@mui/material/MenuItem'
import InputBase from '@mui/material/InputBase'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import * as wjCore from '@grapecity/wijmo'
import * as wjcgrid from '@grapecity/wijmo.grid'
import * as wjFlexGrid from '@grapecity/wijmo.react.grid'
import {DataChangeAction} from '@grapecity/wijmo.grid.immutable'
import {ImmutabilityProvider} from '@grapecity/wijmo.react.grid.immutable'
import {Menu} from '@grapecity/wijmo.input'
import useBatchedGridChangeHandler from '../../../hooks/useBatchedGridChangeHandler'
import {ModalLayoutI} from '../../../components/Modals/ModalLayouts'
import {FooterLayout} from '../../../components/Modals/BasicModalWrapper'
import {useAppModal} from '../../../context/GlobalModalContext'
import NotLoaded, {useIsLoaded} from '../../../components/IsLoaded'
import Refresh from '../../../components/Refresh'
import {IndentCellTemplate} from '../../../components/templates/FlexGridTemplates'

export default function BidQuoteChecklist({id, setBidQuoteChecklistID, bidQuoteID}) {
  // Manages a checklist, whether a template or an instance, the difference being which redux actions are called

  const dispatch = useDispatch()
  const bidQuoteChecklist = useSelector((state) => state.reference.BidQuoteChecklist)
  const businessUnits = useSelector((state) => state.reference.BusinessUnits)
  const syncChecklistToBidQuote = useSelector((state) => state.reference.Status.syncChecklistToBidQuote)
  const roleDesc = useSelector((state) => state.auth.roleDesc)
  const assumedUserID = useSelector((state) => state.auth.assumedUserID)
  const referenceCache = useSelector((state) => state.reference.cache)
  const isLoaded = useIsLoaded({
    type: 'bidQuote',
    referenceDeps: ['FETCH_BidQuoteChecklistTemplate'],
    refreshReferenceDeps: true
  })

  const {openModal} = useAppModal()

  useEffect(() => {
    if ((id || id === 0) && (!referenceCache.FETCH_BidQuoteChecklistTemplate || referenceCache?.FETCH_BidQuoteChecklistTemplate?.payload === 'VOID' || id !== bidQuoteChecklist.id)) {
      dispatch(Reference.actions.fetchBidQuoteChecklistTemplate({bidQuoteChecklistID: id}))
    }
  }, [dispatch, referenceCache.FETCH_BidQuoteChecklistTemplate, id, bidQuoteChecklist.id])

  const initBidQuoteChecklistItem = () => {
    return {
      id: 0,
      bidQuoteChecklistItemID: 0,
      bidQuoteChecklistID: id,
      businessUnitID: '',
      section: 'General Scope',
      label: '',
      desiredScope: '',
      itemIndex: 999,
      prevIndex: 999,
      isHeader: false
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Template Name is required')
  })

  const submitForm = (values) => {
    var payload = {
      bidQuoteChecklist: {...values, items: undefined}
    }
    values.id !== 0 && setBidQuoteChecklistID(false)
    dispatch(Reference.actions.setCache({caller: {type: 'FETCH_BidQuoteChecklistTemplate'}, clear: true}))
    dispatch(Reference.actions.putBidQuoteChecklistTemplate(payload))
  }

  const deleteBidQuoteChecklist = (values) => {
    var payload = {
      bidQuoteChecklist: {...values, items: undefined}
    }
    setBidQuoteChecklistID(false)
    dispatch(Reference.actions.setCache({caller: {type: 'FETCH_BidQuoteChecklistTemplate'}, clear: true}))
    dispatch(Reference.actions.deleteBidQuoteChecklistTemplate(payload))
  }

  const handleCancel = () => {
    setBidQuoteChecklistID(false)
    dispatch(Reference.actions.setCache({caller: {type: 'FETCH_BidQuoteChecklistTemplate'}, clear: true}))
  }
  // const handleCancel = useMemo(() => {
  //   setBidQuoteChecklistID(false)
  //   dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BidQuoteChecklistTemplate'}, clear: true}))
  // }, [setBidQuoteChecklistID, dispatch])

  // grid templates
  const renderBidQuoteChecklistIndentTemplate = useCallback(
    (context) => <IndentCellTemplate label={context.item.label} headerClass={'checklistitems-grid-header'} indentLevel={context.item.indentLevel} isHeader={context.item.isHeader} />,
    []
  )

  const gridRef = useRef()

  // create ref to bidQuoteID is state so that when users change it, we can fetch it for the payload in the grid add/change actions
  const payloadRef = useRef(id)
  useEffect(() => {
    payloadRef.current = id
  }, [id])

  const putItems = (payload) => {
    dispatch(Reference.actions.putBidQuoteChecklistItemTemplate(payload))
  }

  const deleteItem = (payload) => {
    dispatch(Reference.actions.deleteBidQuoteChecklistItemTemplate(payload))
  }

  const onGridDataChanged = (context, events) => {
    const newGrid = context?.collectionView?.items
    let payload = {}
    const filteredEvents = events.filter((e) => (e.newItem && !e.newItem.isSectionHeader) || (e.oldItem && !e.oldItem.isSectionHeader))

    if (newGrid && filteredEvents.length > 0) {
      const action = filteredEvents[0].action
      switch (action) {
        case DataChangeAction.Add:
          payload = filteredEvents.map((e) => ({
            ...initBidQuoteChecklistItem(),
            ...e.newItem,
            bidQuoteChecklistID: payloadRef.current,
            desiredScope: e.newItem.desiredScope === 'i' || e.newItem.desiredScope === 'I' ? 'Included' : e.newItem.desiredScope
          }))
          putItems({bidQuoteChecklistID: payloadRef.current, gridRows: payload})
          break
        case DataChangeAction.Change: {
          payload = filteredEvents.map((e) => ({
            ...e.newItem,
            desiredScope: e.newItem.desiredScope === 'i' || e.newItem.desiredScope === 'I' ? 'Included' : e.newItem.desiredScope
          }))
          const rowsToChangeMap = new Map(payload.map((e) => [e.checklistItemID, e]))
          let grid = newGrid.map((item) => rowsToChangeMap.get(item.checklistItemID) || item)
          dispatch(Reference.actions.setBidQuoteChecklistTemplateItems(grid))
          dispatch(Precon.actions.setPreconUpdates({name: 'BidQuoteChecklist', id: id, value: 1}))
          putItems({bidQuoteChecklistID: payloadRef.current, gridRows: payload})
          break
        }
        case DataChangeAction.Remove:
          // const removePos = newGrid.map(function(row) { return row.id; }).indexOf(e.oldItem.id);
          //newGrid.splice(removePos, 1)
          payload = filteredEvents.map((e) => e.oldItem)
          deleteItem({bidQuoteChecklistID: payloadRef.current, gridRows: payload})
          break
        default:
        // throw 'Unknown data action';
      }
    } else {
      console.log('save failed: newGrid has no items')
      console.log(newGrid)
    }
  }
  const batchEvents = useBatchedGridChangeHandler(onGridDataChanged)

  function getRowHeight(text) {
    // paint text on canvas to measure rendered length
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    context.font = 'font-family: "Open Sans"; font-size: 12px'
    let textLen = context.measureText(text).width
    // calculate row height based on the length
    let wraps = textLen / 350
    let rowHeight = 26
    if (wraps >= 2) {
      //&& wraps < 3
      rowHeight = 26 + (wraps - 1) * 12 //+ (numUpper * 0.35)
    } else if (wraps >= 4) {
      //&& wraps < 3
      rowHeight = 26 + (wraps - 1) * 8 //+ (numUpper * 0.35)
    }
    return rowHeight
  }

  const buildMenu = (s) => {
    let menu = new Menu(document.createElement('div'), {
      owner: s.hostElement,
      displayMemberPath: 'header',
      subItemsPath: 'items',
      commandParameterPath: 'cmd',
      dropDownCssClass: 'ctx-menu',
      closeOnLeave: true,
      itemsSource: [
        {header: 'Insert row', cmd: 'cmdInsertNewRow'},
        {header: 'Insert 10 rows', cmd: 'cmdInsert10NewRows'},
        {header: 'Insert 50 rows', cmd: 'cmdInsert50NewRows'},
        {header: 'Insert header row', cmd: 'cmdInsertNewHeaderRow'},
        {header: 'Change to header row', cmd: 'cmdMakeHeaderRow'},
        {header: 'Change to normal row', cmd: 'cmdMakeItemRow'},
        {header: 'Indent Right', cmd: 'cmdIndentLevelAdd'},
        {header: 'Indent Left', cmd: 'cmdIndentLevelSubtract'},
        {header: '-'},
        {header: 'Exclude from HITT Adjustments', cmd: 'cmdMakeExcludeFromCalcRow'},
        {header: 'Include in HITT Adjustments', cmd: 'cmdMakeIncludeInCalcRow'},
        {header: '-'},
        {header: 'Delete', cmd: 'cmdDeleteRow'}
      ],
      command: {
        // enable/disable menu commands
        canExecuteCommand: (cmd) => {
          let dataItem = s.rows[s.selection.row].dataItem
          if (dataItem.isHeader & (cmd === 'cmdMakeHeaderRow')) {
            return false
          } else if (!dataItem.isHeader & (cmd === 'cmdMakeItemRow')) {
            return false
          } else if (dataItem.excludeFromCalculation & (cmd === 'cmdMakeExcludeFromCalcRow')) {
            return false
          } else if (!dataItem.excludeFromCalculation & (cmd === 'cmdMakeIncludeInCalcRow')) {
            return false
          } else if (!dataItem.indentLevel & (cmd === 'cmdIndentLevelSubtract')) {
            return false
          } else if (dataItem.indentLevel && dataItem.indentLevel >= 5 && cmd === 'cmdIndentLevelAdd') {
            return false
          }
          return true
        },
        // execute menu commands
        executeCommand: (cmd) => {
          let dataItem = s.rows[s.selection.row].dataItem
          let payload = {}
          console.log(dataItem)
          switch (cmd) {
            case 'cmdMakeItemRow':
            case 'cmdMakeHeaderRow': {
              payload = {
                isHeader: cmd === 'cmdMakeHeaderRow' ? 1 : 0
              }
              let grid = s.collectionView.items.map((item) => (item.checklistItemID === dataItem.checklistItemID ? {...dataItem, ...payload} : item))
              dispatch(Reference.actions.setBidQuoteChecklistTemplateItems(grid))
              dispatch(Reference.actions.putBidQuoteChecklistItemTemplate({bidQuoteChecklistID: dataItem.bidQuoteChecklistID, gridRows: [{...dataItem, ...payload}]}))
              dispatch(Precon.actions.setPreconUpdates({name: 'BidQuoteChecklist', id: id, value: 1}))
              break
            }
            case 'cmdDeleteRow':
              deleteItem({bidQuoteChecklistID: dataItem.bidQuoteChecklistID, gridRows: [dataItem]})
              break
            case 'cmdInsertNewRow':
            case 'cmdInsert10NewRows':
            case 'cmdInsert50NewRows':
            case 'cmdInsertNewHeaderRow':
              payload = {
                isHeader: cmd === 'cmdInsertNewHeaderRow' ? 1 : 0,
                itemIndex: dataItem.itemIndex,
                prevIndex: dataItem.itemIndex - 1,
                section: dataItem.section,
                IndentLevel: dataItem.indentLevel ?? 0,
                insertRows: cmd === 'cmdInsertNewRow' || cmd === 'cmdInsertNewHeaderRow' ? 1 : cmd === 'cmdInsert50NewRows' ? 50 : 10
              }
              dispatch(Reference.actions.putBidQuoteChecklistItemTemplate({bidQuoteChecklistID: dataItem.bidQuoteChecklistID, gridRows: [{...initBidQuoteChecklistItem(), ...payload}], refresh: true}))
              break
            case 'cmdIndentLevelAdd':
            case 'cmdIndentLevelSubtract': {
              let newIndentLevel = cmd === 'cmdIndentLevelAdd' ? (dataItem.indentLevel ? dataItem.indentLevel + 1 : 1) : dataItem.indentLevel ? dataItem.indentLevel - 1 : 0
              payload = {
                indentLevel: newIndentLevel
              }
              let grid = s.collectionView.items.map((item) => (item.checklistItemID === dataItem.checklistItemID ? {...dataItem, ...payload} : item))
              dispatch(Reference.actions.setBidQuoteChecklistTemplateItems(grid))
              dispatch(Reference.actions.putBidQuoteChecklistItemTemplate({bidQuoteChecklistID: dataItem.bidQuoteChecklistID, gridRows: [{...dataItem, ...payload}]}))
              dispatch(Precon.actions.setPreconUpdates({name: 'BidQuoteChecklist', id: id, value: 1}))
              break
            }
            case 'cmdMakeExcludeFromCalcRow':
            case 'cmdMakeIncludeInCalcRow': {
              payload = {
                excludeFromCalculation: cmd === 'cmdMakeExcludeFromCalcRow' ? 1 : 0
              }
              let grid = s.collectionView.items.map((item) => (item.checklistItemID === dataItem.checklistItemID ? {...dataItem, ...payload} : item))
              dispatch(Reference.actions.setBidQuoteChecklistTemplateItems(grid))
              dispatch(Reference.actions.putBidQuoteChecklistItemTemplate({bidQuoteChecklistID: dataItem.bidQuoteChecklistID, gridRows: [{...dataItem, ...payload}]}))
              break
            }
            default:
          }
          // restore focus to active grid cell (TFS 439964)
          s.refresh()
          let sel = s.selection,
            cell = s.cells.getCellElement(sel.row, sel.col)
          if (cell) {
            cell.focus()
          }
        }
      }
    })
    // done
    return menu
  }

  function onGridInitialized(s) {
    // Start editing, expand row height if editing Label
    s.beginningEdit.addHandler((s, e) => {
      let col = s.columns[e.col]
      if (col.binding === 'label') {
        s.rows[e.row].height = 100
      }
    })
    // End editing: reset row height and handle error
    s.cellEditEnding.addHandler((s, e) => {
      let item = s.rows[e.row].dataItem
      s.rows[e.row].height = getRowHeight(item.label)
    })

    // Add context menu
    let host = s.hostElement,
      menu = buildMenu(s)
    host.addEventListener(
      'contextmenu',
      (e) => {
        // select the cell/column that was clicked
        let sel = s.selection,
          ht = s.hitTest(e),
          row = ht.getRow()
        switch (ht.panel) {
          case s.cells:
            s.select(ht.row, ht.col)
            break
          case s.columnHeaders:
            s.select(sel.row, ht.col)
            break
          case s.rowHeaders:
            s.select(ht.row, sel.col)
            break
          default:
            return // invalid panel
        }
        // show the menu for the current column
        if (s.selection.col > -1 && row.dataItem) {
          e.preventDefault() // cancel the browser's default menu
          menu.show(e) // and show ours
        }
      },
      true
    )
    s.collectionView.sortDescriptions.splice(0, 0, new wjCore.SortDescription('itemIndex', true))
    s.columnHeaders.rows.cssClassAll = 'group-row'
    console.log('Grid initialised')
  }

  function onloadedRows(grid) {
    for (let i = 0; i < grid.rows.length; i++) {
      let row = grid.rows[i]
      let item = row.dataItem
      // Section header row
      if (item && item.isSectionHeader) {
        row.cssClass = 'checklistitems-grid-section-header'
      }
      // Header row
      else if (item && item.isHeader) {
        row.cssClass = 'checklistitems-grid-header'
      }
      // item row
      else if (item && !item.isHeader) {
        row.cssClass = 'checklistitems-grid-item'
      }
    }
  }

  // create ref to bidtrade is state so that when users change it, we can fetch it for the payload in the grid add/change actions
  const moveRef = useRef()

  function handleDraggingRow(s, e) {
    moveRef.current = {dataItem: e.panel.rows[e.row].dataItem, from: e.row}
  }

  function handleDraggedRow(s, e) {
    let dropIndex = e.row
    console.log(moveRef.current)
    dispatch(Reference.actions.putBidQuoteChecklistItemTemplate({bidQuoteChecklistID: moveRef.current.bidQuoteChecklistID, gridRows: [{...moveRef.current.dataItem, itemIndex: dropIndex + 1}]}))
  }

  const putSyncProject = () => {
    dispatch(Reference.actions.setRefStatus({statusName: 'syncChecklistToBidQuote', statusValue: 'Syncing...'}))
    dispatch(Precon.actions.syncChecklistToBidQuote({bidQuoteChecklistItem: {bidQuoteChecklistID: id, bidQuoteID: bidQuoteID}}))
  }

  const SyncButton = ({status}) => {
    return (
      <Button className='ml-3' size='sm' variant='primary' onClick={() => putSyncProject()}>
        {status !== 'Not Started' ? status : 'Sync with Project'}
      </Button>
    )
  }

  if (!isLoaded.result) {
    return <NotLoaded payload={isLoaded} noHeader={true} />
  }

  return (
    <div>
      <Grid container justifyContent='space-between'>
        <Grid item md={6}>
          {bidQuoteID ? '' : <h4>General Scope Template</h4>}
        </Grid>
        <Grid item md={6} className='text-right mb-1'>
          <Refresh isLoaded={isLoaded} />
          {bidQuoteID ? (
            <>
              <SyncButton status={syncChecklistToBidQuote} />
              <Button size='sm' className='ml-3' onClick={() => openModal(BidQuoteChecklistTradeScopeModalKey)}>
                Templates
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>

      {bidQuoteID ? null : (
        <Formik
          enableReinitialize={true}
          initialValues={bidQuoteChecklist}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            submitForm(values)
          }}
        >
          {(props) => {
            const {values, errors, touched, handleSubmit, isSubmitting, setFieldValue} = props
            return (
              <form noValidate={true} autoComplete='off' onSubmit={!isSubmitting ? handleSubmit : undefined}>
                <Table aria-label='simple table' className='summary-table w-[700px]'>
                  <tbody className='summary-tablebody'>
                    <tr>
                      <td>
                        <Row className='mb-6'>
                          <Col md='5'>
                            <Form.Group>
                              <Form.Label>Template Name</Form.Label>
                              <Form.Control
                                type='text'
                                id='name'
                                name='name'
                                value={values.name}
                                onChange={(event) => setFieldValue('name', event.target.value)}
                                autoComplete='new-password'
                                isInvalid={touched.name && !!errors.name}
                                isValid={touched.name && !errors.name}
                              />
                              <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          {roleDesc === 'Administrator' && assumedUserID === 0 ? (
                            <Col md='7'>
                              <Form.Group>
                                <Form.Label>Business Unit</Form.Label>
                                <br></br>
                                <Select
                                  id='businessUnitID'
                                  name='businessUnitID'
                                  variant='outlined'
                                  size='small'
                                  className='select w-full bg-white-primary'
                                  value={values.businessUnitID ? values.businessUnitID : ''}
                                  onChange={(event) => setFieldValue('businessUnitID', event.target.value)}
                                >
                                  <MUIMenuItem key={`bu-0`} value={''} label={'Any'}>
                                    - Any -
                                  </MUIMenuItem>
                                  {businessUnits.map((row, index) => (
                                    <MUIMenuItem key={`bu-${index}`} value={row.id} label={row.businessUnitName}>
                                      <Grid container direction='row'>
                                        <Grid item md={2}>
                                          {row.businessUnitCode}
                                        </Grid>
                                        <Grid item md={10}>
                                          {row.businessUnitName}
                                        </Grid>
                                      </Grid>
                                    </MUIMenuItem>
                                  ))}
                                </Select>
                              </Form.Group>
                            </Col>
                          ) : null}
                        </Row>
                        <Grid container justifyContent='space-between'>
                          <Grid item>
                            {values.id !== 0 ? (
                              <Button variant='warning' size='sm' onClick={() => deleteBidQuoteChecklist(values)}>
                                Delete
                              </Button>
                            ) : null}
                          </Grid>
                          <Grid item>
                            <Button variant='secondary' size='sm' onClick={handleCancel}>
                              Cancel
                            </Button>
                            <Button variant='primary' className='ml-2' size='sm' type='submit'>
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </form>
            )
          }}
        </Formik>
      )}
      {id > 0 ? (
        <div className='w-full mb-[30px]'>
          <wjFlexGrid.FlexGrid
            id='checklistitems-grid'
            ref={gridRef}
            initialized={onGridInitialized}
            draggingRow={handleDraggingRow}
            draggedRow={handleDraggedRow}
            loadedRows={onloadedRows}
            frozenColumns={5}
            alternatingRowStep={0}
            allowSorting={false}
            allowDragging='Rows'
            allowResizing='Columns'
            keyActionTab={wjcgrid.KeyAction.Cycle}
            allowDelete={true}
            allowAddNew={false}
            autoRowHeights={true}
          >
            <ImmutabilityProvider itemsSource={bidQuoteChecklist ? bidQuoteChecklist.items : []} dataChanged={batchEvents} />
            <wjFlexGrid.FlexGridColumn binding='id' header='ID' width={20} isReadOnly={true} visible={false} />
            <wjFlexGrid.FlexGridColumn binding='itemIndex' header=' ' width={30} isReadOnly={true} visible={false} />
            <wjFlexGrid.FlexGridColumn binding='lineNumber' header='#' width={45} isReadOnly={true} visible={true} cssClass='cell-monospace-right' />
            <wjFlexGrid.FlexGridColumn binding='label' header='Description' width='*' isReadOnly={false} cssClassAll='header-left' wordWrap={true}>
              <wjFlexGrid.FlexGridCellTemplate cellType='Cell' template={renderBidQuoteChecklistIndentTemplate}></wjFlexGrid.FlexGridCellTemplate>
              <wjFlexGrid.FlexGridCellTemplate
                cellType='CellEdit'
                template={(context) => {
                  return <InputBase className='grid-text-editor-textfield' size='small' multiline defaultValue={context.value} onChange={(event) => (context.value = event.target.value)} />
                }}
              />
            </wjFlexGrid.FlexGridColumn>
            <wjFlexGrid.FlexGridColumn binding='desiredScope' header='Desired Scope' width={180} isReadOnly={false} cssClassAll='header-left' />
            <wjFlexGrid.FlexGridColumn binding='excludeFromCalculationFlag' header='Exclude from Adjustments' format='t' width={170} isReadOnly={true} />
          </wjFlexGrid.FlexGrid>
        </div>
      ) : null}
    </div>
  )
}

export const BidQuoteChecklistTradeScopeModalKey = '@Modal_BidQuoteChecklistTradeScope'
export const BidQuoteChecklistTradeScopeTemplateModal = () => {
  return (
    <ModalLayoutI modalKey={BidQuoteChecklistTradeScopeModalKey} title='General Scope Templates' footerLayout={FooterLayout.Empty}>
      <BidQuoteChecklistTemplates />
    </ModalLayoutI>
  )
}
