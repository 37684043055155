import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import AppForm from '../../../../components/Form/AppForm'
import BasicModalWrapper, {FooterLayout} from '../../../../components/Modals/BasicModalWrapper'
import {Field, useFormikContext} from 'formik'
import AppSelect from '../../../../components/Form/AppSelect'
import {UNITS_OF_QUANTITY, UNITS_OF_AREA} from './BidQuoteAreasTab'
import AppFormField from '../../../../components/Form/AppFormField'
import AppSingleCheckbox from '../../../../components/Form/AppSingleCheckbox'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Grid from '@mui/material/Grid'
import {useAppModal} from '../../../../context/GlobalModalContext'

export const EditBreakoutAreaModalKey = '@Modal_EditBreakoutArea'
const EditBreakoutAreaFormId = 'edit-breakout-area-form'

const EditBreakoutAreaModal = ({orderOptions, initialValues, validationSchema, onSubmitForm, onDeleteBreakoutArea}) => {
  return (
    <BasicModalWrapper
      modalKey={EditBreakoutAreaModalKey}
      dialogClassName='edit-sm-dialog-modal'
      headerClassName='edit-sm-modal'
      title='Edit Breakout Area'
      bodyClassName='edit-md-modal'
      footerLayout={FooterLayout.None}
    >
      <AppForm
        formId={EditBreakoutAreaFormId}
        enableReinitialize={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
        formProps={{
          noValidate: true,
          autoComplete: 'off'
        }}
      >
        <Tabs id='EditModal' activeKey='details' mountOnEnter={true}>
          <Tab eventKey='details' title='Details' style={{backgroundColor: 'rgb(245, 245, 245)', padding: 6}}>
            <Row className='mt-8'>
              <Col md='6'>
                <Field component={AppFormField} id='edit-breakout-area-summary-area' type='text' name='area' label='Area' autoComplete='new-password' />
              </Col>
              <Col md='6'>
                <Field
                  component={AppSelect}
                  label='Order'
                  id='edit-breakout-area-summary-area-index'
                  name='areaIndex'
                  options={orderOptions}
                  variant='outlined'
                  size='small'
                  className='select bg-white-primary w-[50px]'
                  labelProps={{className: 'form-label'}}
                />
              </Col>
            </Row>
            <Row className='mt-8'>
              <Col md='3'>
                <Field component={AppFormField} id='edit-breakout-area-summary-areaSf' className={'number-input'} type='text' name='areaSf' label='Area Size (sf)' autoComplete='new-password' />
              </Col>
              <Col md='3'>
                <Field
                  component={AppSelect}
                  label='Unit of Area'
                  id='edit-breakout-area-summary-area-label'
                  name='areaSfLabel'
                  options={UNITS_OF_AREA}
                  variant='outlined'
                  size='small'
                  className='select bg-white-primary w-[50px]'
                  labelProps={{className: 'form-label'}}
                />
              </Col>
              <Col md='3'>
                <Field component={AppFormField} id='edit-breakout-area-summary-areaUnits' className={'number-input'} type='text' name='areaUnits' label='Quantity' autoComplete='new-password' />
              </Col>
              <Col md='3'>
                <Field
                  component={AppSelect}
                  label='Unit of Quantity'
                  id='edit-breakout-area-summary-area-unit'
                  name='areaUnitLabel'
                  options={UNITS_OF_QUANTITY}
                  variant='outlined'
                  size='small'
                  className='select bg-white-primary w-[50px]'
                  labelProps={{className: 'form-label'}}
                />
              </Col>
            </Row>
            <Row className='mt-8'>
              <Col md='12'>
                <Form.Label>Contingency</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <Field
                  component={AppFormField}
                  id='edit-breakout-area-summary-project-contingency-pct'
                  className={'number-input'}
                  type='text'
                  name='projectContingencyPc'
                  label='Project'
                  showPctSuffix
                />
              </Col>
              <Col md='3'>
                <Field component={AppFormField} id='edit-breakout-area-summary-design-contingency-pct' className={'number-input'} type='text' name='designContingencyPc' label='Design' showPctSuffix />
              </Col>
              <Col md='3'>
                <Field
                  component={AppFormField}
                  id='edit-breakout-area-summary-escalation-contingency-pct'
                  className={'number-input'}
                  type='text'
                  name='escalationContingencyPc'
                  label='Escalation'
                  showPctSuffix
                />
              </Col>
            </Row>
            <Row className='mt-8'>
              <Col md='6'>
                <Form.Label>Calculation Logic</Form.Label>
                <br />
                <Field component={AppSingleCheckbox} id='edit-breakout-area-summary-isRemainderOfBaseBid' name='isRemainderOfBaseBid' className='checkbox-hpm' label='Value is remainder of base bid' />
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Field
                  component={AppSingleCheckbox}
                  id='edit-breakout-area-summary-excludeSfFromProjectTotal'
                  name='excludeSfFromProjectTotal'
                  className='checkbox-hpm'
                  label='Exclude Sf From Project Total'
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <FooterWithinForm formId={EditBreakoutAreaFormId} onDelete={onDeleteBreakoutArea} />
      </AppForm>
    </BasicModalWrapper>
  )
}

const FooterWithinForm = ({formId, onDelete}) => {
  const {closeModal} = useAppModal()
  const {values} = useFormikContext()

  const handleClickDelete = () => onDelete(values)
  return (
    <Modal.Footer className='edit-md-modal'>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <Button variant='warning' onClick={handleClickDelete}>
            Delete
          </Button>
        </Grid>
        <Grid item>
          <Button variant='secondary' onClick={closeModal}>
            Cancel
          </Button>
          <Button type='submit' form={formId} variant='primary' className='ml-[10px]'>
            Save
          </Button>
        </Grid>
      </Grid>
    </Modal.Footer>
  )
}

export default EditBreakoutAreaModal
