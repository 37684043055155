import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {useCallback, useMemo} from 'react'
import Form from 'react-bootstrap/Form'

const AppSelect = (props) => {
  const {
    placeholder,
    label,
    id,
    field: {name, onBlur, value},
    form: {errors, touched, setFieldTouched, setFieldValue, values},
    options,
    labelProps,
    onChangeCallback,
    disabled,
    getDisabled,
    containerProps,
    ...inputProps
  } = props
  const hasError = errors[name] && touched[name]

  const handleChange = useCallback(
    (event) => {
      setFieldValue(name, event.target.value)
      if (onChangeCallback) {
        onChangeCallback(name, event.target.value, values)
      }
    },
    [name, onChangeCallback, setFieldValue, values]
  )

  const disableField = useMemo(() => {
    let result = disabled
    if (getDisabled) {
      result = getDisabled(name, value, values)
    }
    return result
  }, [disabled, getDisabled, name, value, values])

  return (
    <Form.Group {...containerProps}>
      <label htmlFor={id} {...labelProps}>
        {label}
      </label>
      <br />
      <Select
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={() => {
          setFieldTouched(name)
          onBlur(name)
        }}
        value={value || ''}
        disabled={disableField}
        {...inputProps}
      >
        {options.map(({label, value: val}) => (
          <MenuItem key={val} value={val}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {hasError && <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>}
    </Form.Group>
  )
}

export default AppSelect
