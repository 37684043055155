import {useCallback, useMemo} from 'react'
import * as wjCore from '@grapecity/wijmo'
import Grid from '@mui/material/Grid'
import {parseNumber} from '@brightspace-ui/intl/lib/number.js'
import {numberWithCommas} from '../../helpers/number'

function isNumeric(str) {
  if (typeof str != 'string') return false // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

export const ItemTypeAndCodeNoTemplate = ({itemType, codeNo}) => {
  if (itemType === 'Alternate' || itemType === 'VE') {
    return <>{itemType}</>
  }
  return <div className='h-full pt-0 pb-[2px] pl-3 pr-[2px] font-medium'>{codeNo}</div>
}

export const ItemTypeAlternateIndexTemplate = ({itemType, alternateIndex}) => {
  if (itemType === 'Alternate' || itemType === 'VE') {
    return <>{alternateIndex}</>
  }
  return null
}

export const ItemTypeAllowanceIndexTemplate = ({allowanceID, allowanceIndex}) => {
  if (allowanceID) {
    return <>{allowanceIndex}</>
  }
  return null
}

export const GroupHeaderColumnCellTemplate = ({itemName, disablePrefix = false}) => {
  return <div className='h-full pt-0 pb-[2px] pl-[3px] font-semibold'>{`${disablePrefix ? '' : '> '}${itemName}`}</div>
}

export const LineNumberColumnCellTemplate = ({lineNumber}) => {
  return <div className='h-full pt-0 pb-[2px] pl-0 pr-[2px] font-medium'>{lineNumber}</div>
}

export const TradeTitleColumnCellTemplate = ({tradeTitle}) => {
  return <div className='h-full pt-0 pb-[2px] pl-[10px] pr-[2px] font-medium'>{tradeTitle}</div>
}

// Trade Summary
export const TradeVEColumnCellTemplate = ({tradeVE}) => {
  const className = useMemo(() => (tradeVE < 0 ? 'text-red-primary' : 'text-black-primary'), [tradeVE])
  return <span className={className}>{parseInt(tradeVE) ? wjCore.Globalize.formatNumber(tradeVE, 'N0') : null}</span>
}

// Bid Spreadsheet
export const BidRowTemplate = ({targetQuote, loadContact}) => {
  const handleClickGrid = useCallback(() => {
    loadContact(targetQuote?.subcontractorID, targetQuote?.bidTradeID, targetQuote?.bidderName, targetQuote?.bidTradeQuoteID)
  }, [loadContact, targetQuote?.bidTradeID, targetQuote?.bidTradeQuoteID, targetQuote?.bidderName, targetQuote?.subcontractorID])

  if (targetQuote) {
    const className = 'cursor-pointer h-full' + (targetQuote.selectedBid === 1 ? ' winning-bidder' : '')

    return (
      <Grid container direction='row' justifyContent='space-between' onClick={handleClickGrid} className={className}>
        <Grid item md={12} className='text-center pl-[3px] pt-[3px]'>
          {targetQuote?.bidderName} {targetQuote?.isUserCreatedSubcontractor ? '*' : ''}
        </Grid>
        <Grid item md={12} className='text-center pl-[3px] pb-[3px] text-black-primary'>
          {parseInt(targetQuote.total) && targetQuote?.posted === 1
            ? parseInt(targetQuote.total) < 0
              ? `(${Math.abs(parseInt(targetQuote.total))})`
              : `${wjCore.Globalize.formatNumber(targetQuote.total, 'N0')}${targetQuote.status === 'DISQUALIFIED' ? ' (DQ)' : ''}`
            : targetQuote.status}
        </Grid>
      </Grid>
    )
  }
  return null
}

export const BudgetCellTemplate = ({prevBudget}) => {
  const className = prevBudget < 0 ? 'text-red-primary' : 'text-black-primary'
  let displayValue = prevBudget ? parseNumber(prevBudget.toString()) : ''
  return (
    <span className={className}>
      {displayValue && isNumeric(displayValue.toString())
        ? displayValue < 0
          ? `(${wjCore.Globalize.formatNumber(Math.abs(displayValue), 'N0')})`
          : wjCore.Globalize.formatNumber(displayValue, 'N0')
        : ''}
    </span>
  )
}

export const DeltaCellTemplate = ({prevBudgetVarianceToFinal}) => {
  const className = prevBudgetVarianceToFinal < 0 ? 'text-red-primary' : 'text-black-primary'

  const cellValue = !isNaN(parseFloat(prevBudgetVarianceToFinal))
    ? prevBudgetVarianceToFinal < 0
      ? `(${wjCore.Globalize.formatNumber(Math.abs(prevBudgetVarianceToFinal))})`
      : wjCore.Globalize.formatNumber(prevBudgetVarianceToFinal)
    : prevBudgetVarianceToFinal
  return <span className={className}>{cellValue}</span>
}

export const CSICodeCellTemplate = ({bidTradeID, codeNo, goToTrade, className = 'cursor-pointer'}) => {
  const handleClick = useCallback(() => goToTrade(bidTradeID), [bidTradeID, goToTrade])
  // if codeNo contains 'Alternate' or 'VE', then it is not a CSI code
  if ((codeNo && codeNo.includes('Alternate')) || (codeNo && codeNo.includes('VE'))) {
    return <div>{codeNo}</div>
  }
  return (
    <div className={className} onClick={handleClick}>
      {codeNo}
    </div>
  )
}

export const TradeCellTemplate = ({bidTradeID, title, goToTrade}) => {
  if (bidTradeID > 0) {
    return (
      <div className='cursor-pointer underline' onClick={() => goToTrade(bidTradeID)}>
        {title}
      </div>
    )
  } else {
    return title
  }
}

export const LowestBidCellTemplate = ({lowest}) => {
  const className = lowest < 0 ? 'text-red-primary' : 'text-black-primary'
  const cellValue = !isNaN(parseFloat(lowest)) ? (lowest < 0 ? `(${wjCore.Globalize.formatNumber(Math.abs(lowest))})` : wjCore.Globalize.formatNumber(lowest)) : lowest
  return <span className={className}>{cellValue}</span>
}

export const AdjustmentBidCellTemplate = ({adjustment}) => {
  const className = adjustment < 0 ? 'text-red-primary' : 'text-black-primary'
  const cellValue = !isNaN(parseFloat(adjustment)) ? (adjustment < 0 ? `(${wjCore.Globalize.formatNumber(Math.abs(adjustment))})` : wjCore.Globalize.formatNumber(adjustment)) : adjustment
  return <span className={className}>{cellValue}</span>
}

export const HITTPlugCellTemplate = ({hittPlugDisplay}) => {
  return <span>{Number(hittPlugDisplay) > 0 ? wjCore.Globalize.formatNumber(parseInt(hittPlugDisplay), 'N0') : hittPlugDisplay}</span>
}

export const ParsedCostCellTemplate = ({item, target, onClick, fixedAt0 = false}) => {
  const handleClickCell = useCallback(() => onClick(item), [onClick, item])
  return (
    <div onClick={handleClickCell} className='bid-sheet-cell-link-nobgcolor'>
      {!!target && numberWithCommas(fixedAt0 ? (target || 0).toFixed(0) : target)}
    </div>
  )
}

export const ParsedBillCellTemplate = ({target, prefix, className = ''}) => {
  return (
    <span className={`font-normal text-red-primary ${className}`}>
      {prefix}
      {target != null && !isNaN(target) && numberWithCommas(target.toFixed(0))}
    </span>
  )
}

export const ParsedTotalCellTemplate = ({total}) => {
  const className = total <= 0 ? 'col-negative-value' : ''
  return <div className={className}>{total < 0 ? `(${Math.abs(total)})` : total}</div>
}

export const SelectedCompanyNameCellTemplate = ({selectedSubcontractorID, selectedCompanyName, selectedBidTradeQuoteID, onClick}) => {
  const handleClick = useCallback(
    () => onClick(selectedSubcontractorID, selectedCompanyName, selectedBidTradeQuoteID),
    [onClick, selectedSubcontractorID, selectedCompanyName, selectedBidTradeQuoteID]
  )
  return (
    <div onClick={handleClick} className='bid-sheet-cell-link'>
      {selectedCompanyName}
    </div>
  )
}

// TODO: Verify if this implementation is functional.
export const NotesColumnCellTemplate = ({onClick}) => {
  return (
    <span>
      Notes
      <span onClick={onClick} className='flexgrid-col-header-link pl-[10px]'>
        {' '}
        + Expand
      </span>
    </span>
  )
}

export const IndentCellTemplate = ({isHeader, headerClass, label, indentLevel}) => {
  const indentClassName = useMemo(() => {
    switch (indentLevel) {
      case 1:
        return 'pl-[5px]'
      case 2:
        return 'pl-[10px]'
      case 3:
        return 'pl-[15px]'
      case 4:
        return 'pl-[20px]'
      case 5:
        return 'pl-[25px]'
      default:
        return 'pl-0'
    }
  }, [indentLevel])

  return <div className={`h-full pt-0 pb-[2px] ${indentClassName} ${isHeader ? headerClass : ''} pr-[2px]`}>{label}</div>
}
