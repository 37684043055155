import {Field} from 'formik'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import {NumberFormatCustom} from '../../../../components/NumberFormatCustom'
import AppForm from '../../../../components/Form/AppForm'
import AppSelect from '../../../../components/Form/AppSelect'
import ColFormField from '../../../../components/Form/ColFormField'
import {UNITS_OF_QUANTITY, UNITS_OF_AREA} from './BidQuoteAreasTab'

const BreakoutAreaSummaryForm = ({initialValues, onValidateProjectAreaUnit, onValidateProjectAreaLabel, onClickAddBreakoutArea}) => {
  return (
    <AppForm initialValues={initialValues} enableReinitialize={true} validateOnBlur={false} formProps={{noValidate: true, autoComplete: 'off'}}>
      <Table aria-label='simple table' className='summary-table'>
        <tbody className='summary-tablebody'>
          <tr>
            <td width='10%'>
              <Field
                component={ColFormField}
                id='breakout-area-summary-project-unit-value'
                name='ProjectAreaValue'
                label='Project Size'
                variant='outlined'
                className='mt-2'
                size='small'
                normal={1}
                yellow={0}
                InputProps={{
                  readOnly: true,
                  inputComponent: NumberFormatCustom,
                  inputProps: {decimalplaces: 0}
                }}
                autoComplete='off'
              />
            </td>
            <td width='15%'>
              <div className='summary-innercfe'>
                <Field
                  component={AppSelect}
                  label='Primary unit of area'
                  id='breakout-area-summary-project-area-unit'
                  name='ProjectAreaLabel'
                  options={UNITS_OF_AREA}
                  variant='outlined'
                  size='small'
                  className='select bg-white-primary mt-2 !w-[50px]'
                  validate={onValidateProjectAreaLabel}
                />
              </div>
            </td>
            <td width='10%'>
              <div className='summary-innercfe'>
                <Field
                  component={ColFormField}
                  id='breakout-area-summary-project-unit-value'
                  name='ProjectUnitValue'
                  label='Quantity'
                  variant='outlined'
                  className='mt-2'
                  size='small'
                  normal={1}
                  yellow={0}
                  InputProps={{
                    readOnly: true,
                    inputComponent: NumberFormatCustom,
                    inputProps: {decimalplaces: 0}
                  }}
                  autoComplete='off'
                />
              </div>
            </td>
            <td width='25%'>
              <div className='summary-innercfe'>
                <Field
                  component={AppSelect}
                  label='Primary unit of quantity'
                  id='breakout-area-summary-project-area-unit'
                  name='ProjectAreaUnit'
                  options={UNITS_OF_QUANTITY}
                  variant='outlined'
                  size='small'
                  className='select bg-white-primary mt-2 !w-[50px]'
                  validate={onValidateProjectAreaUnit}
                />
              </div>
            </td>
            <td width='30%' className='text-right'>
              <div className='mt-7'>
                <Button variant='primary' size='sm' onClick={onClickAddBreakoutArea}>
                  New Breakout Area
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </AppForm>
  )
}

export default BreakoutAreaSummaryForm
