import {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Reference from '../../../store/ducks/reference.duck'
import * as Precon from '../../../store/ducks/precon.duck'
import * as wjcgrid from '@grapecity/wijmo.grid'
import * as wjFlexGrid from '@grapecity/wijmo.react.grid'
import {ImmutabilityProvider} from '@grapecity/wijmo.react.grid.immutable'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from 'react-bootstrap/Button'
import {useAppModal} from '../../../context/GlobalModalContext'

export default function BidQuoteChecklistTemplates() {
  const dispatch = useDispatch()
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const bidQuoteChecklists = useSelector((state) => state.reference.BidQuoteChecklists)
  const bidQuoteChecklist = useSelector((state) => state.reference.BidQuoteChecklistPreview)
  const filterBidTradeID = useSelector((state) => state.auth.Filters.bidTradeID)

  const {closeModal} = useAppModal()

  useEffect(() => {
    dispatch(Reference.actions.fetchBidQuoteChecklistTemplates())
  }, [dispatch])

  const [id, setID] = useState(3)

  useEffect(() => {
    if ((bidQuoteChecklist && bidQuoteChecklist.id === 0) || id === 0) {
      dispatch(Reference.actions.fetchBidQuoteChecklistTemplate({bidQuoteChecklistID: id, preview: true}))
    }
  }, [dispatch, id, bidQuoteChecklist, bidQuoteChecklist.id])

  const gridRef = useRef()

  function changeTemplate(id) {
    console.log(`select template ID: ${id}`)
    setID(id)
    dispatch(Reference.actions.fetchBidQuoteChecklistTemplate({bidQuoteChecklistID: id, preview: true}))
  }

  function copyTemplateToBid(action) {
    closeModal()
    dispatch(Reference.actions.setCache({caller: {type: 'FETCH_BidQuoteChecklistTemplate'}, clear: true}))
    dispatch(Precon.actions.copyBidQuoteChecklistToBid({bidQuoteID: bidQuoteID, bidTradeID: filterBidTradeID, bidQuoteChecklistID: id, action: action}))
  }

  function onloadedRows(grid) {
    for (let i = 0; i < grid.rows.length; i++) {
      let row = grid.rows[i]
      let item = row.dataItem
      // Section header row
      if (item && item.isSectionHeader) {
        row.cssClass = 'checklistitems-grid-section-header'
      }
      // Header row
      else if (item && item.isHeader) {
        row.cssClass = 'checklistitems-grid-header'
      }
      // item row
      else if (item && !item.isHeader) {
        row.cssClass = 'checklistitems-grid-item'
      }
    }
  }

  return (
    <div className='container-fluid'>
      <Grid container justifyContent='space-between' className='mb-3'>
        <Grid item>
          <FormControl variant='outlined' size='small'>
            <Select
              id='templateSelector'
              aria-label='Select Template'
              name='templateID'
              value={id && bidQuoteChecklists.some((template) => template.id === id) ? id : ''}
              onChange={(event) => changeTemplate(parseInt(event.target.value))}
              className='bg-white-primary min-w-[230px]'
              placeholder='Select a tempalte'
            >
              <MenuItem value={0}></MenuItem>
              {bidQuoteChecklists.map((template) => (
                <MenuItem key={`key=${template.id}`} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant='primary' size='sm' className='ml-2' onClick={() => copyTemplateToBid('replace')}>
            Copy &amp; Replace
          </Button>
          {/* Removing this button for now.  We can add it back in if we need it. The use case is questionable, and there are issues with ordering 
          <Button variant='primary' size='sm' className='ml-2' onClick={() => copyTemplateToBid('append')}>
            Copy &amp; Append
          </Button> */}
        </Grid>
      </Grid>

      <div className='mb-[30px]'>
        <wjFlexGrid.FlexGrid
          id='bidtradeitemtemplates-grid'
          ref={gridRef}
          loadedRows={onloadedRows}
          frozenColumns={5}
          alternatingRowStep={0}
          allowSorting={false}
          allowResizing='Columns'
          keyActionTab={wjcgrid.KeyAction.Cycle}
          allowDelete={false}
          allowAddNew={false}
        >
          <ImmutabilityProvider itemsSource={bidQuoteChecklist.items} />
          <wjFlexGrid.FlexGridColumn binding='id' header='ID' width={20} isReadOnly={true} visible={false} />
          <wjFlexGrid.FlexGridColumn binding='itemIndex' header=' ' width={30} isReadOnly={true} visible={false} />
          <wjFlexGrid.FlexGridColumn binding='lineNumber' header='#' width={35} isReadOnly={true} visible={true} cssClass='cell-monospace-right' />
          <wjFlexGrid.FlexGridColumn binding='label' header='Description' width='*' isReadOnly={true} cssClassAll='header-left' />
          <wjFlexGrid.FlexGridColumn binding='desiredScope' header='Desired Scope' width={150} isReadOnly={true} cssClassAll='header-left' />
        </wjFlexGrid.FlexGrid>
      </div>
    </div>
  )
}
