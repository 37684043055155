import Form from 'react-bootstrap/Form'
import CustomTextField from '../SummaryTableTextField'

const ColFormFieldControl = (props) => {
  const {
    placeholder,
    label,
    id,
    field: {name, onBlur, onChange, value},
    form: {errors, touched, setFieldTouched},
    labelProps,
    ...inputProps
  } = props
  const hasError = errors[name] && touched[name]
  return (
    <Form.Group>
      <label htmlFor={id} {...labelProps}>
        {label}
      </label>
      <br />
      <CustomTextField
        id={id}
        name={name}
        placeholder={placeholder}
        onBlur={() => {
          setFieldTouched(name)
          onBlur(name)
        }}
        value={value}
        onChange={(value) => onChange(name)(value)}
        {...inputProps}
      />
      {hasError && <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>}
    </Form.Group>
  )
}
export default ColFormFieldControl
