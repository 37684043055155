import {useSelector, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import * as Auth from '../store/ducks/auth.duck'
import * as Project from '../store/ducks/project.duck'
import Button from 'react-bootstrap/Button'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import CancelIcon from '@mui/icons-material/Cancel'

export const EndImpersonate = ({assumedUserName, endImpersonate}) => {
  return (
    <Grid className='mb-[10px] p-3' container direction='column' justifyContent='center' alignItems='center' spacing={3}>
      <Paper>
        <Grid className='m-[10px] font-semibold' item md={12}>
          {assumedUserName}
        </Grid>
        <Grid className='m-[10px]' item md={12}>
          <Link to='/users' onClick={endImpersonate}>
            <Button size='sm' variant='warning'>
              <CancelIcon /> End Impersonate
            </Button>
          </Link>
        </Grid>
      </Paper>
    </Grid>
  )
}

const EndImpersonateContainer = () => {
  const assumedUserName = useSelector((state) => state.auth.assumedUserName)
  const filters = useSelector((state) => state.auth.Filters)
  const dispatch = useDispatch()

  const endImpersonate = () => {
    dispatch(Auth.actions.setAssumedUser({assumedUserID: 0, userName: '', roleDesc: ''}))
    dispatch(Project.actions.fetchProjectsGrid({status: filters.projectsGridStatus, popCache: true}))
    // console.log('End impersonation')
  }

  if (assumedUserName.length) {
    return <EndImpersonate assumedUserName={assumedUserName} endImpersonate={endImpersonate} />
  } else {
    return null
  }
}

export default EndImpersonateContainer
