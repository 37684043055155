import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import * as Auth from '../../../store/ducks/auth.duck'

const NewSubcontractorTab = ({initialKeyword, formClassName, showToggleFilter, onToggleFilter, statusFilter, onClickNewSubcontractor}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState(initialKeyword)

  const changeTerm = (term) => {
    setKeyword(term)
    dispatch(Auth.actions.setFilters({filterName: 'subcontractorListTerm', filterValue: term}))
  }

  const navigateToTrades = () => {
    navigate('/trades')
  }

  return (
    <Paper>
      <Grid container justifyContent='space-between'>
        <Grid item md={8} className='pl-2 pt-2 pb-2'>
          <InputGroup size='sm'>
            <InputGroup.Text>
              <SearchIcon fontSize='small' className='-m-1' />
            </InputGroup.Text>
            <Form.Control type='text' value={keyword} onChange={(event) => changeTerm(event.target.value)} autoComplete='OFF' className={formClassName.text} />
            <InputGroup.Text onClick={() => changeTerm('')}>
              <ClearIcon fontSize='small' className={formClassName.clearIcon} />
            </InputGroup.Text>
          </InputGroup>
        </Grid>
        <Grid item md={4} className='pr-2 pt-2 text-right'>
          <>
            {showToggleFilter ? (
              <Button variant='secondary' size='sm' className='mr-[5px]' onClick={onToggleFilter}>
                <FilterListIcon className='-m-[5px]' /> {statusFilter}
              </Button>
            ) : null}
            <Button variant='primary' size='sm' onClick={onClickNewSubcontractor}>
              New Subcontractor
            </Button>
            <Button variant='primary' size='sm' onClick={navigateToTrades} className='ml-2'>
              Trade Scope &gt;
            </Button>
          </>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default NewSubcontractorTab
