export const mapQuotesToObj = (quotes) => {
  const returnObj = {}
  const parsedQuotes = quotes && JSON.parse(quotes)
  if (parsedQuotes) {
    parsedQuotes.forEach((quote, ix) => {
      const objKey = `bid${ix + 1}name`
      returnObj[objKey] = {
        bidTradeID: quote.BidTradeID,
        bidTradeQuoteID: quote.BidTradeQuoteID,
        bidderName: quote.BidderName,
        isUserCreatedSubcontractor: quote.IsUserCreatedSubcontractor,
        selectedBid: quote.SelectedBid,
        posted: quote.Posted,
        status: quote.Status,
        subcontractorID: quote.SubcontractorID,
        total: quote.Total
      }
    })
  }
  return returnObj
}

export const mapAreasToObj = (areas) => {
  const returnObj = {}
  const parsedAreas = areas && JSON.parse(areas)
  if (parsedAreas && parsedAreas.length > 0) {
    parsedAreas.forEach((area, ix) => {
      const objKey = `areaCol_${ix + 1}`
      returnObj[objKey] = {
        total: area.Total,
        totalSF: area.TotalSF,
        totalUnits: area.TotalUnits,
        areaIndex: area.AreaIndex,
        area: area.Area,
        areaSf: area.AreaSF,
        areaUnits: area.AreaUnits,
        areaUnitLabel: area.AreaUnitLabel,
        isRemainderOfBaseBid: area.IsRemainderOfBaseBid
      }
    })
  }
  return returnObj
}

export const transformToApiPayload = (bidSheetRow) => {
  return {
    id: bidSheetRow.BidTradeID,
    bidTradeID: bidSheetRow.BidTradeID,
    bidQuoteID: null, // not available
    codeNo: bidSheetRow.CodeNo,
    title: bidSheetRow.Title,
    textValue: null,
    tradeCost: bidSheetRow.TradeCost,
    tradeVE: null,
    tradeTotal: bidSheetRow.TradeTotal,
    progress: bidSheetRow.Progress,
    prevBudget: bidSheetRow.PrevBudget,
    prevBudgetVarianceToFinal: bidSheetRow.PrevBudgetVarianceToFinal,
    delta: null,
    lowest: bidSheetRow.Lowest,
    adjustment: bidSheetRow.Adjustment,
    proposalValue: null,
    pcContract: null,
    hittPlugText: null,
    hittPlugCost: null,
    hittPlugDisplay: bidSheetRow.hittPlugDisplay,
    selectedBid: null, // TODO: check this value
    maxBids: 0,
    quotes: JSON.stringify(bidSheetRow.BidTradeQuotes),
    areas: JSON.stringify(bidSheetRow.BidTradeAreas)
  }
}
