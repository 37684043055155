import {useSelector} from 'react-redux'
import Grid from '@mui/material/Grid'
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro'
import format from 'date-fns/format'

export default function SubContractorBidHistory() {
  // this is now called in the main subcontractor page to show totals
  // const dispatch = useDispatch()
  // useEffect( () => { dispatch(Precon.actions.fetchSubcontractorBidHistory(props.subcontractorID )) }, [dispatch, props.subcontractorID] )

  const bidHistory = useSelector((state) => state.precon.SubcontractorBidHistory)

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const cols = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'projectName', type: 'string', headerName: 'Project Name', width: 240},
    {field: 'constructionDate', type: 'date', headerName: 'Start Date', width: 125, valueFormatter: ({value}) => format(new Date(value), 'MM-dd-yyyy')},
    {field: 'trade', type: 'string', headerName: 'Trade', flex: 1},
    {field: 'selectedBid', type: 'string', headerName: 'Selected', width: 110, valueFormatter: ({value}) => (value ? 'Yes' : 'No')},
    {field: 'total', type: 'number', headerName: 'Bid', width: 95, valueFormatter: ({value}) => formatter.format(value).replace(/\D00$/, '')},
    {field: 'variation', type: 'number', headerName: 'Variation to Carried Number', width: 190, valueFormatter: ({value}) => (value === 0 ? '-' : value + '%')}
  ]

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <h5>Bid History</h5>
          <div className='bg-white-primary h-[400px] mt-[10px]'>
            <DataGridPro
              height={400}
              rows={bidHistory}
              columns={cols}
              checkboxSelection={false}
              sortModel={[{field: 'constructionDate', sort: 'desc'}]}
              rowHeight={38}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false
                  }
                }
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}
