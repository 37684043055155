import {useState, useEffect, useCallback, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../../../store/ducks/project.duck'
import * as Reference from '../../../store/ducks/reference.duck'
import FieldLabel from '../../../components/FieldLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Autocomplete from '@mui/material/Autocomplete'
import isEmpty from 'lodash/isEmpty'
import CustomTextField from '../../../components/SummaryTableTextField'
import CustomSelect from '../../../components/CustomSelect'
import BuildersRiskTableCells from '../../../components/TableRows/BuildersRiskTableCells'
import {isNumeric} from '../../../helpers/number'
import {buildInputPropsBase, buildInputPropsDollar, buildInputPropsPct} from '../../../helpers/input'
import SoftCostsWorksheetModal, {SoftCostsWorksheetModalKey} from './BuildersRisk/SoftCostsWorksheetModal'
import {useAppModal} from '../../../context/GlobalModalContext'

function getBadgeClass(status) {
  switch (status) {
    case 'Approved to Proceed':
      return 'success'
    case 'Pending':
      return 'secondary'
    case 'Approved With Warning':
      return 'warning'
    case 'Not Approved':
      return 'danger'
    default:
  }
}

function getCountyOption(option) {
  return option.County_Parish_Independent_City
}

function BuildersRisk() {
  const projectID = useSelector((state) => state.project.projectID)
  const projectInformationGrid = useSelector((state) => state.project.ProjectInformationGrid.values)
  const editPermission = useSelector((state) => state.project.editPermission)
  const counties = useSelector((state) => state.reference.Counties).filter((county) => county.State === projectInformationGrid.StateWorkPerformedIn)
  const dispatch = useDispatch()
  const {openModal} = useAppModal()

  const [thisCounty, setThisCounty] = useState(null)
  const [structuralRenovationReplacementCost, setStructuralRenovationReplacementCost] = useState(projectInformationGrid.StructuralRenovationReplacementCost)
  const [buildersRiskOFEValue, setBuildersRiskOFEValue] = useState(projectInformationGrid.BuildersRiskOFEValue)
  // const [softCostsValue, setSoftCostsValue] = useState(projectInformationGrid.SoftCosts)

  const setCounty = useCallback(() => {
    if (projectInformationGrid.ProjectCounty && counties && (!thisCounty || projectInformationGrid.ProjectCounty !== thisCounty)) {
      setThisCounty(counties.find((county) => county.County_Parish_Independent_City === projectInformationGrid.ProjectCounty))
    }
  }, [counties, projectInformationGrid.ProjectCounty, thisCounty])

  const handlePutProperty = useCallback(
    (propertyName, propertyValue) => {
      const payload = {
        [propertyName]: propertyValue
      }
      dispatch(Project.actions.putProperty(payload))
    },
    [dispatch]
  )

  const handleBlur = useCallback(
    (propertyName, value, event) => {
      if ((event.key === 'Enter' || event.key === undefined) && editPermission) {
        handlePutProperty(propertyName, value)
      }
    },
    [editPermission, handlePutProperty]
  )

  const handleSelectChange = useCallback(
    (propertyName, event) => {
      if (editPermission) {
        const payload = {
          CollectionName: 'ProjectInformationGrid',
          PropertyName: event.target.name,
          PropertyValue: event.target.value
        }
        dispatch(Project.actions.setProperty(payload))
        handlePutProperty(event.target.name, event.target.value)
      }
    },
    [dispatch, editPermission, handlePutProperty]
  )

  const handleAutocompleteChange = useCallback(
    (propertyName, event, newValue) => {
      if (editPermission) {
        const payload = {
          CollectionName: 'ProjectInformationGrid',
          PropertyName: propertyName,
          PropertyValue: newValue.County_Parish_Independent_City
        }
        setThisCounty(newValue)
        dispatch(Project.actions.setProperty(payload))
        handlePutProperty(propertyName, newValue.County_Parish_Independent_City)
      }
    },
    [dispatch, editPermission, handlePutProperty]
  )

  const setStructuralRenovationReplacementCostChange = useCallback((values) => {
    setStructuralRenovationReplacementCost(values.target.value)
  }, [])

  const putStructuralRenovationReplacementCost = useCallback(
    (event) => {
      if (
        (event.key === 'Enter' || event.key === undefined) &&
        editPermission &&
        parseInt(structuralRenovationReplacementCost) !== parseInt(projectInformationGrid.StructuralRenovationReplacementCost)
      ) {
        if (isNumeric(structuralRenovationReplacementCost)) {
          handleBlur('StructuralRenovationReplacementCost', structuralRenovationReplacementCost, event)
        } else {
          setStructuralRenovationReplacementCost(projectInformationGrid.StructuralRenovationReplacementCost)
        }
      }
    },
    [editPermission, handleBlur, projectInformationGrid.StructuralRenovationReplacementCost, structuralRenovationReplacementCost]
  )

  const setBuildersRiskOFEValueChange = useCallback((values) => {
    setBuildersRiskOFEValue(values.target.value)
  }, [])

  const putBuildersRiskOFEValue = useCallback(
    (event) => {
      if ((event.key === 'Enter' || event.key === undefined) && editPermission && parseInt(buildersRiskOFEValue) !== parseInt(projectInformationGrid.BuildersRiskOFEValue)) {
        if (isNumeric(buildersRiskOFEValue)) {
          handleBlur('BuildersRiskOFEValue', buildersRiskOFEValue, event)
        } else {
          setBuildersRiskOFEValue(projectInformationGrid.BuildersRiskOFEValue)
        }
      }
    },
    [buildersRiskOFEValue, editPermission, handleBlur, projectInformationGrid.BuildersRiskOFEValue]
  )

  const badgeClass = useMemo(() => getBadgeClass(projectInformationGrid.BuildersRiskQuoteApproval), [projectInformationGrid.BuildersRiskQuoteApproval])
  // const shouldHideValues = useMemo(() => projectInformationGrid.BuildersRiskQuoteApproval === 'Not Approved', [projectInformationGrid.BuildersRiskQuoteApproval])
  const shouldHideValues = false

  /* Input Field methods */

  const inputPropsDollar0dpEdit = useMemo(() => buildInputPropsDollar({readOnly: !editPermission, dp: 0}), [editPermission])
  const inputPropsDollar0dp = useMemo(() => buildInputPropsDollar({readOnly: true, dp: 0}), [])
  const inputPropsPct4dp = useMemo(() => buildInputPropsPct({readOnly: true, dp: 4}), [])
  const inputPropsEQZone = useMemo(
    () => buildInputPropsBase({readOnly: true, props: {disabled: projectInformationGrid.BuildersRiskEQZone === 'County not found' ? true : false}}),
    [projectInformationGrid.BuildersRiskEQZone]
  )
  const inputPropsTRIAZone = useMemo(
    () => buildInputPropsBase({readOnly: true, props: {disabled: projectInformationGrid.BuildersRiskTRIAZone === 'County not found' ? true : false}}),
    [projectInformationGrid.BuildersRiskTRIAZone]
  )
  const inputPropsBuildersRiskNSZone = useMemo(
    () => buildInputPropsBase({readOnly: true, props: {disabled: projectInformationGrid.BuildersRiskNSZone === 'County not found' ? true : false}}),
    [projectInformationGrid.BuildersRiskNSZone]
  )
  const inputPropsBuildersRiskNSRate = useMemo(() => buildInputPropsPct({readOnly: true, dp: 4, props: {classes: {input: 'input-pc-edit'}}}), [])
  const inputPropsBuildersRiskNSPremium = useMemo(() => buildInputPropsDollar({readOnly: true, dp: 0, props: {classes: {input: 'input-money-edit'}}}), [])

  const handleProjectCountyOnChange = useCallback(
    (event, newValue) => {
      handleAutocompleteChange('ProjectCounty', event, newValue)
    },
    [handleAutocompleteChange]
  )

  const projectCountyValue = useMemo(() => (thisCounty ? thisCounty : null), [thisCounty])

  const projectCountyRenderInput = useCallback(
    (params) => (
      <CustomTextField
        {...params}
        type='text'
        variant='outlined'
        size='small'
        name='ProjectCounty'
        normal={1}
        alignLeft={1}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password',
          classes: {input: 'input-text-edit'}
        }}
        className='textfield-edit mt-0 bg-white-primary'
      />
    ),
    []
  )

  const handleConstructionTypeOnChange = useCallback((event) => handleSelectChange('ConstructionType', event), [handleSelectChange])

  const constructionTypeValue = useMemo(() => projectInformationGrid.ConstructionType || '', [projectInformationGrid.ConstructionType])

  const handleStructuralRenovationOnChange = useCallback((event) => handleSelectChange('StructuralRenovation', event), [handleSelectChange])

  const structuralRenovationValue = useMemo(() => projectInformationGrid.StructuralRenovation || '', [projectInformationGrid.StructuralRenovation])

  const handleStructuralRenovationReplacementEvent = useCallback((event) => putStructuralRenovationReplacementCost(event), [putStructuralRenovationReplacementCost])

  // const structuralRenovationCostFieldYellow = useMemo(() => (projectInformationGrid.StructuralRenovation === 'False' ? 0 : 1), [projectInformationGrid.StructuralRenovation])

  const handleDataCenterOnChange = useCallback((event) => handleSelectChange('DataCenter', event), [handleSelectChange])

  const dataCenterValue = useMemo(() => projectInformationGrid.DataCenter || '', [projectInformationGrid.DataCenter])

  const handleInteriorsOnlyOnChange = useCallback((event) => handleSelectChange('InteriorsOnly', event), [handleSelectChange])

  const interiorsOnlyValue = useMemo(() => projectInformationGrid.InteriorsOnly || '', [projectInformationGrid.InteriorsOnly])

  const handlePutBuildersRiskOFEEvent = useCallback(
    (event) => {
      putBuildersRiskOFEValue(event)
    },
    [putBuildersRiskOFEValue]
  )

  // const handleSoftCostsEvent = useCallback(
  //   (event) => {
  //     putSoftCostsValue(event)
  //   },
  //   [putSoftCostsValue]
  // )

  const handleBuildersRiskFloodZoneOnChange = useCallback((event) => handleSelectChange('BuildersRiskFloodZone', event), [handleSelectChange])

  const buildersRiskFloodZoneValue = useMemo(() => projectInformationGrid.BuildersRiskFloodZone || '', [projectInformationGrid.BuildersRiskFloodZone])

  /* Soft Costs Worksheet */

  const showSoftCostsWorksheet = useCallback(() => {
    openModal(SoftCostsWorksheetModalKey)
  }, [openModal])

  useEffect(() => {
    dispatch(Project.actions.fetchProjectInformationGrid())
  }, [dispatch, projectID])

  useEffect(() => {
    dispatch(Reference.actions.fetchCounties())
  }, [dispatch])

  useEffect(() => {
    setCounty()
  }, [setCounty])

  if (isEmpty(projectInformationGrid)) {
    return 'Please select a project to load.'
  }

  return (
    <div>
      <div className='container-fluid summary-div pt-0'>
        <div className='mb-[15px] pl-[10px]'>
          <span className='font-semibold'>QUOTE APPROVAL:</span>
          <Badge className='m-[10px]' bg={badgeClass}>
            {projectInformationGrid.BuildersRiskQuoteApproval}
          </Badge>
          {projectInformationGrid.BuildersRiskMessage !== '' ? (
            <div className='mt-5 mb-0 pb-[15px]'>
              <Badge bg={badgeClass}>Approval Notes: {projectInformationGrid.BuildersRiskMessage}</Badge>
            </div>
          ) : null}
        </div>

        <Table aria-label='simple table' className='summary-table'>
          <tbody className='summary-tablebody'>
            {/* Row 1 */}
            <tr>
              <BuildersRiskTableCells fieldName={'County'}>
                <Autocomplete
                  id='ProjectCounty'
                  variant='outlined'
                  options={counties}
                  getOptionLabel={getCountyOption}
                  value={projectCountyValue}
                  onChange={handleProjectCountyOnChange}
                  className='flex flex-nowrap mt-0 w-full'
                  disableClearable
                  renderInput={projectCountyRenderInput}
                />
              </BuildersRiskTableCells>
              <td> </td>
              <BuildersRiskTableCells fieldName={'Construction Type'} colspanField={2} colspanLabel={2}>
                <FormControl size='small'>
                  <CustomSelect
                    id='ConstructionType'
                    name='ConstructionType'
                    variant='outlined'
                    size='small'
                    value={constructionTypeValue}
                    onChange={handleConstructionTypeOnChange}
                    readOnly={!editPermission}
                  >
                    <MenuItem key='Non-Combustible or Better' value='Non-Combustible or Better' label='Non-Combustible or Better'>
                      Non-Combustible or Better
                    </MenuItem>
                    <MenuItem key='Joisted Masonry' value='Joisted Masonry' label='Joisted Masonry'>
                      Joisted Masonry
                    </MenuItem>
                    <MenuItem key='Wood Frame' value='Wood Frame' label='Wood Frame'>
                      Wood Frame
                    </MenuItem>
                    <MenuItem key='CLT / Mass Timber' value='CLT / Mass Timber' label='CLT / Mass Timber'>
                      CLT / Mass Timber
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
              </BuildersRiskTableCells>
              <td> </td>
            </tr>
            <tr>
              <BuildersRiskTableCells fieldName={'Structural Renovation Project?'}>
                <FormControl size='small'>
                  <CustomSelect
                    id='StructuralRenovation'
                    name='StructuralRenovation'
                    variant='outlined'
                    size='small'
                    value={structuralRenovationValue}
                    onChange={handleStructuralRenovationOnChange}
                    readOnly={!editPermission}
                  >
                    <MenuItem key='True' value='True' label='Yes'>
                      Yes
                    </MenuItem>
                    <MenuItem key='False' value='False' label='No'>
                      No
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
              </BuildersRiskTableCells>
              <td> </td>
              <BuildersRiskTableCells fieldName={'Existing Structure Replacement Cost'} colspanField={2} colspanLabel={2}>
                <CustomTextField
                  id='StructuralRenovationReplacementCost'
                  name='StructuralRenovationReplacementCost'
                  variant='outlined'
                  defaultValue={projectInformationGrid.StructuralRenovationReplacementCost}
                  value={structuralRenovationReplacementCost}
                  onChange={setStructuralRenovationReplacementCostChange}
                  onBlur={handleStructuralRenovationReplacementEvent}
                  onKeyPress={handleStructuralRenovationReplacementEvent}
                  size='small'
                  yellow={1}
                  adornment={1}
                  normal={1}
                  // disabled={structuralRenovationCostIsDisabled}
                  InputProps={inputPropsDollar0dpEdit}
                  autoComplete='off'
                />
              </BuildersRiskTableCells>
              <td> </td>
            </tr>
            <tr>
              <BuildersRiskTableCells fieldName={'Project is data center?'}>
                <FormControl size='small'>
                  <CustomSelect id='DataCenter' name='DataCenter' variant='outlined' size='small' value={dataCenterValue} onChange={handleDataCenterOnChange} readOnly={!editPermission}>
                    <MenuItem key='True' value='True' label='Yes'>
                      Yes
                    </MenuItem>
                    <MenuItem key='False' value='False' label='No'>
                      No
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
              </BuildersRiskTableCells>
              <td> </td>
              <BuildersRiskTableCells fieldName={'Project is interiors only?'} colspanField={2} colspanLabel={2}>
                <FormControl size='small'>
                  <CustomSelect id='InteriorsOnly' name='InteriorsOnly' variant='outlined' size='small' value={interiorsOnlyValue} onChange={handleInteriorsOnlyOnChange} readOnly={!editPermission}>
                    <MenuItem key='True' value='True' label='Yes'>
                      Yes
                    </MenuItem>
                    <MenuItem key='False' value='False' label='No'>
                      No
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
              </BuildersRiskTableCells>
              <td> </td>
            </tr>

            <tr>
              <BuildersRiskTableCells fieldName={'Total Hard Cost'}>
                <CustomTextField
                  id='BuildersRiskProjectInsurableValueQuoted'
                  name='BuildersRiskProjectInsurableValueQuoted'
                  variant='outlined'
                  value={projectInformationGrid.BuildersRiskProjectInsurableValueQuoted}
                  size='small'
                  adornment={1}
                  normal={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </BuildersRiskTableCells>
              <td> </td>
              <BuildersRiskTableCells fieldName={'Value of Owner Furnished Equipment'} colspanField={2} colspanLabel={2}>
                <CustomTextField
                  id='BuildersRiskOFEValue'
                  name='BuildersRiskOFEValue'
                  variant='outlined'
                  defaultValue={projectInformationGrid.BuildersRiskOFEValue}
                  value={buildersRiskOFEValue}
                  onChange={setBuildersRiskOFEValueChange}
                  onBlur={handlePutBuildersRiskOFEEvent}
                  onKeyPress={handlePutBuildersRiskOFEEvent}
                  size='small'
                  yellow={1}
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dpEdit}
                  autoComplete='off'
                />
              </BuildersRiskTableCells>
              <td> </td>
            </tr>
            <tr>
              <BuildersRiskTableCells fieldName={'Soft Costs'}>
                <>
                  <CustomTextField
                    id='SoftCostsValue'
                    name='SoftCostsValue'
                    variant='outlined'
                    value={projectInformationGrid.SoftCosts}
                    red={projectInformationGrid.BuildersRiskSoftCostLimitReached === 1 ? 1 : 0}
                    size='small'
                    normal={1}
                    adornment={1}
                    error={projectInformationGrid.BuildersRiskSoftCostLimitReached === 1 ? true : false}
                    InputProps={inputPropsDollar0dp}
                    autoComplete='off'
                  />
                  <Button size='sm ml-3 mt-1' variant='primary' onClick={showSoftCostsWorksheet}>
                    Show Worksheet
                  </Button>
                </>
              </BuildersRiskTableCells>
              <td> </td>
              <BuildersRiskTableCells fieldName={'Project Months (Based on Named Season Days)'} colspanField={2} colspanLabel={2}>
                <CustomTextField
                  id='BuildersRiskProjectMonths'
                  name='BuildersRiskProjectMonths'
                  variant='outlined'
                  value={Math.round(projectInformationGrid.BuildersRiskProjectMonths * 100) / 100}
                  size='small'
                  // adornment={1}
                  normal={1}
                  // InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </BuildersRiskTableCells>
              {/* <td className='font-bold pl-[30px] align-bottom'> {Math.round(projectInformationGrid.BuildersRiskProjectMonths * 100) / 100} Months</td> */}
            </tr>
            <tr>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            {/* Floodzone, EQ Zone, NS Zone & Terrorism Zone */}
            <tr className='h-[48px] !border-t-[2px] border-solid !border-silver-500 bg-silver-100'>
              <td></td>
              <td></td>
              <td></td>
              <td className='font-bold pl-[22px] align-bottom'>Monthly Rate</td>
              <td className='font-bold align-bottom whitespace-nowrap'>Monthly Premium</td>
              <td className='font-bold pl-[30px] align-bottom'>Sublimit</td>
              <td className='font-bold pl-8 align-bottom'>Deductible</td>
            </tr>
            <tr className='border-t-[2px] border-solid border-silver-500'>
              {/* , backgroundColor: '#f8f8f8' */}
              <td>
                <FieldLabel field='AOP (inc. TRIA and Reno)' />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskTRIAZone'
                  name='BuildersRiskTRIAZone'
                  variant='outlined'
                  value={projectInformationGrid.BuildersRiskTRIAZone}
                  size='small'
                  normal={1}
                  alignLeft={1}
                  normalWidth={1}
                  InputProps={inputPropsTRIAZone}
                  autoComplete='off'
                />
              </td>
              <td></td>
              <td>
                <CustomTextField
                  id='BuildersRiskAOPRate'
                  name='BuildersRiskAOPRate'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskAOPRate}
                  onChange={null}
                  size='small'
                  normal={1}
                  pc={1}
                  adornment={1}
                  InputProps={inputPropsPct4dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskAOPPremiumQuoted'
                  name='BuildersRiskAOPPremiumQuoted'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskAOPPremiumQuoted}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskTotalCoverageLimit'
                  name='BuildersRiskTotalCoverageLimit'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskTotalCoverageLimit}
                  onChange={null}
                  size='small'
                  red={projectInformationGrid.BuildersRiskTotalCoverageLimitReached === 1 ? 1 : 0}
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                  error={projectInformationGrid.BuildersRiskTotalCoverageLimitReached === 1 ? true : false}
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskStandardDeductible'
                  name='BuildersRiskStandardDeductible'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskStandardDeductible}
                  size='small'
                  autoComplete='off'
                  normal={1}
                  normalWidth={1}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FieldLabel field='Earthquake' />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskEQZone'
                  name='BuildersRiskEQZone'
                  variant='outlined'
                  value={projectInformationGrid.BuildersRiskEQZone}
                  size='small'
                  normal={1}
                  alignLeft={1}
                  normalWidth={1}
                  InputProps={inputPropsEQZone}
                  autoComplete='off'
                />
              </td>
              <td></td>
              <td>
                <CustomTextField
                  id='BuildersRiskEQRate'
                  name='BuildersRiskEQRate'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskEQRate}
                  onChange={null}
                  size='small'
                  pc={1}
                  normalWidth={1}
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsPct4dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskEQPremium'
                  name='BuildersRiskEQPremium'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskEQPremium}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  normalWidth={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskEQSublimit'
                  name='BuildersRiskEQSublimit'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskEQSublimit}
                  onChange={null}
                  size='small'
                  red={projectInformationGrid.BuildersRiskEQSublimitReached === '1' ? 1 : 0}
                  normal={1}
                  adornment={1}
                  error={projectInformationGrid.BuildersRiskEQSublimitReached === '1' ? true : false}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskEQDeductible'
                  name='BuildersRiskEQDeductible'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskEQDeductible}
                  size='small'
                  alignLeft={0}
                  normal={1}
                  normalWidth={1}
                  autoComplete='off'
                  className='textfield'
                />
              </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                <FieldLabel field='Named Storm' />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskNSZone'
                  name='BuildersRiskNSZone'
                  variant='outlined'
                  value={projectInformationGrid.BuildersRiskNSZone}
                  size='small'
                  normal={1}
                  normalWidth={1}
                  alignLeft={1}
                  InputProps={inputPropsBuildersRiskNSZone}
                  autoComplete='off'
                />
              </td>
              <td></td>
              <td>
                <CustomTextField
                  id='BuildersRiskNSRate'
                  name='BuildersRiskNSRate'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskNSRate}
                  onChange={null}
                  size='small'
                  pc={1}
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsBuildersRiskNSRate}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskNSPremium'
                  name='BuildersRiskNSPremium'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskNSPremium}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsBuildersRiskNSPremium}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskNSSublimit'
                  name='BuildersRiskNSSublimit'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskNSSublimit}
                  onChange={null}
                  size='small'
                  red={projectInformationGrid.BuildersRiskNSSublimitReached === '1' ? 1 : 0}
                  normal={1}
                  adornment={1}
                  error={projectInformationGrid.BuildersRiskNSSublimitReached === '1' ? true : false}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskNSDeductible'
                  name='BuildersRiskNSDeductible'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskNSDeductible}
                  size='small'
                  alignLeft={0}
                  normalWidth={1}
                  normal={1}
                  autoComplete='off'
                  className='textfield'
                />
              </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                <FieldLabel field='Flood' />
              </td>
              <td>
                <FormControl size='small'>
                  <CustomSelect
                    id='BuildersRiskFloodZone'
                    name='BuildersRiskFloodZone'
                    variant='outlined'
                    size='small'
                    value={buildersRiskFloodZoneValue}
                    onChange={handleBuildersRiskFloodZoneOnChange}
                    readOnly={!editPermission}
                  >
                    <MenuItem key='Flood Level 1 (high)' value='Flood Level 1 (high)' label='Flood Level 1 (high)'>
                      Flood Level 1 (high)
                    </MenuItem>
                    <MenuItem key='Flood Level 2 (moderate)' value='Flood Level 2 (moderate)' label='Flood Level 2 (moderate)'>
                      Flood Level 2 (moderate)
                    </MenuItem>
                    <MenuItem key='Flood Level 3 (low)' value='Flood Level 3 (low)' label='Flood Level 3 (low)'>
                      Flood Level 3 (low)
                    </MenuItem>
                    <MenuItem key='Flood Level 4 (unknown)' value='Flood Level 4 (unknown)' label='Flood Level 4 (unknown)'>
                      Flood Level 4 (unknown)
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
              </td>
              <td></td>
              <td>
                <CustomTextField
                  id='BuildersRiskFloodRate'
                  name='BuildersRiskFloodRate'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskFloodRate}
                  onChange={null}
                  size='small'
                  pc={1}
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsPct4dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskFloodPremium'
                  name='BuildersRiskFloodPremium'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskFloodPremium}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskFloodSublimit'
                  name='BuildersRiskFloodSublimit'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskFloodSublimit}
                  onChange={null}
                  size='small'
                  red={projectInformationGrid.BuildersRiskFloodSublimitReached === '1' ? 1 : 0}
                  adornment={1}
                  normal={1}
                  error={projectInformationGrid.BuildersRiskFloodSublimitReached === '1' ? true : false}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskFloodDeductible'
                  name='BuildersRiskFloodDeductible'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskFloodDeductible}
                  size='small'
                  alignLeft={0}
                  normal={1}
                  normalWidth={1}
                  autoComplete='off'
                />
              </td>
              <td> </td>
            </tr>

            <tr>
              <td>
                <FieldLabel field='Water Damage Deductible' />
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td>
                <CustomTextField
                  id='BuildersRiskWaterDamageDeductible'
                  name='BuildersRiskWaterDamageDeductible'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskWaterDamageDeductible}
                  size='small'
                  autoComplete='off'
                  normal={1}
                  normalWidth={1}
                />
              </td>
            </tr>
            <tr className='!border-t-[2px] border-solid !border-silver-500 bg-silver-100'>
              <td>
                <FieldLabel field='Hard Cost Premium' />
              </td>
              <td> </td>
              <td> </td>
              <td>
                <CustomTextField
                  id='BuildersRiskBlendedRate'
                  name='BuildersRiskBlendedRate'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskBlendedRate}
                  onChange={null}
                  size='small'
                  pc={1}
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsPct4dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskBlendedPremium'
                  name='BuildersRiskBlendedPremium'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskBlendedPremium}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className='border-t-[2px] border-solid border-silver-500 bg-silver-100'>
              <td>
                <FieldLabel field='Soft Cost Premium' />
              </td>
              <td> </td>
              <td> </td>
              <td>
                <CustomTextField
                  id='BuildersRiskBlendedSoftCostsRate'
                  name='BuildersRiskBlendedSoftCostsRate'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskBlendedSoftCostsRate}
                  onChange={null}
                  size='small'
                  pc={1}
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsPct4dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='BuildersRiskBlendedSoftCostsPremium'
                  name='BuildersRiskBlendedSoftCostsPremium'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.BuildersRiskBlendedSoftCostsPremium}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className='border-t-[2px] border-solid border-silver-500 bg-silver-200'>
              <td>
                <FieldLabel field='Total Premium' />
              </td>
              <td> </td>
              <td> </td>
              <td>
                <CustomTextField
                  id='Calc_BuildersRiskBlendedTotalRate'
                  name='Calc_BuildersRiskBlendedTotalRate'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.Calc_BuildersRiskBlendedTotalRate}
                  onChange={null}
                  size='small'
                  pc={1}
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsPct4dp}
                  autoComplete='off'
                />
              </td>
              <td>
                <CustomTextField
                  id='Calc_BuildersRiskBlendedTotalPremium'
                  name='Calc_BuildersRiskBlendedTotalPremium'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.Calc_BuildersRiskBlendedTotalPremium}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className='!border-t-[2px] border-solid !border-silver-500 bg-silver-100'>
              <td>
                <FieldLabel field='Hard Costs Total' />
              </td>
              <td>
                <CustomTextField
                  id='Calc_BuildersInsuranceQuoted'
                  name='Calc_BuildersInsuranceQuoted'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.Calc_BuildersInsuranceQuoted}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td colSpan={6} />
            </tr>
            <tr className='border-t-[2px] border-solid border-silver-500 bg-silver-100'>
              <td>
                <FieldLabel field='Soft Costs Total' />
              </td>
              <td>
                <CustomTextField
                  id='Calc_BuildersInsuranceSoftCosts'
                  name='Calc_BuildersInsuranceSoftCosts'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.Calc_BuildersInsuranceSoftCosts}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td colSpan={6} />
            </tr>
            <tr className='!border-t-[2px] border-solid !border-silver-500 bg-silver-200'>
              <td>
                <FieldLabel field='Project Term Total Cost' />
              </td>
              <td>
                <CustomTextField
                  id='Calc_BuildersInsuranceProjectTermTotal'
                  name='Calc_BuildersInsuranceProjectTermTotal'
                  variant='outlined'
                  value={shouldHideValues ? '' : projectInformationGrid.Calc_BuildersInsuranceProjectTermTotal}
                  onChange={null}
                  size='small'
                  normal={1}
                  adornment={1}
                  InputProps={inputPropsDollar0dp}
                  autoComplete='off'
                />
              </td>
              <td colSpan={6} />
            </tr>
          </tbody>
        </Table>
      </div>
      <SoftCostsWorksheetModal />
    </div>
  )
}

export default BuildersRisk
