import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

const AppFormField = (props) => {
  const {
    placeholder,
    label,
    id,
    formAs,
    field: {name, onBlur, onChange, value},
    form: {errors, touched, setFieldTouched},
    showPctSuffix,
    ...inputProps
  } = props
  const hasError = errors[name] && touched[name]
  return (
    <Form.Group>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <InputGroup>
        <Form.Control
          id={id}
          as={formAs}
          name={name}
          placeholder={placeholder}
          onBlur={() => {
            setFieldTouched(name)
            onBlur(name)
          }}
          value={value}
          onChange={(value) => onChange(name)(value)}
          isInvalid={touched[name] && !!errors[name]}
          isValid={touched[name] && !errors[name]}
          {...inputProps}
        />
        {showPctSuffix && <InputGroup.Text>%</InputGroup.Text>}
      </InputGroup>
      {hasError && <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>}
    </Form.Group>
  )
}
export default AppFormField
