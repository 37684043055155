import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styled from '@emotion/styled'

const TradeSelector = ({options, value, onChange}) => {
  return (
    <Grid container justifyContent='space-between' className='tradescope-action mb-4'>
      <Grid item md={4}>
        <StyledAutoComplete
          id='bidtrade-selector'
          variant='outlined'
          options={options}
          getOptionLabel={(option) => (option.codeNo ? option.codeNo + ' - ' + option.title : '')}
          isOptionEqualToValue={(option, value) => option.bidTradeID === value.bidTradeID}
          value={value}
          onChange={onChange}
          disableClearable={true}
          className='flex flex-nowrap'
          style={{fullWidth: true}}
          renderInput={(params) => (
            <TextField
              {...params}
              type='text'
              variant='outlined'
              size='small'
              name='bidTrade-selection'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box component='li' sx={{'& > img': {mr: 2, flexShrink: 0}, fontFamily: "'Open Sans', sans-serif"}} {...props}>
              {option.codeNo} - {option.title}
            </Box>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default TradeSelector

const StyledAutoComplete = styled(Autocomplete)({
  '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: 'translate(34px, 20px) scale(1);'
  },
  '& .MuiAutocomplete-inputRoot': {
    fontFamily: "'Open Sans', sans-serif !important",
    color: 'purple',
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      // Default left padding is 6px
      paddingLeft: 6
    }
  },
  '.MuiAutocomplete-option': {
    fontFamily: "'Open Sans', sans-serif !important",
    backgroundColor: 'red'
  },
  '.MuiAutocomplete-endAdornment': {
    top: 6
  }
})
