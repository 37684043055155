import {useState, useEffect, useMemo, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Precon from '../../../store/ducks/precon.duck'
import * as Auth from '../../../store/ducks/auth.duck'
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro'
import Paper from '@mui/material/Paper'
import NotLoaded, {useIsLoaded} from '../../../components/IsLoaded'
import SubContractorModal, {SubContractorModalKey} from '../../../components/Modals/SubContractorModal'
import {useAppModal} from '../../../context/GlobalModalContext'
import TradeSelector from './TradeSelector'
import BidHeader from '../../../components/BidHeader'
import NewSubcontractorTab from './NewSubcontractorTab'

const initSubcontractor = () => {
  return {
    id: 0,
    subcontractorID: 0,
    companyName: '',
    applicationStatus: '',
    lastContacted: new Date(),
    contactedBy: '',
    primaryContact: '',
    primaryEmail: '',
    expirationDate: new Date(),
    internalSingleLimit: '',
    internalTotalLimit: '',
    lastQualStatusUpdate: '',
    yearFounded: 0,
    stateFounded: '',
    lastSubmitted: new Date(),
    marketsServed: '',
    regionsServed: '',
    tradeType: '',
    largestProject: '',
    emr: '',
    recordableRate: '',
    certificationType: '',
    updatedOn: '',
    tagValues: [],
    trades: [],
    regions: [],
    markets: []
  }
}

function Subcontractors({bidTrades, bidTrade, existingSubcontractors, getTradeItems, addSubcontractorToBid, addToBid, removeSubcontractorFromBid, removeFromBid}) {
  const dispatch = useDispatch()

  const filters = useSelector((state) => state.auth.Filters)
  const subcontractors = useSelector((state) => state.precon.Subcontractors)

  const {openModal} = useAppModal()

  const [statusFilter, setStatusFilter] = useState('All')

  const filteredSubcontractors = existingSubcontractors && statusFilter === 'Included' ? subcontractors.filter((row) => existingSubcontractors.includes(row.id)) : subcontractors

  useEffect(() => {
    // if(filters.subcontractorListTerm !== '')
    // {
    ;(filters.subcontractorListTerm.length > 3 || filters.subcontractorListTerm === '') && dispatch(Precon.actions.fetchSubcontractors({searchTerms: filters.subcontractorListTerm}))
    // }
  }, [dispatch, filters.subcontractorListTerm])

  const [subcontractor, setSubcontractor] = useState(() => initSubcontractor())

  const loadSubcontractor = useCallback(
    (thisSubcontractor) => {
      dispatch(Precon.actions.clearSubcontractor())
      setSubcontractor({...thisSubcontractor})
      // setTagValues(thisSubcontractor.tagValues);
      openModal(SubContractorModalKey)
    },
    [dispatch, openModal]
  )

  const handleToggleFilter = useCallback(() => {
    setStatusFilter((currFilter) => (currFilter === 'Included' ? 'All' : 'Included'))
  }, [])

  const handleClickNewSubcontractor = useCallback(() => {
    loadSubcontractor(initSubcontractor())
  }, [loadSubcontractor])

  const columns = useMemo(() => {
    const tempCols = [
      {field: 'id', headerName: 'id', hide: true},
      {
        field: 'subcontractorID',
        type: 'number',
        headerName: 'subcontractorID',
        width: 160,
        hide: true
      },
      {
        field: 'companyName',
        type: 'string',
        headerName: 'Company Name',
        width: 250,
        renderCell: (params) => (
          <strong>
            <div onClick={() => loadSubcontractor(subcontractors.find((row) => row.id === params.row.id))} className='cell-link'>
              {params.row.companyName}
            </div>
          </strong>
        )
      },
      {field: 'primaryContact', type: 'string', headerName: 'Primary Contact', width: 180},
      {field: 'internalSingleLimit', type: 'number', headerName: 'Internal Single Limit', width: 155},
      {field: 'internalTotalLimit', type: 'number', headerName: 'Internal Total Limit', width: 150},
      {field: 'currentEstimatedBacklog', type: 'number', headerName: 'Estimated Backlog', width: 150}
    ]

    // Add a column for actions related to BidTrade (e.g. adding/removing contractors from a bid trade)
    if (addSubcontractorToBid) {
      tempCols.push({
        field: 'lastSubmitted',
        type: 'number',
        headerName: 'Action',
        width: 115,
        renderCell: (params) => (
          <strong>
            {existingSubcontractors && existingSubcontractors.includes(params.row.id) ? (
              <div onClick={() => removeFromBid(params.row.id)} className='cell-link'>
                Remove from Bid
              </div>
            ) : (
              <div onClick={() => addToBid(params.row.id)} className='cell-link'>
                Add to Bid
              </div>
            )}
          </strong>
        )
      })
    }

    return tempCols
  }, [addSubcontractorToBid, addToBid, existingSubcontractors, loadSubcontractor, removeFromBid, subcontractors])

  const preconDeps = !!bidTrades && !!bidTrade ? ['FETCH_SUBCONTRACTORS', 'FETCH_BIDTRADE', 'FETCH_BIDQUOTE'] : ['FETCH_SUBCONTRACTORS']
  const isLoaded = useIsLoaded({type: 'none', preconDeps})

  const formClassName = useMemo(
    () => ({
      text: `max-w-[170px] h-[34px] ${filters.csicodesTerm.length > 0 ? 'bg-yellow-100' : 'bg-white-primary'}`,
      clearIcon: `-ml-[3px] ${filters.csicodesTerm.length > 0 ? 'text-black-medium' : 'text-silver-primary'}`
    }),
    [filters]
  )

  // Modals
  const renderSubContractorModal = useCallback(() => {
    return (
      <SubContractorModal
        subcontractorId={subcontractor.id}
        subcontractorCompanyName={subcontractor.companyName}
        existingSubcontractors={existingSubcontractors}
        addSubcontractorToBid={addSubcontractorToBid}
        removeSubcontractorFromBid={removeSubcontractorFromBid}
      />
    )
  }, [addSubcontractorToBid, existingSubcontractors, removeSubcontractorFromBid, subcontractor.companyName, subcontractor.id])

  return (
    <div className='flex flex-col flex-fill'>
      <BidHeader />
      {!!bidTrades && !!bidTrade && <TradeSelector options={bidTrades} value={bidTrade} onChange={(_event, newValue) => getTradeItems(newValue.bidTradeID)} />}
      <NewSubcontractorTab
        initialKeyword={filters.subcontractorListTerm}
        formClassName={formClassName}
        showToggleFilter={!!existingSubcontractors}
        onToggleFilter={handleToggleFilter}
        statusFilter={statusFilter}
        onClickNewSubcontractor={handleClickNewSubcontractor}
      />

      <Paper className='flex flex-fill overflow-auto h-0 mt-4'>
        {!isLoaded || !isLoaded.result ? (
          <div className='pt-5 pl-5'>
            <NotLoaded payload={isLoaded} noHeader={true} />
          </div>
        ) : (
          <DataGridPro
            rows={filteredSubcontractors}
            columns={columns}
            checkboxSelection={false}
            disableColumnMenu={true}
            rowHeight={38}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                  subcontractorID: false
                }
              }
            }}
          />
        )}
      </Paper>

      {renderSubContractorModal()}
    </div>
  )
}

export const SubcontractorsWithoutTradeScope = () => {
  return (
    <Subcontractors
      bidTrades={undefined}
      bidTrade={undefined}
      existingSubcontractors={undefined}
      getTradeItems={undefined}
      addSubcontractorToBid={undefined}
      addToBid={undefined}
      removeSubcontractorFromBid={undefined}
      removeFromBid={undefined}
    />
  )
}

export const SubcontractorsWithTradeScope = () => {
  const dispatch = useDispatch()
  const {closeModal} = useAppModal()

  const projectID = useSelector((state) => state.project.projectID)
  const filterBidTradeID = useSelector((state) => state.auth.Filters.bidTradeID)
  const bidTrade = useSelector((state) => state.precon.BidTrade)
  const bidTrades = useSelector((state) => state.precon.BidTrades)
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)

  const existingSubcontractors = useMemo(() => bidTrade?.quotes?.map((row) => row.subcontractorID), [bidTrade?.quotes])

  const getTradeItems = useCallback(
    (bidTradeID) => {
      console.log('New Trade: ' + bidTradeID)
      if (bidTradeID !== 0) {
        dispatch(Precon.actions.setBidTradeID(bidTradeID))
        dispatch(Precon.actions.fetchBidTrade(bidTradeID))
        dispatch(Auth.actions.setFilters({filterName: 'bidTradeID', filterValue: bidTradeID}))
      }
    },
    [dispatch]
  )

  const addSubcontractorToBid = useCallback(
    (id) => {
      // if subcontractor is not already added to this trade
      if (!bidTrade.quotes.some((row) => row.subcontractorID === id)) {
        var payload = {
          subcontractorID: id,
          bidQuoteID: bidQuoteID,
          bidTradeID: bidTrade.bidTradeID
        }
        dispatch(Precon.actions.putNewBidTradeQuote(payload))
      }
    },
    [bidQuoteID, bidTrade.bidTradeID, bidTrade.quotes, dispatch]
  )

  const removeSubcontractorFromBid = useCallback(
    (id) => {
      // if subcontractor exists in this trade
      const bidTradeQuoteID = bidTrade.quotes.find((row) => row.subcontractorID === id)?.bidTradeQuoteID
      if (bidTrade.quotes.some((row) => row.subcontractorID === id)) {
        var payload = {
          subcontractorID: id,
          bidQuoteID: bidQuoteID,
          bidTradeID: bidTrade.bidTradeID,
          bidTradeQuoteID
        }
        dispatch(Precon.actions.putDeleteBidTradeQuote(payload))
      }
    },
    [bidQuoteID, bidTrade.bidTradeID, bidTrade.quotes, dispatch]
  )

  const addToBid = useCallback(
    (id) => {
      closeModal()
      addSubcontractorToBid(id)
    },
    [addSubcontractorToBid, closeModal]
  )

  const removeFromBid = useCallback(
    (id) => {
      closeModal()
      removeSubcontractorFromBid(id)
    },
    [closeModal, removeSubcontractorFromBid]
  )

  useEffect(() => {
    dispatch(
      Precon.actions.fetchBidTrades({
        bidQuoteID: bidQuoteID ? bidQuoteID : 0,
        projectID: projectID ? projectID : 0
      })
    )
  }, [dispatch, bidQuoteID, projectID])

  useEffect(() => {
    dispatch(Precon.actions.fetchBidTrade({bidTradeID: filterBidTradeID}))
  }, [dispatch, filterBidTradeID])

  return (
    <Subcontractors
      bidTrades={bidTrades}
      bidTrade={bidTrade}
      existingSubcontractors={existingSubcontractors}
      getTradeItems={getTradeItems}
      addSubcontractorToBid={addSubcontractorToBid}
      addToBid={addToBid}
      removeSubcontractorFromBid={removeSubcontractorFromBid}
      removeFromBid={removeFromBid}
    />
  )
}
