import {Suspense} from 'react'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AppRoutes} from '../app/routing/AppRoutes'
import {ThemeProvider, StyledEngineProvider, createTheme} from '@mui/material/styles'

import {Flip, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalModalProvider from './context/GlobalModalContext'

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins'].join(',')
  },
  palette: {
    contrastThreshold: 2,
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#5d78ff'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: '#0abb87',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#ffffff'
    },
    error: {
      // light: will be calculated from palette.primary.main,
      main: '#fd397a'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  },
  /**
   * @see https://material-ui.com/customization/globals/#default-props
   */
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      }
    },
    // Set default elevation to 1 for popovers.
    MuiPopover: {
      defaultProps: {
        elevation: 1
      }
    }
  }
})

const App = () => {
  console.log('Rendering: App.tsx *******************************************')

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LayoutProvider>
              <GlobalModalProvider>
                <AppRoutes />
                <MasterInit />
              </GlobalModalProvider>
            </LayoutProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Suspense>
      <ToastContainer transition={Flip} position='bottom-right' autoClose={2000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  )
}

export {App}
