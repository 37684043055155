import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FieldLabel from '../FieldLabel'
import {useCallback, useMemo} from 'react'

const SetupSelectField = (props) => {
  const {
    label,
    id,
    field: {name, onBlur, value},
    form: {errors, setFieldTouched, setFieldValue, values},
    options,
    getOptions,
    className,
    getClassname,
    disabled,
    getDisabled,
    onChangeCallback,
    ...inputProps
  } = props

  const errorClassname = errors[name] ? 'setup-error-field-cell' : ''

  const handleChange = useCallback(
    (event) => {
      setFieldValue(name, event.target.value)
      if (onChangeCallback) {
        onChangeCallback(name, event.target.value)
      }
    },
    [name, onChangeCallback, setFieldValue]
  )

  const disableField = useMemo(() => {
    let result = disabled
    if (getDisabled) {
      result = getDisabled(name, value, values)
    }
    return result
  }, [disabled, getDisabled, name, value, values])

  const inputClassname = useMemo(() => {
    let result = className || ''
    if (getClassname) {
      result += ' ' + getClassname(name, value, values)
    }
    return result
  }, [className, getClassname, name, value, values])

  const optionsArray = useMemo(() => {
    let final = options
    if (getOptions) {
      final = getOptions(name, value, values)
    }
    return final
  }, [getOptions, name, options, value, values])

  return (
    <>
      <td className={errorClassname}>
        <FieldLabel field={label} />
      </td>
      <td className={errorClassname}>
        <FormControl size='small' disabled={disableField}>
          <Select
            id={id}
            name={name}
            onChange={handleChange}
            onBlur={() => {
              setFieldTouched(name)
              onBlur(name)
            }}
            value={value}
            disabled={disableField}
            error={errors[name]}
            className={inputClassname}
            {...inputProps}
          >
            {optionsArray.map(({label, value}) => {
              return (
                <MenuItem key={value} value={value} label={label}>
                  {label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </td>
    </>
  )
}

export default SetupSelectField
