import FieldLabel from '../FieldLabel'

const BuildersRiskTableCells = ({fieldName, children, colspanLabel = 1, colspanField = 1}) => {
  return (
    <>
      <td colSpan={colspanLabel}>
        <FieldLabel field={fieldName} />
      </td>
      <td colSpan={colspanField}>{children}</td>
    </>
  )
}

export default BuildersRiskTableCells
