/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useState, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {useIsAuthenticated, useMsal} from '@azure/msal-react'
import {AuthPage} from '../modules/auth/AuthPage'

const AppRoutes: FC = () => {
  const isAuthenticated = useIsAuthenticated()
  const {inProgress} = useMsal()

  const [authStateLoaded, setAuthStateLoaded] = useState(false)
  console.log(`AppRoutes.tsx - isAuthenticated: ${isAuthenticated.toString()}`)

  useEffect(() => {
    if (!authStateLoaded && inProgress === 'none') {
      setAuthStateLoaded(true)
    }
  }, [authStateLoaded, inProgress])

  if (!authStateLoaded) {
    return null
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route element={<App />}> */}
        <Route path='error/*' element={<ErrorsPage />} />
        {/* <Route path='logout' element={<Logout />} /> */}
        {isAuthenticated ? (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to='/dashboard' />} />
          </>
        ) : (
          <>
            <Route path='auth/*' element={<AuthPage />} />
            <Route path='*' element={<Navigate to='/auth' />} />
          </>
        )}
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
