import {useState, useEffect, useMemo, useCallback} from 'react'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import * as Auth from '../store/ducks/auth.duck'
import * as Project from '../store/ducks/project.duck'
import * as Precon from '../store/ducks/precon.duck'
import currencyFormat from '../components/currencyFormat'
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {styled} from '@mui/system'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import NotLoaded, {useIsLoaded} from '../components/IsLoaded'

const DataGrid = styled(DataGridPro)(() => ({
  '& .MuiDataGrid-columnHeaders': {display: 'none'},
  '& .MuiDataGrid-virtualScroller': {marginTop: '0!important'}
}))

export default function BidsInProgress() {
  const filters = useSelector((state) => state.auth.Filters)
  const projects = useSelector((state) => state.project.ProjectsGrid.values)
  const assumedUserID = useSelector((state) => state.auth.assumedUserID)
  const recentProjects = useSelector((state) => state.auth.recentProjects)

  const [keyword, setKeyword] = useState(filters.projectsGridTerm)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      Project.actions.fetchProjectsGrid({
        status: filters.projectsGridStatus,
        assumedUserID: assumedUserID
      })
    )
  }, [dispatch, filters.projectsGridStatus, assumedUserID])

  const changeProject = useCallback(
    (row) => {
      console.log(row)
      const payload = {
        projectID: row.ProjectID,
        bidID: row.BidID,
        bidQuoteID: row.BidQuoteID,
        bidTradeID: row.FirstBidTradeID,
        editPermission: row.PermissionType === 'Edit' ? true : false
      }

      let newBidTradeId = payload.bidTradeID ? payload.bidTradeID : 0

      // clear project
      dispatch(Project.actions.clearProject())

      // setup project
      dispatch(Project.actions.setProjectID(payload))
      dispatch(Project.actions.fetchProjectHeader({projectID: row.ProjectID}))
      dispatch(Precon.actions.setBidID(payload.bidID))

      // setup BidQuote
      dispatch(Precon.actions.setBidQuoteID(payload.bidQuoteID))
      dispatch(Precon.actions.setBidTradeID(newBidTradeId))
      dispatch(Auth.actions.setFilters({filterName: 'bidTradeID', filterValue: newBidTradeId}))
      dispatch(Precon.actions.fetchBidQuote({bidQuoteID: payload.bidQuoteID}))
      dispatch(Precon.actions.fetchBidTrades({bidQuoteID: payload.bidQuoteID}))
      dispatch(Precon.actions.fetchBidTrade({bidTradeID: payload.bidTradeID}))
      dispatch(Precon.actions.fetchBidTradeGrid({bidTradeID: newBidTradeId}))

      // Add project to recent projects list, if not already there
      if (!recentProjects.some((recent) => recent.projectID === row.ProjectID)) {
        dispatch(Auth.actions.putRecentProjects({projectId: row.ProjectID}))
      }
    },
    [dispatch, recentProjects]
  )

  const changeTerm = useCallback(
    (term) => {
      setKeyword(term)
      dispatch(Auth.actions.setFilters({filterName: 'projectsGridTerm', filterValue: term}))
    },
    [dispatch]
  )

  const filteredProjects = useMemo(() => {
    if (!keyword || keyword.length === 0 || (keyword && keyword.match(/^[a-zA-Z0-9_*&@ ]*$/) !== null)) {
      return projects.filter(
        (project) =>
          project.SearchTerms &&
          (keyword === '*' ||
            keyword.length === 0 ||
            (keyword.match(/^[a-zA-Z0-9_&@ ]*$/) !== null && keyword.split(' ').every((word) => project.SearchTerms.toLowerCase().search(word.toLowerCase()) !== -1)))
      )
    }
    return []
  }, [keyword, projects])

  const columns = useMemo(
    () => [
      {field: 'id', headerName: 'id', customHeadRender: () => null},
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        customHeadRender: () => null,
        renderCell: (params) => (
          <Grid container className={`projects-list-grid-container`}>
            <Grid item md={8} className='projects-list-grid-item-name'>
              <Link to='/setup' onClick={() => changeProject(params.row)}>
                <span className='text-info-active font-semibold text-[14px] text-blue-600'>{params.row.ProjectName}</span>
              </Link>
            </Grid>
            <Grid item md={4} className='projects-list-grid-item-state-bu'>
              <span>
                {params.row.CRM_State} - {params.row.CRM_PrimaryBusinessUnit}
              </span>
            </Grid>
            <Grid item md={8} className='projects-list-grid-item-dates'>
              <Row>
                <Col md={6}>
                  <span>Construction Start:</span> <span className='projects-list-grid-item-date-value-blue'>{params.row.EstConstructionStartDate}</span>
                </Col>
                <Col md={6}>
                  <span>End:</span> <span className='projects-list-grid-item-date-value-blue'>{params.row.EstConstructionEndDate}</span>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <span>Value:</span> <span className='projects-list-grid-item-date-value-blue'>${currencyFormat(params.row.CRM_AnticipatedProjectCosts, 0)}</span>
                </Col>
                <Col md={6}>
                  <span>RFP Due:</span> <span className='projects-list-grid-item-date-value-red'>{params.row.RFPDueDate}</span>
                </Col>
              </Row>
            </Grid>
            <Grid item md={2} className='projects-list-grid-item-costs'>
              <span className='projects-list-grid-item-cost-values'>
                {params?.row?.Manhours && !isNaN(params.row.Manhours) && `${parseFloat((params?.row?.Months || 0).toFixed(1)).toString()}/${params.row.Manhours}`}
              </span>
              <br />
              <span className='kt-widget5__sales'>Months/Hours</span>
            </Grid>
            <Grid item md={2} className='projects-list-grid-item-costs'>
              <span className='projects-list-grid-item-cost-values'>${currencyFormat(params.row.TotalTargetProfit, 0)}</span>
              <br />
              <span className='kt-widget5__votes'>Target Profit</span>
            </Grid>
          </Grid>
        )
      }
    ],
    [changeProject]
  )

  const dataGridIsLoadedInitialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          id: false
        }
      }
    }),
    []
  )

  const resetTerm = useCallback(() => {
    changeTerm('')
  }, [changeTerm])

  const isLoaded = useIsLoaded({
    type: 'none',
    projectDeps: ['FETCH_ProjectsGrid'],
    ignoreSetup: true
  })

  useEffect(() => {
    function handleResize() {
      const topPanel = document.querySelector('.dashboard-top')
      const totalHeight = document.documentElement.clientHeight
      const topPanelHeight = topPanel.offsetHeight
      const realHeight = totalHeight - topPanelHeight - 70 - 25 - 70
      const listPanel = document.querySelector('.projects-list')
      listPanel.style.height = `${realHeight}px`
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <div className='kt-portlet__head-label w-full'>
            <Form className='w-full' autoComplete='OFF'>
              {/* onSubmit={searchList}  */}
              <Row>
                <Col md='4'>
                  <h3 className='kt-portlet__head-title mt-2'>
                    <span className='card-label fw-bold fs-3 mb-1 text-gray-600'>Projects</span>
                  </h3>
                </Col>
                <Col md='4' className='text-right pt-1'>
                  <FormControl variant='standard' size='small'>
                    <Select
                      labelId='Date filter'
                      id='date-filter'
                      value={filters.projectsGridStatus}
                      onChange={(event) =>
                        dispatch(
                          Auth.actions.setFilters({
                            filterName: 'projectsGridStatus',
                            filterValue: event.target.value
                          })
                        )
                      }
                    >
                      <MenuItem value='0'>Open/Won</MenuItem>
                      <MenuItem value='All'>All</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col md='4' className='text-right pt-1'>
                  <FormControl variant='standard' size='small'>
                    <Input
                      name='randomized12312'
                      id='input-with-icon-adornment'
                      className='w-full'
                      value={keyword}
                      onChange={(event) => changeTerm(event.target.value)}
                      // onKeyPress={(event) => submitSearch(event)}
                      autoComplete='new-password'
                      startAdornment={
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position='end'>
                          <CloseIcon className='cursor-pointer' onClick={resetTerm} />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className='kt-portlet__body projects-list p-0 relative'>
          {!isLoaded || !isLoaded.result ? (
            <div className='pt-5 pl-5'>
              <NotLoaded payload={isLoaded} noHeader={true} />
            </div>
          ) : (
            <DataGrid
              rows={filteredProjects}
              columns={columns}
              checkboxSelection={false}
              rowHeight={80}
              headerHeight={0}
              initialState={dataGridIsLoadedInitialState}
              getRowClassName={(params) => `projects-list-grid-row${params.row.OrderPriorityLevel2 > 0 ? '-recent-project' : ''}`}
            />
          )}
        </div>
      </div>
    </>
  )
}
