import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Project from '../../store/ducks/project.duck'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Form from 'react-bootstrap/Form'
import {CustomCheckbox, CustomFormControl} from './styles'

export default function ProjectConfigurationOptions({propertyName, heading}) {
  const dispatch = useDispatch()
  const projectID = useSelector((state) => state.project.projectID)
  const projectOptions = useSelector((state) => state.project.ProjectConfiguration.values[propertyName])
  const defaultOptions = useSelector((state) => state.reference.ProjectConfigurationOptions[propertyName])

  const [optionsSelected, setOptionsSelected] = useState([])

  useEffect(() => {
    dispatch(Project.actions.fetchProjectConfiguration({projectID: projectID}))
  }, [dispatch, projectID])

  useEffect(() => {
    // parse json array from project property, and clean up values from string to bool
    let temp = projectOptions
      ? JSON.parse(projectOptions).map((opt) => {
          return {...opt, value: opt.value === true || opt.value === 'true'}
        })
      : []
    // set local state with project values merged into default array
    setOptionsSelected(defaultOptions.map((row) => (temp.find((pobj) => pobj.name === row.name) ? {...row, value: temp.find((pobj) => pobj.name === row.name).value} : row)))
  }, [projectOptions, defaultOptions])

  function changeOptions(option, checked) {
    setOptionsSelected(optionsSelected.map((obj) => (obj.name === option.name ? {...option, value: checked} : obj)))
    dispatch(
      Project.actions.putProjectConfigurationOption({
        projectID: projectID,
        propertyName: propertyName,
        optionName: option.name,
        newValue: checked
      })
    )
  }

  // show options
  return (
    <Form.Group>
      <Form.Label>{heading}</Form.Label>
      <br />
      <CustomFormControl component='fieldset'>
        <FormGroup className='mb-0'>
          {optionsSelected.map((option, index) => {
            return (
              <FormControlLabel
                key={`key_cl_${index}`}
                control={<CustomCheckbox key={`key_${index}`} name='tagContexts' checked={option.value} onChange={(event) => changeOptions(option, event.target.checked)} value={true} />}
                label={option.label}
              />
            )
          })}
        </FormGroup>
      </CustomFormControl>
    </Form.Group>
  )
}
