const actionTypes = {
  SET_PRECON_TEMP: 'SET_PRECON_TEMP',
  SET_PRECONCACHE: 'SET_PRECONCACHE',
  SET_PRECON_UPDATES: 'SET_PRECON_UPDATES',
  SET_PRECON_UPDATES_ACTUAL: 'SET_PRECON_UPDATES_ACTUAL',
  SET_BIDID: 'SET_BIDID',
  SET_BIDQUOTEID: 'SET_BIDQUOTEID',
  CLEAR_BIDQUOTE: 'CLEAR_BIDQUOTE',
  SET_BIDQUOTE: 'SET_BIDQUOTE',
  SET_BIDTRADE: 'SET_BIDTRADE',
  SET_BIDTRADEID: 'SET_BIDTRADEID',
  SET_ACTION_PARAMS: 'SET_ACTION_PARAMS',
  FETCH_BIDQUOTE: 'FETCH_BIDQUOTE',
  FETCH_PRECON_REFERENCES: 'FETCH_PRECON_REFERENCES',
  SET_PRECON_COLLECTION: 'SET_PRECON_COLLECTION',
  PUT_BIDQUOTE_BCPROJECTID: 'PUT_BIDQUOTE_BCPROJECTID',
  PUT_BIDQUOTE_UNLINK_BCPROJECTID: 'PUT_BIDQUOTE_UNLINK_BCPROJECTID',

  FETCH_BIDQUOTEAREAS: 'FETCH_BIDQUOTEAREAS',
  PUT_BIDQUOTEAREA: 'PUT_BIDQUOTEAREA',
  DELETE_BIDQUOTEAREA: 'DELETE_BIDQUOTEAREA',

  FETCH_TAGVALUES: 'FETCH_TAGVALUES',
  CLEAR_SUBCONTRACTOR: 'CLEAR_SUBCONTRACTOR',
  FETCH_SUBCONTRACTORS: 'FETCH_SUBCONTRACTORS',
  FETCH_SUBCONTRACTOR: 'FETCH_SUBCONTRACTOR',
  FETCH_SUBCONTRACTOR_CONTACTS: 'FETCH_SUBCONTRACTOR_CONTACTS',
  FETCH_SUBCONTRACTOR_OFFICES: 'FETCH_SUBCONTRACTOR_OFFICES',
  FETCH_SUBCONTRACTOR_BIDHISTORY: 'FETCH_SUBCONTRACTOR_BIDHISTORY',
  FETCH_BIDCLARIFICATIONS: 'FETCH_BIDCLARIFICATIONS',
  SET_BIDCLARIFICATIONS: 'SET_BIDCLARIFICATIONS',
  COPY_CLARIFICATIONSTEMPLATE_TO_BID: 'COPY_CLARIFICATIONSTEMPLATE_TO_BID',

  FETCH_BIDALTERNATESGRID: 'FETCH_BIDALTERNATESGRID',
  FETCH_TRADEALTERNATESGRID: 'FETCH_TRADEALTERNATESGRID',
  FETCH_ALTERNATE: 'FETCH_ALTERNATE',
  PUT_ALTERNATE_TRADE_ITEM: 'PUT_ALTERNATE_TRADE_ITEM',
  PUT_ALTERNATE_INDIRECT: 'PUT_ALTERNATE_INDIRECT',
  SET_ALTERNATE_ITEMS: 'SET_ALTERNATE_ITEMS',

  FETCH_BIDUNITPRICESGRID: 'FETCH_BIDUNITPRICESGRID',
  FETCH_TRADEUNITPRICESGRID: 'FETCH_TRADEUNITPRICESGRID',
  FETCH_UNITPRICE: 'FETCH_UNITPRICE',
  PUT_UNITPRICE: 'PUT_UNITPRICE',
  DELETE_UNITPRICE: 'DELETE_UNITPRICE',
  PUT_UNITPRICE_TRADEITEM: 'PUT_UNITPRICE_TRADEITEM',
  PUT_UNITPRICE_INDIRECT: 'PUT_UNITPRICE_INDIRECT',
  PUT_UNITPRICE_SELECTTRADEPRICE: 'PUT_UNITPRICE_SELECTTRADEPRICE',

  FETCH_BIDALLOWANCESGRID: 'FETCH_BIDALLOWANCESGRID',
  FETCH_TRADEALLOWANCESGRID: 'FETCH_TRADEALLOWANCESGRID',
  FETCH_ALLOWANCE: 'FETCH_ALLOWANCE',
  PUT_ALLOWANCE: 'PUT_ALLOWANCE',
  DELETE_ALLOWANCE: 'DELETE_ALLOWANCE',
  PUT_ALLOWANCE_TRADE_ITEM: 'PUT_ALLOWANCE_TRADE_ITEM',
  SET_ALLOWANCE_ITEMS: 'SET_ALLOWANCE_ITEMS',

  FETCH_BCPROJECTS: 'FETCH_BCPROJECTS',
  FETCH_BIDTRADE: 'FETCH_BIDTRADE',
  FETCH_BIDTRADE_TEMP: 'FETCH_BIDTRADE_TEMP',
  FETCH_BIDTRADES: 'FETCH_BIDTRADES',
  FETCH_BIDTRADES_TEMP: 'FETCH_BIDTRADES_TEMP',
  FETCH_BIDTRADES_DELETED: 'FETCH_BIDTRADES_DELETED',
  RESTORE_BIDTRADE: 'RESTORE_BIDTRADE',
  FETCH_BIDTRADESUMMARYGRID: 'FETCH_BIDTRADESUMMARYGRID',
  FETCH_BIDTRADEGRID: 'FETCH_BIDTRADEGRID',
  FETCH_BIDTRADEGRID_TEMP: 'FETCH_BIDTRADEGRID_TEMP',
  FETCH_BIDTRADE_BUDGETGRID: 'FETCH_BIDTRADE_BUDGETGRID',
  FETCH_BIDTRADE_BUDGETGRID_TEMP: 'FETCH_BIDTRADE_BUDGETGRID_TEMP',
  FETCH_BIDSHEETGRID: 'FETCH_BIDSHEETGRID',
  FETCH_BIDSHEETGRID_TEMP: 'FETCH_BIDSHEETGRID_TEMP',
  FETCH_BIDSUMMARY_GRIDTOTALS: 'FETCH_BIDSUMMARY_GRIDTOTALS',
  FETCH_BIDSUMMARY_GRIDTOTALS_TEMP: 'FETCH_BIDSUMMARY_GRIDTOTALS_TEMP',
  FETCH_BIDTRADEQUOTE: 'FETCH_BIDTRADEQUOTE',
  SET_BIDSUMMARY_GRIDTOTALS: 'SET_BIDSUMMARY_GRIDTOTALS',
  SET_BIDSUMMARY_GRIDTOTALS_TEMP: 'SET_BIDSUMMARY_GRIDTOTALS_TEMP',
  CLEAR_BIDSHEETGRID_TEMP: 'CLEAR_BIDSHEETGRID_TEMP',
  CLEAR_BIDSUMMARY_GRIDTOTALS_TEMP: 'CLEAR_BIDSUMMARY_GRIDTOTALS_TEMP',

  PUT_SUBCONTRACTOR: 'PUT_SUBCONTRACTOR',
  PUT_SUBCONTRACTOR_CONTACTS: 'PUT_SUBCONTRACTOR_CONTACTS',
  PUT_SUBCONTRACTOR_OFFICES: 'PUT_SUBCONTRACTOR_OFFICES',
  PUT_BIDTRADEQUOTE: 'PUT_BIDTRADEQUOTE',

  PUT_COPYBIDQUOTE: 'PUT_COPYBIDQUOTE',
  PUT_CREATEBIDQUOTE: 'PUT_CREATEBIDQUOTE',
  PUT_BIDQUOTE: 'PUT_BIDQUOTE',
  PUT_BIDTRADEQUOTE_BASEBID_RESET: 'PUT_BIDTRADEQUOTE_BASEBID_RESET',
  PUT_TAGVALUES: 'PUT_TAGVALUES',
  PUT_BIDTRADE: 'PUT_BIDTRADE',
  PUT_BIDTRADES: 'PUT_BIDTRADES',
  DELETE_BIDTRADE: 'DELETE_BIDTRADE',

  PUT_BIDTRADE_GENERALREQUIREMENTS_PLACEHOLDER: 'PUT_BIDTRADE_GENERALREQUIREMENTS_PLACEHOLDER',
  PUT_BIDTRADESUMMARYGRIDROW: 'PUT_BIDTRADESUMMARYGRIDROW',
  PUT_BIDSHEETGRIDROW: 'PUT_BIDSHEETGRIDROW',
  PUT_BIDSHEETGRID_PREVBUDGET_INDIRECTROW: 'PUT_BIDSHEETGRID_PREVBUDGET_INDIRECTROW',
  PUT_SELECTEDBIDTRADEQUOTE: 'PUT_SELECTEDBIDTRADEQUOTE',
  PUT_BIDTRADEQUOTE_POSTED: 'PUT_BIDTRADEQUOTE_POSTED',
  PUT_NEWBIDTRADEQUOTE: 'PUT_NEWBIDTRADEQUOTE',
  PUT_DELETEBIDTRADEQUOTE: 'PUT_DELETEBIDTRADEQUOTE',
  PUT_BIDTRADEGRIDROW: 'PUT_BIDTRADEGRIDROW',
  PUT_BIDTRADEBUDGET_GRIDROW: 'PUT_BIDTRADEBUDGET_GRIDROW',
  SET_BIDTRADEGRID: 'SET_BIDTRADEGRID',
  SET_BIDTRADEGRIDBUDGET: 'SET_BIDTRADEGRIDBUDGET',
  SET_BIDTRADEGRIDBUDGET_TEMP: 'SET_BIDTRADEGRIDBUDGET_TEMP',
  SET_BIDTRADEGRID_ADDROW: 'SET_BIDTRADEGRID_ADDROW',
  PUT_DELETEBIDTRADEGRIDROW: 'PUT_DELETEBIDTRADEGRIDROW',
  PUT_DELETEBIDTRADE_BUDGET_GRIDROW: 'PUT_DELETEBIDTRADE_BUDGET_GRIDROW',
  PUT_BIDCLARIFICATION: 'PUT_BIDCLARIFICATION',
  DELETE_BIDCLARIFICATION: 'DELETE_BIDCLARIFICATION',
  PUT_ALTERNATE: 'PUT_ALTERNATE',
  DELETE_ALTERNATE: 'DELETE_ALTERNATE',
  REORDER_BIDTRADEQUOTES: 'REORDER_BIDTRADEQUOTES',
  REORDER_BIDTRADEITEMS: 'REORDER_BIDTRADEITEMS',
  REORDER_BIDTRADEITEM: 'REORDER_BIDTRADEITEM',
  REORDER_BIDTRADE_BUDGETITEM: 'REORDER_BIDTRADE_BUDGETITEM',
  COPY_BIDTRADETEMPLATE_TOBID: 'COPY_BIDTRADETEMPLATE_TOBID',

  FETCH_PROPOSALS: 'FETCH_PROPOSALS',
  FETCH_PROPOSAL: 'FETCH_PROPOSAL',
  PUT_PROPOSAL: 'PUT_PROPOSAL',
  DELETE_PROPOSAL: 'DELETE_PROPOSAL',
  FETCH_SNAPSHOTS: 'FETCH_SNAPSHOTS',
  FETCH_SNAPSHOT: 'FETCH_SNAPSHOT',
  PUT_SNAPSHOT: 'PUT_SNAPSHOT',
  DELETE_SNAPSHOT: 'DELETE_SNAPSHOT',
  UPDATE_SNAPSHOT: 'UPDATE_SNAPSHOT',
  PUT_BIDTRADENOTE: 'PUT_BIDTRADENOTE',
  PUT_BIDTRADEITEM_NOTE: 'PUT_BIDTRADEITEM_NOTE',

  COPY_BidQuoteChecklist_TOBID: 'COPY_BidQuoteChecklist_TOBID',
  SYNC_ChecklistToBidQuote: 'SYNC_ChecklistToBidQuote',

  PUT_FILEEXPORTFLAG: 'PUT_FILEEXPORTFLAG',
  FETCH_FILES: 'FETCH_FILES',
  SET_FILES: 'SET_FILES',
  POST_FILE: 'POST_FILE',
  PUT_FILETYPE: 'PUT_FILETYPE',
  DELETE_FILE: 'DELETE_FILE',
  FETCH_FILE: 'FETCH_FILE',
  SET_FILE: 'SET_FILE',

  RESET_STAGINGDATA: 'RESET_STAGINGDATA'
}

export default actionTypes
