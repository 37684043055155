import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Reference from '../store/ducks/reference.duck'
import * as Auth from '../store/ducks/auth.duck'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import Button from 'react-bootstrap/Button'

// Component to display a user's current test executions

export const TestExecutions = () => {
  const dispatch = useDispatch()
  const assumedUserID = useSelector((state) => state.auth.assumedUserID)
  const testExecutions = useSelector((state) => state.reference.CurrentUserTestExecutions)
  const authFilters = useSelector((state) => state.auth.Filters)
  const testCurrentTestScriptExecutionID = authFilters.testCurrentTestScriptExecutionID

  useEffect(() => {
    dispatch(Reference.actions.fetchCurrentUserTestExecutions({assumedUserID: assumedUserID}))
  }, [dispatch, assumedUserID])

  useEffect(() => {
    if (testExecutions?.length > 0 && (!testCurrentTestScriptExecutionID || !testExecutions.find((testExecution) => testExecution.testScriptExecutionID === testCurrentTestScriptExecutionID))) {
      dispatch(
        Auth.actions.setFilters({
          filterName: 'testCurrentTestScriptExecutionID',
          filterValue: testExecutions[0].testScriptExecutionID
        })
      )
    }
  }, [dispatch, assumedUserID, testCurrentTestScriptExecutionID, testExecutions])

  // testingEnabled
  const handleStart = () => {
    dispatch(Auth.actions.setFilters({filterName: 'testingEnabled', filterValue: true}))
    dispatch(
      Auth.actions.setFilters({
        filterName: 'testViewScriptExecutionID',
        filterValue: testCurrentTestScriptExecutionID
      })
    )
  }

  if (testExecutions?.length < 1) {
    return null
  }

  return (
    <>
      <div className='card mb-5'>
        <Row className='px-7 pt-4'>
          <Col md='12' className='pl-0 pr-0'>
            <Alert variant='warning'>
              <Row className='w-full mr-0'>
                <Col md={12} className='pr-0 pt-0 pb-1'>
                  <LibraryAddCheckIcon className='mr-1' />
                  <strong>
                    You have {testExecutions?.length} test{testExecutions?.length > 1 ? 's' : ''}:
                  </strong>
                </Col>
              </Row>
              <Row className='w-full mr-0'>
                <Col md={10} className='pr-2 pt-1 text-right'>
                  <FormControl variant='standard' size='small'>
                    <Select
                      labelId='Date filter'
                      id='date-filter'
                      value={testCurrentTestScriptExecutionID ? testCurrentTestScriptExecutionID : testExecutions[0]}
                      onChange={(event) =>
                        dispatch(
                          Auth.actions.setFilters({
                            filterName: 'testCurrentTestScriptExecutionID',
                            filterValue: event.target.value
                          })
                        )
                      }
                    >
                      {testExecutions.map((testExecution) => (
                        <MenuItem key={testExecution.testScriptExecutionID} value={testExecution.testScriptExecutionID}>
                          {testExecution.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={2}>
                  <Button size='sm' onClick={handleStart}>
                    Start
                  </Button>
                </Col>
              </Row>
            </Alert>
          </Col>
        </Row>
        {/* <PortletHeaderDropdown /> */}
      </div>
    </>
  )
}

export default TestExecutions
