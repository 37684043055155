import {FC} from 'react';
// import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
// import * as Auth from "../../../../../app/store/ducks/auth.duck";
import { clearStorage } from '../../../../../app/modules/auth/AuthHelpers';

const SidebarMenuItemLogout: FC = () => {
  
  const { instance } = useMsal();
  // const dispatch = useDispatch()

  const clearStorageAccount = () => {
    let account = instance.getActiveAccount();
    if(account)
    {
      clearStorage(account);
    }
  }

  const handleLogoutPopup = () => {
    clearStorageAccount();
    instance.logoutPopup({
        // mainWindowRedirectUri: '/', // redirects the top level app after logout
        account: instance.getActiveAccount(),
    });
  }

  // const handleClearToken = () => {
  //   clearStorageAccount();
  //   dispatch(Auth.actions.clearAuthToken());
  // }

  return (
    <>
      {/* <div className='menu-item'>
        <div className='menu-link without-sub'>
          <span className='menu-icon'>
            <i className='bi fs-3 bi-box-arrow-right'></i>
          </span>
          <span className='menu-title' onClick={()=>handleClearToken()}>Clear authToken</span>
        </div>
      </div> */}
      <div className='menu-item'>
        <div className='menu-link without-sub'>
          <span className='menu-icon'>
            <i className='bi fs-3 bi-box-arrow-right'></i>
          </span>
          <span className='menu-title' onClick={handleLogoutPopup}>Log out</span>
        </div>
      </div>
    </>
  )
}

export {SidebarMenuItemLogout}
