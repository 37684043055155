// calculate either hours or budget for a project resource

function getYears(weeks, phase) {
  const debug = false
  debug && console.log('*************************************** in getYears *****************************************')
  debug && console.log(weeks)

  let currentYear = 0
  let weeksCount = 0
  let years = []

  // if filter weeks by phase
  weeks
    .filter((row) => row.Phase === phase)
    .map((week, index) => {
      let thisYear = parseInt(week.Week.substring(0, 4))
      // when the year changes, or at the end, insert a row for the year and the weeks count
      if (currentYear !== 0 && (currentYear !== thisYear || index + 1 === weeks.filter((row) => row.Phase === phase).length)) {
        // If this is the last row, then increment the weeks now for the insert
        if (index + 1 === weeks.filter((row) => row.Phase === phase).length) {
          weeksCount++
        }
        // Add the last Year row with its week count
        years = [...years, {year: currentYear, weeks: weeksCount}]
        weeksCount = 0
      }
      debug && console.log(`phase: ${phase}, index: ${index}, weeks.length: ${weeks.length}, thisYear: ${thisYear}, currentYear: ${currentYear}, weeksCount: ${weeksCount}`)
      currentYear = thisYear
      weeksCount++
      return null
    })
  debug && console.log(years)
  return years
}

export function calcHours(salaryEscalationRate, weeks, phase, postingRate, budgetTotal) {
  //  calculate weekly hours when a user inputs a total budget
  //  To handle salary escalation, we step through years, calculate the % of the total for each year, and calc the hours from there

  let years = getYears(weeks, phase)
  const debug = false

  let result = 0
  let yearNo = 0
  let escRate = 1 + salaryEscalationRate / 100
  let hourlyRate = postingRate
  let multiple = 1
  let yearPC = 0
  let yearPCTotal = 0
  let yearTotal = 0
  let weeklyCost = 0
  let weeklyHours = 0

  // calculate the escalated grand total
  years.forEach((year) => {
    yearNo++
    if (yearNo === 1) {
      yearPCTotal = year.weeks
    }
    if (yearNo === 2) {
      yearPCTotal = yearPCTotal + year.weeks * escRate
    }
    if (yearNo === 3) {
      yearPCTotal = yearPCTotal + year.weeks * escRate * escRate
    }
    if (yearNo === 4) {
      yearPCTotal = yearPCTotal + year.weeks * escRate * escRate * escRate
    }
    if (yearNo === 5) {
      yearPCTotal = yearPCTotal + year.weeks * escRate * escRate * escRate * escRate
    }
    return null
  })
  // console.log(`yearPCTotal: ${yearPCTotal}`)

  yearNo = 0
  years.forEach((year, index) => {
    yearNo++

    // calculate the hourly rate with escalation
    multiple = index === 0 ? 1 : escRate
    hourlyRate = hourlyRate * multiple

    // calculate the %total cost for each year
    if (yearNo === 1) {
      yearPC = year.weeks / yearPCTotal // %total cost for each year
      yearTotal = yearPC * budgetTotal
      weeklyCost = yearTotal / year.weeks
      weeklyHours = weeklyCost / hourlyRate
    }
    if (yearNo === 2) {
      yearPC = (year.weeks * escRate) / yearPCTotal
      yearTotal = yearPC * budgetTotal
      weeklyCost = yearTotal / year.weeks
      weeklyHours = weeklyCost / hourlyRate
    }
    if (yearNo === 3) {
      yearPC = (year.weeks * escRate * escRate) / yearPCTotal
      yearTotal = yearPC * budgetTotal
      weeklyCost = yearTotal / year.weeks
      weeklyHours = weeklyCost / hourlyRate
    }
    if (yearNo === 4) {
      yearPC = (year.weeks * escRate * escRate * escRate) / yearPCTotal
      yearTotal = yearPC * budgetTotal
      weeklyCost = yearTotal / year.weeks
      weeklyHours = weeklyCost / hourlyRate
    }
    if (yearNo === 5) {
      yearPC = (year.weeks * escRate * escRate * escRate * escRate) / yearPCTotal
      yearTotal = yearPC * budgetTotal
      weeklyCost = yearTotal / year.weeks
      weeklyHours = weeklyCost / hourlyRate
    }

    result = weeklyHours // They are all the same

    debug && console.log({...year, hourlyRate: hourlyRate, yearNo: yearNo, yearPC: yearPC, yearTotal: yearTotal, weeklyCost: weeklyCost, weeklyHours: weeklyHours})
    return null
  })

  return result
}

export function calcCost(salaryEscalationRate, weeks, phase, postingRate, hours) {
  //  calculate total budget when a user inputs a weekly hours number
  //  Salary escalattion is added for each year
  const debug = false
  let years = getYears(weeks, phase)

  let budgetTotal = 0
  let yearNo = 0
  let escRate = 1 + salaryEscalationRate / 100
  let hourlyRate = postingRate
  let multiple = 1
  let temp = {}
  let yearTotal = 0
  let weeklyCost = 0

  yearNo = 0
  years.forEach((year) => {
    yearNo++

    // calculate the hourly rate with escalation
    multiple = yearNo === 1 ? 1 : escRate
    hourlyRate = hourlyRate * multiple

    weeklyCost = hourlyRate * hours
    yearTotal = hourlyRate * hours * year.weeks
    budgetTotal = budgetTotal + hourlyRate * hours * year.weeks

    temp = {...year, hourlyRate: hourlyRate, yearNo: yearNo, yearTotal: yearTotal, weeklyCost: weeklyCost, weeklyHours: hours}
    debug && console.log(temp)
    return null
  })

  return budgetTotal
}
