import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
// Apps
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './index.scss'

import {App} from './app/App'
import {setupAxios, getAuthToken} from './app/modules/auth/AuthHelpers'
import store, {persistor} from './app/store/store'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {PublicClientApplication, EventType} from '@azure/msal-browser'
import {msalConfig, loginRequest} from './app/modules/auth/components/authProvider'
import {MsalProvider} from '@azure/msal-react'
import * as Sentry from '@sentry/react'
// import LogRocket from 'logrocket'
// import setupLogRocketReact from 'logrocket-react'

// Instantiate MSAL outside of the component tree to prevent it from being re-instantiated on re-renders.
const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// called by the msal callbacks, we need to setup the user session for the loggers with account details from msal
const setupUserSession = async (message: any) => {
  process.env.REACT_APP_DEBUG && console.log('index.tsx: setupUserSession')

  if (message.payload) {
    store.dispatch({type: 'RESET_REAUTH'})
    store.dispatch({type: 'SET_AUTHTOKEN', payload: message.payload.idToken})

    // configure loggers with user details
    const {username, name, homeAccountId} = message.payload.account
    // Identify user to Sentry
    Sentry.setUser({email: username})
    // Identify user to LogRocket
    // LogRocket.identify(homeAccountId, {
    //   name: name ? name : '',
    //   email: username,
    //   env: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'unknown'
    // })
  }
}

// Add callbacks to msal events to setup logging, and get a token for the api
msalInstance.addEventCallback((message: {eventType: any; payload: any}) => {
  process.env.REACT_APP_DEBUG && console.log('index.tsx: msalInstance callback message:')
  process.env.REACT_APP_DEBUG && console.log(message)
  // moved all the follow on actions such as getToken to AppRoutes useEffect to respond better
  // If we login, reset the reath increment as we may have maxed out causing a login
  if (message.eventType === EventType.LOGIN_SUCCESS) {
    setupUserSession(message)
  }

  if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
    setupUserSession(message)
  }

  if (message.eventType === EventType.HANDLE_REDIRECT_END) {
    const getmsalToken = async () => {
      return await getAuthToken(store, msalInstance)
    }
    getmsalToken()
  }

  if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    msalInstance.loginPopup(loginRequest).catch((error) => {
      console.error(error)
    })
  }
})

if (process.env.REACT_APP_ENV !== 'dev') {
  /* Setup Sentry and LogRocket here */

  /*
   We need to set tracePropagationTargets for all of the backend URI that we hit to enable sentry distributed tracing.
   See; https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/
   Note that the above documentation does not seem very accurate.  We can't use a string such
   as bidcalcapi in here we need a regexp to match instead.

   */
  Sentry.init({
    dsn: 'https://8d0a1ca6bf8f496c8b060df9734f5c1b@o4504359075840000.ingest.sentry.io/4504359141638144',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^.*bidcalcapi.*$/, /^.*hpm-api.*$/]
      })
    ],

    beforeSendTransaction(event) {
      // Filter ChatHub Events from Sentry Performance Monitoring
      if (event.transaction === '/chatHub') {
        // Don't send the event to Sentry
        return null
      }
      return event
    },

    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_VERSION,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })

  // LogRocket.init('6lvqz5/hitt-pricing-model', {
  //   network: {
  //     requestSanitizer: (request) => {
  //       // Filter ChatHub Events from Sentry Performance Monitoring

  //       if (request.url.toLowerCase().indexOf('chathub') !== -1) {
  //         // ignore the request response pair
  //         return null
  //       }

  //       // otherwise log the request normally
  //       return request
  //     }
  //   }
  // })

  // Add LogRocket session url to errors reported to Sentry
  // LogRocket.getSessionURL((sessionURL) => {
  //   Sentry.configureScope((scope) => {
  //     scope.setExtra('sessionURL', sessionURL)
  //   })
  // })

  // add LogRocket react plugin
  // setupLogRocketReact(LogRocket)
}

// Add auth helper to authenticate api requests
setupAxios(axios, store, msalInstance)

setTimeout(() => {
  // console.log('Rendering: index.tsx *******************************************')
  const container = document.getElementById('root')
  if (container) {
    createRoot(container).render(
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </MsalProvider>
    )
  }
})
