import clsx from 'clsx'
import ProjectSelector from '../../../../app/components/ProjectSelector'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'

export const HelpIcon = () => {
  return (<div className={clsx('app-navbar-item', itemClass)}>
  <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
    {/* <KTIcon iconName='message-text-2' className={btnIconClass} /> */}
    <i className={clsx('bi fs-1', 'bi-question-circle')} style={{ WebkitTextStroke: 0.5 }}></i>
  </div>
</div>)
}

const Navbar = () => {
  return (
    <div className='app-navbar flex-shrink-0 hidden lg:flex'>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <ProjectSelector />
      </div>
      <HelpIcon />
    </div>
  )
}

export {Navbar}
