import {useEffect, useRef, useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import ApexCharts from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import ChartWrapper from './ChartWrapper'

const EfficiencyTrend = ({className}) => {
  const chartRef = useRef(null)

  const report = useSelector((state) => state.project.reports.dashboardTrends)
  const labels = useMemo(() => report.map((row) => row.MonthName), [report])
  const data = useMemo(() => report.map((row) => row.AvgTE), [report])

  const refreshChart = useCallback((labels, data) => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, labels, data))
    if (chart) {
      chart.render()
    }

    return chart
  }, [])

  useEffect(() => {
    const chart = refreshChart(labels, data)
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, labels, data, refreshChart]) // Removed mode (useThemeMode) from dependency array as the theme cant be changed

  return <ChartWrapper ref={chartRef} className={className} title='Efficiency Trend' subtitle='Avg Monthly Target Efficiency Last 12 Months' />
}

export {EfficiencyTrend}

function getChartOptions(height, labels, data) {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'Avg Target Efficiency',
        data: data
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        borderRadius: 2
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px'
        }
      }
    },
    fill: {
      opacity: 1
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px'
      },
      y: {
        formatter: function (val) {
          return val + '%'
        }
      }
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true
        }
      }
    }
  }
}
