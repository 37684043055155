import {Form, Formik} from 'formik'

export const FormikWrapper = ({children, ...formikProps}) => {
  return <Formik {...formikProps}>{() => <>{children}</>}</Formik>
}

function AppForm({formId, children, formProps, ...formikProps}) {
  return (
    <FormikWrapper {...formikProps}>
      <Form id={formId} {...formProps}>
        {children}
      </Form>
    </FormikWrapper>
  )
}
export default AppForm
