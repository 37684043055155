import debounce from 'lodash/debounce'
import {useCallback, useRef} from 'react'

const useBatchedGridChangeHandler = (callback) => {
  const contextRef = useRef(null)
  const eventsRef = useRef([])

  const executeCallback = useCallback(() => {
    callback(contextRef.current, eventsRef.current)
    eventsRef.current = []
    contextRef.current = null
  }, [callback])
  const debouncedExecute = debounce(executeCallback, 100, {maxWait: 1000})

  const batchEvents = useCallback(
    (context, event) => {
      contextRef.current = context
      eventsRef.current.push(event)
      debouncedExecute()
    },
    [debouncedExecute]
  )

  return batchEvents
}

export default useBatchedGridChangeHandler
