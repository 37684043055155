const actionTypes = {
  // Init actiontypes to build reference data
  SET_REFCACHE: 'SET_REFCACHE',
  SET_REFSTATUS: 'SET_REFSTATUS',
  SET_INITIAL: 'SET_INITIAL',
  SET_REFERENCE: 'SET_REFERENCE',
  FETCH_REFERENCES: 'FETCH_REFERENCES',
  FETCH_HelpTopics: 'FETCH_HelpTopics',
  DELETE_HelpTopic: 'DELETE_HelpTopic',
  FETCH_HelpVideos: 'FETCH_HelpVideos',
  DELETE_HelpVideoFile: 'DELETE_HelpVideoFile',

  FETCH_Contexts: 'FETCH_Contexts',
  FETCH_PLUGINS: 'FETCH_PLUGINS',
  FETCH_Tags: 'FETCH_Tags',
  FETCH_States: 'FETCH_States',
  FETCH_Counties: 'FETCH_Counties',
  FETCH_BusinessUnits: 'FETCH_BusinessUnits',
  FETCH_Employees: 'FETCH_Employees',
  FETCH_EmployeeRoles: 'FETCH_EmployeeRoles',
  FETCH_ExpenseUnits: 'FETCH_ExpenseUnits',
  FETCH_Tasks: 'FETCH_Tasks',
  FETCH_Users: 'FETCH_Users',

  FETCH_DISPLAY_OPTIONS: 'FETCH_DISPLAY_OPTIONS',
  SET_DISPLAY_OPTIONS: 'SET_DISPLAY_OPTIONS',
  PUT_DISPLAY_OPTIONS: 'PUT_DISPLAY_OPTIONS',

  FETCH_EXPENSETEMPLATES: 'FETCH_EXPENSETEMPLATES',
  FETCH_EXPENSECATEGORIES: 'FETCH_EXPENSECATEGORIES',
  FETCH_EXPENSETEMPLATEITEMS: 'FETCH_EXPENSETEMPLATEITEMS',
  FETCH_BuildersRiskRates: 'FETCH_BuildersRiskRates',

  FETCH_SEARCHCRM: 'FETCH_SEARCHCRM',
  SET_SEARCHCRM: 'SET_SEARCHCRM',
  SET_SEARCHCRMSTATUS: 'SET_SEARCHCRMSTATUS',
  SET_SYNCHCRMSTATUS: 'SET_SYNCHCRMSTATUS',
  PUT_SEARCHCRM: 'PUT_SEARCHCRM',
  PUT_SYNCBCPROJECT: 'PUT_SYNCBCPROJECT',

  PUT_HELP_VIDEO: 'PUT_HELP_VIDEO',
  SET_HELP: 'SET_HELP',
  PUT_USER: 'PUT_USER',
  DELETE_USER: 'DELETE_USER',
  PUT_ERROR: 'PUT_ERROR',
  PUT_SYSTEMPARAMETER: 'PUT_SYSTEMPARAMETER',
  PUT_EXPENSETEMPLATE: 'PUT_EXPENSETEMPLATE',
  PUT_TAG: 'PUT_TAG',

  CHANGE_EXPENSE_CATEGORY_GRID_ROW: 'CHANGE_EXPENSE_CATEGORY_GRID_ROW',
  DELETE_EXPENSE_CATEGORY_GRID_ROW: 'DELETE_EXPENSE_CATEGORY_GRID_ROW',
  CHANGE_EXPENSE_ITEM_GRID_ROW: 'CHANGE_EXPENSE_ITEM_GRID_ROW',
  CHANGE_EXPENSE_UNIT_ROW: 'CHANGE_EXPENSE_UNIT_ROW',
  CHANGE_OFFICE_GRID_ROW: 'CHANGE_OFFICE_GRID_ROW',
  CHANGE_STATE_GRID_ROW: 'CHANGE_STATE_GRID_ROW',
  CHANGE_COUNTY_GRID_ROW: 'CHANGE_COUNTY_GRID_ROW',
  CHANGE_BUILDERSRISKRATE_GRID_ROW: 'CHANGE_BUILDERSRISKRATE_GRID_ROW',
  CHANGE_EMPLOYEE_ROLE_GRID_ROW: 'CHANGE_EMPLOYEE_ROLE_GRID_ROW',
  CHANGE_EMPLOYEE_GRID_ROW: 'CHANGE_EMPLOYEE_GRID_ROW',
  CHANGE_TASK_GRID_ROW: 'CHANGE_TASK_GRID_ROW',
  CHANGE_USER_ROLE_GRID_ROW: 'CHANGE_USER_ROLE_GRID_ROW',
  PUT_HELP_TOPIC: 'PUT_HELP_TOPIC',
  CHANGE_BUSINESSUNIT_ROW: 'CHANGE_BUSINESSUNIT_ROW',
  ADD_BID_PERMISSION: 'ADD_BID_PERMISSION',
  DELETE_BID_PERMISSION: 'DELETE_BID_PERMISSION',
  DELETE_EXPENSETEMPLATE: 'DELETE_EXPENSETEMPLATE',
  DELETE_EXPENSE_UNIT_ROW: 'DELETE_EXPENSE_UNIT_ROW',
  DELETE_EXPENSETEMPLATEITEM: 'DELETE_EXPENSETEMPLATEITEM',
  FETCH_PERDIEMRATES: 'FETCH_PERDIEMRATES',
  PUT_PERDIEMRATE: 'PUT_PERDIEMRATE',
  DELETE_PERDIEMRATE: 'DELETE_PERDIEMRATE',

  FETCH_CSIDIVISIONS: 'FETCH_CSIDIVISIONS',
  PUT_CSICODE: 'PUT_CSICODE',
  PUT_BUSINESSUNIT_CSICODE: 'PUT_BUSINESSUNIT_CSICODE',
  PUT_CSIVERSION: 'PUT_CSIVERSION',
  PUT_Clarification: 'PUT_Clarification',
  DELETE_Clarification: 'DELETE_Clarification',

  //Test Section
  FETCH_TESTSECTIONSGRID: 'FETCH_TESTSECTIONSGRID',
  FETCH_TESTSECTION: 'FETCH_TESTSECTION',
  PUT_TESTSECTION: 'PUT_TESTSECTION',
  DELETE_TESTSECTION: 'DELETE_TESTSECTION',

  //Test Steps
  PUT_TESTSECTIONSTEP: 'PUT_TESTSECTIONSTEP',
  DELETE_TESTSECTIONSTEP: 'DELETE_TESTSECTIONSTEP',

  //Test Script
  FETCH_TESTSCRIPTSGRID: 'FETCH_TESTSCRIPTSGRID',
  FETCH_TESTSCRIPT: 'FETCH_TESTSCRIPT',
  PUT_TESTSCRIPT: 'PUT_TESTSCRIPT',
  DELETE_TESTSCRIPT: 'DELETE_TESTSCRIPT',

  //Test Script Section
  PUT_TESTSCRIPTSECTION: 'PUT_TESTSCRIPTSECTION',
  DELETE_TESTSCRIPTSECTION: 'DELETE_TESTSCRIPTSECTION',

  //Test Execution
  FETCH_TESTEXECUTIONGRID: 'FETCH_TESTEXECUTIONGRID',
  FETCH_TESTEXECUTION: 'FETCH_TESTEXECUTION',
  POST_TESTEXECUTION: 'POST_TESTEXECUTION',
  PUT_TESTEXECUTION: 'PUT_TESTEXECUTION',
  FETCH_CURRENTUSER_TESTEXECUTIONS: 'FETCH_CURRENTUSER_TESTEXECUTIONS',
  FETCH_TESTEXECUTIONSTEPS: 'FETCH_TESTEXECUTIONSTEPS',
  PUT_TESTSTEPEXECUTION_RESULT: 'PUT_TESTSTEPEXECUTION_RESULT',

  FETCH_BIDTRADETEMPLATES: 'FETCH_BIDTRADETEMPLATES',
  FETCH_BIDTRADETEMPLATE: 'FETCH_BIDTRADETEMPLATE',
  PUT_BIDTRADETEMPLATE: 'PUT_BIDTRADETEMPLATE',
  DELETE_BIDTRADETEMPLATE: 'DELETE_BIDTRADETEMPLATE',
  PUT_BIDTRADEITEMTEMPLATE: 'PUT_BIDTRADEITEMTEMPLATE',
  DELETE_BIDTRADEITEMTEMPLATE: 'DELETE_BIDTRADEITEMTEMPLATE',

  FETCH_CLARIFICATIONTEMPLATES: 'FETCH_CLARIFICATIONTEMPLATES',
  FETCH_CLARIFICATIONTEMPLATE: 'FETCH_CLARIFICATIONTEMPLATE',
  PUT_CLARIFICATIONTEMPLATE: 'PUT_CLARIFICATIONTEMPLATE',
  DELETE_CLARIFICATIONTEMPLATE: 'DELETE_CLARIFICATIONTEMPLATE',

  FETCH_APPROVERS: 'FETCH_APPROVERS',
  FETCH_CSICodes: 'FETCH_CSICodes',
  FETCH_CSIVersions: 'FETCH_CSIVersions',

  FETCH_BidQuoteChecklistTemplates: 'FETCH_BidQuoteChecklistTemplates',
  FETCH_BidQuoteChecklistTemplate: 'FETCH_BidQuoteChecklistTemplate',
  SET_BidQuoteChecklistTemplateItems: 'SET_BidQuoteChecklistTemplateItems',
  PUT_BidQuoteChecklistTemplate: 'PUT_BidQuoteChecklistTemplate',
  PUT_BidQuoteChecklistItemTemplate: 'PUT_BidQuoteChecklistItemTemplate',
  DELETE_BidQuoteChecklistItemTemplate: 'DELETE_BidQuoteChecklistItemTemplate',
  DELETE_BidQuoteChecklistTemplate: 'DELETE_BidQuoteChecklistTemplate'
}

export default actionTypes
