import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Form from 'react-bootstrap/Form'

const AppSingleCheckbox = (props) => {
  const {
    label,
    id,
    field: {name, onBlur, onChange, value},
    form: {errors, touched, setFieldTouched},
    ...inputProps
  } = props
  const hasError = errors[name] && touched[name]
  return (
    <Form.Group>
      <FormControlLabel
        htmlFor={id}
        control={
          <Checkbox
            id={id}
            name={name}
            value={value}
            checked={value}
            onChange={(value) => onChange(name)(value)}
            onBlur={() => {
              setFieldTouched(name)
              onBlur(name)
            }}
            {...inputProps}
          />
        }
        label={label}
      />
      {hasError && <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>}
    </Form.Group>
  )
}

export default AppSingleCheckbox
