import isEqual from 'lodash/isEqual'

// cache component to cache actions to prevent unecessary redux api calls

export default function isCached(cache, action) {
  let result = true
  let message = ''
  let timeNow = Date.now()

  // if instructed to pop the action from cache, then return a miss
  if (action.payload && action.payload.popCache) {
    message = 'Cache popped'
    result = false
  }

  // if action is not in the cache, then return a miss
  if (!cache[action.type]) {
    message = 'Cache miss - action not found'
    result = false
  }

  // If action matches, check for a payload match
  if (result) {
    // If payload matches (and less than 30 mins old), then return a hit
    if (!action.payload || (isEqual(cache[action.type].payload, action.payload) && timeNow - cache[action.type].timestamp < 1800000)) {
      message = `Cache hit - action and payload are in cache (${timeNow - cache[action.type].timestamp})`
      result = true
    } // if payload doesn't match, then return a miss
    else {
      message = 'Cache miss - action is in cache but payload do not match'
      result = false
    }
  }

  process.env.REACT_APP_DEBUG && console.log(`${action.type} (${message})`)
  return result
}
