import { useEffect } from "react"; 
import { useDispatch, useSelector } from 'react-redux';
import * as Project from "../../../../app/store/ducks/project.duck";
import currencyFormat from '../../../../app/components/currencyFormat';

const DashboardHighlightWidget = ({className}) => {

  const dispatch = useDispatch()
  const dashboard = useSelector(state => state.project.Dashboard.values)
  useEffect( () => { dispatch(Project.actions.fetchDashboard()) }, [dispatch] )
 
  return (
    <>
    <div className={`card ${className}`}>
      <div className="card-header border-0 py-5">

        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1 text-gray-600">Sales Pipeline</span>
          <span className="text-muted fw-semibold fs-7">Total Contract Value</span>
        </h3>
        
        <div className="fw-bold fs-2 text-danger pt-[6px]">
          ${currencyFormat(dashboard.Calc_TotalPipelineRevenue, 0)} M
        </div>

      </div>
      <div className="card-header border-0 py-5">

        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1 text-gray-600">Anticipated Margin</span>
          <span className="text-muted fw-semibold fs-7">Average Profit exc. Precon</span>
        </h3>
        
        <div className="fw-bold fs-2 text-primary pt-[6px]">
          ${currencyFormat(dashboard.Calc_TotalTargetProfit, 0)}
        </div>

      </div>
      <div className="card-header border-0 py-5">

        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1 text-gray-600">Target Efficiency</span>
          <span className="text-muted fw-semibold fs-7">Average for Last 12 Months</span>
        </h3>
        
        <div className="fw-bold fs-2 text-success pt-[6px]">
          +{dashboard.Calc_YearOnYearGrowthReturnOnLabor}%
        </div>

      </div>
    </div>
    </>
  )
}

export {DashboardHighlightWidget}
