import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Badge from 'react-bootstrap/Badge'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'html-react-parser'

function FieldLabel({field, suffix, disabled, suffixBr, addHelp, addApproved, addFeeExclusion, size}) {
  const help = useSelector((state) => state.reference.help)
  const label = field.replace(/\s+/g, '')
  const disabledColor = disabled ? 'silver' : ''
  const [show, setShow] = useState(false)

  const handleOnMouseEnter = () => {
    setShow(true)
  }
  const handleOnMouseLeave = () => {
    setShow(false)
  }

  let addHelpSplit = []

  if (addHelp !== undefined) {
    addHelpSplit = addHelp.split('<br>')
  }

  if ((help[label] === undefined || help[label] === '') && addHelp === undefined) {
    return (
      <p className='w-fit mb-0' style={{color: disabledColor}}>
        {field} {suffixBr ? <br /> : null} {suffix}
      </p>
    )
  } else {
    return (
      <OverlayTrigger
        key={label}
        show={show}
        topicField={label}
        placement='right-end'
        overlay={
          <Popover id={`popover-fieldlabel-help-${size ? size : 'normal'}`} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
            <Popover.Header as='h3'>
              <Grid container spacing={0}>
                <Grid item xs={1}>
                  <HelpOutlineIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography align='left' className='help-popover-title-label'>
                    {field}
                  </Typography>
                </Grid>
              </Grid>
            </Popover.Header>
            <Popover.Body>
              <AddApprovedBadge approval={addApproved} />
              <Typography align='left'>{help[label] && parse(help[label])}</Typography>
              {addHelpSplit.length > 0 ? <hr /> : null}
              {addHelpSplit.map((subStr, index) => (
                <div key={index}>
                  <Typography align='left' key={index}>
                    {subStr ? subStr : <>&nbsp;</>}
                  </Typography>
                </div>
              ))}
              {addFeeExclusion ? (
                <>
                  <hr />
                  <Typography align='left' sx={{color: 'red'}}>
                    Excluded from Fee Base
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Popover.Body>
          </Popover>
        }
      >
        <p className='w-fit mb-0' onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
          {field} {suffixBr ? <br /> : null} {suffix}
          <AddHelpIcon addFeeExclusion={addFeeExclusion} />
          <AddApproved approval={addApproved} />
        </p>
      </OverlayTrigger>
    )
  }
}

function AddHelpIcon({addFeeExclusion}) {
  return <HelpOutlineIcon className={`text-${addFeeExclusion ? 'red-light' : 'silver-secondary'} ml-[5px]`} />
}

function AddApproved(props) {
  if (props.approval !== undefined) {
    switch (props.approval) {
      case 'Approved':
        return <CheckCircleIcon className='text-green-dark ml-[5px]' />
      case 'Pending':
        return <NotInterestedIcon className='text-silver-secondary ml-[5px]' />
      case 'Not Approved':
        return <NotInterestedIcon className='text-red-light ml-[5px]' />
      default:
        return <NotInterestedIcon className='text-silver-secondary ml-[5px]' />
    }
  } else {
    return ''
  }
}

function AddApprovedBadge(props) {
  if (props.approval !== undefined) {
    return (
      <Badge className='mx-0 mt-0 mb-[6px]' bg={getBadgeClass(props.approval)}>
        {props.approval}
      </Badge>
    )
  } else {
    return ''
  }
}

function getBadgeClass(status) {
  switch (status) {
    case 'Approved':
      return 'success'
    case 'Pending':
      return 'secondary'
    case 'Not Approved':
      return 'danger'
    default:
  }
}

export default FieldLabel
