import {put, takeLatest, all, select, takeEvery} from 'redux-saga/effects'
import axios from 'axios'
import {persistReducer} from 'redux-persist'
// import { REHYDRATE } from 'redux-persist/lib/constants';
import storage from 'redux-persist/lib/storage'
import isCached from '../../components/Cache'
import AppToast from '../../helpers/AppToast'
import actionTypes from './precon/actionTypes'
import {mapQuotesToObj, mapAreasToObj} from '../../helpers/BidSpreadsheetUtils'

export const BASE_URL = process.env.REACT_APP_APIIP + '/precalc'

const projectID = (state) => state.project.projectID
const projectHeader = (state) => state.project.projectHeader
const bidQuoteID = (state) => state.precon.bidQuoteID
const bidTradeID = (state) => state.precon.bidTradeID
const listFilters = (state) => state.auth.Filters.bidsInProgress
const cache = (state) => state.precon.cache

// Initial state for this slice of the store
const initialState = {
  cache: {},
  bidID: '',
  bidQuoteID: '',
  bidTradeID: '',
  Allowance: {
    id: 0,
    allowanceID: 0,
    bidQuoteID: 0,
    bidTradeID: null,
    description: '',
    total: 0,
    items: []
  },
  Alternate: {
    id: 0,
    type: 'Alternate',
    alternateID: 0,
    alternateIndex: 1000,
    description: '',
    total: 0,
    status: 'Proposed',
    comments: '',
    forBidTradeID: 0,
    quotedPC: 100,
    items: []
  },
  AlternatesItemsGrid: [],
  Alternates: [],
  ActionParams: {actionType: '', params: {}},
  BCProjects: [],
  BidAllowancesGrid: [],
  BidAlternatesGrid: [],
  BidQuoteChecklist: {
    id: 0,
    bidQuoteChecklistID: 0,
    name: '',
    description: '',
    status: '',
    istemplate: false,
    businessUnitID: '',
    updatedOn: new Date(),
    updatedBy: 0,
    items: []
  },
  BidQuote: {
    id: 0,
    bidQuoteID: 0,
    bcProjectID: 0,
    bidID: null,
    csiVersionID: 0,
    bidQuoteChecklistID: null,
    prevBudget: null,
    prevBudgetVarianceToFinal: null,
    budgetCost: null,
    budgetSubtotal: null,
    budgetTotal: null,
    directCostLowestTotal: null,
    directCostAdjustmentTotal: null,
    directCostProposalTotal: null,
    directCostTotal: null,
    directCostVE: null,
    directCostFinal: null,
    subTotalLowest: null,
    subTotalAdjustment: null,
    subTotalProposal: null,
    subTotal: null,
    subTotalVE: null,
    subTotalFinal: null,
    buildingPermit: '',
    totalCostLowestTotal: null,
    totalCostAdjustmentTotal: null,
    totalCostProposalTotal: null,
    totalCost: null,
    totalCostVE: null,
    totalCostFinal: null,
    istemplate: false,
    templateName: '',
    templateDescription: '',
    copiedFromBidQuoteID: null,
    progress: 0,
    progressLabel: '',
    status: '',
    preserveBidPackageNames: null,
    preconTotal: null,
    generalConditionsTotal: null,
    ppbondTotal: null,
    buildersRiskTotal: null,
    insuranceLowest: null,
    feeLowest: null,
    insuranceProposal: null,
    feeProposal: null,
    insuranceTotal: null,
    feeTotal: null,
    insuranceFinal: null,
    feeFinal: null,
    updatedOn: new Date(),
    updatedBy: 0,
    trades: [],
    checklist: {
      id: 0,
      bidQuoteChecklistID: 0,
      name: '',
      description: '',
      status: '',
      istemplate: false,
      updatedOn: new Date(),
      updatedBy: 0,
      items: []
    },
    tagValues: []
  },
  BidQuoteAreas: [],
  BidTradeSummaryGrid: [],
  BidTrades: [
    {
      id: 0,
      bidTradeID: 0,
      bidQuoteID: 0,
      codeID: 0,
      textValue: '',
      tradeCost: null,
      tradeVE: null,
      tradeTotal: null,
      progress: 0,
      altAccepted: null,
      altPending: null,
      altRejected: null,
      budget: null,
      delta: null,
      lowest: null,
      adjustment: null,
      hittPlugText: '',
      hittPlugCost: null,
      updatedOn: new Date(),
      updatedBy: 0,
      deleted: null,
      items: [],
      quotes: []
    }
  ],
  BidTradesTemp: [],
  BidTradesDeleted: [],
  BidTrade: {
    id: 0,
    bidTradeID: 0,
    bidQuoteID: 0,
    codeID: 0,
    textValue: '',
    tradeCost: null,
    tradeVE: null,
    tradeTotal: null,
    progress: 0,
    altAccepted: null,
    altPending: null,
    altRejected: null,
    budget: null,
    delta: null,
    lowest: null,
    adjustment: null,
    hittPlugText: '',
    hittPlugCost: null,
    updatedOn: new Date(),
    updatedBy: 0,
    deleted: null,
    items: [],
    quotes: []
  },
  BidTradeTemp: {
    id: 0,
    bidTradeID: 0,
    bidQuoteID: 0,
    codeID: 0,
    textValue: '',
    tradeCost: null,
    tradeVE: null,
    tradeTotal: null,
    progress: 0,
    altAccepted: null,
    altPending: null,
    altRejected: null,
    budget: null,
    delta: null,
    lowest: null,
    adjustment: null,
    hittPlugText: '',
    hittPlugCost: null,
    updatedOn: new Date(),
    updatedBy: 0,
    deleted: null,
    items: [],
    quotes: []
  },
  BidTradeGrid: [],
  BidTradeGridTemp: [],
  BidTradeGridBudget: [],
  BidTradeGridBudgetTemp: [],
  BidSheetGrid: [],
  BidSheetGridTemp: [],
  BidSummaryGridTotals: [],
  BidSummaryGridTotalsTemp: [],
  BidTradeQuotes: [],
  BidTradeQuote: {
    id: 0,
    bidTradeQuoteID: 0,
    bidTradeID: 0,
    subcontractorID: 0,
    baseBid: 0,
    scopeAdjustments: 0,
    total: 0,
    totalVE: 0,
    totalFinal: 0,
    selectedBid: 0,
    quoteIndex: 0,
    bcbidderID: '',
    subcontractorContactID: null
  },
  BidUnitPricesGrid: [],
  ClarificationSections: [],
  Clarifications: [],
  Files: [],
  File: '',
  Proposals: [],
  Proposal: {
    id: 0,
    proposalID: 0,
    projectID: 0,
    proposalType: 'Trade Pricing',
    title: 'Pricing proposal',
    approverUserID: '',
    status: 'Preparing',
    snapshotID: '',
    snapshotName: '',
    historyLog: [],
    bidTrades: [],
    selectedbidTrades: []
  },
  Snapshots: [],
  Snapshot: {
    id: 0,
    snapshotID: 0,
    projectID: 0,
    bidQuoteID: null,
    proposalID: null,
    snapshotTS: new Date(),
    snapshotType: '',
    description: '',
    payload: '',
    createdBy: 0,
    versionNo: 0,
    nodes: []
  },
  Subcontractors: [],
  Subcontractor: {
    id: 0,
    subcontractorID: 0,
    companyName: '',
    applicationStatus: '',
    lastContacted: new Date(),
    contactedBy: '',
    primaryContact: '',
    primaryEmail: '',
    primaryPhone: '',
    expirationDate: null,
    internalSingleLimit: null,
    internalTotalLimit: null,
    lastQualStatusUpdate: null,
    yearFounded: 0,
    stateFounded: '',
    lastSubmitted: new Date(),
    markets: [],
    regions: [],
    trades: [],
    certifications: [],
    largestProject: null,
    emr: '',
    recordableRate: '',
    certificationType: '',
    updatedOn: null
  },
  SubcontractorContacts: [],
  SubcontractorOffices: [],
  SubcontractorBidHistory: [],
  TagValues: {
    BidTemplate: [],
    Project: [],
    User: [],
    Employee: [],
    Subcontractor: [],
    Checklist: [],
    Bid: [],
    ExpenseTemplate: []
  },
  TempTrades: [],
  TempTradesUnselected: [],
  Temp: {},
  TradeAlternatesGrid: [],
  TradeAllowancesGrid: [],
  TradeUnitPricesGrid: [],
  UnitPrice: {
    id: 0,
    unitPriceID: 0,
    bidQuoteID: 0,
    description: '',
    unit: '',
    price: 0,
    priceWithIndirects: 0,
    quotedPC: 100,
    quotedPrice: 0,
    comments: '',
    statusUpdatedBy: null,
    statusUpdatedByNote: '',
    precon: null,
    generalConditions: null,
    projectContingency: null,
    designContingency: null,
    escalationContingency: null,
    ppbond: null,
    buildersRisk: null,
    fee: null,
    lpinsurance: null,
    updatedOn: new Date(),
    updatedBy: 0,
    items: []
  },
  Updates: {
    TradeScope: {},
    TradeBudget: {},
    ProjectResources: {},
    BidQuoteChecklist: {}
  }
}

// Reducer with action payloads
export const reducer = persistReducer({storage, key: 'HPM2-TradePricing-auth-2023-03-02', whitelist: ['bidID', 'projectHeader', 'bidQuoteID', 'bidTradeID']}, (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILES': {
      return {
        ...state,
        Files: action.payload.files
      }
    }
    case 'SET_FILE': {
      return {
        ...state,
        File: action.payload
      }
    }
    case 'SET_BIDID': {
      return {
        ...state,
        bidID: action.payload.bidID,
        bidQuoteID: action.payload.bidQuoteID
      }
    }
    case 'SET_PRECONCACHE': {
      // .clear will put VOID in the payload, meaning that the next time the action is called it will produce a cache miss and get fresh data
      return {
        ...state,
        cache: {...state.cache, [action.payload.caller.type]: {payload: action.payload.clear ? 'VOID' : action.payload.caller.payload, resultCount: action.payload.count, timestamp: Date.now()}}
      }
    }
    case 'SET_PRECON_UPDATES': {
      // updates counter used to display count of updates to API available to Ui such as TradeScope grid
      let newValue = state.Updates[action.payload.name][action.payload.id] ? state.Updates[action.payload.name][action.payload.id] : 0
      let temp = {[action.payload.id]: action.payload.value === 0 ? 0 : action.payload.value + newValue}
      let prev = state.Updates[action.payload.name]
      return {
        ...state,
        Updates: {...state.Updates, [action.payload.name]: {...prev, ...temp}}
      }
    }
    case 'SET_PRECON_UPDATES_ACTUAL': {
      // updates counter used to display count of updates to API available to Ui such as TradeScope grid
      let temp = {[action.payload.id]: action.payload.value === 0 ? 0 : action.payload.value}
      let prev = state.Updates[action.payload.name]
      return {
        ...state,
        Updates: {...state.Updates, [action.payload.name]: {...prev, ...temp}}
      }
    }
    case 'SET_BIDQUOTEID': {
      return {
        ...state,
        bidQuoteID: action.payload
      }
    }
    case 'SET_BIDTRADEID': {
      return {
        ...state,
        bidTradeID: action.payload,
        BidTradeGrid: []
      }
    }
    case 'SET_BIDQUOTE': {
      return {
        ...state,
        BidQuote: action.payload
      }
    }
    case 'SET_BIDTRADE': {
      console.log('SET_BIDTRADE')
      console.log(action.payload)
      return {
        ...state,
        BidTrade: action.payload
      }
    }
    case 'SET_BIDTRADE_TEMP': {
      return {
        ...state,
        BidTradeTemp: action.payload
      }
    }

    case 'CLEAR_SUBCONTRACTOR': {
      // Loop cache and remove any entry with a projectID in the payload
      let newCache = {}
      for (const [key, value] of Object.entries(state.cache)) {
        if (!(key && key.substring(6, 13) === 'SUBCONTRACTOR')) {
          newCache = {...newCache, [key]: value}
        }
      }
      return {
        ...state,
        // Trimmed Cache
        cache: newCache,
        // Subcontractor components
        Subcontractor: initialState.Subcontractor,
        SubcontractorContacts: [],
        SubcontractorOffices: [],
        SubcontractorBidHistory: []
      }
    }
    case 'SET_ACTION_PARAMS': {
      return {
        ...state,
        ActionParams: action.payload
      }
    }
    case 'SET_TAGVALUES': {
      return {
        ...state,
        TagValues: {
          ...state.TagValues,
          [action.payload.context]: action.payload.tagValues
        }
      }
    }
    case 'SET_BIDTRADES': {
      return {
        ...state,
        BidTrades: [...action.payload, initialState.BidTrade]
      }
    }
    case 'SET_BIDTRADES_TEMP': {
      return {
        ...state,
        BidTradesTemp: [...action.payload, initialState.BidTrade]
      }
    }
    case 'SET_BIDCLARIFICATIONS': {
      return {
        ...state,
        Clarifications: action.payload
      }
    }
    case 'SET_ALTERNATES': {
      return {
        ...state,
        Alternates: action.payload
      }
    }
    case 'SET_ALTERNATE_ITEMS': {
      return {
        ...state,
        Alternate: {
          ...state.Alternate,
          items: action.payload
        }
      }
    }
    case 'SET_ALLOWANCE_ITEMS': {
      console.log('SET_ALLOWANCE_ITEMS')
      console.log(action.payload)
      return {
        ...state,
        Allowance: {
          ...state.Allowance,
          items: action.payload
        }
      }
    }
    case 'SET_BIDTRADEGRID': {
      return {
        ...state,
        BidTradeGrid: action.payload
      }
    }
    case 'SET_BIDTRADEGRID_TEMP': {
      return {
        ...state,
        BidTradeGridTemp: action.payload
      }
    }
    // case 'PUT_DELETEBIDTRADEGRIDROW': {
    // let generalGrid = [ ...state.BidTradeGrid.filter(row => row.GroupName !== 'Trade Specific Items') ]
    // let tradeItemsGrid = state.BidTradeGrid.filter(row => row.GroupName === 'Trade Specific Items' && row.BidTradeItemID !== action.payload.BidTradeItemID).map((item, index) => { return { ...item, ItemIndex: index + 1, PrevIndex: index + 1, LineNumber: index + 1 }})
    // generalGrid.push(...tradeItemsGrid);
    // return {
    //   ...state,
    //   BidTradeGrid : generalGrid,
    // };
    // }
    case 'SET_BIDTRADEGRIDBUDGET': {
      console.log('SET_BIDTRADEGRIDBUDGET')
      console.log(action.payload)
      return {
        ...state,
        BidTradeGridBudget: action.payload
      }
    }
    case 'SET_BIDTRADEGRIDBUDGET_TEMP': {
      return {
        ...state,
        BidTradeGridBudgetTemp: action.payload
      }
    }
    case 'SET_BIDSHEETGRID': {
      return {
        ...state,
        BidSheetGrid: [
          ...action.payload.map((row) => {
            return {
              ...row,
              quotes: row.quotes && mapQuotesToObj(row.quotes),
              areas: row.areas && mapAreasToObj(row.areas)
            }
          })
        ]
      }
    }
    case 'SET_BIDSHEETGRID_TEMP': {
      return {
        ...state,
        BidSheetGridTemp: [
          ...action.payload.map((row) => {
            return {
              ...row,
              quotes: row.quotes && mapQuotesToObj(row.quotes),
              areas: row.areas && mapAreasToObj(row.areas)
            }
          })
        ]
      }
    }
    case 'SET_BIDSUMMARY_GRIDTOTALS': {
      return {
        ...state,
        BidSummaryGridTotals: action.payload
      }
    }
    case 'SET_BIDSUMMARY_GRIDTOTALS_TEMP': {
      return {
        ...state,
        BidSummaryGridTotalsTemp: action.payload
      }
    }
    case 'SET_PRECON_COLLECTION': {
      return {
        ...state,
        [action.payload.collectionName]: action.payload.grid
      }
    }
    case 'SET_PRECON_TEMP': {
      return {
        ...state,
        Temp: {
          ...state.Temp,
          [action.payload.name]: action.payload.value
        }
      }
    }
    // case 'SET_BIDTRADEGRID_ADDROW': {
    //   return {
    //     ...state,
    //     BidTradeGrid: [ ...state.BidTradeGrid, action.payload],
    //   };
    // }
    default:
      return state
  }
})

// ************** Action Effects **************

export function* fetchFiles(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/files'
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.put(newURL, action.payload)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        yield put({type: 'SET_FILES', payload: {files: response.data}})
        yield put({type: 'SET_CACHE', payload: {caller: action}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* postFile(action) {
  // ok lets update the server
  try {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
    const newURL = BASE_URL + '/sendfile'
    var response = yield axios.post(newURL, action.payload.formData)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok saved')

      const appToast = new AppToast(undefined, undefined, `Saved`)
      appToast.displaySuccessToast()
      yield put({type: 'SET_REFSTATUS', payload: {statusName: 'postFileStatus', statusValue: 'Complete'}})
      yield put({type: 'FETCH_FILES', payload: {projectID: action.payload.projectID, bidTradeID: action.payload.bidTradeID, bidTradeQuoteID: action.payload.bidTradeQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      // TODO: This message is not mapped to the action type
      const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
      appToast.displayErrorToast()

      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: `REDUX Function, API call has errored.`, Message: error.message, actionType: action.type}})
  }
}

export function* putFileExportFlag(action) {
  // ok lets update the server
  try {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
    const newURL = BASE_URL + '/fileexportflag'
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok saved')
      const appToast = new AppToast(undefined, undefined, `Saved`)
      appToast.displaySuccessToast()
      yield put({type: 'FETCH_FILES', payload: {projectID: action.payload.projectID, bidTradeID: action.payload.bidTradeID, bidTradeQuoteID: action.payload.bidTradeQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      // TODO: This message is not mapped to the action type
      const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: `REDUX Function, API call has errored here is the payload ${action}`, Message: error.message}})
  }
}

export function* fetchFile(action) {
  yield put({type: 'SET_FILE', payload: ''})
  process.env.REACT_APP_DEBUG && console.log(action)
  try {
    const newURL = BASE_URL + '/getfile'
    const response = yield axios.post(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      // process.env.REACT_APP_DEBUG && console.log(response.data.filerequested);
      yield put({type: 'SET_FILE', payload: response.data.filerequested})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteFile(action) {
  // ok lets update the server
  try {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
    const newURL = BASE_URL + '/deletefile'
    const response = yield axios.post(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok saved')
      // toast.success("Deleted",{ autoClose:5000 });
      yield put({type: 'FETCH_FILES', payload: {projectID: action.payload.projectID, bidTradeID: action.payload.bidTradeID, bidTradeQuoteID: action.payload.bidTradeQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      // toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    // toast.error("Save Failed: " + action.type);
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchPreconReferences() {
  // Put all the references collections/grids for Precon
  let currentbidQuoteID = yield select(bidQuoteID)
  let currentProjectID = yield select(projectID)
  // yield put({ type: 'FETCH_APPROVERS' })
  yield put({type: 'FETCH_DISPLAY_OPTIONS', payload: {projectID: currentProjectID, bidQuoteID: currentbidQuoteID}})
  yield put({type: 'FETCH_BidQuoteChecklistTemplates', payload: {popCache: true}})

  yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDTRADESUMMARYGRID'}, clear: true}})
  yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}})
  yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDALTERNATESGRID'}, clear: true}})
  yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDALLOWANCESGRID'}, clear: true}})
  yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_TRADEALTERNATESGRID'}, clear: true}})
}

export function* fetchBidQuote(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (Number(action.payload.bidQuoteID) > 0) {
        const newURL = BASE_URL + '/bidquote/' + action.payload.bidQuoteID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          yield put({type: 'SET_BIDQUOTE', payload: response.data[0]})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
          // yield put({ type: 'FETCH_BIDTRADES', payload: action.payload });
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      } else {
        yield put({type: 'SET_BIDQUOTE', payload: initialState.BidQuote})
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchSubcontractorContacts(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/subcontractorcontacts/' + action.payload.subcontractorID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL, action.payload)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'SubcontractorContacts', grid: response.data}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchSubcontractorOffices(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/subcontractoroffices/' + action.payload.subcontractorID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL, action.payload)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'SubcontractorOffices', grid: response.data}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchSubcontractors(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/subcontractors'
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.put(newURL, action.payload)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Subcontractors', grid: response.data}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchSubcontractor(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload.subcontractorID === 0) {
        process.env.REACT_APP_DEBUG && console.log('New subcontractor ')
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Subcontractor', grid: {...initialState.Subcontractor}}})
      } else {
        const newURL = BASE_URL + '/subcontractor/' + action.payload.subcontractorID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        process.env.REACT_APP_DEBUG && console.log(response)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Subcontractor', grid: response.data[0]}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchSubcontractorBidHistory(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/subcontractorbidhistory/' + action.payload.subcontractorID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL, action.payload)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'SubcontractorBidHistory', grid: response.data}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putSubcontractor(action) {
  try {
    const newURL = BASE_URL + '/subcontractor'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_SUBCONTRACTORS', payload: {tagValues: action.payload.tagValuesFilter, popCache: true}})
      yield put({type: 'FETCH_SUBCONTRACTOR', payload: {subcontractorID: response.data.newID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putSubcontractorOffices(action) {
  try {
    const newURL = BASE_URL + '/subcontractoroffices'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_SUBCONTRACTOR_OFFICES', payload: {subcontractorID: action.payload.subcontractorID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putSubcontractorContacts(action) {
  try {
    const newURL = BASE_URL + '/subcontractorcontacts'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_SUBCONTRACTOR_CONTACTS', payload: {subcontractorID: action.payload.subcontractorID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putCopyBidQuote(action) {
  try {
    const newURL = BASE_URL + '/copybidquote'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_BIDQUOTEID', payload: parseInt(response.data.newID)})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putCreateBidQuote(action) {
  let currentListFilter = yield select(listFilters)
  try {
    const newURL = BASE_URL + '/createbidquote'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_BIDQUOTEID', payload: parseInt(response.data.newID)})
      yield put({type: 'FETCH_BIDQUOTE', payload: {bidQuoteID: parseInt(response.data.newID), popCache: true}})
      yield put({type: 'FETCH_ProjectsGrid', payload: {status: currentListFilter.projectsGridStatus}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidQuote(action) {
  try {
    const newURL = BASE_URL + '/bidquote'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_BIDQUOTETemplates', payload: {tagValues: action.payload.tagValuesFilter}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTrade(action) {
  try {
    const newURL = BASE_URL + '/bidtrade'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteBidTrade(action) {
  try {
    const newURL = BASE_URL + '/deletebidtrade'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBidTradeGeneric(action, type) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const thisPayload = action.payload && action.payload.bidTradeID ? action.payload.bidTradeID : 0
      const newURL = BASE_URL + '/bidtrade/' + thisPayload
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        // if changing to a bid that has no bidquote yet, reset the state to default values
        var payload = initialState.BidTrade
        if (response.data[0]) {
          payload = response.data[0]
        }
        yield put({type: type, payload: payload})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 1}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidTrade(action) {
  yield fetchBidTradeGeneric(action, 'SET_BIDTRADE')
}

export function* fetchBidTradeTemp(action) {
  yield fetchBidTradeGeneric(action, 'SET_BIDTRADE_TEMP')
}

export function* fetchBidTradesGeneric(action, type) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/bidtrades/' + action.payload.bidQuoteID + '/' + (action.payload.projectID ? action.payload.projectID : 0)
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: type, payload: response.data})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidTrades(action) {
  yield fetchBidTradesGeneric(action, 'SET_BIDTRADES')
}

export function* fetchBidTradesTemp(action) {
  yield fetchBidTradesGeneric(action, 'SET_BIDTRADES_TEMP')
}

export function* fetchBidTradesDeleted(action) {
  try {
    const newURL = BASE_URL + '/BidTrades/Deleted/' + action.payload
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.get(newURL)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BidTradesDeleted', grid: response.data}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* restoreBidTrade(action) {
  try {
    const newURL = BASE_URL + '/BidTrades/Restore'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBidTradeSummaryGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/BidTradeSummary/' + action.payload.bidQuoteID + '/' + (action.payload.projectID ? action.payload.projectID : 0)
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({
          ...item,
          areas: item.areas && mapAreasToObj(item.areas)
        }))
        process.env.REACT_APP_DEBUG && console.log(gridValues)
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BidTradeSummaryGrid', grid: gridValues}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: gridValues.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidTradeQuote(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload.bidTradeQuoteID > 0) {
        const newURL = BASE_URL + '/bidtradequote/' + action.payload.bidTradeQuoteID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response.data)
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BidTradeQuote', grid: response.data[0]}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          process.env.REACT_APP_DEBUG && console.log(response)
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
          })
        }
      } else {
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BidTradeQuote', grid: initialState.BidTradeQuote}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 0}})
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidTradeGridGeneric(action, type, reset = false) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const thisPayload = action.payload && action.payload.bidTradeID ? action.payload.bidTradeID : 0
      const newURL = BASE_URL + '/bidtradegrid/' + thisPayload
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item, index) => ({...item, id: index}))
        if (reset) yield put({type: 'SET_PRECON_UPDATES', payload: {name: 'TradeScope', id: action.payload && action.payload.bidTradeID ? action.payload.bidTradeID : 0, value: 0}})
        yield put({type: 'SET_REFSTATUS', payload: {statusName: 'fetchBidTradeGridStatus', statusValue: 'Complete'}})
        yield put({type: type, payload: gridValues})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: gridValues.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidTradeGrid(action) {
  yield fetchBidTradeGridGeneric(action, 'SET_BIDTRADEGRID', true)
}

export function* fetchBidTradeGridTemp(action) {
  yield fetchBidTradeGridGeneric(action, 'SET_BIDTRADEGRID_TEMP')
}

export function* fetchBidTradeBudgetGridGeneric(action, type, reset = false) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const thisPayload = action.payload && action.payload.bidTradeID ? action.payload.bidTradeID : 0
      const newURL = BASE_URL + '/bidtradebudgetgrid/' + thisPayload
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValuesBudget = JSON.parse(response.data[0].result).map((item, index) => ({...item, id: index}))
        if (reset) yield put({type: 'SET_PRECON_UPDATES', payload: {name: 'TradeBudget', id: action.payload && action.payload.bidTradeID ? action.payload.bidTradeID : 0, value: 0}})
        yield put({type: 'SET_REFSTATUS', payload: {statusName: 'fetchBidTradeBudgetGridStatus', statusValue: 'Complete'}})
        yield put({type: type, payload: gridValuesBudget})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: gridValuesBudget.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidTradeBudgetGrid(action) {
  yield fetchBidTradeBudgetGridGeneric(action, 'SET_BIDTRADEGRIDBUDGET', true)
}

export function* fetchBidTradeBudgetGridTemp(action) {
  yield fetchBidTradeBudgetGridGeneric(action, 'SET_BIDTRADEGRIDBUDGET_TEMP')
}

export function* fetchBidSheetGridGeneric(action, type) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action?.payload?.bidQuoteID === 0 && action?.payload?.projectID === 0) {
        yield put({type: type, payload: []})
      } else {
        const newURL = BASE_URL + '/bidsheetgrid/' + action.payload.bidQuoteID + '/' + action.payload.projectID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response)
          yield put({type: type, payload: response.data})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidSheetGrid(action) {
  yield fetchBidSheetGridGeneric(action, 'SET_BIDSHEETGRID')
}

export function* fetchBidSheetGridTemp(action) {
  yield fetchBidSheetGridGeneric(action, 'SET_BIDSHEETGRID_TEMP')
}

export function* clearBidSheetGridTemp() {
  yield put({type: 'SET_BIDSHEETGRID_TEMP', payload: []})
}

export function* fetchBidAlternatesGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload && action.payload.bidQuoteID && action.payload.bidQuoteID === 0) {
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BidAlternatesGrid', grid: []}})
      } else {
        const newURL = BASE_URL + '/bidAlternatesGrid/' + action.payload.bidQuoteID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response)
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BidAlternatesGrid', grid: response.data}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchTradeAlternatesGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload && action.payload.bidQuoteID && action.payload.bidQuoteID === 0) {
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'TradeAlternatesGrid', grid: []}})
      } else {
        const newURL = BASE_URL + '/tradeAlternatesGrid/' + action.payload.bidQuoteID + '/' + action.payload.bidTradeID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response)
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'TradeAlternatesGrid', grid: response.data}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchAlternate(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload.alternateID > 0) {
        const newURL = BASE_URL + '/Alternate/' + action.payload.bidQuoteID + '/' + action.payload.alternateID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response.data)
          if (response.data.length > 0) {
            yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Alternate', grid: response.data[0]}})
            yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
          } else {
            yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Alternate', grid: initialState.Alternate}})
            yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 0}})
          }
        } // API completed with 200, however there is an error message
        else {
          process.env.REACT_APP_DEBUG && console.log(response)
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
          })
        }
      } else {
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Alternate', grid: {...initialState.Alternate, type: action.payload.type}}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 0}})
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidClarifications(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const thisPayload = action.payload && action.payload.bidQuoteID ? action.payload.bidQuoteID : 0
      const newURL = BASE_URL + '/bidclarifications/' + thisPayload
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response)
        yield put({type: 'SET_BIDCLARIFICATIONS', payload: response.data})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putNewBidTradeQuote(action) {
  try {
    const newURL = BASE_URL + '/newbidtradequote'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putSelectedBidTradeQuote(action) {
  try {
    const newURL = BASE_URL + '/selectedbidtradequote'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeQuotePosted(action) {
  try {
    const newURL = BASE_URL + '/PostedBidTradeQuote'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeQuoteBaseBidReset(action) {
  try {
    const newURL = BASE_URL + '/bidTradeQuoteBaseBidReset'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putDeleteBidTradeQuote(action) {
  try {
    const newURL = BASE_URL + '/deletebidtradequote'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeGridRow(action) {
  try {
    const newURL = BASE_URL + '/bidtradegridrow'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      if (action.payload.insertRows) {
        yield put({type: 'FETCH_BIDTRADEGRID', payload: {bidTradeID: action.payload.bidTradeID, popCache: true}})
      }
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeBudgetGridRow(action) {
  try {
    const newURL = BASE_URL + '/bidtradebudgetgridrow'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteBidTradeGridRow(action) {
  try {
    const newURL = BASE_URL + '/deletebidtradeitem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteBidTradeBudgetGridRow(action) {
  try {
    const newURL = BASE_URL + '/deletebidtradebudgetitem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidClarification(action) {
  try {
    const newURL = BASE_URL + '/bidclarification'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* reorderBidTradeQuotes(action) {
  try {
    const newURL = BASE_URL + '/ReorderBidTradeQuotes/' + action.payload.bidTradeID
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* reorderBidTradeItems(action) {
  try {
    const newURL = BASE_URL + '/reorderbidtradeitems'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* reorderBidTradeItem(action) {
  try {
    const newURL = BASE_URL + '/reorderbidtradeitem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* reorderBidTradeBudgetItem(action) {
  try {
    const newURL = BASE_URL + '/reorderbidtradebudgetitem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteBidClarification(action) {
  try {
    const newURL = BASE_URL + '/deletebidclarification'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* copyClarificationTemplateToBid(action) {
  try {
    const newURL = BASE_URL + '/copyclarificationtemplatetobid/' + action.payload.bidQuoteID + '/' + action.payload.clarificationTemplateID // + "/" + action.payload.action;
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      // yield put({type: 'FETCH_BIDCLARIFICATIONS', payload: {bidQuoteID: action.payload.bidQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* copyBidQuoteChecklistToBid(action) {
  let currentBidQuoteID = yield select(bidQuoteID)
  try {
    let payload = {
      ...action.payload,
      bidQuoteID: currentBidQuoteID
    }
    const newURL = BASE_URL + '/copybidquotechecklisttobid'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(payload))
    const response = yield axios.put(newURL, payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      // yield put({ type: 'FETCH_BidQuoteChecklist', payload: currentBidQuoteID });
      yield put({type: 'FETCH_BidQuoteChecklistTemplate', payload: {bidQuoteChecklistID: action.payload.bidQuoteChecklistID, popCache: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDTRADE'}, clear: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDTRADEGRID'}, clear: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putAlternate(action) {
  try {
    const newURL = BASE_URL + '/alternate'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      // yield put({type: 'FETCH_BIDALTERNATESGRID', payload: {bidQuoteID: action.payload.bidQuoteID, popCache: true}})
      // yield put({type: 'FETCH_TRADEALTERNATESGRID', payload: {bidQuoteID: action.payload.bidQuoteID, bidTradeID: currentbidTradeID, popCache: true}})
      // if (action.payload.bidTradeID) {
      //   yield put({type: 'FETCH_BIDTRADE', payload: {bidTradeID: action.payload.bidTradeID, popCache: true}})
      // }
      // yield put({type: 'FETCH_BIDQUOTE', payload: {bidQuoteID: action.payload.bidQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putAlternateIndirect(action) {
  try {
    const newURL = BASE_URL + '/AlternateIndirect'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putAlternateTradeItem(action) {
  try {
    const newURL = BASE_URL + '/AlternateTradeItem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      // if(action.payload.bidTradeID && action.payload.bidTradeID > 0){
      //   yield put({ type: 'FETCH_TRADEALTERNATESGRID', payload: { bidQuoteID: action.payload.bidQuoteID, bidTradeID: action.payload.bidTradeID, popCache: true } });
      //   yield put({ type: 'FETCH_BIDTRADE', payload: { bidTradeID: action.payload.bidTradeID, popCache: true } });
      // }
      // yield put({ type: 'FETCH_BIDALTERNATESGRID', payload: { bidQuoteID: action.payload.bidQuoteID, popCache: true } });
      // yield put({ type: 'FETCH_BIDQUOTE', payload: { bidQuoteID: action.payload.bidQuoteID, popCache: true } });
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteAlternate(action) {
  try {
    const newURL = BASE_URL + '/deletealternate'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BIDALTERNATESGRID', payload: {bidQuoteID: action.payload.bidQuoteID, popCache: true}})
      yield put({type: 'FETCH_BIDTRADE', payload: {bidTradeID: action.payload.bidTradeID, popCache: true}})
      yield put({type: 'FETCH_BIDQUOTE', payload: {bidQuoteID: action.payload.bidQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidSheetGridRow(action) {
  try {
    const newURL = BASE_URL + '/bidsheetgridrow'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidSheetGridPrevBudgetIndirect(action) {
  try {
    const newURL = BASE_URL + '/BidSheetGridPrevBudgetIndirect'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeSummaryGridRow(action) {
  try {
    const newURL = BASE_URL + '/bidtradesummaryrow'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putGeneralRequirementsPlaceholder(action) {
  try {
    const newURL = BASE_URL + '/generalRequirementsPlaceholder'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchTagValues(action) {
  try {
    const newURL = BASE_URL + '/tagvalues/' + action.payload.context + '/' + action.payload.fkID
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.get(newURL)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'SET_TAGVALUES', payload: {context: action.payload.context.replaceAll(' ', ''), fkID: action.payload.fkID, tagValues: response.data}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putTagValues(action) {
  try {
    const newURL = BASE_URL + '/tagvalues'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'SET_TAGVALUES', payload: {context: action.payload.context, fkID: action.payload.fkID, tagValues: action.payload.tagValues}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* copyBidTradeTemplateToBid(action) {
  let currentbidTradeID = yield select(bidTradeID)
  try {
    let payload = {
      ...action.payload,
      bidTradeID: currentbidTradeID
    }
    const newURL = BASE_URL + '/CopyBidTradeTemplateToBid'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(payload))
    const response = yield axios.put(newURL, payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_BIDTRADE', payload: {bidTradeID: currentbidTradeID, popCache: true}})
      yield put({type: 'FETCH_BIDTRADEGRID', payload: {bidTradeID: currentbidTradeID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBCProjects(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/bcprojects/'
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(`${response.data.length} BC Projets loaded`)
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BCProjects', grid: response.data}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putBidQuoteBCProjectID(action) {
  let currentbidQuoteID = yield select(bidQuoteID)
  try {
    let payload = {
      ...action.payload,
      bidQuoteID: currentbidQuoteID
    }
    const newURL = BASE_URL + '/putbidquotebcprojectid'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(payload))
    const response = yield axios.put(newURL, payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      if (response.data.returnText === 'BC Project already linked to another bid.') {
        const appToast = new AppToast(response.data.returnText, action.type)
        appToast.displayErrorToast(response.data.returnText)
        yield put({type: 'SET_REFSTATUS', payload: {statusName: 'syncBCProjectStatus', statusValue: 'Sync with BC'}})
      } else {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'FETCH_BIDQUOTE', payload: {bidQuoteID: currentbidQuoteID, popCache: true}})
        yield put({type: 'FETCH_BIDTRADES', payload: {bidQuoteID: currentbidQuoteID, popCache: true}})
        yield put({type: 'FETCH_BIDTRADEGRID', payload: {bidQuoteID: currentbidQuoteID, popCache: true}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}})
        yield put({type: 'SET_REFSTATUS', payload: {statusName: 'syncBCProjectStatus', statusValue: 'Sync with BC'}})
        yield put({type: 'FETCH_BCPROJECTS', payload: {popCache: true}})

        if (response.data.returnText === 'Project linked and first trade selected.') {
          yield put({type: 'SET_BIDTRADEID', payload: response.data.newID})
          yield put({type: 'SET_FILTERS', payload: {filterName: 'bidTradeID', filterValue: response.data.newID}})
          yield put({type: 'FETCH_BIDTRADE', payload: {bidTradeID: response.data.newID}})
          yield put({type: 'FETCH_BIDTRADEGRID', payload: {bidTradeID: response.data.newID}})
        }
      }
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* unlinkBidQuoteBCProjectID(action) {
  let currentbidQuoteID = yield select(bidQuoteID)
  try {
    let payload = {
      ...action.payload,
      bidQuoteID: currentbidQuoteID
    }
    const newURL = BASE_URL + '/putbidquotebcprojectid'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(payload))
    const response = yield axios.put(newURL, payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_BCPROJECTS', payload: {popCache: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDTRADEGRID'}, clear: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDTRADESUMMARYGRID'}, clear: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDALTERNATESGRID'}, clear: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchProposals(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload && action.payload.projectID && action.payload.projectID > 0) {
        const newURL = BASE_URL + '/proposals/' + action.payload.projectID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response.data)
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Proposals', grid: response.data}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          process.env.REACT_APP_DEBUG && console.log(response)
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchProposal(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      let currentProjectHeader = yield select(projectHeader)
      const newURL = BASE_URL + '/proposal/' + action.payload.proposalID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      if (action.payload.proposalID === 0) {
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Proposal', grid: {...initialState.Proposal, projectID: currentProjectID, title: currentProjectHeader.values.name}}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 1}})
      } else {
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response.data)
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Proposal', grid: response.data[0]}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          process.env.REACT_APP_DEBUG && console.log(response)
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putProposal(action) {
  try {
    let currentProjectID = yield select(projectID)
    let currentbidQuoteID = yield select(bidQuoteID)
    const newURL = BASE_URL + '/proposal/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_PROPOSALS', payload: {projectID: currentProjectID, popCache: true}})
      yield put({type: 'FETCH_PROPOSAL', payload: {proposalID: response.data.newID > 0 ? response.data.newID : action.payload.proposal.id, popCache: true}})
      yield put({type: 'FETCH_BIDTRADES', payload: {bidQuoteID: currentbidQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteProposal(action) {
  try {
    let currentProjectID = yield select(projectID)
    let currentbidQuoteID = yield select(bidQuoteID)
    const newURL = BASE_URL + '/deleteproposal/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_PROPOSALS', payload: {projectID: currentProjectID, popCache: true}})
      yield put({type: 'FETCH_BIDTRADES', payload: {bidQuoteID: currentbidQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message   { bidQuoteID: action.payload.bidQuoteID, popCache: true }
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchSnapshots(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    let currentProjectID = yield select(projectID)
    try {
      if (currentProjectID && currentProjectID > 0) {
        const newURL = BASE_URL + '/snapshots/' + currentProjectID
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response.data)
          // if getting a snapshot, save that to it's own state
          console.log('Saving Snapshots')
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Snapshots', grid: response.data}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          process.env.REACT_APP_DEBUG && console.log(response)
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchSnapshot(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/snapshot/' + action.payload
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        if (action.payload === 0) {
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Snapshot', grid: {...initialState.Snapshot}}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 1}})
        } else {
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Snapshot', grid: response.data[0]}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        }
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putSnapshot(action) {
  let currentProjectID = yield select(projectID)
  try {
    const newURL = BASE_URL + '/snapshot/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      if (action.payload.snapshot) {
        yield put({type: 'FETCH_SNAPSHOTS', payload: {projectID: currentProjectID, popCache: true}})
        // yield put({type: 'FETCH_PROPOSAL', payload: {proposalID: action.payload.snapshot.proposalID, popCache: true}})
      }
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteSnapshot(action) {
  let currentProjectID = yield select(projectID)
  try {
    const newURL = BASE_URL + '/deletesnapshot/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_SNAPSHOTS', payload: {projectID: currentProjectID, popCache: true}})
    } else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* updateSnapshot(action) {
  let currentProjectID = yield select(projectID)
  try {
    const newURL = BASE_URL + '/updatesnapshot/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_SNAPSHOTS', payload: {projectID: currentProjectID, popCache: true}})
    } else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeNote(action) {
  try {
    let currentbidQuoteID = yield select(bidQuoteID)
    let currentprojectID = yield select(projectID)
    const newURL = BASE_URL + '/bidtradenote/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BIDTRADESUMMARYGRID', payload: {bidQuoteID: currentbidQuoteID, projectID: currentprojectID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeItemNote(action) {
  try {
    const newURL = BASE_URL + '/bidTradeItemNote/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BIDTRADEGRID', payload: {bidTradeID: action.payload.bidTradeID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeQuote(action) {
  let currentbidQuoteID = yield select(bidQuoteID)
  try {
    const newURL = BASE_URL + '/bidTradeQuote/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BIDTRADEQUOTES', payload: {bidTradeID: action.payload.bidTradeQuote.bidTradeID, popCache: true}})
      yield put({type: 'FETCH_BIDTRADEQUOTE', payload: {bidTradeQuoteID: action.payload.bidTradeQuote.bidTradeQuoteID, popCache: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      // yield put({ type: 'SET_PRECONCACHE', payload: { caller: { type: 'FETCH_BIDSHEETGRID' }, clear: true }});
      yield put({type: 'FETCH_BIDSHEETGRID', payload: {bidQuoteID: currentbidQuoteID, popCache: true}})
      yield put({type: 'FETCH_BIDTRADE', payload: {bidTradeID: action.payload.bidTradeQuote.bidTradeID, popCache: true}})
      yield put({type: 'FETCH_BIDTRADES', payload: {bidQuoteID: currentbidQuoteID, popCache: true}})
      yield put({type: 'FETCH_BIDTRADEGRID', payload: {bidTradeID: action.payload.bidTradeQuote.bidTradeID, popCache: true}})
      yield put({type: 'FETCH_BIDTRADEQUOTE', payload: {bidTradeQuoteID: action.payload.bidTradeQuote.bidTradeQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBidQuoteAreas(action) {
  try {
    const thisPayload = action.payload && action.payload.bidQuoteID ? action.payload.bidQuoteID : 0
    const newURL = BASE_URL + '/BidQuoteAreas/' + thisPayload
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.get(newURL)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BidQuoteAreas', grid: response.data}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidQuoteArea(action) {
  try {
    const newURL = BASE_URL + '/BidQuoteArea/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteBidQuoteArea(action) {
  try {
    const newURL = BASE_URL + '/DeleteBidQuoteArea/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BIDQUOTEAREAS', payload: {bidQuoteID: action.payload.bidQuoteArea.bidQuoteId, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* syncChecklistToBidQuote(action) {
  let currentbidTradeID = yield select(bidTradeID)
  try {
    const newURL = BASE_URL + '/syncChecklistToBidQuote/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_REFSTATUS', payload: {statusName: 'syncChecklistToBidQuote', statusValue: 'Not Started'}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}})
      yield put({type: 'FETCH_BIDTRADE', payload: {bidTradeID: currentbidTradeID, popCache: true}})
      yield put({type: 'FETCH_BIDTRADEGRID', payload: {bidTradeID: currentbidTradeID, popCache: true}})
      yield put({type: 'FETCH_BidQuoteChecklistTemplate', payload: {bidQuoteChecklistID: action.payload.bidQuoteChecklistItem.bidQuoteChecklistID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putFileType(action) {
  // ok lets update the server
  try {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
    const newURL = BASE_URL + '/filetype'
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok saved')
      const appToast = new AppToast(undefined, undefined, `Saved`)
      appToast.displaySuccessToast()
      yield put({type: 'FETCH_FILES', payload: {projectID: action.payload.projectID, bidTradeID: action.payload.bidTradeID, bidTradeQuoteID: action.payload.bidTradeQuoteID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      // TODO: This message is not mapped to the action type
      const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: `REDUX Function, API call has errored here is the payload ${action}`, Message: error.message}})
  }
}

export function* fetchBidUnitPricesGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/BidUnitPrices/' + action.payload.bidQuoteID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BidUnitPricesGrid', grid: response.data}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchTradeUnitPricesGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload && action.payload.bidQuoteID && action.payload.bidQuoteID === 0) {
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'TradeUnitPricesGrid', grid: []}})
      } else {
        const newURL = BASE_URL + '/tradeUnitPricesGrid/' + action.payload.bidQuoteID + '/' + action.payload.bidTradeID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response)
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'TradeUnitPricesGrid', grid: response.data}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchUnitPrice(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload.unitPriceID > 0) {
        const newURL = BASE_URL + '/UnitPrice/' + action.payload.unitPriceID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response.data)
          if (response.data.length > 0) {
            yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'UnitPrice', grid: response.data[0]}})
            yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
          } else {
            yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'UnitPrice', grid: initialState.UnitPrice}})
            yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 0}})
          }
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
          })
        }
      } else {
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'UnitPrice', grid: initialState.UnitPrice}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 0}})
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putUnitPrice(action) {
  try {
    const newURL = BASE_URL + '/unitPrice/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)

      yield put({type: 'FETCH_UNITPRICES'})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: action, clear: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteUnitPrice(action) {
  try {
    const newURL = BASE_URL + '/deleteunitprice/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_UNITPRICES'})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putUnitPriceTradeItem(action) {
  try {
    const newURL = BASE_URL + '/UnitPriceTradeItem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      // if(action.payload.bidTradeID && action.payload.bidTradeID > 0){
      //   yield put({ type: 'FETCH_TRADEALTERNATESGRID', payload: { bidQuoteID: action.payload.bidQuoteID, bidTradeID: action.payload.bidTradeID, popCache: true } });
      //   yield put({ type: 'FETCH_BIDTRADE', payload: { bidTradeID: action.payload.bidTradeID, popCache: true } });
      // }
      // yield put({ type: 'FETCH_BIDALTERNATESGRID', payload: { bidQuoteID: action.payload.bidQuoteID, popCache: true } });
      // yield put({ type: 'FETCH_BIDQUOTE', payload: { bidQuoteID: action.payload.bidQuoteID, popCache: true } });
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putUnitPriceIndirect(action) {
  try {
    const newURL = BASE_URL + '/UnitPriceIndirect'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putUnitPriceSelectTradePrice(action) {
  try {
    const newURL = BASE_URL + '/UnitPriceSelectTradePrice'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBidAllowancesGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/BidAllowancesGrid/' + action.payload.bidQuoteID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'BidAllowancesGrid', grid: response.data}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchAllowance(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload.allowanceID > 0) {
        const newURL = BASE_URL + '/Allowance/' + action.payload.bidQuoteID + '/' + action.payload.allowanceID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response.data)
          if (response.data.length > 0) {
            yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Allowance', grid: response.data[0]}})
            yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
          } else {
            yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Allowance', grid: initialState.Allowance}})
            yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 0}})
          }
        } // API completed with 200, however there is an error message
        else {
          process.env.REACT_APP_DEBUG && console.log(response)
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
          })
        }
      } else {
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'Allowance', grid: {...initialState.Allowance, type: action.payload.type}}})
        yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: 0}})
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchTradeAllowancesGrid(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      if (action.payload && action.payload.bidQuoteID && action.payload.bidQuoteID === 0) {
        yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'TradeAllowancesGrid', grid: []}})
      } else {
        const newURL = BASE_URL + '/TradeAllowancesGrid/' + action.payload.bidQuoteID + '/' + action.payload.bidTradeID
        process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
        const response = yield axios.get(newURL)
        process.env.REACT_APP_DEBUG && console.log('Response: ' + action.type + ' :' + response.status + ' ' + response.statusText)
        if (response.status === 200 && response.data.returnStatus !== 'Error') {
          process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
          process.env.REACT_APP_DEBUG && console.log(response)
          yield put({type: 'SET_PRECON_COLLECTION', payload: {collectionName: 'TradeAllowancesGrid', grid: response.data}})
          yield put({type: 'SET_PRECONCACHE', payload: {caller: action, count: response.data.length}})
        } // API completed with 200, however there is an error message
        else {
          const appToast = new AppToast(undefined, action.type)
          appToast.displayErrorToast()
          yield put({
            type: 'PUT_ERROR',
            payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
          })
        }
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putAllowance(action) {
  try {
    const newURL = BASE_URL + '/allowance/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putAllowanceTradeItem(action) {
  try {
    const newURL = BASE_URL + '/AllowanceTradeItem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteAllowance(action) {
  try {
    const newURL = BASE_URL + '/deleteallowance/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* resetStagingData(action) {
  let currentListFilter = yield select(listFilters)
  try {
    const newURL = BASE_URL + '/resetStagingData/' + action.payload.bidID + '/' + action.payload.bidQuoteID
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_REFSTATUS', payload: {statusName: 'resetStagingData', statusValue: 'Complete'}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDTRADE'}, clear: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDTRADEGRID'}, clear: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDALTERNATESGRID'}, clear: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BIDCLARIFICATIONS'}, clear: true}})
      yield put({type: 'SET_PRECONCACHE', payload: {caller: {type: 'FETCH_BidQuoteChecklistTemplate'}, clear: true}})

      yield put({type: 'SET_BIDQUOTEID', payload: parseInt(response.data.newID)})
      yield put({type: 'FETCH_BIDQUOTE', payload: {bidQuoteID: parseInt(response.data.newID), popCache: true}})
      yield put({type: 'FETCH_ProjectsGrid', payload: {status: currentListFilter.projectsGridStatus}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

// ************** Action Creators, return action objects with payloads **************
export const actions = {
  setCache: (payload) => ({type: actionTypes.SET_PRECONCACHE, payload: payload}),
  clearCache: (payload) => ({type: actionTypes.SET_PRECONCACHE, payload: {caller: {type: payload?.type}, clear: true}}),
  setPreconTemp: (payload) => ({type: actionTypes.SET_PRECON_TEMP, payload: payload}),
  setPreconUpdates: (payload) => ({type: actionTypes.SET_PRECON_UPDATES, payload: payload}),
  setPreconUpdatesActual: (payload) => ({type: actionTypes.SET_PRECON_UPDATES_ACTUAL, payload: payload}),
  setPreconCollection: (payload) => ({type: actionTypes.SET_PRECON_COLLECTION, payload: payload}),
  setBidID: (payload) => ({type: actionTypes.SET_BIDID, payload: payload}),
  setBidQuoteID: (bidQuoteID) => ({type: actionTypes.SET_BIDQUOTEID, payload: bidQuoteID}),
  setBidQuote: (payload) => ({type: actionTypes.SET_BIDQUOTE, payload: payload}),
  setBidTradeID: (payload) => ({type: actionTypes.SET_BIDTRADEID, payload: payload}),
  setBidTrade: (payload) => ({type: actionTypes.SET_BIDTRADE, payload: payload}),
  setActionParams: (payload) => ({type: actionTypes.SET_ACTION_PARAMS, payload: payload}),
  putBidQuoteBCProjectID: (payload) => ({type: actionTypes.PUT_BIDQUOTE_BCPROJECTID, payload: payload}),
  unlinkBidQuoteBCProjectID: (payload) => ({type: actionTypes.PUT_BIDQUOTE_UNLINK_BCPROJECTID, payload: payload}),
  fetchBCProjects: (payload) => ({type: actionTypes.FETCH_BCPROJECTS, payload: payload}),

  clearSubcontractor: (payload) => ({type: actionTypes.CLEAR_SUBCONTRACTOR, payload: payload}),
  putSubcontractor: (payload) => ({type: actionTypes.PUT_SUBCONTRACTOR, payload: payload}),
  fetchSubcontractor: (payload) => ({type: actionTypes.FETCH_SUBCONTRACTOR, payload: payload}),
  fetchSubcontractors: (payload) => ({type: actionTypes.FETCH_SUBCONTRACTORS, payload: payload}),
  fetchSubcontractorContacts: (payload) => ({type: actionTypes.FETCH_SUBCONTRACTOR_CONTACTS, payload: payload}),
  fetchSubcontractorOffices: (payload) => ({type: actionTypes.FETCH_SUBCONTRACTOR_OFFICES, payload: payload}),
  fetchSubcontractorBidHistory: (payload) => ({type: actionTypes.FETCH_SUBCONTRACTOR_BIDHISTORY, payload: payload}),
  fetchBidClarifications: (payload) => ({type: actionTypes.FETCH_BIDCLARIFICATIONS, payload: payload}),
  fetchBidTrades: (payload) => ({type: actionTypes.FETCH_BIDTRADES, payload: payload}),
  fetchBidTradesTemp: (payload) => ({type: actionTypes.FETCH_BIDTRADES_TEMP, payload: payload}),
  fetchBidTradesDeleted: (payload) => ({type: actionTypes.FETCH_BIDTRADES_DELETED, payload: payload}),
  fetchBidTradeSummaryGrid: (payload) => ({type: actionTypes.FETCH_BIDTRADESUMMARYGRID, payload: payload}),
  fetchFiles: (payload) => ({type: actionTypes.FETCH_FILES, payload: payload}),
  postFile: (payload) => ({type: actionTypes.POST_FILE, payload: payload}),
  deleteFile: (payload) => ({type: actionTypes.DELETE_FILE, payload: payload}),
  fetchFile: (payload) => ({type: actionTypes.FETCH_FILE, payload: payload}),
  putFileExportFlag: (payload) => ({type: actionTypes.PUT_FILEEXPORTFLAG, payload: payload}),
  updateFileType: (payload) => ({type: actionTypes.PUT_FILETYPE, payload: payload}),

  putSubcontractorContacts: (payload) => ({type: actionTypes.PUT_SUBCONTRACTOR_CONTACTS, payload: payload}),
  putSubcontractorOffices: (payload) => ({type: actionTypes.PUT_SUBCONTRACTOR_OFFICES, payload: payload}),
  putBidClarification: (payload) => ({type: actionTypes.PUT_BIDCLARIFICATION, payload: payload}),
  setClarifications: (payload) => ({type: actionTypes.SET_BIDCLARIFICATIONS, payload: payload}),
  deleteBidClarification: (payload) => ({type: actionTypes.DELETE_BIDCLARIFICATION, payload: payload}),
  copyClarificationTemplateToBid: (payload) => ({type: actionTypes.COPY_CLARIFICATIONSTEMPLATE_TO_BID, payload: payload}),
  putAlternate: (payload) => ({type: actionTypes.PUT_ALTERNATE, payload: payload}),
  putAlternateIndirect: (payload) => ({type: actionTypes.PUT_ALTERNATE_INDIRECT, payload: payload}),
  deleteAlternate: (payload) => ({type: actionTypes.DELETE_ALTERNATE, payload: payload}),
  putAlternateTradeItem: (payload) => ({type: actionTypes.PUT_ALTERNATE_TRADE_ITEM, payload: payload}),

  putCopyBidQuote: (payload) => ({type: actionTypes.PUT_COPYBIDQUOTE, payload: payload}),
  putCreateBidQuote: (payload) => ({type: actionTypes.PUT_CREATEBIDQUOTE, payload: payload}),
  putBidQuote: (payload) => ({type: actionTypes.PUT_BIDQUOTE, payload: payload}),
  fetchBidQuote: (payload) => ({type: actionTypes.FETCH_BIDQUOTE, payload: payload}),
  fetchTagValues: (payload) => ({type: actionTypes.FETCH_TAGVALUES, payload: payload}),
  putTagValues: (payload) => ({type: actionTypes.PUT_TAGVALUES, payload: payload}),
  putBidTrade: (payload) => ({type: actionTypes.PUT_BIDTRADE, payload: payload}),
  deleteBidTrade: (payload) => ({type: actionTypes.DELETE_BIDTRADE, payload: payload}),
  fetchBidTrade: (payload) => ({type: actionTypes.FETCH_BIDTRADE, payload: payload}),
  fetchBidTradeTemp: (payload) => ({type: actionTypes.FETCH_BIDTRADE_TEMP, payload: payload}),
  fetchBidTradeGrid: (payload) => ({type: actionTypes.FETCH_BIDTRADEGRID, payload: payload}),
  fetchBidTradeGridTemp: (payload) => ({type: actionTypes.FETCH_BIDTRADEGRID_TEMP, payload: payload}),
  fetchBidTradeBudgetGrid: (payload) => ({type: actionTypes.FETCH_BIDTRADE_BUDGETGRID, payload: payload}),
  fetchBidTradeBudgetGridTemp: (payload) => ({type: actionTypes.FETCH_BIDTRADE_BUDGETGRID_TEMP, payload: payload}),
  restoreBidTrade: (payload) => ({type: actionTypes.RESTORE_BIDTRADE, payload: payload}),
  fetchBidSheetGrid: (payload) => ({type: actionTypes.FETCH_BIDSHEETGRID, payload: payload}),
  fetchBidSheetGridTemp: (payload) => ({type: actionTypes.FETCH_BIDSHEETGRID_TEMP, payload: payload}),
  fetchBidTradeQuote: (payload) => ({type: actionTypes.FETCH_BIDTRADEQUOTE, payload: payload}),
  fetchBidAlternatesGrid: (payload) => ({type: actionTypes.FETCH_BIDALTERNATESGRID, payload: payload}),
  fetchTradeAlternatesGrid: (payload) => ({type: actionTypes.FETCH_TRADEALTERNATESGRID, payload: payload}),
  fetchAlternate: (payload) => ({type: actionTypes.FETCH_ALTERNATE, payload: payload}),
  setAlternateItems: (payload) => ({type: actionTypes.SET_ALTERNATE_ITEMS, payload: payload}),
  clearBidSheetGridTemp: (payload) => ({type: actionTypes.CLEAR_BIDSHEETGRID_TEMP, payload: payload}),
  clearBidSummaryGridTotalsTemp: (payload) => ({type: actionTypes.CLEAR_BIDSUMMARY_GRIDTOTALS_TEMP, payload: payload}),
  fetchBidQuoteAreas: (payload) => ({type: actionTypes.FETCH_BIDQUOTEAREAS, payload: payload}),
  putBidQuoteArea: (payload) => ({type: actionTypes.PUT_BIDQUOTEAREA, payload: payload}),
  deleteBidQuoteArea: (payload) => ({type: actionTypes.DELETE_BIDQUOTEAREA, payload: payload}),

  fetchBidUnitPricesGrid: (payload) => ({type: actionTypes.FETCH_BIDUNITPRICESGRID, payload: payload}),
  fetchTradeUnitPricesGrid: (payload) => ({type: actionTypes.FETCH_TRADEUNITPRICESGRID, payload: payload}),
  fetchUnitPrice: (payload) => ({type: actionTypes.FETCH_UNITPRICE, payload: payload}),
  putUnitPrice: (payload) => ({type: actionTypes.PUT_UNITPRICE, payload: payload}),
  deleteUnitPrice: (payload) => ({type: actionTypes.DELETE_UNITPRICE, payload: payload}),
  putUnitPriceTradeItem: (payload) => ({type: actionTypes.PUT_UNITPRICE_TRADEITEM, payload: payload}),
  putUnitPriceIndirect: (payload) => ({type: actionTypes.PUT_UNITPRICE_INDIRECT, payload: payload}),
  putUnitPriceSelectTradePrice: (payload) => ({type: actionTypes.PUT_UNITPRICE_SELECTTRADEPRICE, payload: payload}),

  fetchBidAllowancesGrid: (payload) => ({type: actionTypes.FETCH_BIDALLOWANCESGRID, payload: payload}),
  fetchTradeAllowancesGrid: (payload) => ({type: actionTypes.FETCH_TRADEALLOWANCESGRID, payload: payload}),
  fetchAllowance: (payload) => ({type: actionTypes.FETCH_ALLOWANCE, payload: payload}),
  putAllowance: (payload) => ({type: actionTypes.PUT_ALLOWANCE, payload: payload}),
  deleteAllowance: (payload) => ({type: actionTypes.DELETE_ALLOWANCE, payload: payload}),
  putAllowanceTradeItem: (payload) => ({type: actionTypes.PUT_ALLOWANCE_TRADE_ITEM, payload: payload}),
  setAllowanceItems: (payload) => ({type: actionTypes.SET_ALLOWANCE_ITEMS, payload: payload}),

  putGeneralRequirementsPlaceholder: (payload) => ({type: actionTypes.PUT_BIDTRADE_GENERALREQUIREMENTS_PLACEHOLDER, payload: payload}),
  putBidTradeSummaryGridRow: (payload) => ({type: actionTypes.PUT_BIDTRADESUMMARYGRIDROW, payload: payload}),
  putBidSheetGridRow: (payload) => ({type: actionTypes.PUT_BIDSHEETGRIDROW, payload: payload}),
  putBidSheetGridPrevBudgetIndirect: (payload) => ({type: actionTypes.PUT_BIDSHEETGRID_PREVBUDGET_INDIRECTROW, payload: payload}),
  putSelectedBidTradeQuote: (payload) => ({type: actionTypes.PUT_SELECTEDBIDTRADEQUOTE, payload: payload}),
  putBidTradeQuotePosted: (payload) => ({type: actionTypes.PUT_BIDTRADEQUOTE_POSTED, payload: payload}),
  putBidTradeQuoteBaseBidReset: (payload) => ({type: actionTypes.PUT_BIDTRADEQUOTE_BASEBID_RESET, payload: payload}),
  putBidTradeQuote: (payload) => ({type: actionTypes.PUT_BIDTRADEQUOTE, payload: payload}),
  putNewBidTradeQuote: (payload) => ({type: actionTypes.PUT_NEWBIDTRADEQUOTE, payload: payload}),
  putDeleteBidTradeQuote: (payload) => ({type: actionTypes.PUT_DELETEBIDTRADEQUOTE, payload: payload}),
  putBidTradeGridRow: (payload) => ({type: actionTypes.PUT_BIDTRADEGRIDROW, payload: payload}),
  putBidTradeBudgetGridRow: (payload) => ({type: actionTypes.PUT_BIDTRADEBUDGET_GRIDROW, payload: payload}),
  setBidTradeGrid: (payload) => ({type: actionTypes.SET_BIDTRADEGRID, payload: payload}),
  setBidTradeGridBudget: (payload) => ({type: actionTypes.SET_BIDTRADEGRIDBUDGET, payload: payload}),
  deleteBidTradeGridRow: (payload) => ({type: actionTypes.PUT_DELETEBIDTRADEGRIDROW, payload: payload}),
  deleteBidTradeBudgetGridRow: (payload) => ({type: actionTypes.PUT_DELETEBIDTRADE_BUDGET_GRIDROW, payload: payload}),

  reorderBidTradeQuotes: (payload) => ({type: actionTypes.REORDER_BIDTRADEQUOTES, payload: payload}),
  reorderBidTradeItems: (payload) => ({type: actionTypes.REORDER_BIDTRADEITEMS, payload: payload}),
  reorderBidTradeItem: (payload) => ({type: actionTypes.REORDER_BIDTRADEITEM, payload: payload}),
  reorderBidTradeBudgetItem: (payload) => ({type: actionTypes.REORDER_BIDTRADE_BUDGETITEM, payload: payload}),
  copyBidTradeTemplateToBid: (payload) => ({type: actionTypes.COPY_BIDTRADETEMPLATE_TOBID, payload: payload}),

  fetchProposals: (payload) => ({type: actionTypes.FETCH_PROPOSALS, payload: payload}),
  fetchProposal: (payload) => ({type: actionTypes.FETCH_PROPOSAL, payload: payload}),
  putProposal: (payload) => ({type: actionTypes.PUT_PROPOSAL, payload: payload}),
  deleteProposal: (payload) => ({type: actionTypes.DELETE_PROPOSAL, payload: payload}),
  fetchSnapshots: (payload) => ({type: actionTypes.FETCH_SNAPSHOTS, payload: payload}),
  fetchSnapshot: (payload) => ({type: actionTypes.FETCH_SNAPSHOT, payload: payload}),
  putSnapshot: (payload) => ({type: actionTypes.PUT_SNAPSHOT, payload: payload}),
  deleteSnapshot: (payload) => ({type: actionTypes.DELETE_SNAPSHOT, payload: payload}),
  updateSnapshot: (payload) => ({type: actionTypes.UPDATE_SNAPSHOT, payload: payload}),
  putBidTradeNote: (payload) => ({type: actionTypes.PUT_BIDTRADENOTE, payload: payload}),
  putBidTradeItemNote: (payload) => ({type: actionTypes.PUT_BIDTRADEITEM_NOTE, payload: payload}),
  copyBidQuoteChecklistToBid: (payload) => ({type: actionTypes.COPY_BidQuoteChecklist_TOBID, payload: payload}),
  syncChecklistToBidQuote: (payload) => ({type: actionTypes.SYNC_ChecklistToBidQuote, payload: payload}),

  resetStagingData: (payload) => ({type: actionTypes.RESET_STAGINGDATA, payload: payload})
}

// ************** Action Watchers **************
function* actionWatcher() {
  yield takeLatest('SET_BIDQUOTEID', fetchPreconReferences)
  yield takeLatest('FETCH_PRECON_REFERENCES', fetchPreconReferences)
  yield takeLatest('FETCH_SUBCONTRACTOR', fetchSubcontractor)
  yield takeLatest('FETCH_SUBCONTRACTORS', fetchSubcontractors)
  yield takeLatest('FETCH_SUBCONTRACTOR_CONTACTS', fetchSubcontractorContacts)
  yield takeLatest('FETCH_SUBCONTRACTOR_OFFICES', fetchSubcontractorOffices)
  yield takeLatest('FETCH_SUBCONTRACTOR_BIDHISTORY', fetchSubcontractorBidHistory)
  yield takeLatest('FETCH_BIDCLARIFICATIONS', fetchBidClarifications)
  yield takeLatest('FETCH_BIDALTERNATESGRID', fetchBidAlternatesGrid)
  yield takeLatest('FETCH_TRADEALTERNATESGRID', fetchTradeAlternatesGrid)
  yield takeLatest('FETCH_ALTERNATE', fetchAlternate)

  yield takeLatest('FETCH_TAGVALUES', fetchTagValues)
  yield takeLatest('FETCH_BCPROJECTS', fetchBCProjects)
  yield takeLatest('PUT_BIDQUOTE_BCPROJECTID', putBidQuoteBCProjectID)
  yield takeLatest('PUT_BIDQUOTE_UNLINK_BCPROJECTID', unlinkBidQuoteBCProjectID)

  yield takeLatest('FETCH_BIDQUOTE', fetchBidQuote)
  yield takeLatest('PUT_BIDQUOTE', putBidQuote)
  yield takeLatest('PUT_BIDTRADE', putBidTrade)
  yield takeLatest('DELETE_BIDTRADE', deleteBidTrade)
  yield takeLatest('FETCH_BIDTRADES', fetchBidTrades)
  yield takeLatest('FETCH_BIDTRADES_TEMP', fetchBidTradesTemp)
  yield takeLatest('FETCH_BIDTRADES_DELETED', fetchBidTradesDeleted)
  yield takeLatest('RESTORE_BIDTRADE', restoreBidTrade)
  yield takeLatest('FETCH_BIDTRADE', fetchBidTrade)
  yield takeLatest('FETCH_BIDTRADE_TEMP', fetchBidTradeTemp)
  yield takeLatest('FETCH_BIDTRADESUMMARYGRID', fetchBidTradeSummaryGrid)
  yield takeLatest('FETCH_BIDTRADEGRID', fetchBidTradeGrid)
  yield takeLatest('FETCH_BIDTRADEGRID_TEMP', fetchBidTradeGridTemp)
  yield takeLatest('FETCH_BIDTRADE_BUDGETGRID', fetchBidTradeBudgetGrid)
  yield takeLatest('FETCH_BIDTRADE_BUDGETGRID_TEMP', fetchBidTradeBudgetGridTemp)
  yield takeLatest('FETCH_BIDSHEETGRID', fetchBidSheetGrid)
  yield takeLatest('FETCH_BIDSHEETGRID_TEMP', fetchBidSheetGridTemp)
  yield takeLatest('FETCH_BIDTRADEQUOTE', fetchBidTradeQuote)
  yield takeLatest('FETCH_FILES', fetchFiles)
  yield takeLatest('POST_FILE', postFile)
  yield takeLatest('PUT_FILETYPE', putFileType)
  yield takeLatest('DELETE_FILE', deleteFile)
  yield takeLatest('FETCH_FILE', fetchFile)
  yield takeLatest('PUT_FILEEXPORTFLAG', putFileExportFlag)
  yield takeLatest('CLEAR_BIDSHEETGRID_TEMP', clearBidSheetGridTemp)
  yield takeLatest('FETCH_BIDQUOTEAREAS', fetchBidQuoteAreas)
  yield takeLatest('PUT_BIDQUOTEAREA', putBidQuoteArea)
  yield takeLatest('DELETE_BIDQUOTEAREA', deleteBidQuoteArea)

  yield takeLatest('PUT_BIDTRADE_GENERALREQUIREMENTS_PLACEHOLDER', putGeneralRequirementsPlaceholder)
  yield takeLatest('PUT_BIDTRADESUMMARYGRIDROW', putBidTradeSummaryGridRow)
  yield takeLatest('PUT_BIDSHEETGRIDROW', putBidSheetGridRow)
  yield takeLatest('PUT_BIDSHEETGRID_PREVBUDGET_INDIRECTROW', putBidSheetGridPrevBudgetIndirect)
  yield takeLatest('PUT_SELECTEDBIDTRADEQUOTE', putSelectedBidTradeQuote)
  yield takeLatest('PUT_BIDTRADEQUOTE_POSTED', putBidTradeQuotePosted)

  yield takeLatest('PUT_BIDTRADEQUOTE_BASEBID_RESET', putBidTradeQuoteBaseBidReset)
  yield takeLatest('PUT_BIDTRADEQUOTE', putBidTradeQuote)
  yield takeLatest('PUT_NEWBIDTRADEQUOTE', putNewBidTradeQuote)
  yield takeLatest('PUT_DELETEBIDTRADEQUOTE', putDeleteBidTradeQuote)
  yield takeEvery('PUT_BIDTRADEGRIDROW', putBidTradeGridRow)
  yield takeEvery('PUT_BIDTRADEBUDGET_GRIDROW', putBidTradeBudgetGridRow)
  yield takeEvery('PUT_DELETEBIDTRADEGRIDROW', deleteBidTradeGridRow)
  yield takeEvery('PUT_DELETEBIDTRADE_BUDGET_GRIDROW', deleteBidTradeBudgetGridRow)
  yield takeLatest('REORDER_BIDTRADEQUOTES', reorderBidTradeQuotes)
  yield takeLatest('REORDER_BIDTRADEITEMS', reorderBidTradeItems)
  yield takeLatest('REORDER_BIDTRADEITEM', reorderBidTradeItem)
  yield takeLatest('REORDER_BIDTRADE_BUDGETITEM', reorderBidTradeBudgetItem)
  yield takeEvery('PUT_BIDCLARIFICATION', putBidClarification)
  yield takeEvery('DELETE_BIDCLARIFICATION', deleteBidClarification)
  yield takeLatest('COPY_CLARIFICATIONSTEMPLATE_TO_BID', copyClarificationTemplateToBid)
  yield takeLatest('COPY_BIDTRADETEMPLATE_TOBID', copyBidTradeTemplateToBid)

  yield takeLatest('PUT_ALTERNATE', putAlternate)
  yield takeLatest('PUT_ALTERNATE_TRADE_ITEM', putAlternateTradeItem)
  yield takeLatest('PUT_ALTERNATE_INDIRECT', putAlternateIndirect)
  yield takeLatest('DELETE_ALTERNATE', deleteAlternate)

  yield takeLatest('FETCH_BIDUNITPRICESGRID', fetchBidUnitPricesGrid)
  yield takeLatest('FETCH_TRADEUNITPRICESGRID', fetchTradeUnitPricesGrid)
  yield takeLatest('FETCH_UNITPRICE', fetchUnitPrice)
  yield takeLatest('PUT_UNITPRICE', putUnitPrice)
  yield takeLatest('DELETE_UNITPRICE', deleteUnitPrice)
  yield takeLatest('PUT_UNITPRICE_TRADEITEM', putUnitPriceTradeItem)
  yield takeLatest('PUT_UNITPRICE_INDIRECT', putUnitPriceIndirect)
  yield takeLatest('PUT_UNITPRICE_SELECTTRADEPRICE', putUnitPriceSelectTradePrice)

  yield takeLatest('FETCH_BIDALLOWANCESGRID', fetchBidAllowancesGrid)
  yield takeLatest('FETCH_TRADEALLOWANCESGRID', fetchTradeAllowancesGrid)
  yield takeLatest('FETCH_ALLOWANCE', fetchAllowance)
  yield takeLatest('PUT_ALLOWANCE', putAllowance)
  yield takeLatest('DELETE_ALLOWANCE', deleteAllowance)
  yield takeLatest('PUT_ALLOWANCE_TRADE_ITEM', putAllowanceTradeItem)

  yield takeLatest('PUT_SUBCONTRACTOR', putSubcontractor)
  yield takeLatest('PUT_SUBCONTRACTOR_CONTACTS', putSubcontractorContacts)
  yield takeLatest('PUT_SUBCONTRACTOR_OFFICES', putSubcontractorOffices)
  yield takeLatest('PUT_COPYBIDQUOTE', putCopyBidQuote)
  yield takeLatest('PUT_CREATEBIDQUOTE', putCreateBidQuote)
  yield takeLatest('PUT_TAGVALUES', putTagValues)

  yield takeEvery('FETCH_PROPOSALS', fetchProposals)
  yield takeLatest('FETCH_PROPOSAL', fetchProposal)
  yield takeLatest('PUT_PROPOSAL', putProposal)
  yield takeLatest('DELETE_PROPOSAL', deleteProposal)

  yield takeLatest('FETCH_SNAPSHOTS', fetchSnapshots)
  yield takeLatest('FETCH_SNAPSHOT', fetchSnapshot)
  yield takeLatest('PUT_SNAPSHOT', putSnapshot)
  yield takeLatest('DELETE_SNAPSHOT', deleteSnapshot)
  yield takeLatest('UPDATE_SNAPSHOT', updateSnapshot)
  yield takeLatest('PUT_BIDTRADENOTE', putBidTradeNote)
  yield takeLatest('PUT_BIDTRADEITEM_NOTE', putBidTradeItemNote)

  yield takeLatest('COPY_BidQuoteChecklist_TOBID', copyBidQuoteChecklistToBid)
  yield takeLatest('SYNC_ChecklistToBidQuote', syncChecklistToBidQuote)
  yield takeLatest('RESET_STAGINGDATA', resetStagingData)
}

//  ************** Saga **************
export function* saga() {
  yield all([actionWatcher()])
}
