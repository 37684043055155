const actionTypes = {
  INIT_FILTERS: 'INIT_FILTERS',
  SET_FILTERS: 'SET_FILTERS',

  STOP_REHYDRATE: 'STOP_REHYDRATE',
  SET_ADACCESS: 'SET_ADACCESS',
  SET_USERROLE: 'SET_USERROLE',
  SET_VALIDUSER: 'SET_VALIDUSER',
  SET_ASSUMEDUSER: 'SET_ASSUMEDUSER',
  AAD_ACQUIRED_ID_TOKEN_SUCCESS: 'AAD_ACQUIRED_ID_TOKEN_SUCCESS',

  AAD_INITIALIZED: 'AAD_INITIALIZED',
  AAD_LOGIN_SUCCESS: 'AAD_LOGIN_SUCCESS',
  SET_ACCOUNT: 'SET_ACCOUNT',
  SET_ACCESSTOKEN: 'SET_ACCESSTOKEN',
  SET_AUTHTOKEN: 'SET_AUTHTOKEN',
  SET_AUTHTOKEN_ONLY: 'SET_AUTHTOKEN_ONLY',
  SET_CLEAR_AUTHTOKEN: 'SET_CLEAR_AUTHTOKEN',
  GET_USERROLE: 'GET_USERROLE',

  PUT_RECENTPROJECTS: 'PUT_RECENTPROJECTS',
  SET_RECENTPROJECTS: 'SET_RECENTPROJECTS',
  GET_RECENTPROJECTS: 'GET_RECENTPROJECTS',

  ATTEMPT_REAUTH: 'ATTEMPT_REAUTH',
  RESET_REAUTH: 'RESET_REAUTH'
}

export default actionTypes
