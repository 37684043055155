import {useEffect, useMemo, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Reference from '../../store/ducks/reference.duck'
import * as Auth from '../../store/ducks/auth.duck'
import {Portlet, PortletBody} from '../../partials/content/Portlet'
import Grid from '@mui/material/Grid'
import Button from 'react-bootstrap/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextField from '@mui/material/TextField'
import Popover from '@mui/material/Popover'
import AppToast from '../../helpers/AppToast'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {Link} from 'react-router-dom'

function TestExecution() {
  const dispatch = useDispatch()
  const authFilters = useSelector((state) => state.auth.Filters)
  const fullscreen = useSelector((state) => state.auth.Filters.fullscreen)
  const testCurrentTestScriptExecutionID = authFilters.testCurrentTestScriptExecutionID
  const steps = useSelector((state) => state.reference.TestScriptExecutionSteps)
  const className = useMemo(() => `bid-header ${fullscreen ? 'mb-[5px]' : 'mb-[20px]'}`, [fullscreen])

  const FailButtonId = 'fail-button-popover'
  const FailContainerId = 'fail-container'
  const [notes, setNotes] = useState('')

  const step = steps.find((step) => step.status === 'Not Started')

  useEffect(() => {
    if (testCurrentTestScriptExecutionID && authFilters.testingEnabled) {
      dispatch(Reference.actions.fetchTestExecutionSteps({testScriptExecutionID: testCurrentTestScriptExecutionID}))
    }
  }, [authFilters.testingEnabled, dispatch, testCurrentTestScriptExecutionID])

  const handlePass = () => {
    dispatch(Reference.actions.putTestStepExecutionResult({testScriptExecution: {...step, status: 'Pass'}}))
  }

  //** Popover */
  const [anchorEl, setAnchorEl] = useState(null)

  const handleFail = (event) => {
    setNotes('')
    setAnchorEl(event.currentTarget)
  }

  function submitFail() {
    if (notes !== '') {
      dispatch(Reference.actions.putTestStepExecutionResult({testScriptExecution: {...step, status: 'Fail', notes: notes}}))
      closePopover()
    } else {
      const appToast = new AppToast()
      appToast.displayErrorToast('You must complete all fields.')
    }
  }

  const closePopover = () => {
    setAnchorEl(null)
  }

  const handleClose = () => {
    closePopover()
  }

  const handleStop = () => {
    dispatch(Auth.actions.setFilters({filterName: 'testingEnabled', filterValue: false}))
  }

  const handleComplete = () => {
    dispatch(Auth.actions.setFilters({filterName: 'testingEnabled', filterValue: false}))
  }

  const open = Boolean(anchorEl)
  const id = open ? FailButtonId : undefined

  if (!testCurrentTestScriptExecutionID || !authFilters.testingEnabled) {
    return null
  }

  if (testCurrentTestScriptExecutionID && !step) {
    return (
      <Portlet className={className}>
        <PortletBody fit={true} className='test-execution-header-grid-container'>
          Test Complete! Thank you.
          <Link to='/dashboard'>
            <Button size='sm' variant='success' onClick={handleComplete} className='ml-5'>
              Finish
            </Button>
          </Link>
        </PortletBody>
      </Portlet>
    )
  }

  return (
    <Portlet className={className}>
      <PortletBody fit={true} className='test-execution-header-grid-container'>
        <Grid container>
          <Grid item md={5} sx={{borderBottom: '1px dashed silver'}}>
            <Stack direction='row' spacing={1}>
              <Box sx={{fontWeight: 600}}>
                {step?.sectionNumber}. {step?.sectionTitle}
              </Box>
              <Box sx={{fontStyle: 'italic', fontWeight: 600, color: '#AAA'}}>Action:</Box>
            </Stack>
          </Grid>
          {/* <Grid item md={5} sx={{fontStyle: 'italic', fontWeight: 600, color: '#AAA', borderBottom: '1px dashed silver'}}>
            Section {step?.sectionNumber}. {step?.sectionTitle}:
          </Grid> */}
          <Grid item md={5} sx={{fontStyle: 'italic', fontWeight: 600, color: '#AAA', borderBottom: '1px dashed silver'}}>
            Expected Result:
          </Grid>
          <Grid item md={2} sx={{fontStyle: 'italic', fontWeight: 600, color: '#AAA', borderBottom: '1px dashed silver'}}>
            <Stack direction='row' spacing={1} justifyContent='space-between'>
              <Box sx={{fontWeight: 600}}>Test Result:</Box>
              <Box sx={{fontStyle: 'italic', fontWeight: 600}}>
                <HighlightOffIcon className='cursor-pointer' onClick={handleStop} sx={{mt: -1.5, fontSize: 24}} />
              </Box>
            </Stack>
          </Grid>
          <Grid item md={5} sx={{marginTop: 1}}>
            <Stack direction='row' spacing={1}>
              <Box sx={{fontWeight: 900}}>{step?.stepNumber}</Box>
              <Box sx={{display: 'inline'}}>{step?.action}</Box>
            </Stack>
          </Grid>
          <Grid item md={5} sx={{marginTop: 1, paddingLeft: 1, borderLeft: '1px dashed silver'}}>
            {step?.expectedResult}
          </Grid>
          <Grid item md={2} sx={{marginTop: 1}}>
            <Button size='sm' variant='success' className='mr-5 mt-2' onClick={handlePass}>
              Pass
            </Button>
            <Button size='sm' variant='danger' className='mt-2' onClick={handleFail}>
              Fail
            </Button>
          </Grid>
        </Grid>
      </PortletBody>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        container={() => document.getElementById(FailContainerId)}
      >
        <div className='p-5'>
          <Grid sx={{minWidth: 500}}>
            <Col md='12'>
              <Form onSubmit={submitFail} autoComplete='OFF'>
                <Row>
                  <Col md='12'>
                    <Form.Group controlId='notes'>
                      <Form.Label>What went wrong?</Form.Label>
                      <br />
                      <TextField
                        id='notes'
                        name='notes'
                        variant='outlined'
                        value={notes}
                        onChange={(event) => setNotes(event.target.value)}
                        size='small'
                        InputProps={{classes: {input: 'input-text-edit-100'}}}
                        autoComplete='off'
                        decimalplaces={0}
                        className='textfield-edit-100'
                        error={notes === ''}
                        multiline
                        rows={5}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Grid container justifyContent='space-between' className='mt-5'>
              <Grid item></Grid>
              <Grid item>
                <Button variant='secondary' onClick={handleClose} className='mr-5'>
                  Cancel
                </Button>
                <Button variant='primary' onClick={() => submitFail(true)}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </Portlet>
  )
}

export default TestExecution
