import {useState, useEffect, useMemo, useCallback, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import * as Precon from '../../../store/ducks/precon.duck'
import * as Project from '../../../store/ducks/project.duck'
import * as Auth from '../../../store/ducks/auth.duck'
import * as wjcgrid from '@grapecity/wijmo.grid'
import * as wjFlexGrid from '@grapecity/wijmo.react.grid'
import {DataChangeAction} from '@grapecity/wijmo.grid.immutable'
import {ImmutabilityProvider} from '@grapecity/wijmo.react.grid.immutable'
import {Menu} from '@grapecity/wijmo.input'
import BidHeader from '../../../components/BidHeader'
import ClarificationsTemplates from '../templateselectors/ClarificationsTemplates'
import NotLoaded, {useIsLoaded} from '../../../components/IsLoaded'
import Refresh from '../../../components/Refresh'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Grid from '@mui/material/Grid'
import {CustomTextField} from './styles'
import {CSICodeCellTemplate, LineNumberColumnCellTemplate, IndentCellTemplate} from '../../../components/templates/FlexGridTemplates'
import {ModalLayoutI} from '../../../components/Modals/ModalLayouts'
import {FooterLayout} from '../../../components/Modals/BasicModalWrapper'
import {GlobalModal, useAppModal} from '../../../context/GlobalModalContext'
import useBatchedGridChangeHandler from '../../../hooks/useBatchedGridChangeHandler'

const AddGeneralClarificationModalKey = '@Modal_AddGeneralClarification'
export const ClarificationsTemplatesModalKey = '@Modal_TradeScope'

export const ClarificationsTemplatesModal = () => {
  return (
    <ModalLayoutI modalKey={ClarificationsTemplatesModalKey} title='Clarifications Templates' footerLayout={FooterLayout.Empty}>
      <ClarificationsTemplates />
    </ModalLayoutI>
  )
}

export default function Clarifications(props) {
  const hideHeader = props.hideHeader,
    showAll = props.showAll,
    readOnly = props.readOnly

  /* Props: 
      bidQuoteID: FK - may be template or an instance
      hideHeader: supresses display of header when shown for a specific trade
      readOnly:   When viewing a template for selection, it is readonly
  */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const filterBidTradeID = useSelector((state) => state.auth.Filters.bidTradeID)
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const bidTrade = useSelector((state) => state.precon.BidTrade)
  const clarifications = useSelector((state) => state.precon.Clarifications)
  const preconCache = useSelector((state) => state.precon.cache)
  const isLoaded = useIsLoaded({
    type: 'bidQuote',
    preconDeps: ['FETCH_BIDCLARIFICATIONS']
  })

  const {openModal, closeModal} = useAppModal()

  const clarificationsFiltered = useMemo(
    () =>
      clarifications
        ? clarifications.filter((row) => {
            if (showAll) {
              return true
            } else {
              return row.bidTradeID === filterBidTradeID && !row.isTradeHeader ? true : null
            }
          })
        : [],
    [showAll, clarifications, filterBidTradeID]
  )

  useEffect(() => {
    if (!preconCache.FETCH_BIDCLARIFICATIONS || preconCache?.FETCH_BIDCLARIFICATIONS?.payload === 'VOID') {
      dispatch(Precon.actions.fetchBidClarifications({bidQuoteID: bidQuoteID}))
    }
  }, [dispatch, bidQuoteID, preconCache.FETCH_BIDCLARIFICATIONS])

  const [newClarification, setNewClarification] = useState('')

  const initClarification = useCallback(() => {
    return {
      id: 0,
      clarificationID: 0,
      bidQuoteID: bidQuoteID,
      bidTradeID: null,
      clarificationIndex: 1000,
      prevIndex: null,
      description: '',
      copiedFromClarificationID: null,
      updatedBy: 0,
      updatedOn: new Date()
    }
  }, [bidQuoteID])

  function addClarification(action) {
    var payload = {
      ...initClarification(),
      bidTradeID: showAll ? null : filterBidTradeID,
      description: newClarification
    }
    dispatch(Precon.actions.putBidClarification([payload]))
    setNewClarification('')
    action && closeModal() // save and close
  }

  const onGridDataChanged = useCallback(
    (context, events) => {
      // function onGridDataChanged(s, e) {
      const newGrid = context?.collectionView?.items
      if (newGrid) {
        console.log('events')
        console.log(events)
        const action = events[0].action
        switch (action) {
          case DataChangeAction.Change: {
            const putActions = []
            for (const event of events) {
              // send the row off to the API to update the DB
              putActions.push({...event.newItem, description: shortcuts(event.newItem.description.trim())})
            }
            if (putActions.length > 0) {
              dispatch(Precon.actions.putBidClarification(putActions))
            }
            break
          }
          case DataChangeAction.Remove: {
            const putActions = []
            for (const event of events) {
              // send the row off to the API to update the DB
              putActions.push({...event.oldItem})
            }
            if (putActions.length > 0) {
              dispatch(Precon.actions.deleteBidClarification(putActions))
            }
            break
          }
          default:
          // throw 'Unknown data action';
        }
      } else {
        console.log('save failed: newGrid has no items')
        console.log(newGrid)
      }
    },
    [dispatch]
  )

  const batchEvents = useBatchedGridChangeHandler(onGridDataChanged)

  // link to trades with filter
  const goToTrade = useCallback(
    (bidTradeID) => {
      dispatch(Project.actions.setTab({page: 'tradescope', tab: 'clarifications'}))
      dispatch(Precon.actions.setBidTradeID(bidTradeID))
      dispatch(Auth.actions.setFilters({filterName: 'bidTradeID', filterValue: bidTradeID}))
      navigate('/trades')
    },
    [dispatch, navigate]
  )

  // Flexgrid templates
  const renderLineNumberColumnCellTempate = useCallback((context) => <LineNumberColumnCellTemplate lineNumber={context.item.isTradeHeader ? null : context.item.lineNumber} />, [])

  const renderDescriptionCell = useCallback(
    (context) => {
      if (context.item.isTradeHeader && context.item.clarificationIndex > 0) {
        return <CSICodeCellTemplate bidTradeID={context.item.bidTradeID} codeNo={context.item.description} goToTrade={goToTrade} />
      } else {
        return <IndentCellTemplate label={context.item.description} indentLevel={context.item.indentLevel} isHeader={false} />
      }
    },
    [goToTrade]
  )

  // create ref to bidtrade is state so that when users change it, we can fetch it for the payload in the grid add/change actions
  const payloadRef = useRef(bidTrade)
  useEffect(() => {
    payloadRef.current = bidTrade
  }, [bidTrade])

  const buildMenuGeneralClarifications = useCallback(
    (s) => {
      let menu = new Menu(document.createElement('div'), {
        owner: s && s.hostElement,
        displayMemberPath: 'header',
        subItemsPath: 'items',
        commandParameterPath: 'cmd',
        dropDownCssClass: 'ctx-menu',
        closeOnLeave: true,
        itemsSource: [
          {header: 'Insert row', cmd: 'cmdInsertNewRow'},
          {header: 'Insert 10 rows', cmd: 'cmdInsert10NewRows'},
          {header: 'Insert 50 rows', cmd: 'cmdInsert50NewRows'},
          {header: 'Indent Right', cmd: 'cmdIndentLevelAdd'},
          {header: 'Indent Left', cmd: 'cmdIndentLevelSubtract'},
          {header: '-'},
          {header: 'Delete row', cmd: 'cmdDeleteRow'}
        ],
        command: {
          canExecuteCommand: (cmd) => {
            let dataItem = s.rows[s.selection.row]?.dataItem
            if (!dataItem.indentLevel & (cmd === 'cmdIndentLevelSubtract')) {
              return false
            } else if (dataItem.indentLevel && dataItem.indentLevel >= 5 && cmd === 'cmdIndentLevelAdd') {
              return false
            }
            return true
          },
          // execute menu commands
          executeCommand: (cmd) => {
            let dataItem = s.rows[s.selection.row].dataItem
            console.log('General Clarification Menu cmd:' + cmd)
            console.log(dataItem)
            let payload = {}
            switch (cmd) {
              case 'cmdDeleteRow':
                console.log(dataItem)
                dispatch(Precon.actions.deleteBidClarification([dataItem]))
                break
              case 'cmdInsertNewRow':
              case 'cmdInsert10NewRows':
              case 'cmdInsert50NewRows':
                payload = {
                  bidTradeID: null, // General Clarificaitons have no bidTradeID
                  id: 0,
                  clarificationID: 0,
                  description: '',
                  clarificationIndex: dataItem.clarificationIndex - 2,
                  prevIndex: dataItem.clarificationIndex - 3,
                  indentLevel: dataItem.indentLevel,
                  insertAt: 'In position',
                  insertRows: cmd === 'cmdInsertNewRow' ? 1 : cmd === 'cmdInsert50NewRows' ? 50 : 10
                }
                dispatch(Precon.actions.putBidClarification([{...initClarification(), ...dataItem, ...payload}]))
                break
              case 'cmdIndentLevelAdd':
              case 'cmdIndentLevelSubtract': {
                let newIndentLevel = cmd === 'cmdIndentLevelAdd' ? (dataItem.indentLevel ? dataItem.indentLevel + 1 : 1) : dataItem.indentLevel ? dataItem.indentLevel - 1 : 0
                payload = {
                  indentLevel: newIndentLevel
                }
                const thisBidClarification = {...initClarification(), ...dataItem, ...payload}
                console.log('thisBidClarification', thisBidClarification)
                dispatch(Precon.actions.putBidClarification([thisBidClarification]))
                let temp = s.collectionView.items.map((item) => (item.clarificationID === dataItem.clarificationID ? {...item, indentLevel: newIndentLevel} : item))
                dispatch(Precon.actions.setClarifications(temp))
                break
              }
              default:
            }
            // restore focus to active grid cell (TFS 439964)
            s.refresh()
            let sel = s.selection,
              cell = s.cells.getCellElement(sel.row, sel.col)
            if (cell) {
              cell.focus()
            }
          }
        }
      })
      // done
      return menu
    },
    [dispatch, initClarification]
  )

  const buildMenuTradeClarifications = useCallback(
    (s) => {
      let menu = new Menu(document.createElement('div'), {
        owner: s && s.hostElement,
        displayMemberPath: 'header',
        subItemsPath: 'items',
        commandParameterPath: 'cmd',
        dropDownCssClass: 'ctx-menu',
        closeOnLeave: true,
        itemsSource: [
          {header: 'Insert Row into Trade', cmd: 'cmdInsertNewRow'},
          {header: 'Insert 10 rows', cmd: 'cmdInsert10NewRows'},
          {header: 'Insert 50 rows', cmd: 'cmdInsert50NewRows'},
          {header: 'Indent Right', cmd: 'cmdIndentLevelAdd'},
          {header: 'Indent Left', cmd: 'cmdIndentLevelSubtract'},
          {header: '-'},
          {header: 'Delete row', cmd: 'cmdDeleteRow'}
        ],
        command: {
          canExecuteCommand: (cmd) => {
            let dataItem = s.rows[s.selection.row]?.dataItem
            if (!dataItem.indentLevel & (cmd === 'cmdIndentLevelSubtract')) {
              return false
            } else if (dataItem.indentLevel && dataItem.indentLevel >= 5 && cmd === 'cmdIndentLevelAdd') {
              return false
            }
            return true
          },
          // execute menu commands
          executeCommand: (cmd) => {
            let dataItem = s.rows[s.selection.row].dataItem
            console.log('Trade Clarification Menu cmd:' + cmd)
            console.log(dataItem)
            let payload = {}
            switch (cmd) {
              case 'cmdDeleteRow':
                console.log(dataItem)
                dispatch(Precon.actions.deleteBidClarification([dataItem]))
                break
              case 'cmdInsertNewRow':
              case 'cmdInsert10NewRows':
              case 'cmdInsert50NewRows':
                payload = {
                  id: 0,
                  clarificationID: 0,
                  description: '',
                  clarificationIndex: dataItem.clarificationIndex - 2,
                  prevIndex: dataItem.clarificationIndex - 3,
                  indentLevel: dataItem.indentLevel,
                  insertAt: 'In position',
                  insertRows: cmd === 'cmdInsertNewRow' ? 1 : cmd === 'cmdInsert50NewRows' ? 50 : 10
                }
                dispatch(Precon.actions.putBidClarification([{...initClarification(), ...dataItem, ...payload}]))
                break
              case 'cmdIndentLevelAdd':
              case 'cmdIndentLevelSubtract': {
                let newIndentLevel = cmd === 'cmdIndentLevelAdd' ? (dataItem.indentLevel ? dataItem.indentLevel + 1 : 1) : dataItem.indentLevel ? dataItem.indentLevel - 1 : 0
                payload = {
                  indentLevel: newIndentLevel
                }
                const thisBidClarification = {...initClarification(), ...dataItem, ...payload}
                console.log('thisBidClarification', thisBidClarification)
                dispatch(Precon.actions.putBidClarification([thisBidClarification]))
                let temp = s.collectionView.items.map((item) => (item.clarificationID === dataItem.clarificationID ? {...item, indentLevel: newIndentLevel} : item))
                dispatch(Precon.actions.setClarifications(temp))
                break
              }
              default:
            }
            // restore focus to active grid cell (TFS 439964)
            s.refresh()
            let sel = s.selection,
              cell = s.cells.getCellElement(sel.row, sel.col)
            if (cell) {
              cell.focus()
            }
          }
        }
      })
      // done
      return menu
    },
    [dispatch, initClarification]
  )
  const gridRef = useRef()

  function onGridInitialized(s) {
    gridRef.current = s
    // Add context menu
    let host = s.hostElement,
      menuGeneralClarifications = buildMenuGeneralClarifications(s),
      menuTradeClarifications = buildMenuTradeClarifications(s)
    host.addEventListener(
      'contextmenu',
      (e) => {
        // select the cell/column that was clicked
        let sel = s.selection,
          ht = s.hitTest(e),
          row = ht.getRow()
        switch (ht.panel) {
          case s.cells:
            s.select(ht.row, ht.col)
            break
          case s.columnHeaders:
            s.select(sel.row, ht.col)
            break
          case s.rowHeaders:
            s.select(ht.row, sel.col)
            break
          default:
            return // invalid panel
        }
        // show the menu for the current column
        if (s.selection.col > -1 && row.dataItem && row.dataItem.bidTradeID > 0 && !row.dataItem.isTradeHeader) {
          e.preventDefault() // cancel the browser's default menu
          menuTradeClarifications.show(e) // and show ours
        } else if (s.selection.col > -1 && row.dataItem && !row.dataItem.isTradeHeader) {
          e.preventDefault() // cancel the browser's default menu
          menuGeneralClarifications.show(e) // and show ours
        } else {
          e.preventDefault() // cancel the browser's default menu
        }
      },
      true
    )
    s.columnHeaders.rows.cssClassAll = 'group-row'

    s.formatItem.addHandler((grid, e) => {
      let row = grid.rows[e.row]
      let item = row && row.dataItem
      if (s.rows[e.row] !== undefined && item && item.isTradeHeader) {
        row.cssClass = 'bidTradeGrid-section-header'
      }
    })
  }

  if (!isLoaded.result) {
    return <NotLoaded payload={isLoaded} />
  }

  return (
    <>
      {!hideHeader && (
        <>
          <BidHeader />
          <div className='text-right mb-[6px]'>
            <Refresh isLoaded={isLoaded} />
            <Button size='sm' className='ml-3' onClick={() => openModal(ClarificationsTemplatesModalKey)}>
              Templates
            </Button>
            <Button size='sm' className='ml-3' onClick={() => openModal(AddGeneralClarificationModalKey)}>
              Add General Clarification
            </Button>
          </div>
        </>
      )}

      {
        !showAll ? (
          <>
            <div className='text-right mb-[6px]'>
              <Button size='sm' className='ml-3' onClick={() => openModal(AddGeneralClarificationModalKey)}>
                Add Clarification
              </Button>
            </div>
          </>
        ) : null // showAll
      }

      <wjFlexGrid.FlexGrid
        id='bidtradeitems-grid'
        className='trade-clarifications-grid'
        initialized={onGridInitialized}
        alternatingRowStep={0}
        allowSorting={false}
        keyActionTab={wjcgrid.KeyAction.Cycle}
        allowDelete={true}
        allowAddNew={false}
        autoRowHeights={true}
      >
        <ImmutabilityProvider itemsSource={clarificationsFiltered} dataChanged={batchEvents} />
        <wjFlexGrid.FlexGridColumn binding='id' header='ID' width={20} isReadOnly={true} visible={false} />
        <wjFlexGrid.FlexGridColumn binding='lineNumber' header='#' width={50} visible={true} isReadOnly={true} cssClassAll='header-right'>
          <wjFlexGrid.FlexGridCellTemplate cellType='Cell' template={renderLineNumberColumnCellTempate}></wjFlexGrid.FlexGridCellTemplate>
        </wjFlexGrid.FlexGridColumn>
        <wjFlexGrid.FlexGridColumn binding='description' header='Description' width='*' cssClassAll='header-left' wordWrap={true} isReadOnly={readOnly}>
          <wjFlexGrid.FlexGridCellTemplate cellType='Cell' template={renderDescriptionCell} />
        </wjFlexGrid.FlexGridColumn>
      </wjFlexGrid.FlexGrid>

      <GlobalModal modalKey={AddGeneralClarificationModalKey} dialogClassName='edit-sm-dialog-modal'>
        <Modal.Header closeButton className='edit-sm-modal'>
          <Modal.Title>Add {showAll ? 'General' : 'Trade'} Clarification</Modal.Title>
        </Modal.Header>
        <Modal.Body className='edit-sm-modal'>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <br></br>
                <CustomTextField id='description' multiline variant='outlined' size='small' value={newClarification} minRows={2} onChange={(event) => setNewClarification(event.target.value)} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='edit-lg-modal'>
          <Grid container justifyContent='space-between'>
            <Grid item></Grid>
            <Grid item>
              <Button variant='secondary' onClick={closeModal}>
                Cancel
              </Button>
              <>
                <Button variant='primary' onClick={() => newClarification !== '' && addClarification(false)} className='ml-2'>
                  Save &amp; Add More
                </Button>
                <Button variant='primary' onClick={() => newClarification !== '' && addClarification(true)} className='ml-2'>
                  Save &amp; Close
                </Button>
              </>
            </Grid>
          </Grid>
        </Modal.Footer>
      </GlobalModal>
    </>
  )
}

function shortcuts(val) {
  // function to transform shortcut keys to words, and also to format numbers to currency with commas (As column type is string due to mixed text and numeric values)
  if (val === 'i') {
    return 'Included'
  } else if (val === 'n') {
    return 'No/Ok'
  } else {
    return val ? val.substring(0, 2000) : ''
  }
}
