import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Grid from '@mui/material/Grid'
import Dropzone from 'react-dropzone'

const UploadFileDropzone = ({onDrop, files, onSuccess, onCancel, postFileStatus}) => {
  return (
    <div className='p-3'>
      <Col md='12'>
        <h3>Upload File</h3>
      </Col>
      <hr />
      <Col md='12'>
        <Form.Group>
          <Form.Label>Add Files:</Form.Label>
          <Dropzone accept={{'application/pdf': ['.pdf'], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']}} multiple={true} onDrop={onDrop}>
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} style={{...baseStyle}}>
                  <input {...getInputProps()} />
                  <i className='bi bi-cloud-arrow-up text-[5rem]'></i>
                  <p>Drag &apos;n&apos; drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>
        </Form.Group>
      </Col>
      <Col md={12} className='mt-5'>
        {files.map((file) => (
          <li key={file.name}>{file.name}</li>
        ))}
      </Col>
      <hr />
      <Grid container justifyContent='space-between'>
        <Grid item></Grid>
        <Grid item>
          <Button variant='success' className='mr-[10px]' onClick={onSuccess}>
            {postFileStatus === 'Not Started' ? (
              'Upload'
            ) : (
              <>
                {postFileStatus} <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
              </>
            )}
          </Button>
          <Button variant='secondary' onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#ccc',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#666',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
}

export default UploadFileDropzone
