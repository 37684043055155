import {useEffect, useCallback, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../../../../store/ducks/project.duck'
import * as Precon from '../../../../store/ducks/precon.duck'
import * as Reference from '../../../../store/ducks/reference.duck'
import InputAdornment from '@mui/material/InputAdornment'
import Table from 'react-bootstrap/Table'
import {NumberFormatCustom} from '../../../../components/NumberFormatCustom'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import NotLoaded, {useIsLoaded} from '../../../../components/IsLoaded'
import isEmpty from 'lodash/isEmpty'
import {useAppModal} from '../../../../context/GlobalModalContext'
import ProjectStatus from './ProjectStatus'
import {FormikWrapper} from '../../../../components/Form/AppForm'
import {Field, useFormikContext} from 'formik'
import SetupTextField from '../../../../components/Form/SetupTextField'
import SetupSelectField from '../../../../components/Form/SetupSelectField'
import SetupDateField from '../../../../components/Form/SetupDateField'
import {isNumeric} from '../../../../helpers/number'
import * as Yup from 'yup'
import {getDateDiffInWeeks} from '../../../../helpers/date'
import {ProjectDateConfirmModalKey} from './ConfirmDateChangeModal'
import {CompleteProjectSetupModalKey} from './CompleteProjectSetupModal'
import {UNITS_OF_QUANTITY} from '../BidQuoteAreas/BidQuoteAreasTab'

//** Constants */

const validationSchema = Yup.object()
  .shape({
    // CRM_AnticipatedProjectCosts: Yup.number().moreThan(0, 'Value must be greater than 0'),
    SalaryEscalationRate: Yup.number().moreThan(0, 'Value must be greater than 0'),
    PreConstructionStartDate: Yup.string().trim().notOneOf([''], 'This field cannot be empty'),
    PreConstructionEndDate: Yup.string().trim().notOneOf([''], 'This field cannot be empty'),
    StateWorkPerformedIn: Yup.string().trim().notOneOf([''], 'This field cannot be empty'),
    CRM_EstConstructionStartDate: Yup.string().trim().notOneOf([''], 'This field cannot be empty'),
    CRM_EstConstructionEndDate: Yup.string().trim().notOneOf([''], 'This field cannot be empty'),
    CloseOutDate: Yup.string().trim().notOneOf([''], 'This field cannot be empty')
    // ProjectAreaUnit: Yup.string().trim().notOneOf([''], 'This field cannot be empty'),
    // ProjectAreaValue: Yup.number().moreThan(0, 'Value must be greater than 0')
  })
  .test('PPBondEffectiveDate', 'Error', function (values) {
    const {PerformancePaymentBondRequired, WarrantyPeriodOneYearOrLess, PPBondEffectiveDate} = values
    if (PerformancePaymentBondRequired !== 'No' && WarrantyPeriodOneYearOrLess === 'No' && PPBondEffectiveDate === '') {
      return this.createError({
        path: 'PPBondEffectiveDate',
        message: 'Error in PPBondEffectiveDate'
      })
    }
    return true
  })
  .test('WarrantyPeriodMonths', 'Error', function (values) {
    const {PerformancePaymentBondRequired, WarrantyPeriodOneYearOrLess, WarrantyPeriodMonths} = values
    if (PerformancePaymentBondRequired !== 'No' && WarrantyPeriodOneYearOrLess === 'No' && WarrantyPeriodMonths === 0) {
      return this.createError({
        path: 'WarrantyPeriodMonths',
        message: 'Error in WarrantyPeriodMonths'
      })
    }
    return true
  })

// Menu options
const YesAndNoOptions = [
  {label: 'Yes', value: 'Yes'},
  {label: 'No', value: 'No'}
]

const TrueAndFalseOptions = [
  {label: 'Yes', value: 'True'},
  {label: 'No', value: 'False'}
]

const YesNoStandardOptions = [
  {label: 'No', value: 'No'},
  {label: 'Yes - Standard', value: 'TrueStandard'},
  {label: 'Yes - Design Build Obligations', value: 'TrueDesignBuildObligations'}
]

const NAOption = [{label: 'N/A', value: 'No'}]

const SetupTab = () => {
  //** Setup */
  const projectID = useSelector((state) => state.project.projectID)
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const states = useSelector((state) => state.reference.States)
  const plugins = useSelector((state) => state.reference.Plugins)
  const projectInformationGrid = useSelector((state) => state.project.ProjectInformationGrid.values)
  const editPermission = useSelector((state) => state.project.editPermission)
  const bidQuoteAreas = useSelector((state) => state.precon.BidQuoteAreas)

  const dispatch = useDispatch()
  const {openModal, closeModal} = useAppModal()

  useEffect(() => {
    dispatch(Project.actions.fetchProjectInformationGrid({projectID: projectID}))
  }, [dispatch, projectID])

  useEffect(() => {
    dispatch(Reference.actions.fetchStates())
  }, [dispatch])

  useEffect(() => {
    dispatch(Reference.actions.fetchPlugins())
  }, [dispatch])

  useEffect(() => {
    dispatch(Precon.actions.fetchBidQuoteAreas({bidQuoteID: bidQuoteID}))
  }, [dispatch, bidQuoteID])

  //** Methods */
  const showSalesTaxExempt = useMemo(
    () =>
      states.length &&
      states.some((row) => row.Abbr === projectInformationGrid.StateWorkPerformedIn) &&
      states.find((row) => row.Abbr === projectInformationGrid.StateWorkPerformedIn).GrossReceiptsTaxRate > 0,
    [projectInformationGrid.StateWorkPerformedIn, states]
  )

  const handlePutProperty = useCallback(
    (propertyName, propertyValue) => {
      const payload = {
        [propertyName]: propertyValue
      }
      dispatch(Project.actions.putProperty(payload))
    },
    [dispatch]
  )

  //   Field specific change and blur callbacks
  const handleBlurTextField = useCallback(
    (propertyName, fieldValue) => {
      if (!editPermission) {
        return
      }
      if (isNumeric(fieldValue)) {
        handlePutProperty(propertyName, fieldValue)
      }
    },
    [editPermission, handlePutProperty]
  )

  const handleSelectChange = useCallback(
    (propertyName, fieldValue) => {
      if (!editPermission) {
        return
      }
      const payload = {
        CollectionName: 'ProjectInformationGrid',
        PropertyName: propertyName,
        PropertyValue: fieldValue
      }
      dispatch(Project.actions.setProperty(payload))
      handlePutProperty(propertyName, fieldValue)
    },
    [dispatch, editPermission, handlePutProperty]
  )

  const handleSelectChangeState = useCallback(
    (propertyName, fieldValue) => {
      if (fieldValue !== 'FD') {
        const payload1 = {
          CollectionName: 'ProjectInformationGrid',
          PropertyName: 'WorkmensCompensationandLiabilityInsurancePerFARCAS',
          PropertyValue: 'No'
        }
        dispatch(Project.actions.setProperty(payload1))
        const payload2 = {
          CollectionName: 'ProjectInformationGrid',
          PropertyName: 'FARCASRate_approved',
          PropertyValue: 'No'
        }
        dispatch(Project.actions.setProperty(payload2))
        handlePutProperty('WorkmensCompensationandLiabilityInsurancePerFARCAS', 'No')
      }
      handleSelectChange(propertyName, fieldValue)
    },
    [dispatch, handlePutProperty, handleSelectChange]
  )

  // Check project dates are all in sequence (pre start, pre end, const start, const end)
  //   On save handler for ConfirmDateChangeModal
  const handleSaveDate = useCallback(
    (propertyName, propertyValue, updateDateChangeLocally) => {
      updateDateChangeLocally(propertyValue)
      const payload = {
        CollectionName: 'ProjectInformationGrid',
        PropertyName: propertyName,
        PropertyValue: propertyValue
      }
      dispatch(Project.actions.setProperty(payload))
      handlePutProperty(propertyName, propertyValue)
      closeModal()
    },
    [closeModal, dispatch, handlePutProperty]
  )

  const handleDateChangeCheck = useCallback(
    (propertyName, updateDateChangeLocally, setFieldError, propertyValue, oldDate, values) => {
      let passEarly = true
      let passLate = true
      let tempDateBracketType = ''
      let propertyLabel
      let dateChangePhase
      let dateChangeOperation
      let dateChangeWeeks

      // Check dates do not overlap incorrectly
      switch (propertyName) {
        case 'PreConstructionStartDate':
          if (Math.floor(Date.parse(propertyValue) - Date.parse(values.PreConstructionEndDate)) > 0) {
            setFieldError('PreConstructionStartDate', '! Must be before End Date')
            passEarly = false
          }
          propertyLabel = 'Preconstruction Start Date'
          dateChangePhase = 'Preconstruction'
          tempDateBracketType = 'Start'
          break
        case 'PreConstructionEndDate':
          if (Math.floor(Date.parse(propertyValue) - Date.parse(values.PreConstructionStartDate)) < 0) {
            setFieldError('PreConstructionEndDate', '! Must be after Start Date')
            passEarly = false
          }
          // NOTE: Precon End date can be before Construction Start Date - sp_synchWeeks can handle this
          propertyLabel = 'Preconstruction End Date'
          dateChangePhase = 'Preconstruction'
          tempDateBracketType = 'End'
          break
        case 'CRM_EstConstructionStartDate':
          if (Math.floor(Date.parse(propertyValue) - Date.parse(values.CRM_EstConstructionEndDate)) > 0) {
            setFieldError('CRM_EstConstructionStartDate', '! Must be before Construction End')
            passLate = false
          }
          propertyLabel = 'Est Construction Start Date'
          dateChangePhase = 'Construction'
          tempDateBracketType = 'Start'
          break
        case 'CRM_EstConstructionEndDate':
          if (Math.floor(Date.parse(propertyValue) - Date.parse(values.CRM_EstConstructionStartDate)) < 0) {
            setFieldError('CRM_EstConstructionEndDate', '! Must be after Start Date')
            passEarly = false
          }
          propertyLabel = 'Construction Substantial Completion Date'
          dateChangePhase = 'Construction'
          tempDateBracketType = 'End'
          break
        case 'CloseOutDate':
          if (Math.floor(Date.parse(propertyValue) - Date.parse(values.CRM_EstConstructionEndDate)) < 0) {
            setFieldError('CloseOutDate', '! Must be after Construction End')
            passEarly = false
          }
          propertyLabel = 'Closeout Date'
          dateChangePhase = 'Closeout'
          tempDateBracketType = 'End'
          break
        default:
      }
      // if we pass all the rules and have a valid date, then put to API
      if (propertyValue && passEarly && passLate) {
        // Calculate operation and qty for date change
        const newDate = propertyValue
        const oldDateParsed = oldDate ? new Date(oldDate) : undefined
        let tempDifferenceInWeeks = 0

        if (isAfter(newDate, oldDateParsed)) {
          dateChangeOperation = tempDateBracketType === 'Start' ? 'remove' : 'add'
          tempDifferenceInWeeks = differenceInWeeks(newDate, oldDateParsed)
          console.log('after')
        } else if (isBefore(newDate, oldDateParsed)) {
          dateChangeOperation = tempDateBracketType === 'Start' ? 'add' : 'remove'
          tempDifferenceInWeeks = differenceInWeeks(oldDateParsed, newDate)
          console.log('before')
        }
        dateChangeWeeks = tempDifferenceInWeeks

        //   Open modal to confirm date change
        openModal(ProjectDateConfirmModalKey, {
          onSaveDate: handleSaveDate,
          propertyName,
          updateDateChangeLocally,
          propertyLabel,
          oldValue: oldDateParsed,
          agreeDateChangeValue: propertyValue,
          dateChangeOperation,
          dateChangeWeeks,
          dateChangePhase
        })
      }
    },
    [handleSaveDate, openModal]
  )

  const isLoaded = useIsLoaded({
    type: 'project',
    projectDeps: ['FETCH_ProjectInformationGrid'],
    preconDeps: ['FETCH_BIDQUOTEAREAS'],
    referenceDeps: ['FETCH_States'],
    ignoreSetup: true
  })

  if (!isLoaded.result) {
    return <NotLoaded payload={isLoaded} noHeader={true} />
  }

  return (
    <div>
      <Table aria-label='simple table' className='summary-table mb-0'>
        <FormikWrapper initialValues={projectInformationGrid} validationSchema={validationSchema} validateOnMount enableReinitialize={true}>
          <tbody className='summary-tablebody'>
            {/* Complete Setup Row */}
            <ProjectStatusContainer />
            {/* Row 1 */}
            <tr>
              <Field
                component={SetupDateField}
                label='Deliverable Due Date'
                name='RFPDueDate'
                hideField={(_name, value) => value === ''}
                views={['year', 'month', 'day']}
                slotProps={{
                  textField: {
                    size: 'small',
                    className: 'summary-dense summary-date',
                    readOnly: true
                  },
                  inputAdornment: {sx: {paddingRight: 1}}
                }}
                readOnly={true}
              />
              <td className='border-t-0'></td>
              <Field
                component={SetupDateField}
                label='Bid Created Date'
                id='BidCreatedDateTime'
                name='BidCreatedDateTime'
                views={['year', 'month', 'day']}
                slotProps={{
                  textField: {
                    size: 'small',
                    className: 'summary-dense summary-date',
                    readOnly: true
                  },
                  inputAdornment: {sx: {paddingRight: 1}}
                }}
                readOnly={true}
              />
            </tr>
            {/* Row 2 */}
            <tr>
              {projectInformationGrid?.TradePricingMode !== 'TRUE' ? (
                <Field
                  component={SetupTextField}
                  label='Estimated Contract Value'
                  id='CRM_AnticipatedProjectCosts'
                  name='CRM_AnticipatedProjectCosts'
                  variant='outlined'
                  size='small'
                  onBlurCallback={handleBlurTextField}
                  getClassname={(_name, _value, values) => (values['TradePricingMode'] === 'TRUE' ? 'textfield' : 'textfield-edit')}
                  InputProps={{
                    classes: {input: 'input-number-edit'},
                    readOnly: projectInformationGrid.TradePricingMode === 'TRUE',
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    inputComponent: NumberFormatCustom,
                    inputProps: {decimalplaces: 0}
                  }}
                  autoComplete='off'
                />
              ) : (
                <Field
                  component={SetupTextField}
                  label='Contract Total'
                  id='CRM_AnticipatedProjectCosts'
                  name='CRM_AnticipatedProjectCosts'
                  variant='outlined'
                  size='small'
                  onBlurCallback={handleBlurTextField}
                  getClassname={() => 'textfield'}
                  InputProps={{
                    classes: {input: 'input-pc-edit'},
                    readOnly: true,
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    inputComponent: NumberFormatCustom,
                    inputProps: {decimalplaces: 0}
                  }}
                  autoComplete='off'
                />
              )}
              <td> </td>
              <Field
                component={SetupDateField}
                label='Preconstruction Start Date'
                id='PreConstructionStartDate'
                name='PreConstructionStartDate'
                onChangeCallback={handleDateChangeCheck}
                slotProps={{
                  textField: {
                    size: 'small',
                    className: 'excel-inputDate summary-dense summary-date',
                    readOnly: !editPermission
                  },
                  inputAdornment: {sx: {paddingRight: 1}}
                }}
                readOnly={!editPermission}
              />
            </tr>
            {/* Row 3 */}
            <tr>
              <Field
                component={SetupTextField}
                label='Salary Escalation Rate'
                onBlurCallback={handleBlurTextField}
                id='SalaryEscalationRate'
                name='SalaryEscalationRate'
                size='small'
                className='textfield-edit'
                InputProps={{
                  classes: {input: 'input-pc-edit'},
                  readOnly: !editPermission,
                  startAdornment: <InputAdornment position='start'>%</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                  inputProps: {decimalplaces: 2}
                }}
                autoComplete='off'
              />
              <td> </td>
              <Field
                component={SetupDateField}
                label='Preconstruction End Date'
                getLabelSuffix={(_name, value, values) => getDateDiffInWeeks(value, values.PreConstructionStartDate)}
                id='PreConstructionEndDate'
                name='PreConstructionEndDate'
                onChangeCallback={handleDateChangeCheck}
                slotProps={{
                  textField: {
                    size: 'small',
                    className: 'excel-inputDate summary-dense summary-date',
                    readOnly: !editPermission
                  },
                  inputAdornment: {sx: {paddingRight: 1}}
                }}
              />
            </tr>
            {/* Row 4 */}
            <tr>
              <Field
                component={SetupSelectField}
                label='State where work will be performed'
                id='StateWorkPerformedIn'
                name='StateWorkPerformedIn'
                onChangeCallback={handleSelectChangeState}
                variant='outlined'
                size='small'
                className='select-edit'
                readOnly={!editPermission}
                options={states.map((row) => ({label: row.Name, value: row.Abbr}))}
              />
              <td> </td>
              <Field
                component={SetupDateField}
                label='Est Construction Start Date'
                name='CRM_EstConstructionStartDate'
                onChangeCallback={handleDateChangeCheck}
                readOnly={!editPermission}
                slotProps={{
                  textField: {
                    size: 'small',
                    className: 'excel-inputDate summary-dense summary-date',
                    readOnly: !editPermission
                  },
                  inputAdornment: {sx: {paddingRight: 1}}
                }}
              />
            </tr>
            {/* Row 5 */}
            <tr>
              <Field
                component={SetupSelectField}
                label='State Sales Tax Exempt?'
                disabled={!showSalesTaxExempt}
                id='SalesTaxExempt'
                name='SalesTaxExempt'
                onChangeCallback={handleSelectChange}
                variant='outlined'
                size='small'
                className={!showSalesTaxExempt ? 'select' : 'select-edit'}
                readOnly={!editPermission}
                getOptions={(_name, _value, values) => (values.StateSalesTaxRate > 0 ? YesAndNoOptions : NAOption)}
              />
              <td> </td>
              <Field
                component={SetupDateField}
                label='Construction Substantial Completion Date'
                getLabelSuffix={(_name, value, values) => getDateDiffInWeeks(value, values.CRM_EstConstructionStartDate)}
                name='CRM_EstConstructionEndDate'
                onChangeCallback={handleDateChangeCheck}
                readOnly={!editPermission}
                slotProps={{
                  textField: {
                    size: 'small',
                    className: 'excel-inputDate summary-dense summary-date',
                    readOnly: !editPermission
                  },
                  inputAdornment: {sx: {paddingRight: 1}}
                }}
              />
            </tr>
            {/* Row 6 */}
            <tr>
              <Field
                component={SetupSelectField}
                label='OCIP/CCIP?'
                id='OcipCcip'
                name='OcipCcip'
                onChangeCallback={handleSelectChange}
                variant='outlined'
                size='small'
                className={'select-edit'}
                readOnly={!editPermission}
                options={YesAndNoOptions}
              />
              <td> </td>
              <Field
                component={SetupDateField}
                label='Closeout Date'
                getLabelSuffix={(_name, value, values) => getDateDiffInWeeks(value, values.CRM_EstConstructionEndDate)}
                name='CloseOutDate'
                onChangeCallback={handleDateChangeCheck}
                readOnly={!editPermission}
                slotProps={{
                  textField: {
                    size: 'small',
                    className: 'excel-inputDate summary-dense summary-date',
                    readOnly: !editPermission
                  },
                  inputAdornment: {sx: {paddingRight: 1}}
                }}
              />
            </tr>
            {/* Row 7 */}
            <tr>
              <Field
                component={SetupSelectField}
                label='Is the project a Joint Venture?'
                id='JointVenture'
                name='JointVenture'
                onChangeCallback={handleSelectChange}
                variant='outlined'
                size='small'
                className={'select-edit'}
                readOnly={!editPermission}
                options={TrueAndFalseOptions}
              />
              <td> </td>
              <td></td>
              <td></td>
            </tr>
            {/* Row 8 */}
            <tr>
              <Field
                component={SetupSelectField}
                label='Performance & Payment Bond Required?'
                id='PerformancePaymentBondRequired'
                name='PerformancePaymentBondRequired'
                onChangeCallback={handleSelectChange}
                variant='outlined'
                size='small'
                className={'select-edit'}
                readOnly={!editPermission}
                options={YesNoStandardOptions}
              />
              <td></td>
              <Field
                component={SetupDateField}
                label='Enter the effective date of the P & P Bond'
                getLabelDisabled={(_name, _value, values) => values.PerformancePaymentBondRequired === 'No' || values.WarrantyPeriodOneYearOrLess === 'Yes'}
                name='PPBondEffectiveDate'
                onChangeCallback={handleDateChangeCheck}
                readOnly={!editPermission}
                getSlotProps={(_name, _value, values) => ({
                  textField: {
                    size: 'small',
                    className:
                      values.PerformancePaymentBondRequired === 'No' || values.WarrantyPeriodOneYearOrLess === 'Yes' ? 'summary-dense summary-date' : 'excel-inputDate summary-dense summary-date',
                    readOnly: !editPermission
                  },
                  inputAdornment: {sx: {paddingRight: 1}}
                })}
              />
            </tr>
            {/* Row 9 */}
            <FormFragmentWithPerformancePaymentBondRequired handleSelectChange={handleSelectChange} editPermission={editPermission} handleBlurTextField={handleBlurTextField} />
            {/* Row 10 */}
            {bidQuoteAreas.length === 0 ? (
              <>
                <tr>
                  <Field
                    component={SetupSelectField}
                    label='Primary unit of quantity'
                    id='ProjectAreaUnit'
                    name='ProjectAreaUnit'
                    onChangeCallback={handleSelectChangeState}
                    variant='outlined'
                    size='small'
                    className='select-edit'
                    readOnly={!editPermission}
                    options={UNITS_OF_QUANTITY}
                  />
                  <td></td>
                  <Field
                    component={SetupTextField}
                    label='Project Size (sf)'
                    id='ProjectAreaValue'
                    name='ProjectAreaValue'
                    variant='outlined'
                    size='small'
                    onBlurCallback={handleBlurTextField}
                    className='textfield-edit'
                    InputProps={{
                      classes: {input: 'input-number-edit'},
                      readOnly: !editPermission,
                      inputComponent: NumberFormatCustom,
                      inputProps: {decimalplaces: 0}
                    }}
                    autoComplete='off'
                  />
                </tr>
                {/* Row 10 */}
                <FormFragmentWithProjectAreaUnit handleBlurTextField={handleBlurTextField} editPermission={editPermission} />
              </>
            ) : null}

            {/* Row 11  */}
            <tr>
              <Field
                component={SetupSelectField}
                label='Pricing Plugin'
                id='PricingPlugin'
                name='PricingPlugin'
                onChangeCallback={handleSelectChange}
                variant='outlined'
                size='small'
                className={'select-edit'}
                readOnly={!editPermission}
                options={plugins.map((row) => ({label: row.pluginName, value: row.pluginName}))}
              />
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </FormikWrapper>
      </Table>
    </div>
  )
}

const ProjectStatusContainer = () => {
  const {openModal} = useAppModal()
  const {errors} = useFormikContext()

  const hasNoErrors = useMemo(() => isEmpty(errors), [errors])

  const handleClickCompleteProjectSetup = useCallback(() => {
    openModal(CompleteProjectSetupModalKey, {showSaveButton: hasNoErrors, errors})
  }, [errors, hasNoErrors, openModal])

  return <ProjectStatus hasErrors={!hasNoErrors} hasBuildersRiskQuoteApprovalError={!!errors?.BuildersRiskQuoteApproval} onClickCompleteSetup={handleClickCompleteProjectSetup} />
}

const FormFragmentWithPerformancePaymentBondRequired = ({handleSelectChange, editPermission, handleBlurTextField}) => {
  const {values} = useFormikContext()

  if (values.PerformancePaymentBondRequired !== 'No') {
    return (
      <tr>
        <Field
          component={SetupSelectField}
          label='Is the Basic Warranty Period one year or less?'
          getDisabled={(_name, _value, values) => values.PerformancePaymentBondRequired === 'No' || values.PerformancePaymentBondRequired === ''}
          id='WarrantyPeriodOneYearOrLess'
          name='WarrantyPeriodOneYearOrLess'
          onChangeCallback={handleSelectChange}
          variant='outlined'
          size='small'
          getClassname={(_name, _value, values) => (values.PerformancePaymentBondRequired === 'No' || values.PerformancePaymentBondRequired === '' ? 'select' : 'select-edit')}
          readOnly={!editPermission}
          options={[
            {label: 'Yes', value: 'Yes'},
            {label: 'No', value: 'No'}
          ]}
        />
        <td></td>
        <Field
          component={SetupTextField}
          label='Enter Warranty Period in Months'
          id='WarrantyPeriodMonths'
          name='WarrantyPeriodMonths'
          variant='outlined'
          size='small'
          onBlurCallback={handleBlurTextField}
          getDisabled={(_name, _value, values) => values.PerformancePaymentBondRequired === 'No' || values.WarrantyPeriodOneYearOrLess === 'Yes'}
          getClassname={(_name, _value, values) => (values.PerformancePaymentBondRequired === 'No' || values.WarrantyPeriodOneYearOrLess === 'Yes' ? 'textfield' : 'textfield-edit')}
          InputProps={{
            classes: {input: 'input-number-edit'},
            readOnly: !editPermission,
            inputComponent: NumberFormatCustom,
            inputProps: {decimalplaces: 0}
          }}
          autoComplete='off'
        />
      </tr>
    )
  }
  return null
}

const FormFragmentWithProjectAreaUnit = ({handleBlurTextField, editPermission}) => {
  const {values} = useFormikContext()

  if (values.ProjectAreaUnit !== 'N/A') {
    return (
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <Field
          component={SetupTextField}
          getLabel={(_name, _value, values) => `Project Quantity (${values.ProjectAreaUnit})`}
          id='ProjectUnitValue'
          name='ProjectUnitValue'
          variant='outlined'
          size='small'
          onBlurCallback={handleBlurTextField}
          className='textfield-edit'
          InputProps={{
            classes: {input: 'input-number-edit'},
            readOnly: !editPermission,
            inputComponent: NumberFormatCustom,
            inputProps: {decimalplaces: 0}
          }}
          autoComplete='off'
        />
      </tr>
    )
  }
  return null
}

export default SetupTab
