import BasicModalWrapper from './BasicModalWrapper'

export const AddResourceModalWrapper = ({children, modalKey, bodyClassName = '', ...restProps}) => {
  return (
    <BasicModalWrapper
      modalKey={modalKey}
      className=''
      dialogClassName='edit-sm-modal-second-modal'
      headerClassName='addresource-modal'
      bodyClassName={`addresource-modal ${bodyClassName}`}
      footerClassName='addresource-modal'
      {...restProps}
    >
      {children}
    </BasicModalWrapper>
  )
}

export const ModalLayoutI = ({children, modalKey, className = '!flex flex-fill', ...restProps}) => {
  return (
    <BasicModalWrapper
      modalKey={modalKey}
      dialogClassName='edit-lg-dialog-modal flex flex-fill'
      className={className}
      headerClassName='edit-lg-modal'
      bodyClassName='edit-lg-modal !flex'
      footerClassName='edit-lg-modal'
      {...restProps}
    >
      {children}
    </BasicModalWrapper>
  )
}

export const ModalLayoutMd = ({children, modalKey, bodyClassName, ...restProps}) => {
  return (
    <BasicModalWrapper
      modalKey={modalKey}
      dialogClassName='edit-md-dialog-modal'
      headerClassName='edit-md-modal'
      bodyClassName={`edit-lg-modal ${bodyClassName}`}
      footerClassName='edit-md-modal'
      {...restProps}
    >
      {children}
    </BasicModalWrapper>
  )
}

export const ModalLayoutMdLg = ({children, modalKey, bodyClassName, ...restProps}) => {
  return (
    <BasicModalWrapper
      modalKey={modalKey}
      dialogClassName='edit-mdlg-dialog-modal'
      headerClassName='edit-mdlg-modal'
      bodyClassName={`edit-mdlg-modal ${bodyClassName}`}
      footerClassName='edit-mdlg-modal'
      {...restProps}
    >
      {children}
    </BasicModalWrapper>
  )
}
