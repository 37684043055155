import {useSelector, useDispatch} from 'react-redux'
import * as Precon from '../../../store/ducks/precon.duck'
import * as Yup from 'yup'
import * as wjCore from '@grapecity/wijmo'
import * as wjcgrid from '@grapecity/wijmo.grid'
import * as wjFlexGrid from '@grapecity/wijmo.react.grid'
import {Menu} from '@grapecity/wijmo.input'
import {ImmutabilityProvider} from '@grapecity/wijmo.react.grid.immutable'
import AppForm from '../../../components/Form/AppForm'
// import BasicModalWrapper, {FooterLayout} from '../../../components/Modals/BasicModalWrapper'
import {Field, useFormikContext} from 'formik'
import AppSelect from '../../../components/Form/AppSelect'
import {ALTERNATE_STATUSES} from '../BidAlternates'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Grid from '@mui/material/Grid'
import {useAppModal} from '../../../context/GlobalModalContext'
import AppFormField from '../../../components/Form/AppFormField'
import ColFormField from '../../../components/Form/ColFormField'
import {NumberFormatCustom} from '../../../components/NumberFormatCustom'

export const EditAlternateModalKey = '@Modal_EditAlternate'

const validationSchema = Yup.object().shape({
  description: Yup.string().required('Description is required')
})

const EditAlternateFormId = 'edit-alternate-form'

const EditAlternateModal = ({initialValues, onSubmitForm, onDeleteAlternate, onSubmitRecalc}) => {
  const dispatch = useDispatch()
  const alternateItemsGrid = useSelector((state) => state.precon.Alternate.items)
  // const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)

  function formatItem(s, e) {
    if (e.panel.cellType === wjcgrid.CellType.ColumnHeader) {
      let col = s.columns[e.col]
      if (col.binding === 'trade' || col.binding === 'comments') {
        wjCore.addClass(e.cell, 'column-header-left')
      } else {
        wjCore.addClass(e.cell, 'column-header-center')
      }
    }
    if (e.panel.cellType === wjcgrid.CellType.Cell) {
      let item = e.panel.rows[e.row].dataItem
      // Colour the totals and subtotals rows
      if (item && item.trade === 'Total Quoted') {
        wjCore.addClass(e.cell, 'precalc-total-row-highlight')
      } else if (item.trade === 'Trade Total' || item.trade === 'Subtotal Quoted') {
        wjCore.addClass(e.cell, 'precalc-subtotal-row-highlight')
      }
    }
  }

  const calcAlternateTotal = (grid, newItem) => {
    // merge the new row into the grid
    let temp = grid.map((row) => {
      // console.log(
      //   `row.id: ${row.id}, row.trade: ${row.trade}, newItem.id: ${newItem.id}, newItem.trade: ${newItem.trade}, itemCost: ${row.note === 'Excluded' ? 0 : row.budget} (row.note: ${
      //     row.note
      //   }, row.budget: ${row.budget})`
      // )
      return row.id === newItem.id && row.trade === newItem.trade ? {...newItem, note: newItem.note, itemCost: newItem.note === 'Excluded' ? 0 : row.budget} : row
    })
    // calculate the Total
    let total = temp.filter((r) => r.id === 0 && r.trade !== 'Trade Total' && r.trade !== 'Total Quoted').reduce((acc, row) => acc + row.itemCost, 0)
    dispatch(Precon.actions.setAlternateItems(temp.map((row) => (row.trade === 'Total Quoted' ? {...row, itemCost: total} : row))))
  }

  const buildMenu = (s) => {
    let menu = new Menu(document.createElement('div'), {
      owner: s.hostElement,
      displayMemberPath: 'header',
      subItemsPath: 'items',
      commandParameterPath: 'cmd',
      dropDownCssClass: 'ctx-menu',
      closeOnLeave: true,
      itemsSource: [
        {header: 'Include', cmd: 'cmdIncludeIndirectCost'},
        {header: 'Exclude', cmd: 'cmdExcludeIndirectCost'}
      ],
      command: {
        // enable/disable commands
        canExecuteCommand: (cmd) => {
          let dataItem = s.rows[s.selection.row].dataItem
          if (dataItem.note === 'Included' && cmd === 'cmdIncludeIndirectCost') {
            return false
          } else if (dataItem.note === 'Excluded' && cmd === 'cmdIncludeIndirectCost') {
            return false
          }
          return true
        },
        // execute menu commands
        executeCommand: (cmd) => {
          let dataItem = s.rows[s.selection.row].dataItem
          if (dataItem && dataItem.alternateID > 0) {
            switch (cmd) {
              case 'cmdIncludeIndirectCost':
                dispatch(Precon.actions.putAlternateIndirect([{...dataItem, status: 'Included'}]))
                calcAlternateTotal(s.collectionView.items, {...dataItem, note: 'Included'})
                break
              case 'cmdExcludeIndirectCost':
                dispatch(Precon.actions.putAlternateIndirect([{...dataItem, status: 'Excluded'}]))
                calcAlternateTotal(s.collectionView.items, {...dataItem, note: 'Excluded'})
                break
              default:
            }
          }
          // restore focus to active grid cell (TFS 439964)
          s.refresh()
          let sel = s.selection,
            cell = s.cells.getCellElement(sel.row, sel.col)
          if (cell) {
            cell.focus()
          }
        }
      }
    })
    // done
    return menu
  }

  function onGridInitialized(s) {
    // Add context menu
    let host = s.hostElement,
      menu = buildMenu(s)
    host.addEventListener(
      'contextmenu',
      (e) => {
        // select the cell/column that was clicked
        let sel = s.selection,
          ht = s.hitTest(e),
          row = ht.getRow()
        switch (ht.panel) {
          case s.cells:
            s.select(ht.row, ht.col)
            break
          case s.columnHeaders:
            s.select(sel.row, ht.col)
            break
          case s.rowHeaders:
            s.select(ht.row, sel.col)
            break
          default:
            return // invalid panel
        }
        e.preventDefault() // cancel the browser's default menu
        // show the menu for the current column
        if (s.selection.col > -1 && row.dataItem) {
          menu.show(e) // and show ours
        }
      },
      true
    )
    console.log('Grid initialised')
  }

  return (
    // <BasicModalWrapper
    //   modalKey={EditAlternateModalKey}
    //   dialogClassName='edit-lg-dialog-modal'
    //   headerClassName='edit-lg-modal'
    //   title={`Edit ${type}`}
    //   bodyClassName='edit-lg-modal'
    //   footerLayout={FooterLayout.None}
    // >
    <AppForm
      formId={EditAlternateFormId}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
      formProps={{
        noValidate: true,
        autoComplete: 'off'
      }}
    >
      <Grid container spacing={3}>
        <Grid item md={4}>
          <Row>
            <Col md='12'>
              <Field component={AppFormField} formAs='textarea' id='edit-alternate-description' type='text' name='description' label='Description' autoComplete='new-password' rows={2} />
            </Col>
          </Row>
          <Row className='mt-8'>
            <Col md='12'>
              <Field component={AppFormField} formAs='textarea' id='edit-alternate-comments' type='text' name='comments' label='Comments' autoComplete='new-password' rows={2} />
            </Col>
          </Row>
          <Row className='mt-8'>
            <Col md='6'>
              <Field
                component={AppSelect}
                label='Status'
                id='edit-alternate-status'
                name='status'
                options={ALTERNATE_STATUSES}
                variant='outlined'
                size='small'
                className='select bg-white-primary w-[50px] mt-0.5'
                labelProps={{className: 'form-label'}}
              />
            </Col>
            <Col md='6'>
              <Field
                component={ColFormField}
                id='breakout-area-summary-project-unit-value'
                name='quotedPC'
                label='Quoted %'
                variant='outlined'
                className='mt-2'
                size='small'
                normal={1}
                yellow={1}
                InputProps={{
                  readOnly: false,
                  inputComponent: NumberFormatCustom,
                  inputProps: {decimalplaces: 0}
                }}
                autoComplete='off'
              />
            </Col>
          </Row>
        </Grid>
        {console.log(initialValues.alternateID)}
        <Grid item md={8}>
          <Row>
            <Col md='12'>
              <div className='mb-2'>Trade Costs</div>
              <wjFlexGrid.FlexGrid
                className='mt-0'
                id='bidalternates-grid'
                alternatingRowStep={0}
                headersVisibility='Column'
                allowSorting={false}
                allowDelete={false}
                allowAddNew={false}
                autoRowHeights={true}
                formatItem={formatItem}
                initialized={onGridInitialized}
              >
                <ImmutabilityProvider itemsSource={alternateItemsGrid} />
                <wjFlexGrid.FlexGridColumn binding='trade' header='Trade' width='*' cssClass='cell-monospace-left' wordWrap={true} isReadOnly={true} />
                <wjFlexGrid.FlexGridColumn binding='itemCost' header='Total' format='n0' width={120} isReadOnly={true} cssClass='cell-monospace-right' isRequired={false} />
                <wjFlexGrid.FlexGridColumn binding='note' header='Comments' width='*' cssClassAll='header-left' wordWrap={true} isReadOnly={true} cssClass='cell-monospace-left' />
              </wjFlexGrid.FlexGrid>
            </Col>
          </Row>
        </Grid>
      </Grid>
      <FooterWithinForm formId={EditAlternateFormId} onDelete={onDeleteAlternate} onSubmitRecalc={onSubmitRecalc} alternateId={initialValues?.alternateID} />
    </AppForm>
    // </BasicModalWrapper>
  )
}

const FooterWithinForm = ({formId, onDelete, onSubmitRecalc, alternateId}) => {
  const {closeModal} = useAppModal()
  const {values} = useFormikContext()

  const handleClickDelete = () => {
    onDelete(values)
    closeModal()
    return null
  }
  return (
    <Modal.Footer className='edit-lg-modal footer-within-form'>
      <Grid container justifyContent='space-between'>
        <Grid item>
          {alternateId ? (
            <Button variant='warning' onClick={handleClickDelete}>
              Delete
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          {alternateId ? (
            <Button variant='success' onClick={() => onSubmitRecalc(values)}>
              Recalculate
            </Button>
          ) : null}
          <Button variant='secondary' onClick={closeModal} className='ml-[10px]'>
            Cancel
          </Button>
          <Button type='submit' form={formId} variant='primary' className='ml-[10px]'>
            Save
          </Button>
        </Grid>
      </Grid>
    </Modal.Footer>
  )
}

export default EditAlternateModal
