import FieldLabel from '../FieldLabel'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import isDate from 'date-fns/isDate'
import {useCallback, useRef} from 'react'

const SetupDateField = (props) => {
  const {
    label,
    id,
    field: {name, onChange, value},
    form: {errors, values, setFieldError},
    hideField,
    getLabelSuffix,
    getLabelDisabled,
    getSlotProps,
    onChangeCallback,
    ...inputProps
  } = props

  const errorClassname = errors[name] ? 'setup-error-field-cell' : ''

  const shouldHideField = hideField ? hideField(name, value) : undefined

  const labelSuffix = getLabelSuffix ? getLabelSuffix(name, value, values) : undefined

  const labelDisabled = getLabelDisabled ? getLabelDisabled(name, value, values) : undefined

  const slotProps = getSlotProps ? getSlotProps(name, value, values) : inputProps.slotProps

  // Keep previous value in a ref
  const prevValue = useRef(value)
  // This method is called on confirming ConfirmDateChangeModal
  const updateDateChangeLocally = useCallback(
    (newVal) => {
      prevValue.current = value
      onChange(name)(newVal.toString())
    },
    [name, onChange, value]
  )

  const handleChange = useCallback(
    (val) => {
      const propertyValue = val?.toDate()
      // Initial valid date check
      if (!propertyValue || !isDate(propertyValue) || propertyValue.toString() === 'Invalid Date') {
        // not a date object, or date is invalid
        console.log(`isDateResult: ${false} for ${name} : ${val}`)
        setFieldError(name, 'Not a valid date')
        return
      }
      setFieldError(name, undefined)
      // Pass the date change relating to related date fields
      onChangeCallback(name, updateDateChangeLocally, setFieldError, propertyValue, prevValue.current, values)
    },
    [name, onChangeCallback, setFieldError, updateDateChangeLocally, values]
  )

  return (
    <>
      <td className={`border-t-0 ${errorClassname}`}>
        <FieldLabel field={label} suffix={labelSuffix} disabled={labelDisabled} />
        {errors[name] && <span className='field-error'>{errors[name]}</span>}
      </td>
      <td className={`border-t-0 ${errorClassname}`}>
        {!shouldHideField ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker id={id} name={name} disabled={labelDisabled} onChange={handleChange} value={value ? dayjs(value) : undefined} error={errors[name]} slotProps={slotProps} {...inputProps} />
          </LocalizationProvider>
        ) : (
          ''
        )}
      </td>
    </>
  )
}

export default SetupDateField
