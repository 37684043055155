import React from 'react'
import PropTypes from 'prop-types'
import {NumberFormatBase, useNumericFormat} from 'react-number-format'
import {parseNumber} from '@brightspace-ui/intl/lib/number.js'

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  // specify additional numeric format options
  const _props = {
    ...props,
    thousandSeparator: true,
    decimalScale: props.decimalplaces,
    valueIsNumericString: false
  }

  // call hook to use the NumericFormat logic
  const {format, removeFormatting, onChange, name, ...rest} = useNumericFormat(_props)

  // format function to remove the negative sign, and add parenthesis to the formatted value (this will already include thousands separator etc.)
  const _format = (val) => {
    const _val = format(val)
    return props.value >= 0 ? _val : val ? `(${_val.replace('-', '')})` : '0'
  }

  const _removeFormatting = (val) => {
    // parsing the number is causing issues, we don't really need this as we modify the value based on parsing the formatted value during onChange
    // const _val = parseNumber(`${val.replace('(','').replace(')','')}`)
    return removeFormatting(val)
  }

  return (
    <NumberFormatBase
      format={_format}
      removeFormatting={_removeFormatting}
      {...rest}
      name={name}
      getInputRef={ref}
      onValueChange={(values) => {
        // handle negative numbers in parentheses (n)
        let newval = values.floatValue && values.formattedValue !== '' ? parseNumber(values.formattedValue) : 0
        onChange({
          target: {
            name: name,
            value: newval, // this is our local parsed number. The built-in parsing will not work with negative numbers with parentheses
            floatValue: values.floatValue
          }
        })
      }}
    />
  )
})

NumberFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired
}
