import WarningIcon from '@mui/icons-material/Warning'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Button from 'react-bootstrap/Button'
import {Link} from 'react-router-dom'

const ProjectStatus = ({hasErrors, hasBuildersRiskQuoteApprovalError, onClickCompleteSetup}) => {
  if (hasErrors) {
    return (
      <tr className='setup-action-bar'>
        <td colSpan={4} className='setup-action-bar-cell'>
          <>
            <WarningIcon className='inline-block -mt-1 mr-[6px]' />
            {`Please complete all required fields ${hasBuildersRiskQuoteApprovalError ? 'and a Builders Risk quote ' : ''}to continue.`}
          </>
        </td>
        <td>
          <Button size='sm' className='mt-1' onClick={onClickCompleteSetup}>
            Complete Setup
          </Button>
        </td>
      </tr>
    )
  }
  return (
    <tr className='setup-action-bar'>
      <td colSpan={3} className='setup-action-bar-cell'>
        <CheckBoxIcon className='inline-block -mt-1 mr-[6px] text-green-400' />
        Project Setup is complete.
      </td>
      <td className='text-right'>
        <Link to='/summary'>
          <Button size='sm' className='mt-1'>
            GCs and Fee &gt;
          </Button>
        </Link>
      </td>
      <td>
        <Link to='/tradepricingsum'>
          <Button size='sm' className='mt-1'>
            Trade Pricing &gt;
          </Button>
        </Link>
      </td>
    </tr>
  )
}

export default ProjectStatus
