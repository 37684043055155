import {useEffect, useState, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {actions} from '../../../store/ducks/project.duck'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProjectConfigurationOptions from '../../../components/ProjectConfigurationOptions'

const mapContingencyNamesToProperty = {
  incProjectContingency: {
    property: 'ProjectContingencyFeeExclusions',
    label: 'Project Contingency'
  },
  incDesignContingency: {
    property: 'DesignContingencyFeeExclusions',
    label: 'Design Contingency'
  },
  incEscalationContingency: {
    property: 'EscalationContingencyFeeExclusions',
    label: 'Escalation Contingency'
  }
}

const FeeExclusions = () => {
  const dispatch = useDispatch()
  const projectID = useSelector((state) => state.project.projectID)
  const indirectCostInclusions = useSelector((state) => state.project.ProjectConfiguration.values['TradePricingIndirectCostInclusions'])
  const defaultIndirectCostOptions = useSelector((state) => state.reference.ProjectConfigurationOptions['TradePricingIndirectCostInclusions'])

  const [optionsSelected, setOptionsSelected] = useState([])

  const renderColumns = useCallback(() => {
    return (
      optionsSelected &&
      optionsSelected
        .filter((option) => option.value)
        .map((option) => {
          const propertyName = mapContingencyNamesToProperty[option.name].property
          const heading = `Exclude from ${mapContingencyNamesToProperty[option.name].label}`
          return (
            <Col key={option.name}>
              <div className='container-fluid summary-div pl-[15px]'>
                <ProjectConfigurationOptions propertyName={propertyName} heading={heading} />
              </div>
            </Col>
          )
        })
    )
  }, [optionsSelected])

  useEffect(() => {
    dispatch(actions.fetchProjectConfiguration({projectID: projectID}))
  }, [dispatch, projectID])

  useEffect(() => {
    // parse json array from project property, and clean up values from string to bool
    const temp = indirectCostInclusions
      ? JSON.parse(indirectCostInclusions).map((opt) => {
          return {...opt, value: opt.value === true || opt.value === 'true'}
        })
      : []
    // set local state with project values merged into default array
    setOptionsSelected(defaultIndirectCostOptions.map((row) => (temp.find((pobj) => pobj.name === row.name) ? {...row, value: temp.find((pobj) => pobj.name === row.name).value} : row)))
  }, [indirectCostInclusions, defaultIndirectCostOptions])

  return (
    <Row>
      <Col>
        <div className='container-fluid summary-div pl-[15px]'>
          <ProjectConfigurationOptions propertyName='CalculateFeeExclusions' heading='Exclude from Quoted Fee:' />
        </div>
        <div className='container-fluid summary-div pl-[15px]'>
          <ProjectConfigurationOptions propertyName='BidTotalsMiscOptions' heading='Bid Totals Options:' />
        </div>
      </Col>
      {renderColumns()}
    </Row>
  )
}

export default FeeExclusions
