export const getPercentage = (numerator, denominator) => {
  if (denominator === 0) return 0
  return (numerator / denominator) * 100
}

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function numberWithCommas(x) {
  const result = x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (!result || result === 'NaN') {
    return ''
  }

  return result
}

export const looseConvertNumeric = (n) => {
  // strip out dollars and commas
  const stripped = n.replace(/[$,]/g, '')
  if (isNumeric(stripped)) {
    return stripped
  }
  return false
}
