import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import styled from '@emotion/styled'

export const CustomFormControl = styled(FormControl)(({theme}) => ({
  marginLeft: theme.spacing(2),
  marginTop: 0
}))

export const CustomCheckbox = styled(Checkbox)`
  margin-right: 12px;
`
