import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import styled from '@emotion/styled'

export const CustomTextField = styled(TextField)`
  flex-basis: 200px;
  width: 100%;
  background-color: white;
`

export const CustomFormControl = styled(FormControl)`
  margin: 0;
  min-width: 120px;
`
export const CustomInputLabel = styled(InputLabel)`
  background-color: white;
  padding-left: 3px;
  padding-right: 3px;
`
