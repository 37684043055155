import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import * as Project from '../store/ducks/project.duck'

export const RecentActivities = ({report}) => {
  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <Row className='w-full mr-0'>
            <Col md='7' className='pl-0 pr-0'>
              <h3 className='kt-portlet__head-title text-gray-600 mt-2'>Recently Added</h3>
            </Col>
            <Col md='5' className='text-right pl-0 pr-0'>
              <Link to='/SearchnSynchCRM'>
                <Button size='sm' onClick={null}>
                  Search CRM
                </Button>
              </Link>
            </Col>
          </Row>
          {/* <PortletHeaderDropdown /> */}
        </div>

        {/* begin::Body */}
        <div className='card-body pt-5 overflow-auto added-list'>
          {/* begin::Timeline */}
          <div className='timeline-label overflow-auto'>
            {report.map((row, index) => {
              return (
                <div className='timeline-item' key={index}>
                  {/* begin::Label */}
                  <div className='timeline-label fw-bold text-gray-800 fs-6' style={{paddingRight: 4}}>
                    {row.InsertedOn}
                  </div>
                  {/* end::Label */}
                  {/* begin::Badge */}
                  <div className='timeline-badge'>
                    <i className='bi fs-3 bi-record-circle-fill' style={{color: 'rgb(255, 158, 27)'}}></i>
                    {/* <i className='fa fa-genderless text-warning fs-1'></i> */}
                  </div>
                  {/* end::Badge */}
                  {/* begin::Text */}
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    {row.ProjectName}
                    <br />
                    {row.CRM_State} / {row.CRM_PrimaryBusinessUnit}
                  </div>
                  {/* end::Text */}
                </div>
              )
            })}
          </div>
          {/* end::Timeline */}
        </div>
        {/* end: Card Body */}
      </div>
    </>
  )
}

const RecentActivitiesContainer = () => {
  const dispatch = useDispatch()
  const assumedUserID = useSelector((state) => state.auth.assumedUserID)
  const report = useSelector((state) => state.project.reports.recentActivities)
  useEffect(() => {
    dispatch(Project.actions.fetchReportDashboardRecentActivities({assumedUserID: assumedUserID}))
  }, [dispatch, assumedUserID])

  useEffect(() => {
    function handleResize() {
      const topPanel = document.querySelector('.dashboard-top')
      const totalHeight = document.documentElement.clientHeight
      const topPanelHeight = topPanel.offsetHeight
      const realHeight = totalHeight - topPanelHeight - 70 - 25 - 70
      const listPanel = document.querySelector('.added-list')
      listPanel.style.height = `${realHeight}px`
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return <RecentActivities report={report} />
}

export default RecentActivitiesContainer
