/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import HelpViewer from '../../../../app/components/HelpViewer'

const DrawerMessenger: FC = () => (
  <div
    id='kt_drawer_chat'
    className='bg-body drawer drawer-end'
    data-kt-drawer='true'
    data-kt-drawer-name='chat'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'md': '500px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_drawer_chat_toggle'
    data-kt-drawer-close='#kt_drawer_chat_close'
  >
    <div className='card w-100 rounded-0 bg-yellow-light' id='kt_drawer_chat_messenger'>
      <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
        <div className='card-title'>
          <div className='d-flex justify-content-center flex-column me-3'>
            <a href='#' className='fs-3 text-gray-600 text-hover-primary me-1 mb-2 lh-1 mt-2'>
              Help
            </a>
          </div>
        </div>

        <div className='card-toolbar'>
          <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
            <i className='bi fs-1 bi-x' style={{ WebkitTextStroke: 0.5 }}></i>
          </div>
        </div>
      </div>

      <div className='p-5'>
        <HelpViewer />
      </div>
    </div>
  </div>
)

export {DrawerMessenger}
