import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Reference from '../../../store/ducks/reference.duck'
import * as Precon from '../../../store/ducks/precon.duck'
import * as wjcgrid from '@grapecity/wijmo.grid'
import * as wjFlexGrid from '@grapecity/wijmo.react.grid'
import {ImmutabilityProvider} from '@grapecity/wijmo.react.grid.immutable'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from 'react-bootstrap/Button'

export default function TradeScopeTemplates({closeModal}) {
  const dispatch = useDispatch()
  const bidTradeTemplates = useSelector((state) => state.reference.BidTradeTemplates)
  const bidTradeTemplate = useSelector((state) => state.reference.BidTradeTemplate)

  useEffect(() => {
    dispatch(Reference.actions.fetchBidTradeTemplates())
  }, [dispatch])

  const [id, setID] = useState(1)

  useEffect(() => {
    if (id !== bidTradeTemplate?.id) {
      dispatch(Reference.actions.fetchBidTradeTemplate(id))
    }
  }, [dispatch, id, bidTradeTemplate?.id])

  const gridRef = useRef()

  function changeTemplate(id) {
    setID(id)
    dispatch(Reference.actions.fetchBidTradeTemplate(id))
  }

  function copyTemplateToBid(action) {
    closeModal()
    dispatch(Precon.actions.copyBidTradeTemplateToBid({bidTradeTemplateID: id, action: action}))
  }

  function onGridInitialized(s) {
    s.columnHeaders.rows.cssClassAll = 'group-row'
    console.log('Grid initialised')
  }

  function onloadedRows(grid) {
    for (let i = 0; i < grid.rows.length; i++) {
      let row = grid.rows[i]
      let item = row.dataItem
      // Trade specific items header row
      if (item && item.isHeader) {
        row.cssClass = 'bidtradeitemtemplates-grid-header'
      }
      // Trade specific items row
      if (item && !item.isHeader) {
        row.cssClass = 'bidtradeitemtemplates-grid-item'
      }
    }
  }

  return (
    <div className='container-fluid'>
      <Grid container justifyContent='space-between' className='mb-3'>
        <Grid item md={6}>
          <FormControl variant='outlined' size='small'>
            <Select
              id='templateSelector'
              aria-label='Select Template'
              name='templateID'
              value={id && bidTradeTemplates.some((template) => template.id === id) ? id : ''}
              onChange={(event) => changeTemplate(parseInt(event.target.value))}
              className='bg-white-primary min-w-[230px]'
              placeholder='Select a tempalte'
            >
              {bidTradeTemplates.map((template) => (
                <MenuItem key={`key=${template.id}`} value={template.id}>
                  {template.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid md={6} item className='button-right'>
          <Button variant='primary' size='sm' className='ml-2' onClick={() => copyTemplateToBid('replace')}>
            Copy &amp; Replace
          </Button>
          <Button variant='primary' size='sm' className='ml-2' onClick={() => copyTemplateToBid('append')}>
            Copy &amp; Append
          </Button>
        </Grid>
      </Grid>

      <div className='mb-[30px]'>
        {/* cellEditEnded={(s, e) => s.autoSizeColumn(e.col)}  */}
        <wjFlexGrid.FlexGrid
          id='bidtradeitemtemplates-grid'
          ref={gridRef}
          initialized={onGridInitialized}
          loadedRows={onloadedRows}
          frozenColumns={5}
          alternatingRowStep={0}
          allowSorting={false}
          allowResizing='Columns'
          keyActionTab={wjcgrid.KeyAction.Cycle}
          allowDelete={false}
          allowAddNew={false}
          autoRowHeights={true}
        >
          <ImmutabilityProvider itemsSource={bidTradeTemplate?.items ? bidTradeTemplate?.items : []} />
          <wjFlexGrid.FlexGridColumn binding='id' header='ID' width={20} isReadOnly={true} visible={false} />
          <wjFlexGrid.FlexGridColumn binding='itemIndex' header='ItemOrder' width={20} isReadOnly={true} visible={false} />
          <wjFlexGrid.FlexGridColumn binding='label' header='Item' width='*' wordWrap={true} isReadOnly={true} cssClassAll='header-left' />
          <wjFlexGrid.FlexGridColumn binding='desiredScope' header='Desired Scope' width={170} isReadOnly={false} cssClassAll='header-left' />
          <wjFlexGrid.FlexGridColumn binding='excludeFromCalculationFlag' header='Exclude from Adjustments' format='t' width={170} isReadOnly={false} />
        </wjFlexGrid.FlexGrid>
      </div>
    </div>
  )
}
